import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    imageVariationData: null,
    isLoading: false,
    isError: false,
    error: ''
};

const imageVariationSlice = createSlice({
    name: 'imageVariation',
    initialState: initialState,
    reducers: {
        imageVariation: (state, action) => {
            state.isLoading = true;
        },
        imageVariationSuccess: (state, action) => {
            state.imageVariationData = action?.payload;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        imageVariationFailed: (state, action) => {
            state.imageVariationData = null;
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },
        imageVariationStateClear: (state, action) => {
            state.imageVariationData = null;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        }
    }
});

export const {
    imageVariation,
    imageVariationSuccess,
    imageVariationFailed,
    imageVariationStateClear,
} = imageVariationSlice.actions;

export default imageVariationSlice.reducer;