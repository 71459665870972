import {Avatar, Box, Stack, Typography, styled} from '@mui/material';

import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import React, {useEffect, useRef} from 'react';
import {TypeAnimation} from "react-type-animation";

const QuestionBox = styled(Box)(() => ({
  backgroundColor: 'transparent'
}));

const QuestionContent = ({message}) => {
    const {content} = message || {}



  return (
      <QuestionBox p={3}>
        <Stack
            direction={'row'}
            spacing={2}
            alignItems={'flex-start'}
            sx={{
              width: {
                xs: '300px',
                sm: '500px',
                md: '700px',
                lg: '800px',
                xxl: '900px',
                margin: '0 auto'
              }
            }}
        >
          <Avatar sx={{mt: -1}}>
            <QuestionMarkIcon />
          </Avatar>
          <Typography variant={'body1'} color={'common.black'}>

              {content}

              {/*<TypeAnimation*/}
              {/*    sequence={[*/}
              {/*        content,*/}
              {/*        1000,*/}
              {/*    ]}*/}
              {/*    speed={90} // Custom Speed from 1-99 - Default Speed: 40*/}
              {/*    wrapper="span" // Animation will be rendered as a <span>*/}
              {/*    repeat={0} // Repeat this Animation Sequence infinitely*/}
              {/*    cursor={false}*/}
              {/*/>*/}
          </Typography>
        </Stack>
      </QuestionBox>
  );
};

export default QuestionContent;
