import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import hasAuthenticatedUser from "../../helpers/utility/hasAuthenticateUser";
import {useDispatch, useSelector} from "react-redux";
import {selectAccountSettingState} from "../../state/features/accountSetting/accountSettingSelector";
import {getAccountSettingData} from "../../state/features/accountSetting/accountSettingSlice";
import {getUserHasPackageData} from "../../state/features/userPackage/userPackageSlice";
import routeLink from "../../helpers/constant/routeLink";

const PrivateLayout = ({ children }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {accountSetting} = useSelector(selectAccountSettingState);
    const {
        userPackage,
        hasPackage,
        isPackageExpire,
        isSuccess: isSuccessHasPackage,
    } = useSelector((state) => state.userPackage);

    // check if user authenticated or not
    useEffect(() => {
        if (!accountSetting) {
            dispatch(getAccountSettingData());
        }
        if (!hasAuthenticatedUser()) {
            navigate("/login");
        }
    }, []);

    // User Package data check when Package data has not and profile data has present
    useEffect(() => {
        if (!userPackage && accountSetting) {
            dispatch(getUserHasPackageData());
        }
    }, [accountSetting]);

    // check user has package
    useEffect(() => {
        if (!hasPackage) {
            navigate('/client/package/pricing');
        } else if (isSuccessHasPackage && isPackageExpire) {
            navigate("/client/package/renew/" + userPackage?.package_id);
        }
    }, [hasPackage, isPackageExpire, isSuccessHasPackage]);

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    );
};

export default PrivateLayout;
