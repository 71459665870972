import '../../assets/global/css/MaxText.css';

import {Box, Button, Card, CardContent, Stack, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {
    clearPromptCollectionState,
    getPromptCollection
} from '../../state/features/promptCollection/promptCollectionSlice';
import {useDispatch, useSelector} from 'react-redux';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CardHeader from '@mui/material/CardHeader';
import CollectionResponseDetails from './CollectionResponseDetails';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EmptyPage from '../../assets/global/EmptyComponent/EmptyPage';
import FullPageDrawer from '../../assets/global/Drawer/FullPageDrawer';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '../../assets/global/Button/LoadingButton';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Skeleton from "@mui/material/Skeleton";
import noDataImage from '../../assets/images/no_data.jpg';
import {selectPromptCollectionState} from '../../state/features/promptCollection/promptCollectionSelector';
import {showSuccessToaster} from '../../helpers/utility/toaster';
import {styled} from '@mui/system';

const StyledCardHeader = styled(CardHeader)(({theme}) => ({
    '.MuiCardHeader-title': {
        fontSize: '18px',
        fontWeight: '600'
    }
}));

const CollectionDetailsOld = ({collectionData}) => {
    const dispatch = useDispatch();
    const [viewDetailsPageShow, setViewDetailsPageShow] = useState(false);
    const [collectionResponseObj, setCollectionResponseObj] = useState(null);
    const {page, perPage, total, promptCollections, isLoading} = useSelector(
        selectPromptCollectionState
    );
    const pageCount = Math.ceil(total / perPage);
    const toggleViewDetailsPage = () => setViewDetailsPageShow(prevState => !prevState);

    useEffect(() => {
        dispatch(
            getPromptCollection({collection_id: collectionData?.id, page: page, per_page: perPage})
        );
    }, [dispatch, collectionData?.id]);

    // ## Clear  State When Leave the page
    useEffect(() => {
        return () => dispatch(clearPromptCollectionState());
    }, []);

    const handleChange = (event, value) => {
        dispatch(
            getPromptCollection({collection_id: collectionData?.id, page: value, per_page: perPage})
        );
    };

    const handleViewDetailsPage = data => {
        setCollectionResponseObj(data);
        toggleViewDetailsPage();
    };

    const handleCopy = data => {
        navigator.clipboard.writeText(data);
        showSuccessToaster('Text copied');
    };

    // decide to what to render on view
    let content = null;

    if (isLoading) {
        content = (
            <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}} sx={{p: 3}}>
                {[1, 2, 3, 4].map(collection => (
                    <Grid key={collection.id} item xs={3}>
                        <Box sx={{p: 3, marginRight: 0.5}}>
                            <Skeleton variant="rectangular" height={118}/>
                            <Skeleton/>
                            <Skeleton width="60%"/>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        );
    } else if (!isLoading && promptCollections.length > 0) {
        content = (
            <>
                <Grid container spacing={2}>
                    {promptCollections.map(promptCollection => (
                        <Grid item xs={3} key={promptCollection?.id}>
                            <Card sx={{height: 'auto !important'}}>
                                <StyledCardHeader
                                    className="max-1line-text"
                                    sx={{marginRight: '5px', borderBottom: '1px solid #dfe4ea'}}
                                    action={
                                        <Box>
                                            <IconButton
                                                aria-label="settings"
                                                onClick={() => handleCopy(promptCollection?.response)}
                                            >
                                                <ContentCopyIcon/>
                                            </IconButton>
                                        </Box>
                                    }
                                    title={
                                        promptCollection?.text.length > 35
                                            ? promptCollection?.text.slice(0, 35) + '...'
                                            : promptCollection?.text
                                    }
                                />
                                <CardContent>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        className="max-3line-text"
                                        sx={{
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            color: 'rgb(51, 51, 51)',
                                            lineHeight: '1.56'
                                        }}
                                    >
                                        {promptCollection?.response}
                                    </Typography>
                                    <Stack
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                        alignSelf="flex-end"
                                        spacing={2}
                                        mt={2}
                                        sx={{marginLeft: '15px', alignSelf: 'flex-end'}}
                                    >
                                        <LoadingButton
                                            onClick={() => handleViewDetailsPage(promptCollection)}
                                            isLoading={false}
                                            loadingText={'Generating'}
                                            type={'submit'}
                                            size={'small'}
                                            color={'primary'}
                                        >
                                            Show More...
                                        </LoadingButton>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                <Stack direction="row" justifyContent="flex-end" sx={{p: 1}}>
                    <Pagination
                        onChange={handleChange}
                        count={pageCount}
                        page={page}
                        sx={{
                            bottom: '5%',
                            position: 'absolute',
                            right: '5%'
                        }}
                        renderItem={collection => (
                            <PaginationItem
                                slots={{previous: ArrowBackIcon, next: ArrowForwardIcon}}
                                {...collection}
                            />
                        )}
                    />
                </Stack>
            </>
        );
    } else if (!isLoading && promptCollections.length === 0) {
        content = (
            <>
                <EmptyPage title="No information was discovered." img={noDataImage}/>
            </>
        );
    }

    return (
        <Box sx={{p: 3, minHeight: '70vh'}}>
            {content}

            <FullPageDrawer
                title=""
                open={viewDetailsPageShow}
                toggleDrawer={toggleViewDetailsPage}
                width="50%"
                height="70%"
            >
                <CollectionResponseDetails collectionResponseObj={collectionResponseObj}/>
            </FullPageDrawer>
        </Box>
    );
};
export default CollectionDetailsOld;
