import {all, put} from 'redux-saga/effects';
import {call, takeEvery} from '@redux-saga/core/effects';
import {
    createImageCollectionFailed,
    createImageCollectionSuccess,
    createPromptCollectionFailed,
    createPromptCollectionSuccess,
    getPromptCollectionFailed,
    getPromptCollectionSuccess
} from "./promptCollectionSlice";
import PromptCollectionService from "../../../services/PromptCollection.service";
import {showErrorToaster, showSuccessToaster} from "../../../helpers/utility/toaster";

function* promptCollectionWatcher() {
    yield takeEvery('promptCollection/getPromptCollection', getPromptCollectionSaga);
    yield takeEvery('promptCollection/createPromptCollection', createPromptCollectionSaga);
    yield takeEvery('promptCollection/createImageCollection', createImageCollectionSaga);
}

function* getPromptCollectionSaga(action) {
    try {
        const response = yield call(PromptCollectionService.getPromptCollection, action.payload);

        if (response.success) {
            yield put(getPromptCollectionSuccess(response.data));
        } else {
            yield put(getPromptCollectionFailed(response.message));
        }
    } catch (err) {
        console.log('Error: ', err);
        yield put(getPromptCollectionFailed(err.message));
    }
}

function* createPromptCollectionSaga(action) {
    try {
        const response = yield call(PromptCollectionService.createPromptCollection, action.payload);

        if (response.success) {
            yield put(createPromptCollectionSuccess(response.data));
            showSuccessToaster(response.message)
        } else {
            yield put(createPromptCollectionFailed(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log('Error: ', err);
        yield put(createPromptCollectionFailed(err.message));
    }
}

function* createImageCollectionSaga(action) {
    try {
        const response = yield call(PromptCollectionService.createImageCollection, action.payload);

        if (response.success) {
            yield put(createImageCollectionSuccess(response.data));
            showSuccessToaster(response.message)
        } else {
            yield put(createImageCollectionFailed(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log('Error: ', err);
        yield put(createImageCollectionFailed(err.message));
    }
}

export default function* promptCollectionSaga() {
    yield all([promptCollectionWatcher()]);
}
