import React, {useEffect, useState} from 'react';
import '../../assets/global/css/MaxText.css';
import './style/MaxText.css';
import {Box, Button, Tab} from '@mui/material';
import {
    createCollection,
    createImageCollection,
    getAllImageCollection,
    getCollection
} from '../../state/features/collection/collectionSlice';
import {useDispatch, useSelector} from 'react-redux';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BasicDrawer from '../../assets/global/Drawer/BasicDrawer';
import CategoryForm from './CategoryForm';
import CollectionDetailsOld from './CollectionDetailsOld';
import EmptyPage from '../../assets/global/EmptyComponent/EmptyPage';
import Folder from './Folder';
import FullPageDrawer from '../../assets/global/Drawer/FullPageDrawer';
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import {makeStyles} from '@mui/styles';
import {selectCollectionState} from '../../state/features/collection/collectionSelector';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import ImageFolder from "./ImageFolder";

const useStyles = makeStyles(theme => ({
    folderBox: {
        backgroundColor: '#f1f2f6',
        borderRadius: '5px',
        padding: '20px',
        marginBottom: '10px',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#EAEAEA'
        }
    },
    folderIcon: {
        marginRight: '10px',
        fontSize: '24px',
        color: '#A4A4A4'
    },
    folderTitle: {
        fontWeight: 'bold',
        color: '#4F4F4F'
    }
}));

const imageCollection = [
    {
        id: 1,
        title: 'This is Image one',
        url: 'https://images.unsplash.com/photo-1600672202669-3521432b6302?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1476&q=80',

    },
    {
        id: 2,
        title: "This is image two",
        url: 'https://plus.unsplash.com/premium_photo-1661962344655-b605d621bf80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80'
    },
    {
        id: 3,
        title: "This is image three",
        url: 'https://images.unsplash.com/photo-1443376133869-19bce1f036e1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80'
    },
    {
        id: 4,
        title: "This is image four",
        url: 'https://images.unsplash.com/photo-1533305609596-07872146cff3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80'
    },
    {
        id: 1,
        title: 'This is Image one',
        url: 'https://images.unsplash.com/photo-1600672202669-3521432b6302?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1476&q=80',

    },
    {
        id: 2,
        title: "This is image two",
        url: 'https://plus.unsplash.com/premium_photo-1661962344655-b605d621bf80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80'
    },
    {
        id: 3,
        title: "This is image three",
        url: 'https://images.unsplash.com/photo-1443376133869-19bce1f036e1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80'
    },
    {
        id: 4,
        title: "This is image four",
        url: 'https://images.unsplash.com/photo-1533305609596-07872146cff3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80'
    },
    {
        id: 1,
        title: 'This is Image one',
        url: 'https://images.unsplash.com/photo-1600672202669-3521432b6302?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1476&q=80',

    },
    {
        id: 2,
        title: "This is image two",
        url: 'https://plus.unsplash.com/premium_photo-1661962344655-b605d621bf80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80'
    },
    {
        id: 3,
        title: "This is image three",
        url: 'https://images.unsplash.com/photo-1443376133869-19bce1f036e1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80'
    },
    {
        id: 4,
        title: "This is image four",
        url: 'https://images.unsplash.com/photo-1533305609596-07872146cff3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80'
    },

]
const Collection = () => {
    const dispatch = useDispatch();
    const {page, perPage, total, collections, imageCollectionList, isLoading} = useSelector(selectCollectionState);
    const [viewDetailsPageShow, setViewDetailsPageShow] = useState(false);
    const [viewBasicDrawer, setViewBasicDrawer] = useState(false);
    const [viewImageDrawer, setViewImageDrawer] = useState(false);
    const [selectedCollectiontObj, setSelectedCollectionObj] = useState(null);

    const toggleViewDetailsPage = () => setViewDetailsPageShow(prevState => !prevState);
    const toggleViewBasicDrawer = () => setViewBasicDrawer(prevState => !prevState);
    const toggleViewImageDrawer = () => setViewImageDrawer(prevState => !prevState);

    const pageCount = Math.ceil(total / perPage);

    const [value, setValue] = useState('1');

    useEffect(() => {
        dispatch(getCollection({page: page, per_page: perPage}));
    }, []);

    useEffect(() => {
        dispatch(getAllImageCollection());
    }, []);

    const handleViewBasicDrawer = data => {
        toggleViewBasicDrawer();
    };

    const handleViewImageDrawer = () => {
        toggleViewImageDrawer();
    }

    const handleViewDetailsPage = data => {
        toggleViewDetailsPage();
    };

    const handleSubmitCategoryForm = data => {
        dispatch(createCollection(data));
    };

    const handleSubmitImageForm = data => {
        dispatch(createImageCollection(data));
    };

    const handleSelectedCollection = data => {
        setSelectedCollectionObj(data);
    };

    const handleChange = (event, value) => {
        dispatch(getCollection({page: value, per_page: perPage}));
    };

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    // decide to what to render on view
    let content = null;

    if (isLoading) {
        content = (
            <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}} sx={{p: 3}}>
                {[1, 2, 3, 4].map(collection => (
                    <Grid key={collection} item xs={3}>
                        <Box sx={{p: 3, marginRight: 0.5}}>
                            <Skeleton variant="rectangular" height={118}/>
                            <Skeleton/>
                            <Skeleton width="60%"/>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        );
    } else if (!isLoading && Array.isArray(collections)) {
        content = (
            <>
                <Box sx={{textAlign: 'right'}}>
                    <Button
                        sx={{backgroundColor: "rgb(0, 158, 247)"}}
                        onClick={() => handleViewBasicDrawer()}
                        variant="contained"
                    >
                        Add new Collection
                    </Button>
                </Box>

                <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}} sx={{p: 3}}>
                    {collections.map(collection => (
                        <Grid key={collection.id} item xs={3}>
                            <Folder
                                collection={collection}
                                handleViewDetailsPage={handleViewDetailsPage}
                                handleSelectedCollection={handleSelectedCollection}
                            />
                        </Grid>
                    ))}
                </Grid>

                <Stack direction="row" justifyContent="flex-end" sx={{p: 1}}>
                    <Pagination
                        onChange={handleChange}
                        count={pageCount}
                        page={page}
                        sx={{
                            bottom: '5%',
                            position: 'absolute',
                            right: '5%'
                        }}
                        renderItem={collection => (
                            <PaginationItem
                                slots={{previous: ArrowBackIcon, next: ArrowForwardIcon}}
                                {...collection}
                            />
                        )}
                    />
                </Stack>
            </>
        );
    } else if (!isLoading && !Array.isArray(collections)) {
        content = (
            <>
                <EmptyPage title="No information was discovered."/>
            </>
        );
    }

    return (
        <TabContext value={value}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                    <Tab label="Collection" value="1"/>
                    <Tab label="Image Collection" value="2"/>
                </TabList>
            </Box>

            <TabPanel sx={{padding: '12px 0'}} value="1">

                {content}

                <FullPageDrawer
                    title={`Details of ${selectedCollectiontObj?.name}`}
                    open={viewDetailsPageShow}
                    toggleDrawer={toggleViewDetailsPage}
                >
                    <CollectionDetailsOld collectionData={selectedCollectiontObj}/>
                </FullPageDrawer>

                <BasicDrawer
                    title="New Collection"
                    open={viewBasicDrawer}
                    toggleDrawer={toggleViewBasicDrawer}
                >
                    <CategoryForm
                        handleSubmitCategory={handleSubmitCategoryForm}
                        handleDrawerClose={toggleViewBasicDrawer}
                    />
                </BasicDrawer>
            </TabPanel>

            <TabPanel sx={{padding: 2}} value="2">
                <Box sx={{textAlign: 'right'}}>
                    <Button
                        sx={{backgroundColor: "rgb(0, 158, 247)"}}
                        onClick={() => handleViewImageDrawer()}
                        variant="contained"
                    >
                        Add new Image Collection
                    </Button>
                </Box>

                <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}} sx={{p: 3}}>
                    {imageCollectionList.map(collection => (
                        <Grid key={collection.id} item xs={3}>
                            <ImageFolder
                                collection={collection}
                                handleViewDetailsPage={handleViewDetailsPage}
                                handleSelectedCollection={handleSelectedCollection}
                            />
                        </Grid>
                    ))}
                </Grid>

                <BasicDrawer
                    title="New Image Collection"
                    open={viewImageDrawer}
                    toggleDrawer={toggleViewImageDrawer}
                >
                    <CategoryForm
                        handleSubmitCategory={handleSubmitImageForm}
                        handleDrawerClose={handleViewImageDrawer}
                    />
                </BasicDrawer>

                {/*<ImageCollection imageCollection={imageCollection}/>*/}

            </TabPanel>
        </TabContext>
    );
};

export default Collection;
