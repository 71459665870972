import {Box, Button, ImageList, ImageListItem, ImageListItemBar, Stack, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {
    clearPromptCollectionState,
    getPromptCollection
} from '../../state/features/promptCollection/promptCollectionSlice';
import {useDispatch, useSelector} from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CardHeader from '@mui/material/CardHeader';
import CollectionResponseDetails from './CollectionResponseDetails';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EmptyPage from '../../assets/global/EmptyComponent/EmptyPage';
import FullPageDrawer from '../../assets/global/Drawer/FullPageDrawer';
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Skeleton from "@mui/material/Skeleton";
import noDataImage from '../../assets/images/no_data.jpg';
import {selectPromptCollectionState} from '../../state/features/promptCollection/promptCollectionSelector';
import {showErrorToaster, showSuccessToaster} from '../../helpers/utility/toaster';
import {styled} from '@mui/system';
import {useNavigate, useParams} from "react-router-dom";
import UndoIcon from '@mui/icons-material/Undo';
import {getSpecificCollection, promptDelete} from "../../state/features/collection/collectionSlice";
import {selectCollectionState} from "../../state/features/collection/collectionSelector";
import '../../assets/global/css/MaxText.css';
import DownloadIcon from "@mui/icons-material/Download";
import InfoIcon from "@mui/icons-material/Info";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import useBoolean from "../../hooks/useBoolean";
import WarningModalContent from "../../assets/global/Modal/WarningModalContent";
import CloseModal from "../../assets/global/Modal/CloseModal";

const StyledCardHeader = styled(CardHeader)(({theme}) => ({
    '.MuiCardHeader-title': {
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: '600',
        color: 'rgb(51, 51, 51)',
        lineHeight: '1.4',
        borderRadius: '0px',
        opacity: 1,
        wordBreak: 'break-word',
    }
}));

const ImageColllection = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [viewDetailsPageShow, setViewDetailsPageShow] = useState(false);
    const [collectionResponseObj, setCollectionResponseObj] = useState(null);
    const [selectedClientObj, setSelectedClientObj] = useState(null);
    const [anchorElAction, setAnchorElAction] = useState(null);
    const {
        value: openDeleteModal,
        setTrue: onOpenDeleteModal,
        setFalse: onCloseDeleteModal
    } = useBoolean(false);
    const handleOpenActionMenu = event => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorElAction(event.currentTarget);
    };
    const handleCloseActionMenu = event => {
        event.stopPropagation();
        setAnchorElAction(null);
    };
    const {page, perPage, total, promptCollections, isLoading} = useSelector(
        selectPromptCollectionState
    );

    const {specificCollections} = useSelector(
        selectCollectionState
    );

    let {collectionId} = useParams();

    const pageCount = Math.ceil(total / perPage);
    const toggleViewDetailsPage = () => setViewDetailsPageShow(prevState => !prevState);


    useEffect(() => {
        dispatch(
            getPromptCollection({collection_id: collectionId, page: page, per_page: perPage})
        );
    }, [dispatch, collectionId]);

    useEffect(() => {
        dispatch(
            getSpecificCollection({collection_id: collectionId})
        );
    }, [dispatch, collectionId]);

    // ## Clear  State When Leave the page
    useEffect(() => {
        return () => dispatch(clearPromptCollectionState());
    }, []);

    const handleChange = (event, value) => {
        dispatch(
            getPromptCollection({collection_id: collectionId, page: value, per_page: perPage})
        );
    };

    const handleDeleteConfirm = event => {
        event.stopPropagation();
        dispatch(promptDelete({prompt_id: selectedClientObj.id, collection_id: collectionId}));
        onCloseDeleteModal();
    };

    const handleViewDetailsPage = data => {
        setCollectionResponseObj(data);
        toggleViewDetailsPage();
    };

    const handleCopy = data => {
        navigator.clipboard.writeText(data);
        showSuccessToaster('Text copied');
    };

    function getFileExtension(url) {
        const extension = url.split('.').pop();
        return extension.toLowerCase();
    }
    const handleDownload = async (image) => {
        const extension = getFileExtension(image);
        console.log(extension);
        if(extension === 'gif') {
            try {
                const response = await fetch(image); // Replace with your GIF URL
                const arrayBuffer = await response.arrayBuffer();
                const blob = new Blob([arrayBuffer], { type: 'image/gif' });
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.download = 'animation.gif'; // Specify the desired file name
                link.click();
                window.URL.revokeObjectURL(url);
                showSuccessToaster('Image downloaded');
            } catch (error) {
                showErrorToaster('Image download failed');
            }

        } else {
            try {
                let imageName = "Meme.png"
                let type = "png";
                const currentUrl = process.env.REACT_APP_API_URL_MAIN_USER_SERVER + `/download/image?url=${image}&name=${imageName}&type=${type}`;
                await window.open(currentUrl, '_blank');
                showSuccessToaster('Image downloaded');
            } catch (error) {
                showErrorToaster('Image download failed');
            }
        }
    }


    const handleCopyClick = async (imageUrl) => {
        fetch(imageUrl)
            .then((response) => response.blob())
            .then((blob) => {
                const clipboardItems = [
                    new ClipboardItem({[blob.type]: blob}),
                ];

                navigator.clipboard.write(clipboardItems)
                    .then(() => {
                        showSuccessToaster('Meme Copy to clipboard!')
                    })
                    .catch((error) => {
                        console.error('Failed to copy image:', error);
                    });
            });
    }

    // decide to what to render on view
    let content = null;

    if (isLoading) {
        content = (
            <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}} sx={{p: 3}}>
                {[1, 2, 3, 4].map(collection => (
                    <Grid key={collection.id} item xs={3}>
                        <Box sx={{p: 3, marginRight: 0.5}}>
                            <Skeleton variant="rectangular" height={118}/>
                            <Skeleton/>
                            <Skeleton width="60%"/>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        );
    } else if (!isLoading && promptCollections.length > 0) {
        content = (
            <>
                <Grid container spacing={2}>
                    <ImageList cols={4} gap={10} sx={{marginTop: '15px'}}>
                        {promptCollections.length && promptCollections.map((promptCollection) => (
                            <ImageListItem key={promptCollection.id}>
                                {promptCollection &&
                                    <img
                                        src={promptCollection.response}
                                        alt={promptCollection.text}
                                        loading="lazy"
                                    />}

                                <ImageListItemBar
                                    sx={{bottom: 'unset'}}
                                    title="Image Collection"
                                    // subtitle={'Something was told'}
                                    actionIcon={

                                        <Stack direction={'row'} spacing={3} p={'12px 16px'}>
                                            <DownloadIcon
                                                sx={{
                                                    color: 'rgba(255, 255, 255, 0.84)',
                                                    '&:hover': {color: 'rgba(255, 255, 255, 1)'},
                                                    cursor: 'pointer'
                                                }}
                                                aria-label={`info about ${promptCollection.text}`}
                                                onClick={() => handleDownload(promptCollection.response)}
                                            >
                                                <InfoIcon/>
                                            </DownloadIcon>

                                            <ContentCopyIcon
                                                sx={{
                                                    color: 'rgba(255, 255, 255, 0.84)',
                                                    '&:hover': {color: 'rgba(255, 255, 255, 1)'},
                                                    cursor: 'pointer'
                                                }}
                                                aria-label={`info about ${promptCollection.text}`}
                                                onClick={() => handleCopyClick(promptCollection.response)}
                                            >
                                                <InfoIcon/>
                                            </ContentCopyIcon>

                                            <DeleteForeverIcon
                                                sx={{
                                                    color: 'rgba(255, 255, 255, 0.84)',
                                                    '&:hover': {color: 'rgba(255, 255, 255, 1)'},
                                                    cursor: 'pointer'
                                                }}
                                                onClick={e => {
                                                    setSelectedClientObj(promptCollection);
                                                    handleCloseActionMenu(e);
                                                    onOpenDeleteModal(e);
                                                }}
                                            >
                                                <InfoIcon/>
                                            </DeleteForeverIcon>
                                        </Stack>
                                    }
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Grid>
                <Stack direction="row" justifyContent="flex-end" sx={{p: 1}}>
                    <Pagination
                        onChange={handleChange}
                        count={pageCount}
                        page={page}
                        sx={{
                            bottom: '5%',
                            position: 'absolute',
                            right: '5%'
                        }}
                        renderItem={collection => (
                            <PaginationItem
                                slots={{previous: ArrowBackIcon, next: ArrowForwardIcon}}
                                {...collection}
                            />
                        )}
                    />
                </Stack>
            </>
        );
    } else if (!isLoading && promptCollections.length === 0) {
        content = (
            <>
                <EmptyPage title="No information was discovered." img={noDataImage}/>
            </>
        );
    }

    return (
        <Box sx={{p: 3, minHeight: '70vh'}}>
            <Box sx={{padding: '10px', marginBottom: "10px", borderBottom: `1px solid #dfe4ea`, minHeight: 'auto'}}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Typography level="h2" fontSize="lg" fontWeight="bold" id="card-description" mb={0.5}>
                        {specificCollections?.name}
                    </Typography>
                    <Button variant="contained" onClick={() => navigate(-1)}>
                        <UndoIcon sx={{marginRight: "2px"}}/>
                        Back
                    </Button>
                </Grid>
            </Box>

            {content}

            <FullPageDrawer
                title=""
                open={viewDetailsPageShow}
                toggleDrawer={toggleViewDetailsPage}
                width="50%"
                height="70%"
            >
                <CollectionResponseDetails collectionResponseObj={collectionResponseObj}/>
            </FullPageDrawer>

            <CloseModal open={openDeleteModal} onClose={onCloseDeleteModal}>
                <WarningModalContent
                    title={'Warning'}
                    subTitle={'Are you sure to delete this image!!'}
                    description={'If you delete this image, you’ll not be able to recover it again.'}
                    buttonName={'Okay, Delete'}
                    onConfirm={handleDeleteConfirm}
                    onClose={onCloseDeleteModal}
                />
            </CloseModal>
        </Box>
    );
};
export default ImageColllection;

