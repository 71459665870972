import { requests } from "./http.service";

class UserService {
    attemptLogin(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/login", body);
    }

    attemptResetPassword(body) {
        return requests.post(
            process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/forget/password/token/send",
            body
        );
    }

    attemptResetPasswordResendToken(body) {
        return requests.post(
            process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "api/v1/reset/password/token/resend",
            body
        );
    }

    attemptResetPasswordWithToken(body) {
        return requests.post(
            process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/forget/password/token/verify",
            body
        );
    }

    attemptSetNewPassword(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "api/v1/reset/new/password", body);
    }

    attemptLogout() {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "api/v1/logout");
    }

    attemptSignUp(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/registration", body);
    }

    attemptSignUpWithToken(body) {
        return requests.post(
            process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "api/v1/verify/signup/token/and/login",
            body
        );
    }

    attemptSwitchAccount(body) {
        return requests.post(
            process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/attempt/switch/account/from/admin",
            body
        );
    }
}

export default new UserService();
