import { Box, Typography } from "@mui/material";
import Styles from "../styles/PackageBadge.module.css";

export default function PackageBadge({ bgColor, title }) {
    return (
        <Box
            sx={{ background: bgColor }}
            className={Styles.badge}
            anchorOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
        >
            <Typography className={Styles.badge_title} variant='tooltip'>
                {title}
            </Typography>
        </Box>
    );
}
