import {all, put} from 'redux-saga/effects';
import {call, takeEvery} from '@redux-saga/core/effects';
import {imageVariationFailed, imageVariationSuccess} from "./imageVariationSlice";
import ImageVariationService from "../../../services/ImageVariation.service";
import {showSuccessToaster} from "../../../helpers/utility/toaster";

function* imageVariationWatcher() {
    yield takeEvery('imageVariation/imageVariation', generateImageVariationSaga);
}

function* generateImageVariationSaga(action) {
    try {
        const response = yield call(ImageVariationService.imageVariation, action.payload);

        if (response.status) {
            yield put(imageVariationSuccess(response.data));
            showSuccessToaster(response.message)
        } else {
            yield put(imageVariationFailed(response.message));
        }
    } catch (err) {
        console.log('Error: ', err);
        yield put(imageVariationFailed(err.message));
    }
}

export default function* imageVariationSaga() {
    yield all([imageVariationWatcher()]);
}
