import React from "react";
import Skeleton from "@mui/material/Skeleton";
import { Box, Stack, styled } from "@mui/material";

const ItemBox = styled(Box)(({ theme }) => ({
    border: "1px solid rgba(148, 157, 178, 0.12)",
    "& .MuiButton-root svg": {
        marginRight: "8px",
    },
}));

const PackageItemSkeletonLoader = () => {
    return (
        <ItemBox p={3}>
            <Stack spacing={3}>
                <Stack lg={12} md={12} sm={12} direction={"row"} justifyContent={"right"}>
                    <Skeleton variant='rectangular' width={160} />
                </Stack>
                <Stack lg={12} md={12} sm={12} direction={"row"} justifyContent={"center"}>
                    <Skeleton variant='rectangular' width={200} height={110} sx={{ marginTop: "30px" }} />
                </Stack>
                <Stack lg={12} md={12} sm={12} direction={"row"} justifyContent={"center"}>
                    <Skeleton
                        variant='rectangular'
                        sx={{ borderRadius: "4px", float: "right" }}
                        width={120}
                        height={40}
                    />
                </Stack>
                <Stack lg={12} md={12} sm={12}>
                    <Skeleton variant='rectangular' sx={{ width: "100%" }} />
                </Stack>
                <Stack lg={12} md={12} sm={12}>
                    <Skeleton variant='rectangular' height={100} sx={{ width: "100%" }} />
                </Stack>
                <Stack lg={12} md={12} sm={12}>
                    <Skeleton variant='rectangular' sx={{ width: "100%" }} />
                </Stack>
                <Stack lg={12} md={12} sm={12}>
                    <Skeleton variant='rectangular' sx={{ width: "100%" }} />
                </Stack>
                <Stack lg={12} md={12} sm={12}>
                    <Skeleton variant='rectangular' sx={{ width: "100%" }} />
                </Stack>
                <Stack lg={12} md={12} sm={12}>
                    <Skeleton variant='rectangular' sx={{ width: "100%" }} />
                </Stack>
            </Stack>
        </ItemBox>
    );
};

export default PackageItemSkeletonLoader;
