import {all, put} from 'redux-saga/effects';
import {call, takeEvery} from '@redux-saga/core/effects';
import {totalDashboardCountFailed, totalDashboardCountSuccess} from "./totalDashboardCountSlice";
import TotalDashboardCountService from "../../../services/TotalDashboardCount.service";

function* totalDashboardCountWatcher() {
    yield takeEvery('totalDashboardCount/totalDashboardCount', generateTotalDashboardCountSaga);
}

function* generateTotalDashboardCountSaga(action) {
    try {
        const response = yield call(TotalDashboardCountService.totalDashboardCount, action.payload);

        if (response.status) {
            yield put(totalDashboardCountSuccess(response.data));
        } else {
            yield put(totalDashboardCountFailed(response.message));
        }
    } catch (err) {
        yield put(totalDashboardCountFailed(err.message));
    }
}


export default function* totalDashboardCountSaga() {
    yield all([totalDashboardCountWatcher()]);
}
