import {Box, Button, Card, CardContent, Stack, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {
    clearPromptCollectionState,
    getPromptCollection
} from '../../state/features/promptCollection/promptCollectionSlice';
import {useDispatch, useSelector} from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CardHeader from '@mui/material/CardHeader';
import CollectionResponseDetails from './CollectionResponseDetails';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EmptyPage from '../../assets/global/EmptyComponent/EmptyPage';
import FullPageDrawer from '../../assets/global/Drawer/FullPageDrawer';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '../../assets/global/Button/LoadingButton';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Skeleton from "@mui/material/Skeleton";
import noDataImage from '../../assets/images/no_data.jpg';
import {selectPromptCollectionState} from '../../state/features/promptCollection/promptCollectionSelector';
import {showSuccessToaster} from '../../helpers/utility/toaster';
import {styled} from '@mui/system';
import {useNavigate, useParams} from "react-router-dom";
import UndoIcon from '@mui/icons-material/Undo';
import {getSpecificCollection, promptDelete} from "../../state/features/collection/collectionSlice";
import {selectCollectionState} from "../../state/features/collection/collectionSelector";
import '../../assets/global/css/MaxText.css';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import useBoolean from "../../hooks/useBoolean";
import WarningModalContent from "../../assets/global/Modal/WarningModalContent";
import CloseModal from "../../assets/global/Modal/CloseModal";

const StyledCardHeader = styled(CardHeader)(({theme}) => ({
    '.MuiCardHeader-title': {
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: '600',
        color: 'rgb(51, 51, 51)',
        lineHeight: '1.4',
        borderRadius: '0px',
        opacity: 1,
        wordBreak: 'break-word',
    }
}));

const CollectionDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [viewDetailsPageShow, setViewDetailsPageShow] = useState(false);
    const [collectionResponseObj, setCollectionResponseObj] = useState(null);
    const [selectedClientObj, setSelectedClientObj] = useState(null);
    const [anchorElAction, setAnchorElAction] = useState(null);
    const {page, perPage, total, promptCollections, isLoading} = useSelector(
        selectPromptCollectionState
    );
    const {
        value: openDeleteModal,
        setTrue: onOpenDeleteModal,
        setFalse: onCloseDeleteModal
    } = useBoolean(false);

    const {specificCollections} = useSelector(
        selectCollectionState
    );

    let {collectionId} = useParams();

    const pageCount = Math.ceil(total / perPage);
    const toggleViewDetailsPage = () => setViewDetailsPageShow(prevState => !prevState);

    useEffect(() => {
        dispatch(
            getPromptCollection({collection_id: collectionId, page: page, per_page: perPage})
        );
    }, [dispatch, collectionId]);

    useEffect(() => {
        dispatch(
            getSpecificCollection({collection_id: collectionId})
        );
    }, [dispatch, collectionId]);

    // ## Clear  State When Leave the page
    useEffect(() => {
        return () => dispatch(clearPromptCollectionState());
    }, []);

    const handleCloseActionMenu = event => {
        event.stopPropagation();
        setAnchorElAction(null);
    };

    const handleChange = (event, value) => {
        dispatch(
            getPromptCollection({collection_id: collectionId, page: value, per_page: perPage})
        );
    };

    const handleViewDetailsPage = data => {
        setCollectionResponseObj(data);
        toggleViewDetailsPage();
    };

    const handleCopy = data => {
        navigator.clipboard.writeText(data);
        showSuccessToaster('Text copied');
    };

    const handleDeleteConfirm = event => {
        event.stopPropagation();
        dispatch(promptDelete({prompt_id: selectedClientObj.id, collection_id: collectionId}));
        onCloseDeleteModal();
    };

    // decide to what to render on view
    let content = null;

    if (isLoading) {
        content = (
            <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}} sx={{p: 3}}>
                {[1, 2, 3, 4].map(collection => (
                    <Grid key={collection.id} item xs={3}>
                        <Box sx={{p: 3, marginRight: 0.5}}>
                            <Skeleton variant="rectangular" height={118}/>
                            <Skeleton/>
                            <Skeleton width="60%"/>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        );
    } else if (!isLoading && promptCollections.length > 0) {
        content = (
            <>
                <Grid container spacing={2}>
                    {promptCollections.map(promptCollection => (
                        <Grid item xs={4} key={promptCollection?.id}>
                            <Card
                                sx={{
                                    height: 'auto !important',
                                    backgroundColor: '#f1f2f6',
                                    cursor: 'pointer',
                                    padding: '16px',
                                    minHeight: '164px',
                                    ':hover': {
                                        background: 'rgb(230, 230, 230)'
                                    }
                                }}
                            >
                                <StyledCardHeader
                                    className="max-1line-text"
                                    sx={{
                                        marginRight: '5px',
                                        borderBottom: '1px solid #bbc5cf',
                                        padding: '0!important',
                                        paddingBottom: '10px !important'
                                    }}
                                    action={
                                        <>
                                            <Stack direction="row">
                                                <Box>
                                                    <IconButton
                                                        aria-label="settings"
                                                        onClick={() => handleCopy(promptCollection?.response)}
                                                    >
                                                        <ContentCopyIcon/>
                                                    </IconButton>
                                                </Box>
                                                <Box>
                                                    <IconButton
                                                        aria-label="settings"
                                                        onClick={e => {
                                                            setSelectedClientObj(promptCollection);
                                                            handleCloseActionMenu(e);
                                                            onOpenDeleteModal(e);
                                                        }}
                                                    >
                                                        <DeleteForeverIcon/>
                                                    </IconButton>
                                                </Box>
                                            </Stack>
                                        </>

                                    }
                                    title={
                                        promptCollection?.text.length > 35
                                            ? promptCollection?.text.slice(0, 35) + '...'
                                            : promptCollection?.text
                                    }
                                />
                                <CardContent sx={{padding: '0!important',}}>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        className="max-3line-text"
                                        sx={{
                                            fontFamily: 'Poppins',
                                            fontSize: '12px',
                                            fontWeight: '300',
                                            color: 'rgb(51, 51, 51)',
                                            lineHeight: '1.4',
                                            borderRadius: '0px',
                                            opacity: 1,
                                            wordBreak: 'break-word',
                                            paddingTop: '10px'
                                        }}
                                    >
                                        {promptCollection?.response}
                                    </Typography>
                                    <Stack
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                        alignSelf="flex-end"
                                        spacing={2}
                                        mt={2}
                                        sx={{marginLeft: '15px', alignSelf: 'flex-end'}}
                                    >
                                        <LoadingButton
                                            onClick={() => handleViewDetailsPage(promptCollection)}
                                            isLoading={false}
                                            type={'submit'}
                                            size={'small'}
                                            color={'primary'}
                                            sx={{
                                                fontFamily: 'Poppins',
                                                fontSize: '12px',
                                                fontWeight: '400',
                                                opacity: 1,
                                                borderRadius: '5px'
                                            }}
                                        >
                                            Preview
                                        </LoadingButton>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                <Stack direction="row" justifyContent="flex-end" sx={{p: 1}}>
                    <Pagination
                        onChange={handleChange}
                        count={pageCount}
                        page={page}
                        sx={{
                            bottom: '5%',
                            position: 'absolute',
                            right: '5%'
                        }}
                        renderItem={collection => (
                            <PaginationItem
                                slots={{previous: ArrowBackIcon, next: ArrowForwardIcon}}
                                {...collection}
                            />
                        )}
                    />
                </Stack>
            </>
        );
    } else if (!isLoading && promptCollections.length === 0) {
        content = (
            <>
                <EmptyPage title="No information was discovered." img={noDataImage}/>
            </>
        );
    }

    return (
        <Box sx={{p: 3, minHeight: '70vh'}}>
            <Box sx={{padding: '10px', marginBottom: "10px", borderBottom: `1px solid #dfe4ea`, minHeight: 'auto'}}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Typography level="h2" fontSize="lg" fontWeight="bold" id="card-description" mb={0.5}>
                        {specificCollections?.name}
                    </Typography>
                    <Button variant="contained" onClick={() => navigate(-1)}>
                        <UndoIcon sx={{marginRight: "2px"}}/>
                        Back
                    </Button>
                </Grid>
            </Box>

            {content}

            <FullPageDrawer
                title=""
                open={viewDetailsPageShow}
                toggleDrawer={toggleViewDetailsPage}
                width="50%"
                height="70%"
            >
                <CollectionResponseDetails collectionResponseObj={collectionResponseObj}/>
            </FullPageDrawer>

            <CloseModal open={openDeleteModal} onClose={onCloseDeleteModal}>
                <WarningModalContent
                    title={'Warning'}
                    subTitle={'Are you sure to delete this image!!'}
                    description={'If you delete this image, you’ll not be able to recover it again.'}
                    buttonName={'Okay, Delete'}
                    onConfirm={handleDeleteConfirm}
                    onClose={onCloseDeleteModal}
                />
            </CloseModal>
        </Box>
    );
};
export default CollectionDetails;
