export const RESPONSE_LOADING_TIME = 1000;

// ## region [User Has Package table constants]
export const USER__HAS__PACKAGE__TYPE__PAID = 1;
export const USER__HAS__PACKAGE__TYPE__FREE = 2;
export const USER__HAS__PACKAGE__IS__TRIAL = 2;
export const USER__HAS__PACKAGE__IS__NOT__TRIAL = 1;
export const USER_PACKAGE_LIMIT_KEY__DOLLAR_BALANCE = 1;
export const USER_PACKAGE_LIMIT_KEY__TEAM_MEMBER = 2;
export const USER_PACKAGE_LIMIT_KEY__CLIENT = 3;

export const USER_HAS_NEW_CARD__YES = 1;
export const USER_HAS_NEW_CARD__NO = 0;

// ## region [Agency Payment Method table constant]
export const PAYMENT_METHOD__STRIPE = 1;
export const PAYMENT_METHOD__PAYPAL = 2;
export const PAYMENT_METHOD__AUTHORIZE_DOT_NET = 3;
export const PAYMENT_METHOD__2_CHECKOUT = 4;

// ## region [User invoice table constant]
export const USER_INVOICE__FOR_PACKAGE_BUY = 1;
export const USER_INVOICE__FOR_PACKAGE_FREE = 2;
export const USER_INVOICE__FOR_SMS_SEND = 3;
export const USER_INVOICE__FOR_EMAIL_SEND = 4;
export const USER_INVOICE__FOR_LOCATION_REVIEW_REQUEST = 5;
export const USER_INVOICE__FOR_RECHARGE_TOP_UP_AMOUNT = 6;

// ## region [User balance table constant]
export const PACKAGE_LIMIT__BALANCE__KEY__CREDIT_AMOUNT = 1;
// region [Credit Settings table constants]
export const creditSettingSTextContent = 1;
export const creditSettingsImageContent256x256 = 2;
export const creditSettingsImageContent512x512 = 3;
export const creditSettingsImageContent1024x1024 = 4;
export const creditSettingsAITranscription = 5;
// end region [Credit Settings table constants]

// ## region [Package feature access constants]
export const PACKAGE_FEATURE__KEY__TEMPLATE = 1;
export const PACKAGE_FEATURE__KEY__COLLECTION = 2;
export const PACKAGE_FEATURE__KEY__CONTENT_REPURPOSING = 3;
export const PACKAGE_FEATURE__KEY__PLAY_PEN = 4;
export const PACKAGE_FEATURE__KEY__IMAGE_CREATION = 5;
export const PACKAGE_FEATURE__KEY__IMAGE_IN_PAINTING = 6;
export const PACKAGE_FEATURE__KEY__IMAGE_VARIATION = 7;
export const PACKAGE_FEATURE__KEY__IMAGE_TO_PROMPT = 8;
export const PACKAGE_FEATURE__KEY__IMAGE_TO_ALT = 9;
export const PACKAGE_FEATURE__KEY__AUDIO = 10;
export const PACKAGE_FEATURE__KEY__PROMPT = 11;
export const PACKAGE_FEATURE__KEY__BILLING = 12;
export const PACKAGE_FEATURE__KEY__TEXT_TO_MEME = 13;
export const PACKAGE_FEATURE__KEY__THEMED_MEMES = 14;
export const PACKAGE_FEATURE__KEY__SEARCH_TEMPLATES = 15;
export const PACKAGE_FEATURE__KEY__MEME_MAKER = 16;
export const PACKAGE_FEATURE__KEY__DASHBOARD = 17;
// sidebar collups menu
export const AI_TRANSCRIPTION_COLLAPSE_MENU = 19;
export const MEME_MAKER_COLLAPSE_MENU = 20;

// Dashboard
export const DASHBOARD__TOTAL_CONTENT_COUNT = 101;
export const DASHBOARD__TOTAL_USER_COST = 102;
export const DASHBOARD__TOTAL_PLATFORM_COST = 103;


