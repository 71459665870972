import React, {useEffect, useState} from 'react';
import {Box, IconButton} from '@mui/material';
import Typography from '@mui/material/Typography';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {useDispatch, useSelector} from 'react-redux';
import {selectPresetTemplateState} from '../../state/features/presetTemplate/presetTemplateSelector';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import {showSuccessToaster} from '../../helpers/utility/toaster';
import PromptCollectionForm from '../prompt/PromptCollectionForm';
import FullPageDrawer from '../../assets/global/Drawer/FullPageDrawer';
import {createPromptCollection} from '../../state/features/promptCollection/promptCollectionSlice';
import {clearResponseDataState} from '../../state/features/presetTemplate/presetTemplateSlice';
import PresetTemplateLoader from "./PresetTemplateLoader";

const PresetTemplateDetailsGeneratedOutput = () => {
    const dispatch = useDispatch();
    const {specificPresetTemplate, responseData, isLoading} = useSelector(selectPresetTemplateState);

    const [viewDetailsPageShow, setViewDetailsPageShow] = useState(false);
    const [selectedAnswerContent, setSelectedAnswerContent] = useState(null);
    const toggleViewDetailsPage = () => setViewDetailsPageShow(prevState => !prevState);
    const handleViewDetailsPage = data => {
        setSelectedAnswerContent(data);
        toggleViewDetailsPage();
    };

    const handleCopyAnswerContent = data => {
        navigator.clipboard.writeText(data);
        showSuccessToaster('Text copied');
    };

    // unsubscribe or reset state
    useEffect(() => {
        return () => dispatch(clearResponseDataState());
    }, []);

    const handlePromptCollectionSubmit = data => {
        const payload = {};
        payload.collection_id = data.collection_id || '';

        payload.text = specificPresetTemplate?.title || 'N/A';
        payload.response = selectedAnswerContent?.text || '';

        dispatch(createPromptCollection(payload));
    };

    // decide to what to render on view
    let content = null;
    if (isLoading) {
        content = <PresetTemplateLoader/>;
    } else if (!isLoading && responseData.length > 0) {
        content = (
            <>
                <Typography sx={{color: 'rgb(55 65 81)', fontSize: '18px', fontWeight: 500, mb: 3}}>
                    Generated Outputs
                </Typography>

                {Array.isArray(responseData) &&
                    responseData.length > 0 &&
                    responseData.map((item, index) => (
                        <Box
                            key={index}
                            sx={{backgroundColor: '#fff', margin: '10px 0px', p: 2, borderRadius: '5px'}}
                        >

                            <Typography  sx={{whiteSpace:'pre-line',fontWeight:'300 !important'}}>
                                {/*{item?.text}*/}
                                <div
                                    style={{marginTop: '0'}}
                                    dangerouslySetInnerHTML={{__html: item?.text}}
                                />
                            </Typography>

                            {/*<article>*/}
                            {/*  */}
                            {/*</article>*/}


                            <Box sx={{display: 'flex', justifyContent: 'space-between', marginTop: '20px'}}>
                                <IconButton onClick={() => handleViewDetailsPage(item)} sx={{padding: '10px 10px',color:'#00ad00'}}>
                                    <SaveAsIcon/>
                                </IconButton>

                                <IconButton
                                    onClick={() => handleCopyAnswerContent(item?.text)}
                                    sx={{padding: '10px 10px'}}
                                >
                                    <ContentCopyIcon/>
                                </IconButton>
                            </Box>
                        </Box>
                    ))}
            </>
        );
    } else if (!isLoading && responseData.length === 0) {
        content = (
            <Box
                sx={{
                    backgroundColor: '#fff',
                    margin: '10px 0px',
                    p: 2,
                    borderRadius: '5px',
                    textAlign: 'center'
                }}
            >
                <ErrorOutlineIcon fontSize="large" sx={{color: '#6B7280'}}/>

                <Typography fontSize={14} fontWeight="500" color="#6B7280">
                    To get the best result, try multiple inputs and experiment with the tone of voice
                </Typography>
            </Box>
        );
    }

    return (
        <Box>
            {content}

            <FullPageDrawer
                title="Save to collection"
                open={viewDetailsPageShow}
                toggleDrawer={toggleViewDetailsPage}
                width="35%"
                height="60%"
            >
                <PromptCollectionForm
                    toggleDrawer={toggleViewDetailsPage}
                    handlePromptCollectionSubmit={handlePromptCollectionSubmit}
                />
            </FullPageDrawer>
        </Box>
    );
};

export default PresetTemplateDetailsGeneratedOutput;
