import React, {useEffect} from 'react';
import {Container, Grid, Paper} from '@mui/material';
import Styles from '../../styles/PackagePricing.module.css';
import {SinglePaidPackageItem} from '../SinglePaidPackageItem';
import {useDispatch, useSelector} from 'react-redux';
import PackageItemSkeletonLoader from '../skeletonLoader/PackageItemSkeletonLoader';
import PackageOnBoardHeader from '../PackageOnBoard/PackageOnBoardHeader';
import {useNavigate} from 'react-router-dom';
import NoPackageImage from '../../../../assets/images/Vectors/no-package.svg';
import hasAuthenticatedUser from '../../../../helpers/utility/hasAuthenticateUser';
import {getPackages} from '../../../../state/features/package/packageSlice';
import EmptyPage from '../../../../assets/global/EmptyComponent/EmptyPage';
import PackageOnBoardNavbar from "../PackageOnBoard/PackageOnBoardNavbar";

const PackagePricing = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {packages, loading} = useSelector(state => state.package);

    useEffect(() => {
        if (!hasAuthenticatedUser()) {
            navigate('/login');
        }
    }, []);

    useEffect(() => {
        dispatch(getPackages());
    }, []);

    // decide what to render on view
    let content = null;
    if (loading) {
        content = Array.from({length: 3}).map((value, index) => (
            <Grid key={index} item sm={6} md={4} lg={4} className={Styles.packages_grid_wrapper}>
                <PackageItemSkeletonLoader/>
            </Grid>
        ));
    } else if (!loading && packages?.length === 0) {
        content = (
            <EmptyPage
                img={NoPackageImage}
                title={'No Package Found!'}
                subTitle={
                    'Contact with your agency. Without a package you cannot generate revenue. Create a package and start your business.'
                }
            />
        );
    } else if (!loading && packages?.length > 0) {
        content = packages.map(packageData => (
            <Grid
                item
                sm={6}
                md={4}
                lg={4}
                key={packageData.id}
                className={Styles.packages_grid_wrapper}
            >
                <SinglePaidPackageItem packageData={packageData}/>
            </Grid>
        ));
    }

    return (
        <>
            <PackageOnBoardNavbar/>
            <Paper elevation={0} sx={{p: 2, mt: 2}}>
                <PackageOnBoardHeader
                    title="Choose The Best Plan For You"
                    subtitle="Flexible Options to Suit any Business"
                />

                <Container maxWidth="lg">
                    <Grid spacing={2} container className={Styles.package_pricing_wrapper}>
                        {content}
                    </Grid>
                </Container>
            </Paper>
        </>
    );
};

export default PackagePricing;
