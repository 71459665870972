import {TabContext, TabList, TabPanel} from '@mui/lab';
import {Box, Tab} from '@mui/material';
import React from 'react';
import FeatureOutputNoResponse from './FeatureOutputNoResponse';

const FeatureOutput = ({newOutput, history}) => {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box
      sx={{
        width: '80%',
        m: 'auto',
        py: 3,
        shadow: 1
      }}
    >
      <Box sx={{width: '100%', typography: 'body1'}}>
        <TabContext value={value}>
          <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="New outputs" value="1" />
              {history && <Tab label="Dashboard" value="2" />}
            </TabList>
          </Box>

          <TabPanel sx={{padding: '24px 0'}} value="1">
            <Box
              sx={{
                // width: '80%',
                m: 'auto',
                p: 3,
                backgroundColor: '#FFF',
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8
              }}
            >
              {newOutput}
            </Box>
          </TabPanel>

          {history && (
            <TabPanel sx={{padding: '24px 0'}} value="2">
              {history}
            </TabPanel>
          )}
        </TabContext>
      </Box>
    </Box>
  );
};

export default FeatureOutput;
