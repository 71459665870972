import {Badge, Box, Button, Chip, Stack, TextField} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import React from 'react';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import {Quill} from "react-quill";
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';

// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["8", "9", "10", "11"];
Quill.register(Size, true);

const CustomToolbar = ({
                           handleReWriteSelectedText,
                           handleWriteMoreText,
                           handleCopy,
                           handelSaveContent,
                           wordCount,
                           handleTone,
                           tone
                       }) => {

    return (
        <>
            <Box id="toolbar" sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box sx={{width: {/* md: 250, */ lg: 300, xl: 'auto'}}}>
                    <span className="ql-formats">
                        <select className="ql-header" defaultValue={''} onChange={e => e.persist()}>
                          <option value="1">Heading 1</option>
                          <option value="2">Heading 2</option>
                          <option value="3">Heading 3</option>
                          <option value="4">Heading 4</option>
                          <option value="5">Heading 5</option>
                          <option value="6" selected>
                            Heading 6
                          </option>
                        </select>
                        {/*<select className="ql-size" onChange={e => e.persist()}>*/}
                        {/*  <option value="8px">8</option>*/}
                        {/*  <option value="9">9</option>*/}
                        {/*  <option value="10">10</option>*/}
                        {/*  <option value="11">11</option>*/}
                        {/*  <option value="12px">12</option>*/}
                        {/*  <option value="13px" selected>*/}
                        {/*    13*/}
                        {/*  </option>*/}
                        {/*  <option value="14px">14</option>*/}
                        {/*  <option value="16px">16</option>*/}
                        {/*  <option value="18px">18</option>*/}
                        {/*  <option value="20px">20</option>*/}
                        {/*  <option value="24px">24</option>*/}
                        {/*  <option value="28px">28</option>*/}
                        {/*  <option value="32px">32</option>*/}
                        {/*  <option value="36px">36</option>*/}
                        {/*  <option value="40px">40</option>*/}
                        {/*  <option value="48px">48</option>*/}
                        {/*  <option value="56px">56</option>*/}
                        {/*</select>*/}
                    </span>
                    <span className="ql-formats">
                        <Tooltip title="Bold">
                            <button className="ql-bold"/>
                        </Tooltip>
                        <Tooltip title="Italic">
                            <button className="ql-italic"/>
                        </Tooltip>
                        <Tooltip title="Underline">
                            <button className="ql-underline"/>
                        </Tooltip>
                    </span>
                    <span className="ql-formats">
                        <Tooltip title="Order-list">
                            <button className="ql-list" value="ordered"/>
                        </Tooltip>
                        <Tooltip title="Bullet list">
                            <button className="ql-list" value="bullet"/>
                        </Tooltip>
                        <Tooltip title="Left-intend">
                            <button className="ql-indent" value="-1"/>
                        </Tooltip>
                        <Tooltip title="Right-intend">
                            <button className="ql-indent" value="+1"/>
                        </Tooltip>
                    </span>
                    <span className="ql-formats">
                        <Tooltip title="Text Alignment">
                            <select className="ql-align"/>
                        </Tooltip>
                        <Tooltip title="Text color">
                            <select className="ql-color"/>
                        </Tooltip>
                    </span>
                    {/*<span className="ql-formats">*/}
                    {/*   <button className="ql-image"/>*/}
                    {/*</span>*/}
                    <span className="ql-formats">
                         <Tooltip title="Clean">
                            <button className="ql-clean"/>
                        </Tooltip>
                    </span>
                </Box>

                <Box sx={{display: 'flex !important', alignItems: 'center !important'}} className="ql-formats">
                    <Stack direction="row" alignItems="center">
                        {/* <Button className="ql-insertStar">
                    <CustomButton/>
                </Button> */}
                        <TextField id="outlined-basic" placeholder="Tone of voice" onChange={handleTone}
                                   variant="outlined" value={tone} size="small"/>
                        <Tooltip title="Re-write Content">
                            <Button onClick={handleReWriteSelectedText} className="ql-rewriteContent" sx={{
                                // height: "auto!important",
                                // paddingRight:'0!important',
                                display: 'flex !important'
                            }}>

                                <HistoryEduIcon sx={{fontSize: 20}}/>
                            </Button>
                        </Tooltip>
                        <Tooltip title="Write More">
                            <Button onClick={handleWriteMoreText} className="ql-writeMore" style={{display: 'flex'}}>
                                <BorderColorIcon sx={{fontSize: 20}}/>
                            </Button>
                        </Tooltip>
                        <Tooltip title="Copy">
                            <Button onClick={handleCopy} className="ql-copyContent" style={{display: 'flex'}}>
                                <ContentCopyIcon sx={{fontSize: 20}}/>
                            </Button>
                        </Tooltip>
                        <Tooltip title="Content Store">
                            <Button className="ql-contentStore" style={{display: 'flex'}}>
                                <Button onClick={handelSaveContent} type={'submit'} className="ql-copyContent"
                                        style={{display: 'flex'}}>
                                    {/*<MemoryIcon sx={{fontSize: 20}}/>*/}
                                    <SaveAsIcon sx={{fontSize: 20}}/>
                                </Button>
                            </Button>
                        </Tooltip>

                        <Tooltip title="Word Count">
                            <Chip
                                icon={<Badge
                                    color="primary"
                                    sx={{
                                        padding: '2px 5px',
                                        backgroundColor: '#6589ff',
                                        borderRadius: '50%',

                                    }}>
                                    {wordCount}
                                </Badge>}
                                label="Word Count"
                                sx={{padding: '5px'}}
                                color="primary"/>
                        </Tooltip>
                    </Stack>
                </Box>
            </Box>
        </>
    );
};

export default CustomToolbar;
