import React, {useState} from 'react';
import {Box, Button, InputLabel, MenuItem, OutlinedInput, Select, Stack, styled, TextareaAutosize} from '@mui/material';
import PresetTemplateDetailsRadioButton from './PresetTemplateDetailsRadioButton';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import PresetTemplateDetailsHeader from './PresetTemplateDetailsHeader';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {getPresetTemplateGeneratedResponse} from '../../state/features/presetTemplate/presetTemplateSlice';
import LoadingButton from '../../assets/global/Button/LoadingButton';
import {selectPresetTemplateState} from '../../state/features/presetTemplate/presetTemplateSelector';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import Typography from '@mui/material/Typography';
import {getLanguagesList} from '../../helpers/utility/getLanguagesList';
import {creditSettingsAITranscription, creditSettingSTextContent} from "../../helpers/constant/coreConstant";
import BalanceCheck from "../../assets/global/BalanceCheck/BalanceCheck";

const StyledTextArea = styled(TextareaAutosize)(({theme}) => ({
    width: '100%',
    padding: '10px',
    borderRadius: '8px',
    borderColor: '#d1d5db',
    boxShadow: '0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(0,0,0,.05)',
    '&:focus-visible': {
        outlineColor: '#1976d2'
    }
}));

const PresetTemplateDetailsForm = ({specificPresetTemplate}) => {
    const dispatch = useDispatch();
    const {isLoading} = useSelector(selectPresetTemplateState);
    const [radioButtonValue, setRadioButtonValue] = useState(4000);

    let languages = getLanguagesList();

    const {handleSubmit, control, reset} = useForm();
    const handleRadioButtonValue = data => {
        setRadioButtonValue(data);
    };

    const handleClearInput = () => {
        reset();
    };

    let pasrseSpecificPresetTemplate = '';
    if (specificPresetTemplate) {
        pasrseSpecificPresetTemplate = JSON.parse(specificPresetTemplate?.placeholder);
    }
    const onSubmit = data => {
        data.preset = specificPresetTemplate?.title;
        data.length = radioButtonValue;
        dispatch(getPresetTemplateGeneratedResponse(data));
    };

    return (
        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Box
                sx={{
                    width: '80%',
                    m: 'auto',
                    p: 3,
                    backgroundColor: '#FFF',
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8
                }}
            >
                <PresetTemplateDetailsHeader
                    icon={specificPresetTemplate?.icon}
                    title={specificPresetTemplate?.title}
                    subtitle={specificPresetTemplate?.short_description}
                    specificPresetTemplate={specificPresetTemplate}
                />

                <Box sx={{marginTop: '20px'}}>
                    {Array.isArray(pasrseSpecificPresetTemplate) &&
                        pasrseSpecificPresetTemplate.map((item, i) => (
                            <Box key={i}>
                                {item.type && item.type === 'textarea' ? (
                                    <Box mb={3}>
                                        <Stack direction="row" justifyContent="space-between">
                                            <Typography fontSize={14} fontWeight={500} sx={{color: '#374151', mb: 0.7}}>
                                                {item?.label}
                                            </Typography>
                                        </Stack>

                                        <Controller
                                            control={control}
                                            name={item?.name}
                                            defaultValue=""
                                            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                                <StyledTextArea
                                                    value={value}
                                                    onChange={value => {
                                                        onChange(value);
                                                    }}
                                                    minRows={4}
                                                    placeholder={item?.placeholder || ''}
                                                />
                                            )}
                                        />

                                        <Typography color={'#4b5563'} fontSize={12}>
                                            {item?.tooltip || ''}
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Box mb={3}>
                                        <Controller
                                            control={control}
                                            name={item?.name}
                                            defaultValue=""
                                            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                                <>
                                                    <InputLabel
                                                        sx={{color: '#374151', mb: 0.7}}>{item?.label}</InputLabel>
                                                    <OutlinedInput
                                                        fullWidth
                                                        id="send"
                                                        size="small"
                                                        placeholder={item?.placeholder}
                                                        value={value}
                                                        onChange={value => {
                                                            onChange(value);
                                                        }}
                                                        sx={{
                                                            color: '#222',
                                                            border: 'none'
                                                        }}
                                                    />
                                                </>
                                            )}
                                        />
                                    </Box>
                                )}

                                {/*<Box mb={3}>*/}
                                {/*    <Controller*/}
                                {/*        control={control}*/}
                                {/*        name={item?.name}*/}
                                {/*        defaultValue=""*/}
                                {/*        render={({field: {value, onChange}, fieldState: {invalid, error}}) => (*/}
                                {/*            <>*/}
                                {/*                <InputLabel sx={{color: '#374151', mb: 0.7}}>Include emojis?</InputLabel>*/}
                                {/*                <Switch label="Label" defaultChecked />*/}
                                {/*            </>*/}
                                {/*        )}*/}
                                {/*    />*/}
                                {/*</Box>*/}
                            </Box>
                        ))}
                </Box>

                <Box mb={5}>
                    {specificPresetTemplate?.show_output_length ? (
                        <PresetTemplateDetailsRadioButton handleRadioButtonValue={handleRadioButtonValue}/>
                    ) : (
                        ''
                    )}
                </Box>
                <BalanceCheck content_type={creditSettingSTextContent} amount_data={1000}/>
            </Box>

            <Box
                sx={{
                    width: '80%',
                    m: 'auto',
                    p: '12px 24px',
                    borderTop: '1px solid rgb(229 231 235/1)',
                    backgroundColor: 'rgb(249 250 251/1)',
                    borderBottomLeftRadius: 8,
                    borderBottomRightRadius: 8,
                    bgcolor: '#f9fafb'
                }}
            >
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Button
                        onClick={handleClearInput}
                        sx={{
                            bgColor: 'white !important',
                            color: '#374151',
                            borderColor: '#374151',
                            ':hover': {borderColor: '#374151'}
                        }}
                        variant="outlined"
                        startIcon={<CloseIcon/>}
                    >
                        Clear Inputs
                    </Button>

                    <Stack direction="row" spacing={2} justifyContent="flex-end">
                        <Controller
                            control={control}
                            name="output"
                            defaultValue="1"
                            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                <FormControl size="small">
                                    <InputLabel>Output</InputLabel>
                                    <Select
                                        id="demo-simple-select"
                                        value={value}
                                        label="Output"
                                        sx={{minWidth: 100, backgroundColor: 'white'}}
                                        onChange={value => {
                                            onChange(value);
                                        }}
                                    >
                                        <MenuItem value={1}>1</MenuItem>
                                        <MenuItem value={2}>2</MenuItem>
                                        <MenuItem value={3}>3</MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                        />

                        <Controller
                            control={control}
                            name="language"
                            defaultValue="en"
                            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                <FormControl size="small">
                                    <InputLabel id="demo-simple-select-label">Language</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={value}
                                        label="Language"
                                        sx={{minWidth: 150, bgcolor: 'white'}}
                                        onChange={value => {
                                            onChange(value);
                                        }}
                                    >
                                        {languages &&
                                            languages.map(item => <MenuItem value={item?.value}>{item?.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            )}
                        />

                        {/*<Button*/}
                        {/*    sx={{backgroundImage: 'linear-gradient(to right, #9333ea, #4f46e5)'}}*/}
                        {/*    variant="contained"*/}
                        {/*    startIcon={<SmartToyIcon/>}*/}
                        {/*    type={'submit'}*/}
                        {/*>*/}
                        {/*    Generate*/}
                        {/*</Button>*/}

                        <LoadingButton
                            sx={{backgroundImage: 'linear-gradient(to right, #9333ea, #4f46e5)'}}
                            sx={{backgroundColor: '#009EF7'}}
                            variant="contained"
                            isLoading={isLoading}
                            loadingText={'Generating...'}
                            type={'submit'}
                            startIcon={<SmartToyIcon/>}
                        >
                            Generate
                        </LoadingButton>
                    </Stack>
                </Stack>
            </Box>
        </Box>
    );
};

export default PresetTemplateDetailsForm;
