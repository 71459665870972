// @mui
import PropTypes from 'prop-types';
import { Box, Card, Paper, Typography, CardHeader, CardContent } from '@mui/material';
import {appNavBar} from "../../helpers/utility/appSidebarHelper";
import {useSelector} from "react-redux";
import {selectPackageAccessFeatureState} from "../../state/features/packageAccessFeature/packageAccessFeatureSelector";
import {selectTotalDashboardCountState} from "../../state/features/dashboard/totalDashboardCountSelector";
import {
    DASHBOARD__TOTAL_CONTENT_COUNT, DASHBOARD__TOTAL_PLATFORM_COST,
    DASHBOARD__TOTAL_USER_COST,
    PACKAGE_FEATURE__KEY__AUDIO
} from "../../helpers/constant/coreConstant";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import TranscribeIcon from "@mui/icons-material/Transcribe";
import PeopleIcon from "@mui/icons-material/People";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import React from "react";


export default function AppTrafficBySite({ title, subheader, list, ...other }) {

    const {featureAccess} = useSelector(selectPackageAccessFeatureState);

    let AppTraffic = [];
    AppTraffic = list.filter(list => {
        return featureAccess.some(featureAccess => {
            return featureAccess === list.key;
        });
    });

    let ExceptAppTraffic = [];
    ExceptAppTraffic = list.filter(list => {
        return !featureAccess.some(featureAccess => {
            return featureAccess === list.key;
        });
    });

    return (
        <Card {...other}>
            <CardHeader title={title} subheader={subheader} />

            <CardContent>
                <Box
                    sx={{
                        display: 'grid',
                        gap: 2,
                        gridTemplateColumns: 'repeat(2, 1fr)',
                    }}
                >
                    {AppTraffic.map((site) => (
                        <Paper key={site.name} variant="outlined" sx={{ py: 2.5, textAlign: 'center' }}>
                            <Box sx={{ mb: 0.5 }}>{site.icon}</Box>

                            <Typography variant="h6">{site.value}</Typography>

                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                {site.name}
                            </Typography>
                        </Paper>
                    ))}

                    {ExceptAppTraffic.map((site) => (
                        <Paper key={site.name} variant="outlined" sx={{ py: 2.5, textAlign: 'center', borderColor: 'error.main', opacity: '0.5' }} disable>
                            <Box sx={{ mb: 0.5 }}>{site.icon}</Box>

                            <Typography variant="h6">0</Typography>

                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                {site.name}
                            </Typography>
                            <Typography sx={{ p: 1, color: 'error.main', margin: '0px', fontSize: '10px' }}>
                                To access this feature you need to upgrade your package.
                            </Typography>
                        </Paper>
                    ))}
                </Box>
            </CardContent>
        </Card>
    );
}
