import {requests} from "./http.service";

class PromptService {
    getPromptTemplateData(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/templates", body);
    }

    getSpecificPromptTemplateData(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/specific/template", body);
    }

    getGeneratedResponseData(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/generate-response", body);
    }
}

export default new PromptService();
