import React, {useEffect, useState} from 'react';
import {Typography} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {selectPromptState} from '../../state/features/prompts/promptSelector';
import {clearState} from '../../state/features/prompts/promptSlice';
import {makeStyles} from "@mui/styles";
import Paper from "@mui/material/Paper";


const useStyles = makeStyles((theme) => ({

    root: {
        padding: '10px 25px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        border:'1px solid #1976d2',
        lineHeight:'1.4',
        boxShadow:'rgba(145, 158, 171, 0.16) -40px 40px 80px'
    },
    title: {
        fontWeight: 'bold',
        marginBottom: '10px',
    },
    dataContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '10px',
        textAlign:'justify'
    },
    dataItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px',
        backgroundColor: '#fff',
        color: '#222',
        borderRadius: '5px',
        margin: '10px',
    },
}));

const PromptPreview = () => {
    const {promptInputText, isLoading} = useSelector(selectPromptState);
    const classes = useStyles();

    return (
        <>
            {/*<Typography variant="h4">Preview</Typography>*/}

            <Paper className={classes.root}>
                <Typography variant="h5" className={classes.title}>
                    Preview Template
                </Typography>
                <div className={classes.dataContainer}>
                    {isLoading ? 'Loading' : <div dangerouslySetInnerHTML={{__html: promptInputText}}/>}
                </div>
            </Paper>
        </>
    );
};

export default PromptPreview;
