import { requests } from "./http.service";

class BalanceService {
    getAllBalanceData() {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/balance/all/data");
    }

    getSpecificBalanceData(params) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/balance/key/wise/data", params);
    }
}

export default new BalanceService();
