import { requests } from "./http.service";

class PackageService {
    getPackages() {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/package/list");
    }

    buyPackage(params) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/package/buy", params);
    }

    upgradePackage(params) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/package/upgrade", params);
    }

    getPackageDiscountDataByUserPackageUid(params) {
        return requests.get(
            process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/package/discount/data/by/userPackageId",
            params
        );
    }
}

export default new PackageService();
