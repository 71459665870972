import React, {useEffect} from 'react';
import StylesCard from './Styles/Card.module.css';
import {Box} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import UserPaymentCardItem from './UserPaymentCardItem';
import {getUserPaymentCardList} from '../../state/features/userPayment/userPaymentSlice';

const UserPaymentCard = () => {
    const dispatch = useDispatch();
    const {paymentCardList, loading} = useSelector(state => state.userPayment);

    useEffect(() => {
        dispatch(getUserPaymentCardList());
    }, []);

    return (
        <>
            <Box className={StylesCard.container} sx={{padding: '24px 0'}}>
                {paymentCardList &&
                    paymentCardList.map(cardData => (
                        <UserPaymentCardItem key={cardData.uid} cardData={cardData}/>
                    ))}
            </Box>
        </>
    );
};

export default UserPaymentCard;
