import {requests} from "./http.service";

class TotalDashboardCountService{
    totalDashboardCount(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/dashboard/get/total/stat/count", body);
    }

    totalGraphData(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/dashboard/get/total/stat/count/graph/data", body);
    }
}

export default new TotalDashboardCountService();
