import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    aiTranscriptionData: null,
    isLoading: false,
    isError: false,
    error: ''
};

const aiTranscriptionSlice = createSlice({
    name: 'aiTranscription',
    initialState: initialState,
    reducers: {
        aiTranscription: (state, action) => {
            state.isLoading = true;
        },
        aiTranscriptionSuccess: (state, action) => {
            state.aiTranscriptionData = action?.payload;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        aiTranscriptionFailed: (state, action) => {
            state.aiTranscriptionData = null;
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },
    }
});

export const {
    aiTranscription,
    aiTranscriptionSuccess,
    aiTranscriptionFailed,
} = aiTranscriptionSlice.actions;

export default aiTranscriptionSlice.reducer;
