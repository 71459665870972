import { requests } from "./http.service";

class UserBillingService {
    addPaymentCard() {
        return requests.post(process.env.REACT_APP_API_URL_PACKAGE_SERVER + "api/v1/user/payment/add/card/info");
    }

    getPaymentCardList() {
        return requests.get(process.env.REACT_APP_API_URL_PACKAGE_SERVER + "api/v1/user/payment/get/card/info");
    }

    getSpecificPaymentCard() {
        return requests.get(
            process.env.REACT_APP_API_URL_PACKAGE_SERVER +
                "api/v1/user/payment/get/agency/card/info/for/user/payment/card"
        );
    }

    updatePaymentCardAsDefault() {
        return requests.put(
            process.env.REACT_APP_API_URL_PACKAGE_SERVER + "/user/payment/update/card/setup/as/default"
        );
    }

    getUserBillingHistory(params) {
        return requests.get(process.env.REACT_APP_API_URL_PACKAGE_SERVER + "api/v1/user/invoice/get/all/data", params);
    }
}

export default new UserBillingService();
