import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    imageToPromptData: null,
    isLoading: false,
    isError: false,
    error: ''
};

const ImageToPromptSlice = createSlice({
    name: 'imageToPrompt',
    initialState: initialState,
    reducers: {
        imageToPrompt: (state, action) => {
            state.isLoading = true;
        },
        imageToPromptSuccess: (state, action) => {
            state.imageToPromptData = action?.payload[0]?.text;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        imageToPromptFailed: (state, action) => {
            state.imageToPromptData = null;
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },
        imageToPromptStateClear: (state, action) => {
            state.imageToPromptData = null;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
    }
});

export const {
    imageToPrompt,
    imageToPromptSuccess,
    imageToPromptFailed,
    imageToPromptStateClear,
} = ImageToPromptSlice.actions;

export default ImageToPromptSlice.reducer;
