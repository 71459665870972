import {requests} from "./http.node.service";

class TextToMemeService {
    generateTextToMeme(body) {
        return requests.get(process.env.REACT_APP_API_URL_TENOR_API + "/search", body);
    }
    templateSearch(body) {
        return requests.get(process.env.REACT_APP_API_URL_TENOR_API + "/search", body);
    }
    aiGeneratedTextForTemplate(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_NODE_SERVER + "/generate/meme/text", body);
    }
    downloadGif(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_NODE_SERVER + "/gif/download", body);
    }
    downloadImage(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "download/image", body);
    }
}

export default new TextToMemeService();
