import React, {useState} from 'react';
import {Button, Stack} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import FullPageDrawer from "../Drawer/FullPageDrawer";
import TextToMemeHelperText from "../../../components/Helpertext/TextToMemeHelperText";
import MemeEditHelperText from "../../../components/Helpertext/MemeEditHelperText";
import ThemedMemeHelperText from "../../../components/Helpertext/ThemedMemeHelperText";
import SearchTemplatesHelperText from "../../../components/Helpertext/SearchTemplatesHelperText";
import MemeMakerHelperText from "../../../components/Helpertext/MemeMakerHelperText";

const MemeMakerHeader = ({title = '', handleBack}) => {
    const [viewThemedMemePageShow, setViewThemedMemePageShow] = useState(false);
    const toggleViewThemedMeme = () => setViewThemedMemePageShow(prevState => !prevState);

    const [viewTextToMemePageShow, setViewTextToMemePageShow] = useState(false);
    const toggleViewTextToMeme = () => setViewTextToMemePageShow(prevState => !prevState);

    const [viewSearchTemplateageShow, setViewSearchTemplatePageShow] = useState(false);
    const toggleViewSearchTemplate = () => setViewSearchTemplatePageShow(prevState => !prevState);

    const [viewMemeEditShow, setViewMemeEditShow] = useState(false);
    const toggleViewMemeEdit = () => setViewMemeEditShow(prevState => !prevState);

    const [viewMemeMakerShow, setViewMemeMakerShow] = useState(false);
    const toggleViewMemeMaker = () => setViewMemeMakerShow(prevState => !prevState);

    const handleHelperText = (title) => {
        if (title === 'themed-meme') {
            toggleViewThemedMeme()
        } else if (title === 'text-to-meme') {
            toggleViewTextToMeme()
        } else if (title === 'search-template') {
            toggleViewSearchTemplate()
        } else if (title === 'meme-edit') {
            toggleViewMemeEdit()
        } else if (title === 'meme-maker') {
            toggleViewMemeMaker()
        }

    }
    return (
        <>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Button
                    sx={{ml: 2, mb: 2, backgroundImage: 'linear-gradient(to right, #9333ea, #4f46e5)', color: '#ffff'}}
                    onClick={handleBack}
                    startIcon={<ArrowBackIcon/>}
                >
                    Back
                </Button>
                <Button
                    sx={{
                        ml: 2,
                        mb: 2,
                        mr: 1,
                        backgroundImage: 'linear-gradient(to right, #9333ea, #4f46e5)',
                        color: '#ffff'
                    }}
                    onClick={() => handleHelperText(title)}
                    startIcon={<HelpOutlineOutlinedIcon/>}
                >
                    Help
                </Button>
            </Stack>

            <FullPageDrawer
                title="Themed Meme Features Description"
                open={viewThemedMemePageShow}
                toggleDrawer={toggleViewThemedMeme}
                width="75%"
                height="90%"
            >
                <ThemedMemeHelperText/>
            </FullPageDrawer>

            <FullPageDrawer
                title="Text to Meme Features Description"
                open={viewTextToMemePageShow}
                toggleDrawer={toggleViewTextToMeme}
                width="75%"
                height="90%"
            >

                <TextToMemeHelperText/>

            </FullPageDrawer>

            <FullPageDrawer
                title="Search Template Features Description"
                open={viewSearchTemplateageShow}
                toggleDrawer={toggleViewSearchTemplate}
                width="75%"
                height="90%"
            >
                <SearchTemplatesHelperText/>
            </FullPageDrawer>

            <FullPageDrawer
                title="Meme Edit Features Description"
                open={viewMemeEditShow}
                toggleDrawer={toggleViewMemeEdit}
                width="75%"
                height="90%"
            >
                <MemeEditHelperText/>
            </FullPageDrawer>

            <FullPageDrawer
                title="Meme Maker Features Description"
                open={viewMemeMakerShow}
                toggleDrawer={toggleViewMemeMaker}
                width="75%"
                height="90%"
            >
                <MemeMakerHelperText/>
            </FullPageDrawer>

        </>
    );
}

export default MemeMakerHeader;