import {requests} from "./http.node.service";

class ImageInPaintingService {
    imageInPainting(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_NODE_SERVER + "/image/generate/with/inpainting", body);
    }

}

export default new ImageInPaintingService();
