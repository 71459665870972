import React, {useEffect, useState} from 'react';
import {Box, Divider, FormControl, InputLabel, MenuItem, Paper, Select, Typography} from '@mui/material';
import StylesBalanceHistory from './Styles/BalanceHistory.module.css';
import DataTable from 'react-data-table-component';
import {useTheme} from '@mui/material/styles';
import './Styles/BalanceHistory.css';
import {useDispatch, useSelector} from 'react-redux';
import NoBillingImage from '../../assets/images/Vectors/lady-seat.svg';
import {getTopUpRechargeInvoiceReport} from '../../state/features/userBilling/userBillingSlice';
import {invoiceForConstant} from '../../helpers/utility/invoiceForConstant';
import TableSkeletonLoader from '../../assets/global/SkeletonLoader/components/TableSkeletonLoader';
import EmptyPage from '../../assets/global/EmptyComponent/EmptyPage';
import {USER_INVOICE__FOR_RECHARGE_TOP_UP_AMOUNT} from '../../helpers/constant/coreConstant';

const BalanceHistory = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const {topUpRechargeInvoices, perPage, total, loading} = useSelector(state => state.userBilling);

    const [balanceHistorySearchQuery, setBalanceHistorySearchQuery] = useState({
        name: '',
        invoice_for: '',
        status: null
    });

    const handleSearch = query => {
        setBalanceHistorySearchQuery({...balanceHistorySearchQuery, name: query});
    };

    const handleChangeBalanceHistory = event => {
        setBalanceHistorySearchQuery({...balanceHistorySearchQuery, invoice_for: event.target.value});
    };

    useEffect(() => {
        dispatch(getTopUpRechargeInvoiceReport({extra: {...balanceHistorySearchQuery}}));
    }, [balanceHistorySearchQuery]);

    const onChangePerPage = (perPage, newPage) => {
        dispatch(
            getTopUpRechargeInvoiceReport({
                page: newPage,
                per_page: perPage,
                extra: {...balanceHistorySearchQuery}
            })
        );
    };

    const onChangePage = newPage => {
        dispatch(
            getTopUpRechargeInvoiceReport({
                page: newPage,
                per_page: perPage,
                extra: {...balanceHistorySearchQuery}
            })
        );
    };

    const Columns = [
        {
            minWidth: '156px',
            name: (
                <Typography className="truncate" color="text.primary" variant="TableHeader">
                    Amount For
                </Typography>
            ),
            selector: row => {
                return (
                    <Typography
                        className="truncate global__table__body__font"
                        color="text.primary"
                        variant={'body2'}
                    >
                        {invoiceForConstant(row.invoice_for)}
                    </Typography>
                );
            },
            sortable: false
        },
        {
            minWidth: '156px',
            name: (
                <Typography className="truncate" color="text.primary" variant="TableHeader">
                    Card Number
                </Typography>
            ),
            selector: row => {
                return (
                    <Typography
                        className="truncate global__table__body__font"
                        color="text.primary"
                        variant={'body2'}
                    >
                        **** **** **** {row.card_number}
                    </Typography>
                );
            },
            sortable: false
        },
        {
            minWidth: '156px',
            name: (
                <Typography className="truncate" color="text.primary" variant="TableHeader">
                    Amounts($)
                </Typography>
            ),
            selector: row => {
                return (
                    <Typography
                        className="truncate global__table__body__font"
                        color="text.primary"
                        variant={'body2'}
                    >
                        ${row.payment_amount}
                    </Typography>
                );
            }
        },
        {
            minWidth: '202px',
            name: (
                <Typography className="truncate" color="text.primary" variant="TableHeader">
                    Billing Date/ Time
                </Typography>
            ),
            selector: row => {
                return (
                    <Typography
                        className="truncate global__table__body__font"
                        color="text.primary"
                        variant={'body2'}
                    >
                        {row.local_created_at && row.local_created_at.formatted_time}
                    </Typography>
                );
            },
            sortable: false
        }
    ];

    return (
        <>
            <Paper elevation={0}>
                <Box className={`${StylesBalanceHistory.balance__history}`}>
                    <Box className={StylesBalanceHistory.balance__history__left}>
                        <Typography variant="h6">Balance History</Typography>
                    </Box>

                    <Box className={StylesBalanceHistory.balance__history__right}>
                        <FormControl
                            required
                            size="small"
                            fullWidth
                            className={StylesBalanceHistory.balance__history__select}
                        >
                            <InputLabel id="demo-simple-select-label">All</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={balanceHistorySearchQuery.invoice_for}
                                label="All"
                                onChange={handleChangeBalanceHistory}
                            >
                                <MenuItem value={''}>All</MenuItem>
                                <MenuItem value={USER_INVOICE__FOR_RECHARGE_TOP_UP_AMOUNT}>
                                    {invoiceForConstant(USER_INVOICE__FOR_RECHARGE_TOP_UP_AMOUNT)}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                <Divider/>

                <Box
                    className="balance__history__table"
                    sx={{
                        pt: 2,
                        '& .rdt_TableHeadRow': {
                            backgroundColor: "rgba(148, 157, 178, 0.12)"
                        },
                        '& .rdt_TableBody': {
                            '& .rdt_TableRow': {
                                borderBottomColor: 'rgba(148, 157, 178, 0.24)',
                                backgroundColor: theme.palette.background.paper
                            }
                        },
                        '& .rdt_Pagination': {
                            backgroundColor: theme.palette.background.paper
                        }
                    }}
                >
                    <DataTable
                        theme={theme.palette.mode === 'dark' ? 'dark' : ''}
                        persistTableHead
                        columns={Columns}
                        data={topUpRechargeInvoices}
                        pagination={true}
                        paginationServer
                        progressPending={loading}
                        progressComponent={<TableSkeletonLoader columns={Columns} numberOfRows={6}/>}
                        noDataComponent={
                            <EmptyPage
                                img={NoBillingImage}
                                title={'No Balance History Found!'}
                                subTitle={"You don't have any balance history"}
                            />
                        }
                        paginationTotalRows={total}
                        paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100]}
                        onChangeRowsPerPage={onChangePerPage}
                        onChangePage={onChangePage}
                    />
                </Box>
            </Paper>
        </>
    );
};

export default BalanceHistory;
