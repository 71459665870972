import React from 'react';
import PromptInitialPage from './promptInitialPage';
import PromptLoadingPage from './promptLoadingPage';
import PromptResponseMessage from './PromptResponseMessage';
import PromptPage from './PromptPage';
import {useSelector} from 'react-redux';
import {selectPromptState} from '../../state/features/prompts/promptSelector';

const PromptRight = ({templateDate}) => {
    const {isLoading, isResponseSuccess} = useSelector(selectPromptState);

    let content = <PromptInitialPage/>;

    if (isLoading) {
        content = <PromptLoadingPage/>;
    }

    if (isResponseSuccess) {
        content = <PromptResponseMessage templateDate={templateDate} />;
    }

    return (
        <>
            <PromptPage templateDate={templateDate}>{content}</PromptPage>
        </>
    );
};

export default PromptRight;
