import {requests} from "./http.node.service";

class ContentRepurposingService {
    contentRepurposing(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_NODE_SERVER + "/content/repurposing/generate/data", body);
    }

    contentRepurposingInputOutputList(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_NODE_SERVER + "/content/repurposing/get/input/output/type/list/data", body);
    }
}

export default new ContentRepurposingService();
