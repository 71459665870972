import AuthLayout from '../components/layout/AuthLayout/AuthLayout';
import {Navigate} from 'react-router-dom';
import Login from '../components/authUser/Login';
import Register from "../components/authUser/Register";
import Forgot from "../components/authUser/Forgot";
import ResetPassword from "../components/authUser/ResetPassword";
import SwitchUserAccount from "../components/AccountSwitch/SwitchUserAccount";

const AuthenticationRoutes = {
  path: '/',
  element: <AuthLayout />,
  children: [
    {
      path: '',
      element: <Login element={<Navigate to="login" replace={true} />} />
    },
    {
      path: '/login',
      element: <Login />
    },
    {
      path: '/register',
      element: <Register />
    },
    {
      path: '/forgot',
      element: <Forgot />
    },
    {
      path: '/reset-password',
      element: <ResetPassword />
    },
    {
      path: '/account/switch',
      element: <SwitchUserAccount />
    }
  ]
};

export default AuthenticationRoutes;
