import { requests } from "./http.service";

class UserPackageService {
    getUserPackages() {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/package/user/get/all");
    }

    buyPackage(params) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/package/user/buy", params);
    }

    userHasPackageData() {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/has/package/check/data");
    }
    userItemAccess() {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/package/get/feature/key/for/specific/user");
    }
}

export default new UserPackageService();
