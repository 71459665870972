import React from 'react';
import {Avatar, Box, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Stack} from "@mui/material";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LanguageIcon from '@mui/icons-material/Language';
import TextsmsIcon from '@mui/icons-material/Textsms';
import PostAddIcon from '@mui/icons-material/PostAdd';
import SummarizeIcon from '@mui/icons-material/Summarize';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';

const ContentRepurposingHelpertext = () => {

    return (
        <>
            <Stack
                spacing={2}
                sx={{
                    backgroundColor: '#F5F5F5',
                    padding: '20px',
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <List
                    sx={{
                        width: '90%',
                        bgcolor: '#e3f3e7',
                        marginBottom: '20px',
                    }}
                >

                    <ListItem>
                        <ListItemAvatar>
                            <Avatar>
                                <RecordVoiceOverIcon/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Content Repurposing"
                                      secondary="Content Repurposing Feature Description"/>
                    </ListItem>
                    <Divider variant="inset" component="li"/>
                    <Box sx={{
                        alignItems: "center",
                        padding: '20px',
                    }}>
                        <Typography component="div">
                            <Box sx={{fontFamily: 'Monospace', fontSize: 'h6.fontSize', m: 1, fontWeight: 'bold'}}>
                                Input Types
                            </Box>
                            <Divider/>
                            <Box sx={{fontFamily: 'Monospace', m: 1}}> You can select three types of input here :
                            </Box>
                            <List>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <YouTubeIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Youtube Video URL"/>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <LanguageIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Website URL"/>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <TextsmsIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Input Text"/>
                                </ListItem>
                            </List>

                        </Typography>

                        <Typography component="div">
                            <Box sx={{fontFamily: 'Monospace', fontSize: 'h6.fontSize', m: 1, fontWeight: 'bold'}}>
                                Output Types
                            </Box>
                            <Divider/>
                            <Box sx={{fontFamily: 'Monospace', m: 1}}> There are twelve corresponding output based on
                                the input :
                            </Box>
                            <List>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <PostAddIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Blog Post"/>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <SummarizeIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Summary Outline"/>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <FacebookIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Facebook Campaign"/>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <TwitterIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Twitter Campaign"/>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <InstagramIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Instagram Campaign"/>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <LinkedInIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="LinkedIn Campaign"/>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <SummarizeIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Summary"/>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <EmailIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Sales Email"/>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <FacebookIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Facebook Through Leadership"/>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <TwitterIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Twitter Through Leadership"/>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <LinkedInIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="LinkedIn Through Leadership"/>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <InstagramIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Instagram Through Leadership"/>
                                </ListItem>
                            </List>
                        </Typography>

                        <Typography component="div">
                            <Box sx={{fontFamily: 'Monospace', fontSize: 'h6.fontSize', m: 1, fontWeight: 'bold'}}>
                                Description
                            </Box>
                            <Divider/>
                            <Box sx={{fontFamily: 'Monospace', m: 1}}>
                                If you select Youtube URL or Website URl as input than you have to give that URL. For
                                selecting Input Text you have to write that text. For better understanding watch this
                                video.
                            </Box>
                        </Typography>

                        {/*<Box sx={{*/}
                        {/*    margin: '30px',*/}
                        {/*}}>*/}
                        {/*    <div>*/}
                        {/*        <video width="100%" height="100%" controls>*/}
                        {/*            <source src={} type="video/mp4"/>*/}
                        {/*            /!*<source src="https://assets.codepen.io/6093409/river.mp4" type="video/ogg"/>*!/*/}
                        {/*        </video>*/}
                        {/*    </div>*/}
                        {/*</Box>*/}
                    </Box>
                </List>

            </Stack>
        </>
    );
};

export default ContentRepurposingHelpertext;
