import React from 'react';
import {Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Stack} from "@mui/material";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import AudioToText from '../../assets/videos/Audio to text.mp4';

const AITranscriptionHelpertext = () => {

    return (
        <>
            <Stack
                spacing={2}
                sx={{
                    backgroundColor: '#F5F5F5',
                    padding: '20px',
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <List
                    sx={{
                        width: '90%',
                        bgcolor: '#e3f3e7',
                        marginBottom: '20px',
                    }}
                >

                    <ListItem>
                        <ListItemAvatar>
                            <Avatar>
                                <RecordVoiceOverIcon/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="AI Transcription"
                                      secondary="AI Transcription Feature Description"/>
                    </ListItem>
                    <Divider variant="inset" component="li"/>
                    <Box sx={{
                        alignItems: "center",
                        padding: '20px',
                    }}>
                        <Typography component="div">
                            <Box sx={{fontFamily: 'Monospace', fontSize: 'h6.fontSize', m: 1, fontWeight: 'bold'}}>
                                Upload Audio
                            </Box>
                            <Divider/>
                            <Box sx={{fontFamily: 'Monospace', m: 1}}>
                                To generate Audio to Text, you have to upload an audio file here(less than 8MB). Our AI
                                tool will generate this audio file to text.
                            </Box>
                        </Typography>

                        <Typography component="div">
                            <Box sx={{fontFamily: 'Monospace', fontSize: 'h6.fontSize', m: 1, fontWeight: 'bold'}}>
                                Audio file description
                            </Box>
                            <Divider/>
                            <Box sx={{fontFamily: 'Monospace', m: 1}}>
                                Give a brief description about the audio file that you have
                                uploaded.
                            </Box>
                        </Typography>
                        <Typography component="div">
                            <Box sx={{fontFamily: 'Monospace', fontSize: 'h6.fontSize', m: 1, fontWeight: 'bold'}}>
                                Select audio language
                            </Box>
                            <Divider/>
                            <Box sx={{fontFamily: 'Monospace', m: 1}}>
                                Select a language of your audio file.
                            </Box>
                        </Typography>

                        <Typography component="div">
                            <Box sx={{fontFamily: 'Monospace', fontSize: 'h6.fontSize', m: 1, fontWeight: 'bold'}}>
                                Video Description
                            </Box>
                            <Divider/>
                            <Box sx={{fontFamily: 'Monospace', m: 1}}>
                                Here is a video description for AI Transcription.
                            </Box>
                        </Typography>

                        <Box sx={{
                            margin: '30px',
                        }}>
                            <div>
                                <video width="100%" height="100%" controls>
                                    <source src={AudioToText} type="video/mp4"/>
                                    {/*<source src="https://assets.codepen.io/6093409/river.mp4" type="video/ogg"/>*/}
                                </video>
                            </div>
                        </Box>
                    </Box>
                </List>

            </Stack>
        </>
    );
};

export default AITranscriptionHelpertext;
