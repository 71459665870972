import {requests} from "./http.node.service";

class MemeMakerService {
    memeMaker(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_NODE_SERVER + "/image/generate/promots/alttext/from/image", body);
    }

    saveGif(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_NODE_SERVER + "/gif/save", body);
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new MemeMakerService();