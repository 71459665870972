import React, {useEffect, useState} from 'react';
import {Box, Stack} from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/system/Unstable_Grid';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {selectPresetTemplateState} from '../../state/features/presetTemplate/presetTemplateSelector';
import {getPresetTemplate, resetPage} from '../../state/features/presetTemplate/presetTemplateSlice';
import Skeleton from '@mui/material/Skeleton';
import EmptyPage from '../../assets/global/EmptyComponent/EmptyPage';
import noDataImage from '../../assets/images/no_data.jpg';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import '../../assets/global/css/MaxText.css';
import Styles from './styles/PresetTemplateList.module.scss';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const PresetTemplateList = () => {
    const dispatch = useDispatch();
    const {presetTemplate, page, total, perPage, isLoading, filters} =
        useSelector(selectPresetTemplateState);
    const [pageCountStage, setPageCount] = useState(1);

    const pageCount = Math.ceil(total / perPage);
    // unsubscribe or reset state
    useEffect(() => {
        return () => dispatch(resetPage());
    }, [pageCountStage]);

    useEffect(() => {
        setPageCount(pageCount);
    }, [pageCount]);

    useEffect(() => {
        dispatch(
            getPresetTemplate({
                search_key: filters.title,
                category_id: filters.category_id,
                page: page,
                limit: perPage
            })
        );
    }, [dispatch, filters.title, filters.category_id]);

    const handleChange = (event, value) => {
        dispatch(
            getPresetTemplate({
                search_key: filters.title,
                category_id: filters.category_id,
                page: value,
                limit: perPage
            })
        );
    };

    // decide to what to render on view
    let content = null;

    if (isLoading) {
        content = (
            <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}} sx={{p: 3, width:'100%'}}>
                {[1, 2, 3, 4].map(collection => (
                    <Grid key={collection} item xs={3}>
                        <Box sx={{p: 3, marginRight: 0.5}}>
                            <Skeleton variant="rectangular" height={118}/>
                            <Skeleton/>
                            <Skeleton width="60%"/>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        );
    } else if (!isLoading && presetTemplate.length > 0) {
        content = (
            <>
                {presetTemplate.map(template => (
                    <Grid
                        key={template.id}
                        xs={6}
                        md={6}
                        lg={4}
                        xl={3}
                        sx={{
                            backgroundColor: '#fff',
                            p: 3,
                            border: '.5px solid #e5e7eb'
                        }}
                        className={Styles.wrapper}
                    >
                        <Link className={Styles.itemLink} to={`/presets/templates/${template.id}`}>
                            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                <Stack direction={'row'} spacing={2}>
                                    {template?.icon ? (
                                        <Box sx={{bgcolor: template?.extra ? JSON.parse(template?.extra).color : '#f0fdf4', padding: '12px 12px 6px', borderRadius: '8px'}}>
                                            <img src={template?.icon || ''} width="24px" height="24px"/>
                                        </Box>
                                    ) : (
                                        <TipsAndUpdatesIcon sx={{width: '40px', height: '40px'}}/>
                                    )}
                                </Stack>
                                <ArrowOutwardIcon className={Styles.arrowOutWard}/>
                            </Box>
                            <Typography
                                variant="h5"
                                sx={{fontSize: '20px', marginTop: 4}}
                                className="max-1line-text"
                                color={'black'}
                            >
                                {template.title}
                            </Typography>

                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: 400,
                                    marginTop: 1,
                                    color: 'rgb(107 114 128)!important'
                                }}
                                className="max-3linclassNamee-text"
                            >
                                {template?.short_description}
                            </Typography>
                            {/*<Box className={Styles.star}>*/}
                            {/*    <StarBorderIcon/>*/}
                            {/*</Box>*/}
                        </Link>
                    </Grid>
                ))}
            </>
        );
    } else if (!isLoading && presetTemplate.length === 0) {
        content = (
            <>
                <EmptyPage title="No information was discovered." img={noDataImage}/>
            </>
        );
    }

    return (
        <Box
            sx={{
                backgroundColor: 'rgb(229 231 235/1)',
                borderRadius: '15px !important',
                overflow: 'hidden' /* boxShadow: 2, */ /*  border: '1px solid #e5e7eb', */
            }}
        >
            <Grid container spacing={0}>
                {content}

                {presetTemplate && presetTemplate.length > 0 && (
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        sx={{p: 1}}
                        style={{
                            bottom: '2%',
                            position: 'absolute',
                            right: '5%'
                        }}
                    >
                        <Pagination
                            onChange={handleChange}
                            count={pageCountStage}
                            page={page}
                            renderItem={item => (
                                <PaginationItem
                                    slots={{previous: ArrowBackIcon, next: ArrowForwardIcon}}
                                    {...item}
                                />
                            )}
                        />
                    </Stack>
                )}
            </Grid>
        </Box>
    );
};

export default PresetTemplateList;
