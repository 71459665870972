import * as React from 'react';
import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import {FormHelperText} from '@mui/material';
import {imageCreationPattern} from '../../helpers/constant/validationPattern';
import {Controller, useForm} from 'react-hook-form';
import {imageCreation, imageCreationStateClear} from '../../state/features/imageCreation/imageCreationSlice';
import {useDispatch, useSelector} from 'react-redux';
import {selectImageCreationState} from '../../state/features/imageCreation/imageCreationSelector';
import ResponseImage from './ResponseImage';
import FeatureInput from '../common/featureInput/FeatureInput';
import FeatureOutputNoResponse from '../common/featureOutput/FeatureOutputNoResponse';
import FeatureOutput from '../common/featureOutput/FeatureOutput';
import BalanceCheck from '../../assets/global/BalanceCheck/BalanceCheck';
import {
    creditSettingsImageContent1024x1024,
    creditSettingsImageContent256x256,
    creditSettingsImageContent512x512
} from '../../helpers/constant/coreConstant';

const ImageCreation = () => {
    const dispatch = useDispatch();
    const [imageSize, setImageSize] = useState('1024x1024');
    const [contentType, setContentType] = useState(creditSettingsImageContent1024x1024);
    const [amount, setAmount] = useState(3);
    const {imageCreationData, isLoading} = useSelector(selectImageCreationState);

    const {
        handleSubmit,
        control,
        reset,
        formState: {errors},
        clearErrors
    } = useForm({
        defaultValues: {
            image_shape: '',
            image_size: '',
            image_type: '',
            image_keyword: '',
            image_description: '',
            total_image: ''
        }
    });

    useEffect(() => {
        if (imageSize === '256x256') {
            setContentType(creditSettingsImageContent256x256);
        } else if (imageSize === '512x512') {
            setContentType(creditSettingsImageContent512x512);
        } else if (imageSize === '1024x1024') {
            setContentType(creditSettingsImageContent1024x1024);
        }
    }, [imageSize]);

    const handleImageSize = data => {
        setImageSize(data.target.value);
    };
    const handleAmount = data => {
        setAmount(data.target.value);
    };


    const onSubmit = data => {
        dispatch(
            imageCreation({
                image_description: data.image_description,
                image_keyword: data.image_keyword,
                image_size: data.image_size,
                image_type: data.image_type,
                total_image: data.total_image,
                image_shape: data.image_shape
            })
        );
    };

    const handleClearInput = () => {
        reset();
    };

    let imageGrid = null;

    if (Array.isArray(imageCreationData) && imageCreationData.length) {
        switch (imageCreationData.length) {
            case 1:
                imageGrid = 12;
                break;

            case 2:
                imageGrid = 6;
                break;

            default:
                imageGrid = 4;
                break;
        }
    }

    useEffect(() => {
        return () => dispatch(imageCreationStateClear());
    }, []);

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    height: '90vh',
                    paddingTop: '50px',
                    backgroundColor: '#f3f4f6',
                    overflow: 'auto'
                }}
            >
                <FeatureInput
                    headerTitle={'Image Creation'}
                    helpkey={'IMAGECREATION'}
                    subtitle="Create a new image"
                    footer={{generate: handleSubmit(onSubmit), isLoading, handleClearInput}}
                >
                    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Controller
                                    control={control}
                                    name="image_shape"
                                    defaultValue=""
                                    render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                        <FormControl size="small" fullWidth error={!!(invalid && error.message)}>
                                            <TextField
                                                fullWidth
                                                id="outlined-select-currency"
                                                select
                                                value={value}
                                                onChange={onChange}
                                                label="Aspect Ratio *"
                                                defaultValue="SQUARE"
                                                size="small"
                                                sx={{width: '100%!important'}}
                                                error={!!(invalid && error.message)}
                                                // style={{width: '30%'}}
                                            >
                                                <MenuItem key="square" value="square">
                                                    Square
                                                </MenuItem>
                                                <MenuItem key="widescreen" value="widescreen">
                                                    Widescreen
                                                </MenuItem>
                                                <MenuItem key="vertical" value="vertical">
                                                    Vertical
                                                </MenuItem>
                                            </TextField>

                                            <FormHelperText>{invalid && error?.message}</FormHelperText>
                                        </FormControl>
                                    )}
                                    rules={imageCreationPattern.aspect_ratio}
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <Controller
                                    control={control}
                                    name="image_size"
                                    defaultValue=""
                                    render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                        <FormControl size="small" fullWidth error={!!(invalid && error.message)}>
                                            <TextField
                                                id="outlined-select-currency"
                                                select
                                                label="Image Size *"
                                                value={value}
                                                onChange={value => {
                                                    onChange(value);
                                                    handleImageSize(value);
                                                }}
                                                defaultValue="HD"
                                                size="small"
                                                sx={{width: '100%!important'}}
                                                error={!!(invalid && error.message)}
                                            >
                                                <MenuItem key="HD" value="256x256">
                                                    HD
                                                </MenuItem>
                                                <MenuItem key="2K" value="512x512">
                                                    2K
                                                </MenuItem>
                                                <MenuItem key="4K" value="1024x1024">
                                                    4K
                                                </MenuItem>
                                            </TextField>

                                            <FormHelperText>{invalid && error?.message}</FormHelperText>
                                        </FormControl>
                                    )}
                                    rules={imageCreationPattern.image_size}
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <Controller
                                    control={control}
                                    name="image_type"
                                    defaultValue=""
                                    render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                        <FormControl size="small" fullWidth error={!!(invalid && error.message)}>
                                            <TextField
                                                id="outlined-select-currency"
                                                select
                                                label="Image Type *"
                                                value={value}
                                                onChange={onChange}
                                                defaultValue="PHOTO"
                                                size="small"
                                                sx={{width: '100%!important'}}
                                                error={!!(invalid && error.message)}
                                            >
                                                <MenuItem key="PHOTO" value="photo">
                                                    Photo
                                                </MenuItem>
                                                <MenuItem key="ART" value="art">
                                                    Art
                                                </MenuItem>
                                            </TextField>

                                            <FormHelperText>{invalid && error?.message}</FormHelperText>
                                        </FormControl>
                                    )}
                                    rules={imageCreationPattern.image_type}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="image_keyword"
                                    defaultValue=""
                                    render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                        <FormControl size="small" fullWidth error={!!(invalid && error.message)}>
                                            <TextField
                                                id="outlined-textarea"
                                                label="What do you want to avoid? *"
                                                value={value}
                                                onChange={onChange}
                                                placeholder="Please enter phrases as keywords"
                                                sx={{width: '100%!important'}}
                                                size="small"
                                                error={!!(invalid && error.message)}
                                            />
                                            <FormHelperText>{invalid && error?.message}</FormHelperText>
                                        </FormControl>
                                    )}
                                    rules={imageCreationPattern.image_keyword}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="image_description"
                                    defaultValue=""
                                    render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                        <FormControl size="small" fullWidth error={!!(invalid && error.message)}>
                                            <TextField
                                                label="Image Description *"
                                                placeholder="Describe your image in as much detail as possible"
                                                value={value}
                                                onChange={onChange}
                                                margin="normal"
                                                variant="outlined"
                                                sx={{width: '100%!important'}}
                                                multiline
                                                rows={4}
                                                size="small"
                                                error={!!(invalid && error.message)}
                                            />
                                            <FormHelperText>{invalid && error?.message}</FormHelperText>
                                        </FormControl>
                                    )}
                                    rules={imageCreationPattern.image_description}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="total_image"
                                    defaultValue=""
                                    render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                        <FormControl size="small" fullWidth error={!!(invalid && error.message)}>
                                            <TextField
                                                id="outlined-select-currency"
                                                select
                                                label="Number of Image *"
                                                value={value}
                                                onChange={value => {
                                                    onChange(value);
                                                    handleAmount(value);
                                                }}
                                                defaultValue="HD"
                                                size="small"
                                                sx={{width: '100%!important'}}
                                                error={!!(invalid && error.message)}
                                            >
                                                <MenuItem key="1" value="1">
                                                    1
                                                </MenuItem>
                                                <MenuItem key="2" value="2">
                                                    2
                                                </MenuItem>
                                                <MenuItem key="3" value="3">
                                                    3
                                                </MenuItem>
                                            </TextField>

                                            <FormHelperText>{invalid && error?.message}</FormHelperText>
                                        </FormControl>
                                    )}
                                    rules={imageCreationPattern.number_of_image}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <BalanceCheck content_type={contentType} amount_data={amount}/>
                </FeatureInput>

                <FeatureOutput
                    newOutput={
                        <>
                            {Array.isArray(imageCreationData) && imageCreationData.length > 0 ? (
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': {m: 1, width: '25ch'}
                                        // backgroundColor: '#ffffff',
                                        // padding: '40px',
                                        // width: '80%',
                                        // margin: 'auto',
                                        // marginTop: '40px'
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <Grid container spacing={4}>
                                        {imageCreationData.map(output => (
                                            <ResponseImage imageGrid={imageGrid} image={output.url}/>
                                        ))}
                                    </Grid>
                                </Box>
                            ) : (
                                <FeatureOutputNoResponse/>
                            )}
                        </>
                    }
                />
            </Box>
        </>
    );
};

export default ImageCreation;
