import {Box, Divider, Grid, Paper, Stack} from '@mui/material';
import Styles from '../styles/Package.module.css';
import React, {Fragment, useEffect, useState} from 'react';
import {PackagePageHeaderContent} from './PackagePageHeaderContent';
import {useDispatch, useSelector} from 'react-redux';
import PackageItemSkeletonLoader from './skeletonLoader/PackageItemSkeletonLoader';
import {USER__HAS__PACKAGE__TYPE__FREE} from '../../../helpers/constant/coreConstant';
import {getPackages} from '../../../state/features/package/packageSlice';
import EmptyPage from '../../../assets/global/EmptyComponent/EmptyPage';
import Typography from '@mui/material/Typography';
import NoPackageImage from '../../../assets/images/Vectors/no-package.svg';
import {SingleUpgradePackageItem} from "./SingleUpgradePackageItem";

const Packages = () => {
    const dispatch = useDispatch();
    const [packageList, setPackageList] = useState([]);
    const {userPackage} = useSelector(state => state.userPackage);
    const {packages, loading} = useSelector(state => state.package);

    useEffect(() => {
        if (packages) {
            const copyPackages = packages.slice();
            const length = copyPackages.length;
            const newOrderList = [];

            for (let i = 0; i < length; i++) {
                if (copyPackages[i].package_type === USER__HAS__PACKAGE__TYPE__FREE) {
                    newOrderList.unshift(copyPackages[i]);
                } else {
                    newOrderList.push(copyPackages[i]);
                }
            }

            setPackageList(newOrderList);
        }
    }, [packages]);

    useEffect(() => {
        dispatch(getPackages());
    }, []);

    return (
        <>
            <Box sx={{background: 'light', height: '100px'}}>
                {/*<CoreBreadcrumb pageInfo={packagePageInfo}/>*/}

                <Paper elevation={0}>
                    <Stack
                        direction={'row'}
                        alignItems="center"
                        justifyContent="space-between"
                        className={Styles.PackagePageHeader}
                    >
                        <PackagePageHeaderContent
                            title={`Get more out of company name as you grow`}
                            subtitle="Flexible Options to Suit any Business"
                        />
                    </Stack>

                    <Divider/>

                    <Grid spacing={2} container className={Styles.package_wrapper}>
                        {loading ? (
                            <Fragment>
                                {Array.from({length: 3}).map((value, index) => (
                                    <Grid
                                        key={index}
                                        item
                                        md={6}
                                        lg={4}
                                        xl={3}
                                        className={Styles.packages_grid_wrapper}
                                    >
                                        <PackageItemSkeletonLoader/>
                                    </Grid>
                                ))}
                            </Fragment>
                        ) : !loading && packageList && packageList.length === 0 ? (
                            <EmptyPage
                                img={NoPackageImage}
                                title={'No Package Found!'}
                                subTitle={
                                    'Without a package you cannot generate revenue. Create a package and start your business.'
                                }
                            />
                        ) : (
                            <Fragment>
                                {packageList?.length > 0 &&
                                    packageList
                                        // .filter(
                                        //     packageData =>
                                        //         Number(packageData?.package_price) >= Number(userPackage?.package_price)
                                        // )
                                        .map(packageData => (
                                            <Grid
                                                item
                                                md={6}
                                                lg={4}
                                                xl={3}
                                                key={packageData.uid}
                                                className={Styles.packages_grid_wrapper}
                                            >
                                                <SingleUpgradePackageItem packageData={packageData} />
                                            </Grid>
                                        ))}
                            </Fragment>
                        )}
                    </Grid>
                </Paper>
            </Box>
        </>
    );
};

export default Packages;
