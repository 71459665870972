import React, {useEffect} from 'react';
import {Box} from '@mui/system';
import {Stack, Typography} from '@mui/material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import CloudSyncOutlinedIcon from '@mui/icons-material/CloudSyncOutlined';
import {Link, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {templateSearch} from "../../state/features/textToMeme/templateSearch/templateSearchSlice";
import {selectTemplateSearchdata} from "../../state/features/textToMeme/templateSearch/templateSearchSelector";
import Skeleton from "@mui/material/Skeleton";
import EmptyPage from "../../assets/global/EmptyComponent/EmptyPage";
import TemplateSearchBar from "./TemplateSearchBar";
import MemeMakerHeader from "../../assets/global/MemeMaker/MemeMakerHeader";
import {aiGeneratedTextClearState} from "../../state/features/textToMeme/randomAIGeneratedText/AIGeneratedTextSlice";

const SearchTemplatesForMemes = () => {
    const dispatch = useDispatch();
    const {templateSearchData, isLoading} = useSelector(selectTemplateSearchdata);
    const navigate = useNavigate();
    const handleGenerateTemplate = (e, searchText) => {
        e.preventDefault();
        const API_KEY = process.env.REACT_APP_TENOR_API_KEY;
        dispatch(
            templateSearch({
                q: searchText,
                key: API_KEY,
                pos: '20',
                limit: '20'
            })
        );

    };
    const handleBack = () => {
        navigate(-1)
    }

    useEffect(() => {
        dispatch(aiGeneratedTextClearState())
    }, []);


    let content = null;
    if (isLoading) {
        content = (
            <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}} sx={{p: 3}}>
                {[1, 2, 3, 4].map(collection => (
                    <Grid key={collection} item xs={3}>
                        <Box sx={{p: 3, marginRight: 0.5}}>
                            <Skeleton variant="rectangular" height={118}/>
                            <Skeleton/>
                            <Skeleton width="60%"/>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        );
    } else if (templateSearchData?.results?.length > 0) {
        content = (
            <Grid container spacing={2}>
                {templateSearchData?.results.map((items, key) => (
                    <Grid item sm={12} md={6} lg={4} xl={4}>
                        <Box
                            sx={{
                                height: '100%',
                                width: '100%',
                                backgroundColor: '#ffffff',
                                padding: '20px',
                                borderRadius: '5px'
                            }}
                        >
                            <img
                                height="300px"
                                width="260px"
                                src={items?.media_formats?.gifpreview?.url}
                                alt="not found"
                            />
                            <Stack direction="row" useFlexGap flexWrap="wrap" spacing={1} mt={2}>

                                <Link
                                    to={`/themed-memes/?url=${items?.media_formats?.gifpreview?.url}`}
                                    style={{textDecoration: 'none'}}
                                >
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        sx={{
                                            backgroundColor: '#F5F5F5',
                                            borderRadius: '6px',
                                            padding: '5px 10px',
                                            flex: {lg: 1}
                                        }}
                                        startIcon={<CloudSyncOutlinedIcon/>}
                                    >
                                        Generated with AI
                                    </Button>
                                </Link>

                                <Button
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    sx={{
                                        backgroundColor: '#F5F5F5',
                                        borderRadius: '6px',
                                        padding: '5px 10px',
                                        flex: {lg: 1},
                                        width: '100%',
                                    }}
                                    startIcon={<ManageHistoryIcon/>}
                                >
                                    <Link
                                        to={`/meme-edit/?url=${items?.media_formats?.gifpreview?.url}`}
                                        style={{textDecoration: 'none'}}
                                    >
                                        Edit Template
                                    </Link>
                                </Button>
                            </Stack>
                        </Box>
                    </Grid>

                    //---------------------------------------------------------------------------------------------------------------------
                ))}
            </Grid>
        );
    } else {
        content = (
            <EmptyPage title="No theme choose yet..."/>
        );

    }

    return (
        <>
            <Box
                sx={{
                    margin: '20px 10px'
                }}
            >
                <MemeMakerHeader
                    title={'search-template'}
                    handleBack={handleBack}
                />
                {/* Heading */}
                <Box textAlign="center">
                    <Typography variant="h4" fontWeight={800} fontSize={36} marginBottom={3}>
                        World's Most Advanced Multilingual Meme Search Engine
                    </Typography>
                    <Typography variant="p" fontSize={20} fontWeight={500}>
                        Search by describing emotions or actions and hit "Search" to get relevant templates
                    </Typography>
                </Box>

                {/* Search */}
                <Box maxWidth={920} marginX={'auto'} pt={2} textAlign="center" mb={3}>
                    <TemplateSearchBar handleGenerateTemplate={handleGenerateTemplate}/>
                </Box>

                <Divider sx={{margin: '30px 0px'}}/>

                <Box
                    sx={{
                        border: '2px dashed gray',
                        width: '100%',
                        borderRadius: '10px',
                        display: 'block',
                        textAlign: 'center',
                        padding: '20px'
                    }}
                >
                    {content}
                </Box>
            </Box>
        </>
    );
};

export default SearchTemplatesForMemes;
