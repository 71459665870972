import {Box, Stack} from '@mui/material';

import AppNavbar from '../AppNavbar/AppNavbar';
import {Outlet} from 'react-router-dom';
import PrivateLayout from '../PrivateLayout';
import React from 'react';
import Sidebar from './Sidebar';

const AppLayout = () => {
  return (
    <PrivateLayout>
      <AppNavbar />
      <Box
        sx={{
          width: '100%',
          minHeight: '100vh',
          bgcolor: '#f3f4f6',
          paddingTop: '65px',
          position: 'relative'
        }}
      >
        <Stack direction={'col'} sx={{width: '100%'}}>
          <Sidebar />
          <Stack direction={'column'} sx={{width: '100%', height: 'auto', padding: ' 0 12px'}}>
            {/* sx={{width: '100%', height: '100%', bgcolor: '#fff',boxShadow: 'rgba(0, 0, 0, 0.08) 0px 0px 8px 0px'}} */}
            <Box direction={'column'}>
              <Outlet />
            </Box>
          </Stack>
        </Stack>
      </Box>
    </PrivateLayout>
  );
};

export default AppLayout;
