import * as React from 'react';
import {useRef, useState} from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import PlayPenEditor from '../playpen/PlayPenEditor';
import {FormHelperText, styled, TextareaAutosize} from '@mui/material';
import {aiTranscriptionPattern} from '../../helpers/constant/validationPattern';
import {Controller, useForm} from 'react-hook-form';
import {showErrorToaster} from '../../helpers/utility/toaster';
import {useDispatch, useSelector} from 'react-redux';
import {aiTranscription} from '../../state/features/aiTranscription/aiTranscriptionSlice';
import {selectAITranscriptionState} from '../../state/features/aiTranscription/aiTranscriptionSelector';
import MenuItem from '@mui/material/MenuItem';
import FeatureInput from '../common/featureInput/FeatureInput';
import FeatureOutputNoResponse from '../common/featureOutput/FeatureOutputNoResponse';
import FeatureOutput from '../common/featureOutput/FeatureOutput';
import {CountryLanguage} from '../../helpers/utility/countryLanguage';
import {creditSettingsAITranscription, creditSettingsImageContent1024x1024} from "../../helpers/constant/coreConstant";
import BalanceCheck from "../../assets/global/BalanceCheck/BalanceCheck";

const AiTranscription = () => {
    const audioType = [
        {
            name: 'Podcast'
        },
        {
            name: 'Earnings Call'
        },
        {
            name: 'Lecture'
        },
        {
            name: 'Meeting'
        },
        {
            name: 'Phone Call'
        },
        {
            name: 'Other'
        }
    ];

    const [audioFile, setAudioFile] = useState(null);
    const [audioPreviewFile, setAudioPreviewFile] = useState(null);
    const {handleSubmit, control, reset} = useForm();
    const dispatch = useDispatch();
    const {aiTranscriptionData, isLoading} = useSelector(selectAITranscriptionState);
    const audioRef = useRef(null);

    const handleAudioChange = event => {
        const file = event.target.files[0];

        if (file && file.type.includes('audio/')) {
            setAudioFile(file);
            setAudioPreviewFile(URL.createObjectURL(file));
        } else {
            setAudioFile(null);
        }
    };

    const handleClearInput = () => {
        reset();
    };

    const convertSecondsToMinutes = seconds => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);

        return minutes + '.' + remainingSeconds;
    };

    const onSubmit = data => {
        if (!audioFile) {
            showErrorToaster('Add an audio file.');
        } else if (audioFile && audioFile.size > 8 * 1024 * 1024) {
            showErrorToaster('File size must be less than 8MB.');
        } else {
            let formData = new FormData();
            const audioElement = audioRef.current;
            const duration = audioElement.duration;
            const formattedTime = convertSecondsToMinutes(duration);

            formData.append('file', audioFile);
            formData.append('prompt', data.prompt);
            formData.append('language', data.spoken_language);
            formData.append('duration', formattedTime);

            dispatch(aiTranscription(formData));
        }
    };

    const StyledTextArea = styled(TextareaAutosize)(({theme, invalid}) => ({
        width: '100%',
        padding: '10px',
        border: `1px solid ${invalid ? theme.palette.error.main : theme.palette.grey[400]}`,
        borderRadius: '4px',
        boxShadow: '0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(0,0,0,.05)',
        '&:focus-visible': {
            outlineColor: '#1976d2'
        },
        '& .MuiInputBase-input': {
            color: 'inherit'
        },
        '&::placeholder': {
            color: invalid ? theme.palette.error.main : theme.palette.grey[400]
        }
    }));

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    height: '90vh',
                    paddingTop: '50px',
                    backgroundColor: '#f3f4f6',
                    overflow: 'auto'
                }}
            >
                <FeatureInput
                    headerTitle={'AI Transcription'}
                    helpkey={'AITRANSCRIPTION'}
                    subtitle="Generate Audio to Text"
                    footer={{generate: handleSubmit(onSubmit), isLoading, handleClearInput}}
                >
                    <Box component="form">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl component="fieldset" variant="standard">
                                    <FormLabel component="legend" sx={{paddingLeft: '10px'}}>
                                        Upload audio
                                    </FormLabel>
                                    <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        type="file"
                                        accept="audio/*"
                                        onChange={handleAudioChange}
                                        size="small"
                                        sx={{width: '100%!important'}}
                                    />
                                </FormControl>
                                {audioFile ? (
                                    ''
                                ) : (
                                    <FormHelperText sx={{color: '#FF8800'}}>
                                        File size must be less than 8MB.
                                    </FormHelperText>
                                )}
                            </Grid>

                            {audioPreviewFile && (
                                <Grid item xs={12}>
                                    <Box sx={{paddingLeft: '10px'}}>
                                        <audio ref={audioRef} key={audioPreviewFile} src={audioPreviewFile} controls/>
                                    </Box>
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="prompt"
                                    defaultValue=""
                                    render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                        <FormControl fullWidth error={!!(invalid && error.message)}>
                                            <StyledTextArea
                                                minRows={4}
                                                placeholder="Audio Description"
                                                value={value}
                                                onChange={onChange}
                                                invalid={invalid}
                                                error={!!(invalid && error.message)}
                                            />
                                            <FormHelperText>{invalid && error.message}</FormHelperText>
                                        </FormControl>
                                    )}
                                    rules={aiTranscriptionPattern.prompt}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="spoken_language"
                                    defaultValue=""
                                    render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                        <FormControl size="small" fullWidth error={!!(invalid && error.message)}>
                                            <TextField
                                                id="outlined-select-currency"
                                                select
                                                label="Select"
                                                size="small"
                                                sx={{width: '100%!important'}}
                                                value={value}
                                                onChange={onChange}
                                                error={!!(invalid && error.message)}
                                            >
                                                <MenuItem value="Select an option" disabled>
                                                    Select an option
                                                </MenuItem>
                                                {Object.keys(CountryLanguage).map(key => (
                                                    <MenuItem key={key} value={key}>
                                                        {CountryLanguage[key].name} ({CountryLanguage[key].nativeName})
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            <FormHelperText>{invalid && error?.message}</FormHelperText>
                                        </FormControl>
                                    )}
                                    rules={aiTranscriptionPattern.spoken_language}
                                />
                            </Grid>

                            {/*<Grid item xs={12}>*/}
                            {/*    <Controller*/}
                            {/*        control={control}*/}
                            {/*        name="detect_speakers"*/}
                            {/*        defaultValue=""*/}
                            {/*        render={({field: {value, onChange}, fieldState: {invalid, error}}) => (*/}
                            {/*            <FormControl size="small" fullWidth error={!!(invalid && error.message)}>*/}
                            {/*                <FormControl component="fieldset" variant="standard" sx={{paddingLeft: "10px"}}>*/}
                            {/*                    <FormLabel component="legend">*/}
                            {/*                        Detect speakers?*/}
                            {/*                        <Tooltip*/}
                            {/*                            title="Automatically identify and separate individual speaker voices in the audio transcription to make it easier to follow who is saying what. Available for English only."*/}
                            {/*                            placement="right-start"*/}
                            {/*                        >*/}
                            {/*                            <ContactSupportIcon fontSize="small" sx={{paddingTop: "4px"}}/>*/}
                            {/*                        </Tooltip>*/}
                            {/*                    </FormLabel>*/}
                            {/*                    <Switch name="detect_speakers" value={value} onChange={onChange}/>*/}
                            {/*                </FormControl>*/}
                            {/*                <FormHelperText>{invalid && error?.message}</FormHelperText>*/}
                            {/*            </FormControl>*/}
                            {/*        )}*/}
                            {/*        rules={aiTranscriptionPattern.spoken_audio}*/}
                            {/*    />*/}
                            {/*</Grid>*/}

                            {/*<Grid item xs={12}>*/}
                            {/*    <Controller*/}
                            {/*        control={control}*/}
                            {/*        name="enhanced_quality_transcription"*/}
                            {/*        defaultValue=""*/}
                            {/*        render={({field: {value, onChange}, fieldState: {invalid, error}}) => (*/}
                            {/*            <FormControl size="small" fullWidth error={!!(invalid && error.message)}>*/}
                            {/*                <FormControl component="" variant="standard" sx={{paddingLeft: "10px"}}>*/}
                            {/*                    <FormLabel component="legend">*/}
                            {/*                        Enhanced quality transcription*/}
                            {/*                        <Tooltip*/}
                            {/*                            title="Enable Enhanced Quality Transcription for slower processing times but significantly improved accuracy and quality. Ideal for complex audio files or professional use."*/}
                            {/*                            placement="right-start"*/}
                            {/*                        >*/}
                            {/*                            <ContactSupportIcon fontSize="small" sx={{paddingTop: "4px"}}/>*/}
                            {/*                        </Tooltip>*/}
                            {/*                    </FormLabel>*/}
                            {/*                    <Switch name="enhanced_quality_transcription" value={value}*/}
                            {/*                            onChange={onChange}/>*/}
                            {/*                </FormControl>*/}
                            {/*                <FormHelperText>{invalid && error?.message}</FormHelperText>*/}
                            {/*            </FormControl>*/}
                            {/*        )}*/}
                            {/*        rules={aiTranscriptionPattern.spoken_audio}*/}
                            {/*    />*/}
                            {/*</Grid>*/}
                        </Grid>
                    </Box>
                  <BalanceCheck content_type={creditSettingsAITranscription} amount_data={1}/>
                </FeatureInput>

                <FeatureOutput
                    newOutput={
                        <>
                            {!aiTranscriptionData ? (
                                <FeatureOutputNoResponse/>
                            ) : (
                                <PlayPenEditor value={aiTranscriptionData}/>
                            )}
                        </>
                    }
                />
            </Box>
        </>
    );
};

export default AiTranscription;
