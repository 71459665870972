import React from 'react';
import {Stack} from '@mui/material';
import AppNavbarProfile from './AppNavbarProfile';
import AppNavbarBalance from './AppNavbarBalance';

const AppNavbarRight = ({profileData}) => {
    return (
        <Stack direction={'row'} spacing={2}>
            <AppNavbarBalance/>
            <AppNavbarProfile profileData={profileData}/>
        </Stack>
    );
};

export default AppNavbarRight;
