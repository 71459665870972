import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    imageCreationData: [],
    isLoading: false,
    isError: false,
    error: ''
};

const imageCreationSlice = createSlice({
    name: 'imageCreation',
    initialState: initialState,
    reducers: {
        imageCreation: (state, action) => {
            state.isLoading = true;
        },
        imageCreationSuccess: (state, action) => {

            state.imageCreationData = action?.payload;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        imageCreationFailed: (state, action) => {
            state.imageCreationData = null;
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },
        imageCreationStateClear: (state, action) => {
            state.imageCreationData = [];
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
    }
});

export const {
    imageCreation,
    imageCreationSuccess,
    imageCreationFailed,
    imageCreationStateClear,
} = imageCreationSlice.actions;

export default imageCreationSlice.reducer;
