import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';
import PromptForm from './PromptForm';
import {useDispatch} from 'react-redux';
import {getGeneratedResponse, setTemplatePreviewText} from '../../state/features/prompts/promptSlice';
import PromptPreview from './PromptPreview';

const PromptLeft = ({specificTemplateData}) => {
    const dispatch = useDispatch();
    const [formDataArray, setFormDataArray] = useState([]);
    let description = specificTemplateData?.description;

    const handleGenerateResponse = data => {
        let entries = Object.entries(data);

        entries.map(entry => {
            description = description.replaceAll(`[[${entry[0]}]]`, entry[1]);
        });

        let descriptionParseHtml = description.replace(/(<([^>]+)>)/gi, '');
        let finalDescription = descriptionParseHtml.replace(/&nbsp;/g, '');

        dispatch(getGeneratedResponse({prompt: finalDescription}));
    };

    useEffect(() => {
        let previewDescription = specificTemplateData?.description;

        setTimeout(() => {
            let formData = [];

            formDataArray.forEach(item => {
                let key = Object.keys(item)[0];
                let value = item[key];

                let foundIndex = formData.findIndex(element => element[0] === key);

                if (foundIndex === -1) {
                    formData.push([key, value]);
                } else {
                    formData[foundIndex][1] = value;
                }
            });

            if (formData && formDataArray.length > 0) {
                formData = formData.filter(item => item[1] !== undefined);

                formData.map(entry => {
                    previewDescription = previewDescription.replaceAll(`[[${entry[0]}]]`, entry[1]);
                });

                if (previewDescription) {
                    let str = previewDescription.replace(/<strong>/g, '<span style="color:#ff9800;text-decoration:underline">');
                    let previewDescriptionParseHtml = previewDescription.replace(/(<([^>]+)>)/gi, '');
                    let finalPreviewDescription = previewDescriptionParseHtml.replace(/&nbsp;/g, '');
                    dispatch(setTemplatePreviewText({prompt: str}));
                }
            }
        }, 500);
    }, [formDataArray]);

    const handlePreview = data => {
        setFormDataArray(prevState => [...prevState, data]);
    };

    return (
        <Box>
            <PromptForm
                specificTemplateData={specificTemplateData}
                handleGenerateResponse={handleGenerateResponse}
                handlePreview={handlePreview}
            />
        </Box>
    );
};

export default PromptLeft;
