import {requests} from "./http.node.service";

class ImageCreationService {
    ImageCreation(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_NODE_SERVER + "/image/generate", body);
    }

}

export default new ImageCreationService();
