import { Stack, Typography } from "@mui/material";
import Styles from "../styles/PackageCardHeader.module.css";

export const PackagePageHeaderContent = ({ title, subtitle }) => {
    return (
        <Stack className={Styles.PackagePageHeaderContent__title_wrapper}>
            <Typography className={Styles.package__card_header_title} variant='h4'>
                {title}
            </Typography>

            <Typography
                className={Styles.PackagePageHeaderContent__subtitle}
                sx={{ mt: 1 }}
                variant='body1'
                color='text.secondary'
            >
                {subtitle}
            </Typography>
        </Stack>
    );
};
