import {all, put} from 'redux-saga/effects';
import {call, takeEvery} from '@redux-saga/core/effects';
import {showSuccessToaster} from "../../../helpers/utility/toaster";
import {aiTranscriptionFailed, aiTranscriptionSuccess} from "./aiTranscriptionSlice";
import AITranscriptionService from "../../../services/AITranscription.service";

function* aiTranscriptionWatcher() {
    yield takeEvery('aiTranscription/aiTranscription', generateAITranscriptionSaga);
}

function* generateAITranscriptionSaga(action) {
    try {
        const response = yield call(AITranscriptionService.aiTranscription, action.payload);

        if (response.status) {
            yield put(aiTranscriptionSuccess(response.data));
            showSuccessToaster(response.message)
        } else {
            yield put(aiTranscriptionFailed(response.message));
        }
    } catch (err) {
        console.log('Error: ', err);
        yield put(aiTranscriptionFailed(err.message));
    }
}


export default function* aiTranscriptionSaga() {
    yield all([aiTranscriptionWatcher()]);
}
