import React, {useState} from 'react';
import {Box, Card, CardActionArea, Divider, Grid, Typography} from '@mui/material';
import Styles from '../../styles/Package.module.css';
import {SinglePaidPackageItem} from '../SinglePaidPackageItem';
import formatFeatureLimitList from '../../helper/utility/formatFeatureLimitList';
import {useTheme} from '@mui/material/styles';
import CoreRadioButton from '../../../../assets/global/RadioButton/CoreRadioButton';
import {USER__HAS__PACKAGE__TYPE__FREE} from '../../../../helpers/constant/coreConstant';
import BasicDrawer from '../../../../assets/global/Drawer/BasicDrawer';

const OnBoardPackage = ({checked, packageItem, onSelectPackage}) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [packageData, setPackageData] = useState({});
    const theme = useTheme();

    const {title, description, package_price, package_type} = packageItem;
    const {dollarBalance} = formatFeatureLimitList(packageItem.feature_access);

    const drawerClose = () => {
        setDrawerOpen(false);
    };

    const handleSelectPackageForViewDetails = packageInfo => {
        setDrawerOpen(true);
        setPackageData(packageInfo);
    };

    return (
        <>
            <Card
                sx={{
                    border: '1px solid rgba(148, 157, 178, 0.12)',
                    borderRadius: '4px',
                    boxShadow: 0,
                    '&:hover': {
                        borderColor: theme.palette.info.main,
                    }
                }}
                className={Styles.wrapper}
                onClick={() => onSelectPackage(packageItem)}
            >
                <CardActionArea>
                    <Box className={Styles.badge} sx={{ background: theme.palette.primary.main }}>
                        <Typography
                            className={Styles.badge_title}
                            variant="tooltip"
                            color="primary.contrastText"
                        >
                            Get Balance ${dollarBalance && dollarBalance[0]?.limit}
                        </Typography>
                    </Box>

                    <Box sx={{padding: '16px 16px 8px 16px'}}>
                        <CoreRadioButton
                            color="info"
                            checked={checked}
                            value="female"
                            lavel="card"
                        ></CoreRadioButton>
                        <Box sx={{textAlign: 'center'}}>
                            <Typography variant="subtitle1" color="text.primary">
                                {title}
                            </Typography>
                            <Typography variant="badge" color="text.primary">
                                {description}
                            </Typography>
                            <Typography variant="h4" color="primary">
                                {package_price > 0 ? '$' + package_price : 'Free'}
                            </Typography>
                            <Typography variant="body2" color="text.primary">
                                {USER__HAS__PACKAGE__TYPE__FREE === package_type ? 'Free' : 'Per Month'}
                            </Typography>
                        </Box>
                    </Box>
                </CardActionArea>

                <Box className={Styles.bottom}>
                    <Divider/>
                    <CardActionArea onClick={() => handleSelectPackageForViewDetails(packageItem)}>
                        <Typography className={Styles.bottom_text} variant="buttonSmall" color="info.main">
                            {'View Details'}
                        </Typography>
                    </CardActionArea>
                </Box>
            </Card>

            <BasicDrawer
                toggleDrawer={drawerClose}
                open={drawerOpen}
                title={'View details of ' + title + ' package'}
                width="850px"
            >
                <Grid spacing={2} justifyContent={'center'} container>
                    <Grid item md={6}>
                        <Box sx={{py: 2}}>
                            <SinglePaidPackageItem packageData={packageData}/>
                        </Box>
                    </Grid>
                </Grid>
            </BasicDrawer>
        </>
    );
};

export default OnBoardPackage;
