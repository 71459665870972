import React from "react";
import { Stack, Typography } from "@mui/material";
import Styles from "../styles/Package.module.css";
import { CheckCircle } from "@mui/icons-material";

const PackagePreviewFeatureItem = ({ visibility = true, title = "" }) => {
    if (visibility) {
        return (
            <Stack className={Styles.package__list_group_item} direction='row' alignItems='center' spacing={"10px"}>
                <CheckCircle fontSize='16px' color='action' />
                <Typography variant='body2'>{title}</Typography>
            </Stack>
        );
    }
    return null;
};

export default PackagePreviewFeatureItem;
