import {Box, IconButton, InputAdornment, OutlinedInput, Stack} from '@mui/material';
import React, {useRef, useState} from 'react';

import SendIcon from '@mui/icons-material/Send';
import {getChatGPTAnswer} from '../../state/features/chatGPT/chatGPTSlice';
import {useDispatch, useSelector} from 'react-redux';
import {selectChatGPTState} from '../../state/features/chatGPT/chatGPTSelector';
import Button from '@mui/material/Button';
import CachedIcon from '@mui/icons-material/Cached';
import BalanceCheckService from "../../services/BalanceCheck.service";
import {creditSettingSTextContent} from "../../helpers/constant/coreConstant";
import {showErrorToaster} from "../../helpers/utility/toaster";

const InputSection = () => {
    const dispatch = useDispatch();
    const [text, setText] = useState('');
    const {messages = [], isLoading} = useSelector(selectChatGPTState);
    const handleChangeText = e => {
        const {value = ''} = e.target || {};
        setText(value);
    };

    const handleSubmit = e => {
        e.preventDefault();
        if (text.trim().length === 0) return;

        const messages = [{role: 'user', content: text}];
        BalanceCheckService.getBalance({content_type: creditSettingSTextContent, amount_data: 1000})
            .then((response) => {
                if (response.success) {
                    dispatch(getChatGPTAnswer({messages: messages}));
                    setText('');
                } else {
                    showErrorToaster('Insufficient balance')
                }
            })
            .catch((err) => console.log(err));
    };

    const handleRegenerate = e => {

        const lastIndex = messages.length - 1;

        let valueAtMessagesIndex = null;

        if (lastIndex > 0) {
            let message = messages[lastIndex - 1];
            if (message?.role === 'user') {
                valueAtMessagesIndex = message?.content;
            }
        } else {
            let message = messages[lastIndex - 1];

            if (message?.role === 'user') {
                valueAtMessagesIndex = message?.content;
            }
        }

        const payload = [{role: 'user', content: valueAtMessagesIndex}];
        dispatch(getChatGPTAnswer({messages: payload}));
    };

    return (
        <div>
            <Stack alignItems={'center'} justifyContent={'center'} py={4} px={2}>
                {messages.length > 0 && !isLoading && (
                    <Button
                        sx={{
                            marginBottom: '20px',
                            backgroundColor:'rgba(255,255,255,1)',
                            color:'rgba(64,65,79,1)',
                            fontSize:'.875rem',
                            lineHeight:'1.25rem',
                            border : '1px solid rgba(0,0,0,.1)',
                            padding:'.5rem .75rem'
                        }}
                        onClick={handleRegenerate}
                    >
                        <CachedIcon sx={{margin:'2px'}}/>
                        Regenerate
                    </Button>
                )}

                <Box
                    sx={{
                        width: '100%',
                        maxWidth: {xs: '300px', sm: '300px', md: '500px', lg: '800px'},
                        boxShadow: '1px 1px 19px 0px #0000001f',
                        border: 'none',
                        transition: 'all 0.3s ease',
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '40px'
                    }}
                    component="form"
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit}
                >
                    <OutlinedInput
                        disabled={isLoading}
                        fullWidth
                        id="send"
                        size="large"
                        sx={{
                            color: '#222',
                            border: 'none',
                            boxShadow: '0 4px 24px 0 rgba(34, 41, 47, 0.1)',
                            '&.MuiInputBase-root': {
                                backgroundColor: 'rgba(255,255,255,0.15)',
                                position: 'absolute',
                                bottom: '0'
                            }
                        }}
                        value={text}
                        onChange={handleChangeText}
                        placeholder="What are you thinking? Please enter your message in the box."
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton aria-label="send" edge="end" type={'submit'}>
                                    <SendIcon sx={{fontSize: 20, transform: 'translateY(-2px) rotate(-40deg)'}}/>
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </Box>
            </Stack>
        </div>
    );
};

export default InputSection;
