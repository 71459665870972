import React, {useEffect, useRef, useState} from 'react';
import QuestionContent from "./QuestionContent";
import AnswerContent from "./AnswerContent";
import {Box, styled} from "@mui/material";
import LoadingContent from "./LoadingContent";

const ContentBox = styled(Box)(({theme}) => ({
    height: '100%',
    paddingBottom: theme.spacing(5),
    overflowY: 'auto',
    '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
        borderRadius: '10px',
        backgroundColor: theme.palette.text.secondary,
        opacity: 1
    },
    '&::-webkit-scrollbar': {
        width: '6px'
    },
    '&::-webkit-scrollbar-thumb': {
        borderRadius: '10px',
        backgroundColor: theme.palette.text.secondary
    }
}));

const ChatContent = ({messages,isLoading,onScrollBottom}) => {
    const scrollRef = useRef(null);

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({ behaviour: "smooth" });
        }
    }, [isLoading]);

    return (
        <>
            <ContentBox ref={scrollRef} id={'chat_gpt_scroll_area'}>

                {Array.isArray(messages) &&
                    messages.map((message, i) => {
                        let question = null;
                        if (message.role === 'user') {
                            return <QuestionContent key={i} message={message}/>;
                        }
                        return <AnswerContent  key={i} message={message} question={question} arrayIndex={i} onScrollBottom={onScrollBottom}/>;
                    })}
                {/*{isLoading && <AnswerContent onScrollBottom={onScrollBottom}/>}*/}
                {isLoading && <LoadingContent />}
            </ContentBox>
        </>
    );
};

export default ChatContent;