import React, {useEffect, useState} from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import {styled} from '@mui/material/styles';
import {Stack, Typography} from '@mui/material';

const ToggleSwitch = ({labelStart = 'Images', labelEnd = 'GIFs', searchImageOrMeme}) => {
  const [switchValue, setSwitchValue] = useState('off');

  useEffect(() => {
    localStorage.setItem('imageORmeme', switchValue);
    const savedImageOrGif = localStorage.getItem('imageORmeme');
    searchImageOrMeme(savedImageOrGif)
  },[switchValue])

  const handleSwitchChange = (event) => {
    setSwitchValue(event.target.checked ? 'on' : 'off');
  };

  useEffect(() => {
    const savedImageOrGif = localStorage.getItem('imageORmeme');
    if (savedImageOrGif) {
      setSwitchValue(savedImageOrGif);
    }
  }, []);

  const IOSSwitch = styled(props => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({theme}) => ({
    width: 52,
    height: 26,
    padding: 0,

    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(26px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.main,
          opacity: 1,
          border: 0
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5
        }
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff'
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
      }
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.main,
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500
      })
    }
  }));

  return (
    <FormGroup>
      <Stack direction="row" alignItems={'center'} spacing={0.5}>
        {labelStart && <Typography>{labelStart}</Typography>}
        <FormControlLabel control={<IOSSwitch sx={{m: 1}} checked={switchValue === 'on'} onChange={handleSwitchChange} />} label={labelEnd} />
      </Stack>
    </FormGroup>
  );
};

export default ToggleSwitch;
