import {requests} from "./http.service";

class CollectionService {

    getCollection(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/collection/fetch/collection/list", body);
    }

    createCollection(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/collection/create", body);
    }

    updateCollection(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/collection/update", body);
    }

    deleteCollection(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/collection/delete", body);
    }

    getAllCollectionList(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/collection/fetch/collection/list", body);
    }

    getSpecificCollection(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/collection/info/specific", body);
    }

    createImageCollection(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/collection/image/create", body);
    }

    getImageCollection(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/collection/fetch/image/collection/list", body);
    }

    promptDelete(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/prompt/delete", body);
    }
}

export default new CollectionService();
