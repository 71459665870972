import React, {useRef, useState} from 'react';
import {makeStyles} from "@mui/styles";
import {Button, Card, CardActions, CardMedia, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import TextDraggable from "../memeMaker/TextDraggable";
import DownloadIcon from "@mui/icons-material/Download";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import BalanceCheckService from "../../services/BalanceCheck.service";
import {
    creditSettingsImageContent256x256,
    PACKAGE_LIMIT__BALANCE__KEY__CREDIT_AMOUNT
} from "../../helpers/constant/coreConstant";
import {showErrorToaster} from "../../helpers/utility/toaster";
import {getSpecificBalanceData} from "../../state/features/userBalance/userBalanceSlice";
import {useDispatch} from "react-redux";
import PromptCollectionForm from "../prompt/PromptCollectionForm";
import FullPageDrawer from "../../assets/global/Drawer/FullPageDrawer";
import {createImageCollection} from "../../state/features/promptCollection/promptCollectionSlice";
import MemeMakerService from "../../services/MemeMaker.service";

const GifEditItem = ({imageUrl, gifCaptions, setGifCaptions, textPosition}) => {
    const dispatch = useDispatch();
    const [imageSize, setImageSize] = useState({width: 0, height: 0});
    const imageRef = useRef(null);
    let captions = encodeURIComponent(JSON.stringify(gifCaptions));
    let imgSize = encodeURIComponent(JSON.stringify(imageSize));
    const [viewDetailsPageShow, setViewDetailsPageShow] = useState(false);
    const toggleViewGifPage = () => setViewDetailsPageShow(prevState => !prevState);


    const useStyles = makeStyles({
        card: {
            width: '650px', // Set the desired fixed width
            height: 'auto', // Adjust the height as per your requirements
            margin: '0 auto', // Center the card horizontally
            // Add any other styles you need
        },

        cardActions: {
            justifyContent: 'center',
            alignItems: 'center',
        }
    });
    const classes = useStyles();

    const handleDownload = (imageUrl) => {
        var node = document.getElementById('text0');
        var transformValue = node.style.transform;
        var xValue = 0;
        var yValue = 0;
        if (transformValue) {
            // Extract the X-axis and Y-axis values using regular expression
            var match = transformValue.match(/translate\((-?\d+\.?\d*)px,\s*(-?\d+\.?\d*)px\)/);
            if (match && match[1] && match[2]) {
                xValue = parseFloat(match[1]);
                yValue = parseFloat(match[2]);
            }
        }
        const updatedCaptions = [...gifCaptions];

        // Find the caption with ID 1 and update its position values
        const index = updatedCaptions.findIndex(caption => caption.id === 1);
        if (index !== -1) {
            updatedCaptions[index] = {
                ...updatedCaptions[index],
                position_x: xValue,
                position_y: yValue,
            };
        }

        // Update the state with the modified array
        // setGifCaptions(updatedCaptions);
        // captions = encodeURIComponent(JSON.stringify(gifCaptions));

        var node2 = document.getElementById('text1');
        var transformValue2 = node2.style.transform;
        var xValue2 = 0;
        var yValue2 = 0;
        if (transformValue2) {
            // Extract the X-axis and Y-axis values using regular expression
            var match2 = transformValue2.match(/translate\((-?\d+\.?\d*)px,\s*(-?\d+\.?\d*)px\)/);
            if (match2 && match2[1] && match2[2]) {
                xValue2 = parseFloat(match2[1]);
                yValue2 = parseFloat(match2[2]);
            }
        }
        const index2 = updatedCaptions.findIndex(caption => caption.id === 2);
        if (index2 !== -1) {
            updatedCaptions[index2] = {
                ...updatedCaptions[index2],
                position_x: xValue2,
                position_y: yValue2,
            };
        }
        // Update the state with the modified array
        setGifCaptions(updatedCaptions);
        captions = encodeURIComponent(JSON.stringify(updatedCaptions));

        if (imageUrl) {
            BalanceCheckService.getBalance({content_type: creditSettingsImageContent256x256, amount_data: 500})
                .then((response) => {
                    if (response.success) {

                        const GifDownload = async () => {

                            const currentUrl = process.env.REACT_APP_API_URL_MAIN_USER_NODE_SERVER + `/gif/download?url=${imageUrl}&gif_data=${captions}&imageSize=${imgSize}`;
                            await window.open(currentUrl, '_blank');
                            BalanceCheckService.reduceBalance({
                                content_type: creditSettingsImageContent256x256,
                                amount_data: 500
                            });
                            dispatch(getSpecificBalanceData({key: PACKAGE_LIMIT__BALANCE__KEY__CREDIT_AMOUNT}));
                        }
                        GifDownload()
                    } else {
                        showErrorToaster('Insufficient balance')
                    }
                })
                .catch((err) => console.log(err));
        } else {
            showErrorToaster("Select or upload a PNG, JPG, JPEG or GIF type image");
        }
    };

    const handleViewDetailsPage = data => {
        toggleViewGifPage();
    };


    const handleGifCollectionSubmit = (data) => {

        if (imageUrl) {
            MemeMakerService.saveGif({url: imageUrl, gif_data: captions, imageSize: imgSize})
                .then((response) => {
                    if (response?.status) {
                        if (response?.data?.location) {
                            const payload = {};
                            payload.collection_id = data.collection_id || '';
                            payload.type = 3;
                            payload.text = "Gifs";
                            payload.response = response?.data?.location;
                            dispatch(createImageCollection(payload));
                        } else {
                            showErrorToaster("Data not found!")
                        }
                    } else {
                        showErrorToaster('Something went wrong!')
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    const handleImageLoad = (event) => {
        const {naturalWidth, naturalHeight} = event.target;
        setImageSize({width: naturalWidth, height: naturalHeight});
    };
    console.log("============setImageSize=================", imageSize);


    return (
        <>
            <Card className={classes.card}>
                <Box ref={imageRef} id="my-node" sx={{position: 'relative', width: '100%', height: '100%'}}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        {gifCaptions.map((field, index) => (
                            <TextDraggable item={index}
                                           text={field.text}
                                           size={field.size}
                                           color={field.color}
                                           top={index === 0 ? '0' : '100'}
                                           bottom={index === 0 ? '100' : '0'}
                                           textPosition={textPosition}
                                           id={'text'+index}
                            />
                        ))
                        }
                        <CardMedia component="img" image={imageUrl} style={{overflow: 'hidden'}}
                                   alt="Image" onLoad={handleImageLoad}/>
                    </Box>
                </Box>
                <CardActions className={classes.cardActions}>
                    <Stack
                        direction={'row'}
                        spacing={1}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <Button

                            startIcon={<DownloadIcon/>}
                            variant="contained"
                            onClick={() => handleDownload(imageUrl)}>
                            Download
                        </Button>

                        <Button startIcon={<StarBorderIcon/>} variant="outlined"
                                onClick={() => handleViewDetailsPage()}>
                            Save
                        </Button>
                    </Stack>
                </CardActions>
            </Card>

            <FullPageDrawer
                title="Save Image collection"
                open={viewDetailsPageShow}
                toggleDrawer={toggleViewGifPage}
                width="35%"
                height="60%"
            >
                <PromptCollectionForm
                    helperKey={'IMAGE'}
                    toggleDrawer={toggleViewGifPage}
                    handlePromptCollectionSubmit={handleGifCollectionSubmit}
                />
            </FullPageDrawer>
        </>
    );
}
export default GifEditItem;