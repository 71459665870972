import React from 'react';
import {Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Stack} from "@mui/material";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

const MemeEditHelperText = () => {
    return(
        <Stack spacing={2} sx={{
            backgroundColor: '#F5F5F5',
            padding: '20px',
            alignItems: "center",
            justifyContent: "center"
        }}
        >
            <List
                sx={{
                    width: '90%',
                    bgcolor: '#e3f3e7',
                    marginBottom: '20px',
                }}
            >

                <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                            <RecordVoiceOverIcon/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Meme edit"
                                  secondary="Meme Edit
                                   Feature Description"/>
                </ListItem>
                <Divider variant="inset" component="li"/>
                <Box sx={{
                    alignItems: "center",
                    padding: '20px',
                }}>
                    <Typography component="div">
                        <Box sx={{fontFamily: 'Monospace', fontSize: 'h6.fontSize', m: 1, fontWeight: 'bold'}}>
                            Search Topic
                        </Box>
                        <Divider/>
                        <Box sx={{fontFamily: 'default', m: 1}}>
                            Write a topic on the SearchBar or hit "USE RANDOM TEXT" that will generate you a random
                            topic than hit "Generate". This will generate you Meme Images or Gif according to your
                            selected item.
                        </Box>
                    </Typography>

                </Box>
            </List>
        </Stack>
    );
}

export default MemeEditHelperText;