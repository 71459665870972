export const getLanguagesList = () => {

    return [
        {name: "English", value: "en"},
        {name: "Bengali", value: "bn"},
        // {name: "Hindi", value: "hi"},
        {name: "Spanish", value: "es"},
        {name: "French", value: "fr"},
        {name: "German", value: "de"},
        {name: "Italian", value: "it"},
        {name: "Chinese", value: "zh"},
        {name: "Japanese", value: "ja"},
        {name: "Korean", value: "ko"},
        {name: "Russian", value: "ru"},
        {name: "Arabic", value: "ar"},
        {name: "Portuguese", value: "pt"},
        {name: "Dutch", value: "nl"},
        {name: "Swedish", value: "sv"},
        {name: "Norwegian", value: "no"},
        {name: "Danish", value: "da"},
        {name: "Finnish", value: "fi"},
        {name: "Polish", value: "pl"},
        {name: "Czech", value: "cs"},
        {name: "Slovak", value: "sk"},
        {name: "Hungarian", value: "hu"},
        {name: "Romanian", value: "ro"},
        {name: "Greek", value: "el"},
        {name: "Kannada", value: "kn"},
        {name: "Tamil", value: "ta"},
        {name: "Telugu", value: "te"},
        {name: "Malayalam", value: "ml"},
        {name: "Marathi", value: "mr"},
        {name: "Gujarati", value: "gu"},
        {name: "Punjabi", value: "pa"},
        {name: "Urdu", value: "ur"},
        {name: "Indonesian", value: "id"},
        {name: "Vietnamese", value: "vi"},
        {name: "Thai", value: "th"}
    ];
}
