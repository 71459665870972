import React from "react";
import { Link } from "@mui/material";

const CoreHref = ({ href = "/", underline = "", children, ...rest }) => {
    const style = {
        textDecoration: "inherit",
        color: "inherit",
    };

    return (
        <Link href={href} style={style} underline={"none"} {...rest}>
            {children || null}
        </Link>
    );
};

export default CoreHref;
