import React, {useEffect, useState} from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import StripeForm from './StripeForm';
import {useDispatch, useSelector} from 'react-redux';
import Skeleton from '@mui/material/Skeleton';
import {getAgencyStripePaymentIntegrationData} from '../../state/features/paymentIntegration/paymentIntegrationSlice';

const PaymentCard = ({onClosePaymentForm}) => {
    const dispatch = useDispatch('');
    const {primaryKey} = useSelector(state => state.paymentIntegration);
    const [loading, setLoading] = useState(true);

    const [stripePromiseState, setStripePromiseState] = useState(null);

    useEffect(() => {
        if (primaryKey) {
            const stripePromise = loadStripe(primaryKey);
            setStripePromiseState(stripePromise);
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }, [primaryKey]);

    useEffect(() => {
        // here fetch the data;
        if (!primaryKey) {
            dispatch(getAgencyStripePaymentIntegrationData());
        }
    }, []);

    return (
        <div>
            {loading ? (
                <>
                    <Skeleton variant={'rectangular'} height={40} sx={{my: 3}}/>
                    <Skeleton variant={'rectangular'} height={40} sx={{mb: 2}}/>
                </>
            ) : (
                <Elements stripe={stripePromiseState}>
                    <StripeForm onClosePaymentForm={onClosePaymentForm}/>
                </Elements>
            )}
        </div>
    );
};

export default PaymentCard;
