import {all, put} from 'redux-saga/effects';
import {call, takeEvery} from '@redux-saga/core/effects';
import ImageToPromptAltTextService from "../../../services/ImageToPromptAltText.service";
import {imageToPromptFailed, imageToPromptSuccess} from "./imageToPromptSlice";
import {showSuccessToaster} from "../../../helpers/utility/toaster";

function* imageToPromptWatcher() {
    yield takeEvery('imageToPrompt/imageToPrompt', generateImageToPromptSaga);
}

function* generateImageToPromptSaga(action) {
    try {
        const response = yield call(ImageToPromptAltTextService.imageToPromptAltText, action.payload);

        if (response.status) {
            yield put(imageToPromptSuccess(response.data));
            showSuccessToaster(response.message)
        } else {
            yield put(imageToPromptFailed(response.message));
        }
    } catch (err) {
        console.log('Error: ', err);
        yield put(imageToPromptFailed(err.message));
    }
}


export default function* imageToPromptSaga() {
    yield all([imageToPromptWatcher()]);
}
