import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    graphData: null,
    isLoading: false,
    isError: false,
    error: '',
};

const totalGraphDataSlice = createSlice({
    name: 'totalGraphData',
    initialState: initialState,
    reducers: {
        totalGraphData: (state, action) => {
            state.isLoading = true;
        },
        totalGraphDataSuccess: (state, action) => {
            state.graphData = action?.payload;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        totalGraphDataFailed: (state, action) => {
            state.graphData = null;
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },
        totalGraphDataStateClear: (state, action) => {
            state.graphData = null;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
    }
});

export const {
    totalGraphData,
    totalGraphDataSuccess,
    totalGraphDataFailed,
    totalGraphDataStateClear,
} = totalGraphDataSlice.actions;

export default totalGraphDataSlice.reducer;
