import {all, put} from 'redux-saga/effects';
import {call, takeEvery} from '@redux-saga/core/effects';
import {
    contentRepurposingFailed,
    contentRepurposingSuccess,
    getContentRepurposingInputOutputListFailed,
    getContentRepurposingInputOutputListSuccess,
} from "./contentRepurposingSlice";
import ContentRepurposingService from "../../../services/ContentRepurposing.service";
import {showSuccessToaster} from "../../../helpers/utility/toaster";

function* contentRepurposingWatcher() {
    yield takeEvery('contentRepurposing/contentRepurposing', generateContentRepurposingSaga);
    yield takeEvery('contentRepurposing/getContentRepurposingInputOutputList', getContentRepurposingInputOutputListSaga);
}

function* generateContentRepurposingSaga(action) {
    try {
        const response = yield call(ContentRepurposingService.contentRepurposing, action.payload);

        if (response.status) {
            yield put(contentRepurposingSuccess(response.data));
            showSuccessToaster(response.message)
        } else {
            yield put(contentRepurposingFailed(response.message));
        }
    } catch (err) {
        console.log('Error: ', err);
        yield put(contentRepurposingFailed(err.message));
    }
}

function* getContentRepurposingInputOutputListSaga(action) {
    try {
        const response = yield call(ContentRepurposingService.contentRepurposingInputOutputList, action.payload);
        if (response.status) {
            yield put(getContentRepurposingInputOutputListSuccess(response.data));
        } else {
            yield put(getContentRepurposingInputOutputListFailed(response.message));
        }
    } catch (err) {
        console.log('Error: ', err);
        yield put(getContentRepurposingInputOutputListFailed(err.message));
    }
}

export default function* contentRepurposingSaga() {
    yield all([contentRepurposingWatcher()]);
}
