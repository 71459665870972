import React, {useState} from 'react';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import {Logout} from '@mui/icons-material';
import PopHoverMenu from '../../../assets/global/Menu/PopHoverList';
import PopHoverMenuItem from '../../../assets/global/Menu/PopHoverMenuItem';
import CoreHref from "../../../assets/global/Link/CoreHref";
import {clearStorageWhenLogOut} from "../../../helpers/utility/clearStorageWhenLogOut";
import {attemptLogout} from "../../../state/features/user/userSlice";
import {useDispatch} from "react-redux";
import {useNavigate, NavLink, Link} from "react-router-dom";

const AppNavbarProfile = ({profileData}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [anchorElUser, setAnchorElUser] = useState(null);

    const handleOpenUserMenu = event => setAnchorElUser(event.currentTarget);
    const handleCloseUserMenu = () => setAnchorElUser(null);

    const handleLogOut = () => {
        clearStorageWhenLogOut();
        dispatch(attemptLogout());
        navigate('/login');
    };

    return (
        <>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <IconButton sx={{p: 0}}>
                    <Avatar alt="logo" src=""/>
                </IconButton>
                <Typography variant={'subtitle2'} sx={{fontWeight: 'bold'}} mx={1}>
                    {profileData?.first_name || ' '}
                </Typography>
                <IconButton onClick={handleOpenUserMenu}>
                    <ArrowDropDownIcon/>
                </IconButton>
            </Box>

            <PopHoverMenu
                anchorEl={anchorElUser}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                <Divider/>
                <Link to="/accountSetting" style={{padding:'3px', minWidth: '30px', paddingRight: '5px',textDecoration:'none'}}>
                    <PopHoverMenuItem name={'Account'} icon={<Avatar sx={{marginRight:'5px'}}/>}/>

                    {/*<Box sx={{display:'flex'}}>*/}
                    {/*    <IconButton sx={{p: 0}}>*/}
                    {/*        <Avatar alt="logo" src=""/>*/}
                    {/*    </IconButton>*/}
                    {/*    <PopHoverMenuItem name={'Account'} />*/}
                    {/*</Box>*/}
                </Link>
                <Divider/>
                <Divider/>
                <PopHoverMenuItem onClick={handleLogOut} name={'Log out'} icon={<Logout/>}/>

                <CoreHref>

                </CoreHref>
            </PopHoverMenu>
        </>
    );
};

export default AppNavbarProfile;
