import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    accountSetting: null,
    loading: false,
    error: "",
    formSubmitLoader: false,
};

const accountSettingSlice = createSlice({
    name: "accountSetting",
    initialState,
    reducers: {
        getAccountSettingData: (state) => {
            state.loading = true;
        },
        getAccountSettingDataSuccess: (state, action) => {
            state.accountSetting = action.payload;
            state.loading = false;
            state.error = "";
        },
        getAccountSettingDataFailed: (state, action) => {
            state.accountSetting = null;
            state.loading = false;
            state.error = action.payload;
        },

        updateAccountSettingInfo: (state) => {
            state.formSubmitLoader = true;
        },
        updateAccountSettingInfoSuccess: (state, action) => {
            state.formSubmitLoader = false;
            state.error = "";
        },
        updateAccountSettingInfoFailed: (state, action) => {
            state.formSubmitLoader = false;
            state.error = action.payload;
        },
    },
});

export const {
    getAccountSettingData,
    getAccountSettingDataSuccess,
    getAccountSettingDataFailed,
    updateAccountSettingInfo,
    updateAccountSettingInfoSuccess,
    updateAccountSettingInfoFailed,
} = accountSettingSlice.actions;

export default accountSettingSlice.reducer;
