import {all, put} from 'redux-saga/effects';
import {call, takeEvery} from '@redux-saga/core/effects';
import CollectionService from '../../../services/Collection.service';
import {
    createCollectionFailed,
    createCollectionSuccess,
    deleteCollectionFailed,
    deleteCollectionSuccess,
    getAllCollectionListFailed,
    getAllCollectionListSuccess,
    getAllImageCollection,
    getAllImageListFailed,
    getAllImageListSuccess,
    getCollection,
    getCollectionFailed,
    getCollectionSuccess,
    getSpecificCollectionFailed,
    getSpecificCollectionSuccess,
    imageCreateCollectionFailed,
    imageCreateCollectionSuccess,
    promptDeleteFailed,
    promptDeleteSuccess,
    updateCollectionFailed,
    updateCollectionSuccess,
} from "./collectionSlice";
import {showErrorToaster, showSuccessToaster} from "../../../helpers/utility/toaster";
import {getPromptCollection} from "../promptCollection/promptCollectionSlice";

function* collectionWatcher() {
    // yield takeEvery('collection/getCollection', getCollectionSaga);
    yield takeEvery('collection/getCollection', getCollectionSaga);
    yield takeEvery('collection/createCollection', createCollectionSaga);
    yield takeEvery('collection/updateCollection', updateCollectionSaga);
    yield takeEvery('collection/deleteCollection', deleteCollectionSaga);
    yield takeEvery('collection/getAllCollectionList', getAllCollectionListSaga);
    yield takeEvery('collection/getSpecificCollection', getSpecificCollectionSaga);
    yield takeEvery('collection/createImageCollection', createImageCollectionSaga);
    yield takeEvery('collection/getAllImageCollection', getImageCollectionSaga);
    yield takeEvery('collection/promptDelete', promptDeleteSaga);
}

function* getCollectionSaga(action) {
    try {
        const response = yield call(CollectionService.getCollection, action.payload);

        if (response.success) {
            yield put(getCollectionSuccess(response.data));
        } else {
            yield put(getCollectionFailed(response.message));
        }
    } catch (err) {
        console.log('Error: ', err);
        yield put(getCollectionFailed(err.message));
    }
}

function* getSpecificCollectionSaga(action) {
    try {
        const response = yield call(CollectionService.getSpecificCollection, action.payload);

        if (response.success) {
            yield put(getSpecificCollectionSuccess(response.data));
        } else {
            yield put(getSpecificCollectionFailed(response.message));
        }
    } catch (err) {
        console.log('Error: ', err);
        yield put(getSpecificCollectionFailed(err.message));
    }
}


function* createCollectionSaga(action) {
    try {
        const response = yield call(CollectionService.createCollection, action.payload);

        if (response.success) {
            yield put(createCollectionSuccess(response.data));
            showSuccessToaster(response.message)
            yield put(getCollection());
        } else {
            yield put(createCollectionFailed(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log('Error: ', err);
        yield put(createCollectionFailed(err.message));
    }
}

function* updateCollectionSaga(action) {
    try {
        const response = yield call(CollectionService.updateCollection, action.payload);

        if (response.success) {
            yield put(updateCollectionSuccess(response.data));
            showSuccessToaster(response.message)
            yield put(getCollection());
            yield put(getAllImageCollection());
        } else {
            yield put(updateCollectionFailed(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log('Error: ', err);
        yield put(updateCollectionFailed(err.message));
    }
}

function* deleteCollectionSaga(action) {
    try {
        const response = yield call(CollectionService.deleteCollection, action.payload);

        if (response.success) {
            yield put(deleteCollectionSuccess(response.data));
            showSuccessToaster(response.message)
            yield put(getCollection());
            yield put(getAllImageCollection());
        } else {
            yield put(deleteCollectionFailed(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log('Error: ', err);
        yield put(deleteCollectionFailed(err.message));
    }
}

function* getAllCollectionListSaga(action) {
    try {
        const response = yield call(CollectionService.getAllCollectionList, action.payload);
        if (response.success) {
            yield put(getAllCollectionListSuccess(response.data));
        } else {
            yield put(getAllCollectionListFailed(response.message));
        }
    } catch (err) {
        console.log('Error: ', err);
        yield put(getAllCollectionListFailed(err.message));
    }
}

function* createImageCollectionSaga(action) {
    try {
        const response = yield call(CollectionService.createImageCollection, action.payload);

        if (response.success) {
            yield put(imageCreateCollectionSuccess(response.data));
            showSuccessToaster(response.message);
            yield put(getAllImageCollection());
        } else {
            yield put(imageCreateCollectionFailed(response.message));
            showErrorToaster(response.message);
        }
    } catch (err) {
        console.log('Error: ', err);
        yield put(imageCreateCollectionFailed(err.message));
    }
}

function* getImageCollectionSaga(action) {
    try {
        const response = yield call(CollectionService.getImageCollection, action.payload);

        if (response.success) {
            yield put(getAllImageListSuccess(response.data));
        } else {
            yield put(getAllImageListFailed(response.message));
            showErrorToaster(response.message);
        }
    } catch (err) {
        console.log('Error: ', err);
        yield put(getAllImageListFailed(err.message));
    }
}

function* promptDeleteSaga(action) {
    try {
        const response = yield call(CollectionService.promptDelete, action.payload);

        if (response.success) {
            yield put(promptDeleteSuccess(response.data));
            showSuccessToaster(response.message)
            yield put(getPromptCollection({collection_id: action.payload.collection_id}))
        } else {
            yield put(promptDeleteFailed(response.message));
            showErrorToaster(response.message);
        }
    } catch (err) {
        console.log('Error: ', err);
        yield put(promptDeleteFailed(err.message));
    }
}


export default function* collectionSaga() {
    yield all([collectionWatcher()]);
}
