import React, {useEffect} from 'react';
import {Box, Typography} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {getBalance} from '../../../state/features/balanceCheck/balanceCheckSlice';
import {selectBalanceCheckState} from '../../../state/features/balanceCheck/balanceCheckSelector';

const BalanceCheck = ({content_type = 1, amount_data = 100}) => {
    const dispatch = useDispatch();
    const {balanceData} = useSelector(selectBalanceCheckState);

    useEffect(() => {
        dispatch(getBalance({content_type: content_type, amount_data: amount_data}));
    }, [content_type,amount_data]);

    return (
        <Box
            sx={{
                color: '#FF8800',
                padding: '10px 5px',
                border: '1px solid #1976d2',
                borderRadius: '5px',
                margin: '5px 0px'
            }}
        >
            <Typography variant="overline">
                Note: Your Current balance: {balanceData?.creditBalance?.current_balance.toFixed(2)}.
                Approximate cost will be: ${balanceData?.creditCostCount}
            </Typography>
        </Box>
    );
};

export default BalanceCheck;
