import React, {useRef, useState} from 'react';
import {Box, Button, Card, CardActions, CardMedia, Stack, Typography} from '@mui/material';
import {makeStyles} from "@mui/styles";
import DownloadIcon from "@mui/icons-material/Download";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import * as htmlToImage from "html-to-image";
import {showErrorToaster, showSuccessToaster} from "../../helpers/utility/toaster";
import PromptCollectionForm from "../prompt/PromptCollectionForm";
import FullPageDrawer from "../../assets/global/Drawer/FullPageDrawer";
import {createImageCollection} from "../../state/features/promptCollection/promptCollectionSlice";
import {useDispatch} from "react-redux";
import TextDraggable from "../memeMaker/TextDraggable";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import {Link} from "react-router-dom";
import BalanceCheckService from "../../services/BalanceCheck.service";
import {
    creditSettingsImageContent256x256,
    PACKAGE_LIMIT__BALANCE__KEY__CREDIT_AMOUNT
} from "../../helpers/constant/coreConstant";
import {getSpecificBalanceData} from "../../state/features/userBalance/userBalanceSlice";

const ResponseImage = ({imageUrl, formFields, imgId = {imgId}}) => {
    const dispatch = useDispatch();
    const imageRef = useRef(null);
    const [viewDetailsPageShow, setViewDetailsPageShow] = useState(false);
    const textArray = encodeURIComponent(JSON.stringify(formFields));

    const useStyles = makeStyles({
        card: {
            width: '650px', // Set the desired fixed width
            height: 'auto', // Adjust the height as per your requirements
            margin: '0 auto', // Center the card horizontally
            // Add any other styles you need
        },

        cardActions: {
            justifyContent: 'center',
            alignItems: 'center',
        }
    });

    const toggleViewDetailsPage = () => setViewDetailsPageShow(prevState => !prevState);

    const handleViewDetailsPage = data => {
        toggleViewDetailsPage();
    };

    const handlePromptCollectionSubmit = data => {
        var node = document.getElementById(`my-node-${imgId}`);
        htmlToImage.toPng(node, {quality: 0.95}).then(function (dataUrl) {
            const payload = {};
            payload.collection_id = data.collection_id || '';
            payload.type = 2;
            payload.text = "Images";
            payload.response = dataUrl;

            dispatch(createImageCollection(payload));
        });
    };
    const handleDownload = () => {

        BalanceCheckService.getBalance({content_type: creditSettingsImageContent256x256, amount_data: 500})
            .then((response) => {
                if (response.success) {
                    var node = document.getElementById(`my-node-${imgId}`);
                    htmlToImage.toPng(node, {quality: 0.95}).then(function (dataUrl) {
                        var link = document.createElement('a');
                        link.download = 'meme.jpeg';
                        link.href = dataUrl;
                        link.click();
                    });
                    BalanceCheckService.reduceBalance({
                        content_type: creditSettingsImageContent256x256,
                        amount_data: 500
                    });
                    dispatch(getSpecificBalanceData({key: PACKAGE_LIMIT__BALANCE__KEY__CREDIT_AMOUNT}));
                } else {
                    showErrorToaster('Insufficient balance')
                }
            })
            .catch((err) => console.log(err));

    };

    const handleCopy = () => {
        var node = document.getElementById(`my-node-${imgId}`);
        htmlToImage.toPng(node, {quality: 0.95}).then(function (dataUrl) {
            fetch(dataUrl)
                .then((res) => res.blob())
                .then((blob) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        const dataUrl = reader.result;
                        const item = new ClipboardItem({'image/png': blob});
                        navigator.clipboard.write([item]);
                        showSuccessToaster('Image copied');
                    };
                    reader.readAsDataURL(blob);
                })
                .catch((err) => console.error(err));
        });
    }
    const textPosition = (index, position) => {
        //====-=====
    }


    const classes = useStyles();

    return (
        <>
            <Card className={classes.card}>
                <Box ref={imageRef} id={`my-node-${imgId}`}
                     sx={{position: 'relative', width: '100%', height: '100%'}}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        {formFields && Array.isArray(formFields) && formFields.map((field, index) => (

                                <TextDraggable key={index}
                                               text={field.value}
                                               color={field.color}
                                               top={index === 0 ? '0' : '100'}
                                               bottom={index === 0 ? '100' : '0'}
                                               textPosition={textPosition}
                                />
                            )
                        )}

                        <CardMedia component="img" height="650" image={imageUrl} style={{overflow: 'hidden'}}
                                   alt="Image"/>
                    </Box>
                </Box>

                <CardActions className={classes.cardActions}>
                    <Stack
                        direction={'row'}
                        spacing={1}
                        pt={2}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <Button startIcon={<DownloadIcon/>} variant="contained" onClick={handleDownload}>
                            Download
                        </Button>

                        <Link to={`/meme-edit/?url=${imageUrl}&text=${textArray}`}>
                            <Button
                                startIcon={<BorderColorIcon/>}
                                variant="outlined"
                            >
                                Edit
                            </Button>
                        </Link>

                        <Button startIcon={<ContentCopyIcon/>} variant="outlined" onClick={handleCopy}>
                            Copy
                        </Button>

                        <Button startIcon={<StarBorderIcon/>} variant="outlined"
                                onClick={() => handleViewDetailsPage()}>
                            Save
                        </Button>
                    </Stack>
                </CardActions>
                <Box sx={{textAlign: 'center'}}>
                    <Typography p={2} color={'text.secondary'} fontSize={12}>
                        Drag and drop meme text to change text position
                    </Typography>
                </Box>

            </Card>

            <FullPageDrawer
                title="Save Image collection"
                open={viewDetailsPageShow}
                toggleDrawer={toggleViewDetailsPage}
                width="35%"
                height="60%"
            >
                <PromptCollectionForm
                    helperKey={'IMAGE'}
                    toggleDrawer={toggleViewDetailsPage}
                    handlePromptCollectionSubmit={handlePromptCollectionSubmit}
                />
            </FullPageDrawer>
        </>
    );
};

export default ResponseImage;
