import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    template: [],
    page: 1,
    perPage: 9,
    total: 9,
    isLoading: false,
    isError: false,
    error: '',
    specificTemplate: null,
    isResponseSuccess: false,
    responseData: null,
    showGenerateButton: true,
    showReGenerateButton: false,
    isShowReCopyButton: false,
    promptInputText: ""
};

const promptSlice = createSlice({
    name: 'prompt',
    initialState: initialState,
    reducers: {
        getPromptTemplate: (state, action) => {
            state.isLoading = true;

            if (action.payload) {
                state.page = action.payload.page || 1;
                state.perPage = action.payload.per_page || 10;
            }
        },
        getPromptTemplateSuccess: (state, action) => {
            state.template = action.payload.data;
            state.total = action.payload.total;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        getPromptTemplateFailed: (state, action) => {
            state.template = [];
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },

        getSpecificPromptTemplate: (state, action) => {
            state.isLoading = true;
        },
        getSpecificPromptTemplateSuccess: (state, action) => {
            state.specificTemplate = action.payload;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        getSpecificPromptTemplateFailed: (state, action) => {
            state.specificTemplate = null;
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },

        getGeneratedResponse: (state, action) => {
            state.isLoading = true;

            // if (action.payload) {
            //     state.promptInputText = action.payload.prompt || "";
            // }
        },
        getGeneratedResponseSuccess: (state, action) => {
            state.responseData = action.payload?.content;
            state.isLoading = false;
            state.isResponseSuccess = true;
            state.isShowReCopyButton = true;
            state.isError = false;
            state.error = '';
            state.showGenerateButton = false;
            state.showReGenerateButton = true;
        },
        getGeneratedResponseFailed: (state, action) => {
            state.responseData = null;
            state.isLoading = false;
            state.isError = true;
            state.isResponseSuccess = false;
            state.isShowReCopyButton = false;
            state.error = action.payload;
        },

        clearState: (state, action) => {
            state.template = [false];
            state.page = 1;
            state.perPage = 9;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
            // state.specificTemplate = '';
            state.isResponseSuccess = false;
            state.responseData = null;
            state.showGenerateButton = true;
            state.showReGenerateButton = false;
            state.isShowReCopyButton = false;
            // state.promptInputText = "";
        },

        clearTemplateState: (state, action) => {
            state.template = [];
        },

        setTemplatePreviewText: (state, action) => {
            if (action.payload) {
                state.promptInputText = action.payload.prompt || "";
            }
        }
    }
});

export const {
    getPromptTemplate,
    getPromptTemplateSuccess,
    getPromptTemplateFailed,
    getSpecificPromptTemplate,
    getSpecificPromptTemplateSuccess,
    getSpecificPromptTemplateFailed,
    getGeneratedResponse,
    getGeneratedResponseSuccess,
    getGeneratedResponseFailed,
    clearState,
    setTemplatePreviewText
} = promptSlice.actions;

export default promptSlice.reducer;
