import React from 'react';
import {Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Stack} from "@mui/material";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import AltTextImage from '../../assets/videos/OLK9AI Image to Alt text.mp4';

const ImageToAltTextHelperText = () => {


    return (
        <>
            <Stack spacing={2} sx={{
                backgroundColor: '#F5F5F5',
                padding: '20px',
                alignItems: "center",
                justifyContent: "center"
            }}
            >
                <List
                    sx={{
                        width: '90%',
                        bgcolor: '#e3f3e7',
                        marginBottom: '20px',
                    }}
                >

                    <ListItem>
                        <ListItemAvatar>
                            <Avatar>
                                <RecordVoiceOverIcon/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Image to Alt Text"
                                      secondary="Image to Alt Text Feature Description"/>
                    </ListItem>
                    <Divider variant="inset" component="li"/>
                    <Box sx={{
                        alignItems: "center",
                        padding: '20px',
                    }}>
                        <Typography component="div">
                            <Box sx={{fontFamily: 'Monospace', fontSize: 'h6.fontSize', m: 1, fontWeight: 'bold'}}>
                                Add an Image
                            </Box>
                            <Divider/>
                            <Box sx={{fontFamily: 'Monospace', m: 1}}>
                                You have to add an image to generate Alt text.
                            </Box>
                        </Typography>

                        <Typography component="div">
                            <Box sx={{fontFamily: 'Monospace', fontSize: 'h6.fontSize', m: 1, fontWeight: 'bold'}}>
                                Video Description
                            </Box>
                            <Divider/>
                            <Box sx={{fontFamily: 'Monospace', m: 1}}>
                                Here is a video description for Image to Alt Text.
                            </Box>
                        </Typography>

                        <Box sx={{
                            margin: '30px',
                        }}>
                            <div>
                                <video width="100%" height="100%" controls>
                                    <source src={AltTextImage} type="video/mp4"/>
                                    {/*<source src="https://assets.codepen.io/6093409/river.mp4" type="video/ogg"/>*/}
                                </video>
                            </div>
                        </Box>
                    </Box>
                </List>

            </Stack>
        </>
    );
};

export default ImageToAltTextHelperText;
