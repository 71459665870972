import {all, put} from 'redux-saga/effects';
import {call, takeEvery} from '@redux-saga/core/effects';
import {showSuccessToaster} from "../../../helpers/utility/toaster";
import {imageInPaintingFailed, imageInPaintingSuccess,} from "./imageInPaintingSlice";
import ImageInPaintingService from "../../../services/ImageInPainting.service";

function* imageInPaintingWatcher() {
    yield takeEvery('imageInPainting/imageInPainting', generateImageInPainting);
}

function* generateImageInPainting(action) {
    try {
        const response = yield call(ImageInPaintingService.imageInPainting, action.payload);

        if (response.status) {
            yield put(imageInPaintingSuccess(response.data));
            showSuccessToaster(response.message)
        } else {
            yield put(imageInPaintingFailed(response.message));
        }
    } catch (err) {
        console.log('Error: ', err);
        yield put(imageInPaintingFailed(err.message));
    }
}


export default function* imageInPaintingSaga() {
    yield all([imageInPaintingWatcher()]);
}
