import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    aiGeneratedTextData: [],
    isLoading: false,
    isError: false,
    error: ''
};

const AIGeneratedTextSlice = createSlice({
    name: 'aiGeneratedText',
    initialState: initialState,
    reducers: {
        aiGeneratedText: (state, action) => {
            state.isLoading = true;
        },
        aiGeneratedTextSuccess: (state, action) => {
            state.aiGeneratedTextData = action?.payload;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        aiGeneratedTextFailed: (state, action) => {
            state.aiGeneratedTextData = null;
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },
        aiGeneratedTextClearState: (state, action) => {
            state.aiGeneratedTextData = [];
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },
    }
});

export const {
    aiGeneratedText,
    aiGeneratedTextSuccess,
    aiGeneratedTextFailed,
    aiGeneratedTextClearState,
} = AIGeneratedTextSlice.actions;

export default AIGeneratedTextSlice.reducer;
