import React, {Fragment, useEffect, useState} from 'react';
import {Box, FormHelperText, InputLabel, Select, Stack, TextField} from '@mui/material';
import styled from 'styled-components';
import StyledDropzone from '../../assets/global/ImageUpload/StyledDropzone';
import FormControl from '@mui/material/FormControl';
import {imageCreationPattern, imageInPaintingPattern} from '../../helpers/constant/validationPattern';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {selectImageInPaintingState} from '../../state/features/imageInPainting/imageInPaintingSelector';
import {showErrorToaster} from '../../helpers/utility/toaster';
import MenuItem from '@mui/material/MenuItem';
import ResponseImage from '../ImageTricks/ResponseImage';
import FeatureOutputNoResponse from '../common/featureOutput/FeatureOutputNoResponse';
import FeatureOutput from '../common/featureOutput/FeatureOutput';
import {imageInPainting, imageInPaintingStateClear} from '../../state/features/imageInPainting/imageInPaintingSlice';
import FeatureInput from '../common/featureInput/FeatureInput';
import Grid from '@mui/material/Grid';
import {
    creditSettingsImageContent1024x1024,
    creditSettingsImageContent256x256,
    creditSettingsImageContent512x512
} from '../../helpers/constant/coreConstant';
import BalanceCheck from '../../assets/global/BalanceCheck/BalanceCheck';

export const AvatarInput = styled.div`
  margin-bottom: 32px;
  position: relative;
  align-self: center;

  img {
    width: 286px;
    height: 186px;
    object-fit: cover;
    border-radius: 20px;
  }

  .circle {
    width: 186px;
    height: 186px;
    border-radius: 20px;
  }

  label {
    right: 23em !important;
    position: absolute;
    width: 48px;
    height: 48px;
    background: #312e38;
    border-radius: 20px;
    right: 0;
    bottom: 0;
    border: 0;
    cursor: pointer;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      display: none;
    }

    svg {
      width: 20px;
      height: 20px;
      color: #f4ede8;
    }

    &:hover {
      background: blue;
    }
  }
`;

const InpaintingComponents = () => {
    const [imageInput, setImageInput] = useState([]);
    const [imageSize, setImageSize] = useState('1024x1024');
    const [contentType, setContentType] = useState(creditSettingsImageContent1024x1024);
    const [amount, setAmount] = useState(3);

    const {
        handleSubmit,
        control,
        reset,
        formState: {errors}
    } = useForm({
        defaultValues: {
            image_size: '',
            prompt: '',
            total_image: ''
        }
    });
    const dispatch = useDispatch();
    const {imageInPaintingData, isLoading} = useSelector(selectImageInPaintingState);

    useEffect(() => {
        if (imageSize === '256x256') {
            setContentType(creditSettingsImageContent256x256);
        } else if (imageSize === '512x512') {
            setContentType(creditSettingsImageContent512x512);
        } else if (imageSize === '1024x1024') {
            setContentType(creditSettingsImageContent1024x1024);
        }
    }, [imageSize]);

    const handleImageSize = data => {
        setImageSize(data.target.value);
    };
    const handleAmount = data => {
        setAmount(data.target.value);
    };

    const onSubmit = data => {
        if (!imageInput[0]) {
            showErrorToaster('Add an Image');
        } else {
            const img = new Image();
            img.src = URL.createObjectURL(imageInput[0]);
            img.onload = function () {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;

                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);

                const pixelData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;

                let hasTransparentPixels = false;
                for (let i = 0; i < pixelData.length; i += 4) {
                    const alpha = pixelData[i + 3];
                    if (alpha < 255) {
                        hasTransparentPixels = true;
                        break;
                    }
                }

                if (
                    img.height !== img.width &&
                    !imageInput[0].name.endsWith('.png') &&
                    !hasTransparentPixels
                ) {
                    showErrorToaster(
                        'Image dimensions should be equal and extension should be PNG and transparent'
                    );
                    return;
                } else if (img.height !== img.width) {
                    showErrorToaster('Image height and width should be equal');
                    return;
                } else if (!imageInput[0].name.endsWith('.png')) {
                    showErrorToaster('Image extension should be PNG');
                    return;
                } else if (!hasTransparentPixels) {
                    showErrorToaster('Image is not transparent');
                    return;
                } else {
                    let formData = new FormData();
                    formData.append('image_file', imageInput[0]);
                    formData.append('image_size', data.image_size);
                    formData.append('total_image', data.total_image);
                    formData.append('prompt', data.prompt);
                    dispatch(imageInPainting(formData));
                }
            };
        }
    };

    let imageGrid = null;

    if (Array.isArray(imageInPaintingData) && imageInPaintingData.length) {
        switch (imageInPaintingData.length) {
            case 1:
                imageGrid = 12;
                break;

            case 2:
                imageGrid = 6;
                break;

            default:
                imageGrid = 4;
                break;
        }
    }

    const setImageFile = image => {
        setImageInput(image);
    };

    const handleClearInput = () => {
        reset();
    };

    useEffect(() => {
        return () => dispatch(imageInPaintingStateClear());
    }, []);

    return (
        <Fragment>
            <FeatureInput
                headerTitle={'Image Inpainting'}
                helpkey={'IMAGEINPAINTING'}
                subtitle="Generate Image Inpainting"
                footer={{generate: handleSubmit(onSubmit), isLoading, handleClearInput}}
            >
                <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <Stack spacing={2}>
                        <StyledDropzone setImageFile={setImageFile}/>
                        <FormHelperText sx={{color: '#FF8800'}}>
                            Image dimensions should be equal and transparent. Image Extension should be PNG
                        </FormHelperText>

                        <Controller
                            control={control}
                            name="image_size"
                            defaultValue=""
                            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                <FormControl size="small" fullWidth error={!!(invalid && error.message)}>
                                    <InputLabel id="collection-label" required>
                                        Image Size
                                    </InputLabel>
                                    <Select
                                        labelId="input-label"
                                        id="input-select"
                                        defaultValue="1024x1024"
                                        size="small"
                                        value={value}
                                        label="Image Size"
                                        onChange={value => {
                                            onChange(value);
                                            handleImageSize(value);
                                        }}
                                        error={!!(invalid && error.message)}
                                    >
                                        <MenuItem key="HD" value="256x256">
                                            HD
                                        </MenuItem>
                                        <MenuItem key="2K" value="512x512">
                                            2K
                                        </MenuItem>
                                        <MenuItem key="4K" value="1024x1024">
                                            4K
                                        </MenuItem>
                                    </Select>
                                    <FormHelperText>{invalid && error?.message}</FormHelperText>
                                </FormControl>
                            )}
                            rules={imageInPaintingPattern.imageSize}
                        />

                        <Controller
                            control={control}
                            name="prompt"
                            defaultValue=""
                            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                <FormControl size="small" fullWidth error={!!(invalid && error.message)}>
                                    <TextField
                                        label="Image Description *"
                                        placeholder="Describe your image as much details as possible"
                                        value={value}
                                        onChange={onChange}
                                        margin="normal"
                                        variant="outlined"
                                        sx={{width: '100%!important'}}
                                        multiline
                                        rows={4}
                                        size="small"
                                        error={!!(invalid && error.message)}
                                    />
                                    <FormHelperText>{invalid && error?.message}</FormHelperText>
                                </FormControl>
                            )}
                            rules={imageInPaintingPattern.prompt}
                        />

                        <Controller
                            control={control}
                            name="total_image"
                            defaultValue=""
                            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                <FormControl size="small" fullWidth error={!!(invalid && error.message)}>
                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        label="Number of Image *"
                                        value={value}
                                        onChange={value => {
                                            onChange(value);
                                            handleAmount(value);
                                        }}
                                        defaultValue="HD"
                                        size="small"
                                        sx={{width: '100%!important'}}
                                    >
                                        <MenuItem key="1" value="1">
                                            1
                                        </MenuItem>
                                        <MenuItem key="2" value="2">
                                            2
                                        </MenuItem>
                                        <MenuItem key="3" value="3">
                                            3
                                        </MenuItem>
                                    </TextField>

                                    <FormHelperText>{invalid && error?.message}</FormHelperText>
                                </FormControl>
                            )}
                            rules={imageCreationPattern.number_of_image}
                        />
                    </Stack>
                </Box>
                <BalanceCheck content_type={contentType} amount_data={amount}/>

            </FeatureInput>

            <FeatureOutput
                newOutput={
                    <>
                        {Array.isArray(imageInPaintingData) && imageInPaintingData.length > 0 ? (
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': {m: 1, width: '25ch'}
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <Grid container spacing={4}>
                                    {imageInPaintingData.map(output => (
                                        <ResponseImage imageGrid={imageGrid} image={output.url}/>
                                    ))}
                                </Grid>
                            </Box>
                        ) : (
                            <FeatureOutputNoResponse/>
                        )}
                    </>
                }
            />
        </Fragment>
    );
};

export default InpaintingComponents;
