import React from 'react';
import {Avatar, Box, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Stack} from "@mui/material";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import HdIcon from '@mui/icons-material/Hd';
import TwoKIcon from '@mui/icons-material/TwoK';
import FourKIcon from '@mui/icons-material/FourK';
import InpaintingImage from '../../assets/videos/OLK9 Image in Painting.mp4';

const ImageInpaintingHelperText = () => {


    return (
        <>
            <Stack spacing={2} sx={{
                backgroundColor: '#F5F5F5',
                padding: '20px',
                alignItems: "center",
                justifyContent: "center"
            }}
            >
                <List
                    sx={{
                        width: '90%',
                        bgcolor: '#e3f3e7',
                        marginBottom: '20px',
                    }}
                >

                    <ListItem>
                        <ListItemAvatar>
                            <Avatar>
                                <RecordVoiceOverIcon/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Image Inpainting"
                                      secondary="Image Inpainting Feature Description"/>
                    </ListItem>
                    <Divider variant="inset" component="li"/>
                    <Box sx={{
                        alignItems: "center",
                        padding: '20px',
                    }}>
                        <Typography component="div">
                            <Box sx={{fontFamily: 'Monospace', fontSize: 'h6.fontSize', m: 1, fontWeight: 'bold'}}>
                                Add an Image
                            </Box>
                            <Divider/>
                            <Box sx={{fontFamily: 'Monospace', m: 1}}>
                                You have to add an image to generate Image Inpainting.
                            </Box>
                            <Box sx={{fontFamily: 'Monospace', m: 1}}>
                                Image Size should be square and transparent. And image extention should be PNG.
                            </Box>
                        </Typography>

                        <Typography component="div">
                            <Box sx={{fontFamily: 'Monospace', fontSize: 'h6.fontSize', m: 1, fontWeight: 'bold'}}>
                                Image Size
                            </Box>
                            <Divider/>
                            <Box sx={{fontFamily: 'Monospace', m: 1}}> You can select three types of Image Size here :
                            </Box>
                            <List>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <HdIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="HD"/>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <TwoKIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="2K"/>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <FourKIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="4K"/>
                                </ListItem>
                            </List>

                        </Typography>

                        <Typography component="div">
                            <Box sx={{fontFamily: 'Monospace', fontSize: 'h6.fontSize', m: 1, fontWeight: 'bold'}}>
                                Image Description
                            </Box>
                            <Divider/>
                            <Box sx={{fontFamily: 'Monospace', m: 1}}> Give a brief description of the image that you
                                uploaded.
                            </Box>
                        </Typography>

                        <Typography component="div">
                            <Box sx={{fontFamily: 'Monospace', fontSize: 'h6.fontSize', m: 1, fontWeight: 'bold'}}>
                                Number of Image
                            </Box>
                            <Divider/>
                            <Box sx={{fontFamily: 'Monospace', m: 1}}>
                                You can generate maximum 3 number of image here.
                            </Box>
                        </Typography>

                        <Typography component="div">
                            <Box sx={{fontFamily: 'Monospace', fontSize: 'h6.fontSize', m: 1, fontWeight: 'bold'}}>
                                Video Description
                            </Box>
                            <Divider/>
                            <Box sx={{fontFamily: 'Monospace', m: 1}}>
                                Here is a video description for Image Inpainting.
                            </Box>
                        </Typography>

                        <Box sx={{
                            margin: '30px',
                        }}>
                            <div>
                                <video width="100%" height="100%" controls>
                                    <source src={InpaintingImage} type="video/mp4"/>
                                    {/*<source src="https://assets.codepen.io/6093409/river.mp4" type="video/ogg"/>*/}
                                </video>
                            </div>
                        </Box>
                    </Box>
                </List>

            </Stack>
        </>
    );
};

export default ImageInpaintingHelperText;
