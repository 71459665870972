import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    packages: [],
    loading: false,
    error: "",
};

const packageSlice = createSlice({
    name: "package",
    initialState,
    reducers: {
        getPackages: (state, action) => {
            state.loading = true;
            if (action.payload) {
                state.page = action.payload.page || 1;
                state.perPage = action.payload.per_page || 10;
            }
        },
        getPackagesSuccess: (state, action) => {
            state.packages = action.payload?.data;
            state.loading = false;
            state.page = action.payload.page;
            state.perPage = action.payload.per_page;
            state.total = action.payload.total;
        },
        getPackagesFailed: (state, action) => {
            state.packages = [];
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const { getPackages, getPackagesSuccess, getPackagesFailed } = packageSlice.actions;

export default packageSlice.reducer;
