import {all, put} from 'redux-saga/effects';
import {call, takeEvery} from '@redux-saga/core/effects';
import CategoryService from '../../../services/Category.service';
import {
    getCategoryFailed,
    getCategorySuccess,
    getPresetTemplateCategoryFailed,
    getPresetTemplateCategorySuccess
} from "./categorySlice";
import PresetTemplateCategoryService from "../../../services/PresetTemplateCategory.service";

function* categoryWatcher() {
    yield takeEvery('categories/getCategory', getCategorySaga);
    yield takeEvery('categories/getPresetTemplateCategory', getPresetTemplateCategorySaga);
}

function* getCategorySaga(action) {
    try {
        const response = yield call(CategoryService.getCategory, action.payload);

        if (response.success) {
            yield put(getCategorySuccess(response.data));
        } else {
            yield put(getCategoryFailed(response.message));
        }
    } catch (err) {
        console.log('Error: ', err);
        yield put(getCategoryFailed(err.message));
    }
}

function* getPresetTemplateCategorySaga(action) {
    try {
        const response = yield call(PresetTemplateCategoryService.getCategory, action.payload);

        if (response.status) {
            yield put(getPresetTemplateCategorySuccess(response.data));
        } else {
            yield put(getPresetTemplateCategoryFailed(response.message));
        }
    } catch (err) {
        console.log('Error: ', err);
        yield put(getPresetTemplateCategoryFailed(err.message));
    }
}

export default function* categorySaga() {
    yield all([categoryWatcher()]);
}
