import React, {useEffect, useState} from 'react';
import {Box, Grid, Paper, Typography} from '@mui/material';
import {useSelector} from 'react-redux';
import {format} from 'date-fns';
import {CoreCardHeader} from '../../assets/global/CardHeader/CoreCardHeader';

export const AvailableBalance = () => {
    const {dollarBalance, freeCreditDollar, rechargeCreditDollar} = useSelector(state => state.userBalance);
    const {userPackage} = useSelector(state => state.userPackage);
    const [nextBilling, setNextBilling] = useState('');

    useEffect(() => {
        if (userPackage && userPackage.expire_date) {
            const date = new Date(userPackage.expire_date);
            const formattedDate = format(new Date(date), 'MMMM dd, yyyy');
            setNextBilling(formattedDate);
        }
    }, [userPackage]);

    return (
        <Grid item md={6} lg={6} xl={6}>
            <Paper elevation={0} sx={{height: '100%'}}>
                <CoreCardHeader title={'Available Balance'}/>

                <Box p={3}>
                    <Grid container alignItems={'center'} justifyContent="space-between">
                        <Grid item md={6} lg={6} xl={6}>
                            <Typography variant={'h4'} color={'primary'}>
                                $ {dollarBalance}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>

                <Box p={3} pt={0} pb={'30px'}>
                    <Typography variant={'body1'} display="flex" color="text.secondary">
                        Your Free credit amount:{' '}
                        <Typography variant={'body1'} color={'warning.main'} ml={'5px'}>
                            $ {freeCreditDollar}
                        </Typography>
                    </Typography>

                    <Typography variant={'body1'} display="flex" color="text.secondary">
                        Your Recharge credit amount:{' '}
                        <Typography variant={'body1'} color={'warning.main'} ml={'5px'}>
                            $ {rechargeCreditDollar}
                        </Typography>
                    </Typography>

                    <Typography variant={'body1'} display="flex" color="text.secondary">
                        Your next invoice schedule:{' '}
                        <Typography variant={'body1'} color={'warning.main'} ml={'5px'}>
                            {nextBilling}
                        </Typography>
                    </Typography>
                </Box>
            </Paper>
        </Grid>
    );
};
