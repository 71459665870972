import {requests} from "./http.node.service";

class PaymentService {
    createCustomer(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_NODE_SERVER + "/payment/customer/create", body);
    }

    makePayment(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_NODE_SERVER + "/payment/make/payment", body);
    }
}

export default new PaymentService();
