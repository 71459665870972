import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    totalDashboardCountData: null,
    isLoading: false,
    isError: false,
    error: '',
};

const totalDashboardCountSlice = createSlice({
    name: 'totalDashboardCount',
    initialState: initialState,
    reducers: {
        totalDashboardCount: (state, action) => {
            state.isLoading = true;
        },
        totalDashboardCountSuccess: (state, action) => {
            state.totalDashboardCountData = action?.payload;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        totalDashboardCountFailed: (state, action) => {
            state.totalDashboardCountData = null;
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },
        totalDashboardCountStateClear: (state, action) => {
            state.totalDashboardCountData = null;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
    }
});

export const {
    totalDashboardCount,
    totalDashboardCountSuccess,
    totalDashboardCountFailed,
    totalDashboardCountStateClear,
} = totalDashboardCountSlice.actions;

export default totalDashboardCountSlice.reducer;
