import React from 'react';
import {
    AI_TRANSCRIPTION_COLLAPSE_MENU,
    MEME_MAKER_COLLAPSE_MENU,
    PACKAGE_FEATURE__KEY__AUDIO,
    PACKAGE_FEATURE__KEY__COLLECTION,
    PACKAGE_FEATURE__KEY__CONTENT_REPURPOSING,
    PACKAGE_FEATURE__KEY__DASHBOARD,
    PACKAGE_FEATURE__KEY__IMAGE_CREATION,
    PACKAGE_FEATURE__KEY__IMAGE_IN_PAINTING,
    PACKAGE_FEATURE__KEY__IMAGE_TO_ALT,
    PACKAGE_FEATURE__KEY__IMAGE_TO_PROMPT,
    PACKAGE_FEATURE__KEY__IMAGE_VARIATION,
    PACKAGE_FEATURE__KEY__MEME_MAKER,
    PACKAGE_FEATURE__KEY__PLAY_PEN,
    PACKAGE_FEATURE__KEY__PROMPT,
    PACKAGE_FEATURE__KEY__SEARCH_TEMPLATES,
    PACKAGE_FEATURE__KEY__TEMPLATE,
    PACKAGE_FEATURE__KEY__TEXT_TO_MEME,
    PACKAGE_FEATURE__KEY__THEMED_MEMES
} from '../constant/coreConstant';
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import PermMediaIcon from '@mui/icons-material/PermMedia';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ModeIcon from '@mui/icons-material/Mode';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import VrpanoIcon from '@mui/icons-material/Vrpano';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import SatelliteIcon from '@mui/icons-material/Satellite';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import ShortTextIcon from '@mui/icons-material/ShortText';
import HomeIcon from "@mui/icons-material/Home";

export const appSideBar = [
    {
        item: PACKAGE_FEATURE__KEY__DASHBOARD,
        name: 'Home',
        route: '/dashboard',
        icon: <HomeIcon sx={{fontSize: '20px', mr: '5px', fontWeight: '400'}}/>
    },
    {
        item: PACKAGE_FEATURE__KEY__PROMPT,
        name: 'Prompt',
        route: '/prompt',
        icon: <FolderSharedIcon sx={{fontSize: '20px', mr: '5px', fontWeight: '400'}}/>
    },
    {
        item: PACKAGE_FEATURE__KEY__COLLECTION,
        name: 'Collections',
        route: '/collections',
        icon: <PermMediaIcon sx={{fontSize: '20px', mr: '5px', fontWeight: '400'}}/>
    },
    {
        item: PACKAGE_FEATURE__KEY__TEMPLATE,
        name: 'Present Templates',
        route: '/presets/templates',
        icon: <ViewModuleIcon sx={{fontSize: '20px', mr: '5px', fontWeight: '400'}}/>
    },
    {
        item: PACKAGE_FEATURE__KEY__PLAY_PEN,
        name: 'Play pen',
        route: '/play-pen',
        icon: <ModeIcon sx={{fontSize: '20px', mr: '5px', fontWeight: '400'}}/>
    },
    {
        item: PACKAGE_FEATURE__KEY__CONTENT_REPURPOSING,
        name: 'Content Repurposing',
        route: '/image-repurposing',
        icon: <ContentPasteGoIcon sx={{fontSize: '20px', mr: '5px', fontWeight: '400'}}/>
    },
    {
        item: AI_TRANSCRIPTION_COLLAPSE_MENU,
        name: 'AI Image Transcriptions',
        route: '',
        icon: <AddPhotoAlternateIcon sx={{fontSize: '20px', mr: '5px', fontWeight: '400'}}/>,
        subMenu: [
            {
                item: PACKAGE_FEATURE__KEY__IMAGE_CREATION,
                parentItem: AI_TRANSCRIPTION_COLLAPSE_MENU,
                name: 'Image Creation',
                route: '/image-creation',
                icon: <AddPhotoAlternateIcon sx={{fontSize: '20px', mr: '5px', fontWeight: '400'}}/>
            },
            {
                item: PACKAGE_FEATURE__KEY__IMAGE_TO_PROMPT,
                parentItem: AI_TRANSCRIPTION_COLLAPSE_MENU,
                name: 'Image to Prompt',
                route: '/image-to-prompt',
                icon: <ArtTrackIcon sx={{fontSize: '20px', mr: '5px', fontWeight: '400'}}/>
            },
            {
                item: PACKAGE_FEATURE__KEY__IMAGE_TO_ALT,
                parentItem: AI_TRANSCRIPTION_COLLAPSE_MENU,
                name: 'Image to Alt Text',
                route: '/image-to-alt-text',
                icon: <VrpanoIcon sx={{fontSize: '20px', mr: '5px', fontWeight: '400'}}/>
            },
            {
                item: PACKAGE_FEATURE__KEY__IMAGE_IN_PAINTING,
                parentItem: AI_TRANSCRIPTION_COLLAPSE_MENU,
                name: 'Image Inpainting',
                route: '/image-in-painting',
                icon: <ColorLensIcon sx={{fontSize: '20px', mr: '5px', fontWeight: '400'}}/>
            },
            {
                item: PACKAGE_FEATURE__KEY__IMAGE_VARIATION,
                parentItem: AI_TRANSCRIPTION_COLLAPSE_MENU,
                name: 'Image Variation',
                route: '/image-variation',
                icon: <SatelliteIcon sx={{fontSize: '20px', mr: '5px', fontWeight: '400'}}/>
            },
        ]
    },
    {
        item: PACKAGE_FEATURE__KEY__AUDIO,
        name: 'AI Audio Transcription',
        route: '/ai-transcription',
        icon: <AudiotrackIcon sx={{fontSize: '20px', mr: '5px', fontWeight: '400'}}/>
    },
    {
        item: MEME_MAKER_COLLAPSE_MENU,
        name: 'Meme Creation',
        route: '',
        icon: <AddReactionIcon sx={{fontSize: '20px', mr: '5px', fontWeight: '400'}}/>,
        subMenu: [
            {
                item: PACKAGE_FEATURE__KEY__TEXT_TO_MEME,
                parentItem: MEME_MAKER_COLLAPSE_MENU,
                name: 'Text to Meme',
                route: '/text-to-meme',
                icon: <ShortTextIcon sx={{fontSize: '20px', mr: '5px', fontWeight: '400'}}/>
            },
            {
                item: PACKAGE_FEATURE__KEY__THEMED_MEMES,
                parentItem: MEME_MAKER_COLLAPSE_MENU,
                name: 'Themed Memes',
                route: '/themed-memes',
                icon: <AudiotrackIcon sx={{fontSize: '20px', mr: '5px', fontWeight: '400'}}/>
            },
            {
                item: PACKAGE_FEATURE__KEY__SEARCH_TEMPLATES,
                parentItem: MEME_MAKER_COLLAPSE_MENU,
                name: 'Search Templates',
                route: '/search-template',
                icon: <AudiotrackIcon sx={{fontSize: '20px', mr: '5px', fontWeight: '400'}}/>
            },
            {
                item: PACKAGE_FEATURE__KEY__MEME_MAKER,
                parentItem: MEME_MAKER_COLLAPSE_MENU,
                name: 'Meme Maker',
                route: '/meme-maker',
                icon: <AudiotrackIcon sx={{fontSize: '20px', mr: '5px', fontWeight: '400'}}/>
            },
        ]
    },
];
