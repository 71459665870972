import React, {useEffect, useState} from 'react';
import Stack from '@mui/material/Stack';
import {getPresetTemplateCategory} from '../../state/features/category/categorySlice';
import {useDispatch, useSelector} from 'react-redux';
import {selectCategoriesState} from '../../state/features/category/collectionSelector';
import {Button, Tabs} from '@mui/material';
import styled from 'styled-components';
import {filterByCategory} from '../../state/features/presetTemplate/presetTemplateSlice';

const StyledButton = styled(Button)(({theme}) => ({
    color: 'black',
    backgroundColor: '#f8fafc',
    fontSize: 14,
    textTransform: 'capitalize',
    minWidth: 'fit-content',
    '&:hover': {
        color: 'black',
        backgroundColor: '#f8fafc'
    }
}));

const PresetTemplateCategory = () => {
    const dispatch = useDispatch();
    const {presetTemplateCategories} = useSelector(selectCategoriesState);
    const [value, setValue] = useState('All');
    const handleActiveInactive = data => {
        setValue(data);
    };

    useEffect(() => {
        dispatch(getPresetTemplateCategory());
    }, [dispatch]);

    const handleCategoryClick = data => {
        if (data === 'All') {
            dispatch(filterByCategory(null));
        } else {
            dispatch(filterByCategory(data));
        }
    };

    return (
        <Tabs variant="scrollable" scrollButtons="auto" aria-label="scrollable-contact-line-tabs">
            <Stack
                direction="row"
                spacing={1}
                sx={{padding: '20px 0px', maxWidth: 'calc(100vw - 550px)'}}
            >
                <StyledButton
                    className={`radio-button ${value === 'All' ? 'active' : ''}`}
                    onClick={() => {
                        handleActiveInactive('All');
                        handleCategoryClick('All');
                    }}
                    variant="contained"
                    disableElevation
                >
                    All
                </StyledButton>

                {presetTemplateCategories.map(category => (
                    <StyledButton
                        onClick={() => {
                            handleCategoryClick(category?.id);
                            handleActiveInactive(category?.name);
                        }}
                        variant="contained"
                        disableElevation
                        className={value === category?.name ? 'active' : ''}
                    >
                        {category?.name}
                    </StyledButton>
                ))}
            </Stack>
        </Tabs>
    );
};

export default PresetTemplateCategory;
