import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';
import PresetTemplateDetailsForm from './PresetTemplateDetailsForm';
import PresetTemplateDetailsOutput from './PresetTemplateDetailsOutput';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {getSpecificPresetTemplate} from '../../state/features/presetTemplate/presetTemplateSlice';
import {selectPresetTemplateState} from '../../state/features/presetTemplate/presetTemplateSelector';

const PresetTemplateDetails = () => {
    let {templateId} = useParams();
    const dispatch = useDispatch();
    const {specificPresetTemplate,responseData} = useSelector(selectPresetTemplateState);

    const [outputValue, setOutputValue] = useState(1);
    const [language, setLanguage] = useState('english');

    useEffect(() => {
        dispatch(getSpecificPresetTemplate({id: templateId}));
    }, []);

    const handleOutputValue = data => {
        setOutputValue(data);
    };
    const handleLanguageValue = data => {
        setLanguage(data);
    };

    return (
        <Box
            sx={{
                width: '100%',
                height: '90vh',
                paddingTop: '50px',
                backgroundColor: '#f3f4f6',
                overflow: 'auto'
            }}
        >
            <PresetTemplateDetailsForm
                specificPresetTemplate={specificPresetTemplate}
                handleOutputValue={handleOutputValue}
                handleLanguageValue={handleLanguageValue}
            />

            <PresetTemplateDetailsOutput/>
        </Box>
    );
};

export default PresetTemplateDetails;
