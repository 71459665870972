import {all, put} from 'redux-saga/effects';
import {call, takeEvery} from '@redux-saga/core/effects';
import {hasFeatureAccessFailed, hasFeatureAccessSuccess} from "./packageAccessFeatureSlice";
import UserPackageService from "../../../services/UserPackage.service";

function* packageAccessFeatureWatcher() {
    yield takeEvery('hasFeatureAccess/hasFeatureAccess', packageAccessSaga);
}

function* packageAccessSaga(action) {
    try {
        const response = yield call(UserPackageService.userItemAccess, action.payload);
        if (response.success) {
            yield put(hasFeatureAccessSuccess(response.data));
        } else {
            yield put(hasFeatureAccessFailed(response.message));
        }
    } catch (err) {
        yield put(hasFeatureAccessFailed(err.message));
    }
}


export default function* packageAccessFeatureSaga() {
    yield all([packageAccessFeatureWatcher()]);
}
