export const RandomText = [
    'Just finished my final Phd dissertation',
    'Founders making their first money with their product',
    'Product management is a tricky balance between saying yes and no',
    'Programmers like to argue about what language is the best',
    'I have no idea what I\'m going to cook tonight',
    'Programmers like to argue about what language is the best',
    'Spent 10 hours on social media marketing material and finally got 5 likes',
    'Helping customers to be successful will make your business successful',
    'Just got a raise from the boss',
    'I am exhausted because I spent 10 hours in meetings today',
    'Nobody really knows if crypto will go up or down',
    'happy',
    'When you find the perfect meme and can\'t stop laughing',
    'That moment when a meme perfectly captures your mood',
    'Searching for memes like a pro',
    'When memes make your day 100 times better',
    'Trying to find the funniest meme in the universe',
    'The joy of stumbling upon a meme goldmine',
    'When you discover a meme that speaks to your soul',
    'Scrolling through memes like there\'s no tomorrow',
    'sad',
    'When you\'re on a quest for the ultimate meme masterpiece',
    'The art of meme hunting: an endless adventure',

]