import {Controller, useForm} from 'react-hook-form';
import {Link, useNavigate} from 'react-router-dom';
import React, {useEffect} from 'react';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import {attemptResetPasswordWithToken, clearResetPasswordState} from '../../state/features/user/userSlice';
import {useDispatch, useSelector} from 'react-redux';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {registerPattern} from '../../helpers/constant/validationPattern';
import LoadingButton from "../../assets/global/Button/LoadingButton";

const ResetPassword = () => {
    const theme = createTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {resetPasswordState,pending} = useSelector(state => state.user);

    const {
        register,
        control,
        handleSubmit,
        formState: {errors},
        setValue
    } = useForm({
        mode: 'onSubmit'
    });

    useEffect(() => {
        if (resetPasswordState.isCodeVerify) navigate('/login');
    }, [resetPasswordState.isCodeVerify]);

    // ## Clear Reset Password State When Leave the page
    useEffect(() => {
        return () => dispatch(clearResetPasswordState());
    }, []);

    const onSubmit = data => {
        data.email = resetPasswordState.email;
        dispatch(attemptResetPasswordWithToken(data));
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs"sx={{backgroundColor:'#fff', boxShadow: '0 4px 24px 0 rgba(34, 41, 47, 0.1)',borderRadius:'8px'}}>
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: '0',
                        paddingTop: '30px',
                        paddingBottom: '30px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                     <Box sx={{display: 'flex', justifyContent: 'center', alignItems:'center', marginBottom:'15px'}}>
                     <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Reset Password
                    </Typography>
                  </Box>
                  
                    
                   
                    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{mt: 1}}>
                    <Box sx={{margin:'0',padding:'0'}}>
                  <Typography component="h6" variant="h6" sx={{textAlign: 'left'}}>
                    Welcome to OLK9AI! 👋
                    </Typography>
                    <Typography component="p" variant="body2">
                    Please sign-in to your account and start the adventure
                    </Typography>
                  </Box>
                        <Controller
                            control={control}
                            name="token_code"
                            defaultValue=""
                            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                <FormControl fullWidth required error={!!(invalid && error.message)}>
                                    <TextField
                                    size="small"
                                        margin="normal"
                                        required
                                        value={value}
                                        onChange={onChange}
                                        fullWidth
                                        id="token_code"
                                        label="Token"
                                        autoComplete="nope"
                                        error={!!(invalid && error.message)}
                                        helperText={invalid && error.message}
                                    />
                                </FormControl>
                            )}
                            rules={registerPattern.token}
                        />

                        <Controller
                        
                            control={control}
                            name="new_password"
                            defaultValue=""
                            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                <FormControl fullWidth required error={!!(invalid && error.message)}>
                                    <TextField
                                    size="small"
                                        margin="normal"
                                        required
                                        value={value}
                                        onChange={onChange}
                                        fullWidth
                                        id="new_password"
                                        label="New Password"
                                        autoComplete="nope"
                                        error={!!(invalid && error.message)}
                                        helperText={invalid && error.message}
                                    />
                                </FormControl>
                            )}
                            rules={registerPattern.password}
                        />

                        <Controller
                            control={control}
                            name="password_confirmation"
                            defaultValue=""
                            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                <FormControl fullWidth required error={!!(invalid && error.message)}>
                                    <TextField
                                    size="small"
                                        margin="normal"
                                        required
                                        value={value}
                                        onChange={onChange}
                                        fullWidth
                                        id="password_confirmation"
                                        label="Confirm Password"
                                        autoComplete="nope"
                                        error={!!(invalid && error.message)}
                                        helperText={invalid && error.message}
                                    />
                                </FormControl>
                            )}
                            rules={registerPattern.confirm_password}
                        />

                        {/*<Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>*/}
                        {/*    Set new password*/}
                        {/*</Button>*/}

                        <LoadingButton
                            isLoading={pending}
                            fullWidth
                            loadingText={'Set new password'}
                            type={'submit'}
                            color={'primary'}
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                        >
                            Set new password
                        </LoadingButton>
                        <Grid container>
                            <Grid item>
                                <Link to="/login" variant="body2"style={{fontSize:'14px',textDecoration:'none',color:'#1976d2'}}>
                                    {'Back to sign in'}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
};

export default ResetPassword;
