import {combineReducers} from '@reduxjs/toolkit';
import chatGPTSlice from './features/chatGPT/chatGPTSlice';
import userSlice from './features/user/userSlice';
import promptSlice from './features/prompts/promptSlice';
import collectionSlice from './features/collection/collectionSlice';
import promptCollectionSlice from './features/promptCollection/promptCollectionSlice';
import accountSettingSlice from './features/accountSetting/accountSettingSlice';
import categorySlice from './features/category/categorySlice';
import promptByCategorySlice from './features/promptsByCategory/promptByCategorySlice';
import presetTemplateSlice from './features/presetTemplate/presetTemplateSlice';
import contentRepurposingSlice from './features/contentRepurposing/contentRepurposingSlice';
import playPenSlice from './features/playPen/playPenSlice';
import imageToAltTextSlice from './features/imageToAltText/imageToAltTextSlice';
import imageVariationSlice from './features/imageVariation/imageVariationSlice';
import imageCreationSlice from './features/imageCreation/imageCreationSlice';
import imageInPaintingSlice from './features/imageInPainting/imageInPaintingSlice';
import aiTranscriptionSlice from './features/aiTranscription/aiTranscriptionSlice';
import ImageToPromptSlice from './features/imageToPrompt/imageToPromptSlice';
import paymentSlice from './features/payment/paymentSlice';
import packageSlice from './features/package/packageSlice';
import userPackageSlice from './features/userPackage/userPackageSlice';
import paymentIntegrationSlice from "./features/paymentIntegration/paymentIntegrationSlice";
import userBalanceSlice from "./features/userBalance/userBalanceSlice";
import userPaymentSlice from "./features/userPayment/userPaymentSlice";
import userBillingSlice from "./features/userBilling/userBillingSlice";
import totalDashboardCountSlice from "./features/dashboard/totalDashboardCountSlice";
import balanceCheckSlice from "./features/balanceCheck/balanceCheckSlice";
import totalGraphDataSlice from "./features/dashboard/graph/totalGraphDataSlice";
import packageAccessFeatureSlice from "./features/packageAccessFeature/packageAccessFeatureSlice";
import textToImageSlice from "./features/textToMeme/textToImage/textToImageSlice";
import memeMakerSlice from './features/memeMaker/memeMakerSlice';
import templateSearchSlice from "./features/textToMeme/templateSearch/templateSearchSlice";
import AIGeneratedTextSlice from "./features/textToMeme/randomAIGeneratedText/AIGeneratedTextSlice";
import gifDownloadSlice from "./features/textToMeme/gifDownload/gifDownloadSlice";

const combinedReducer = combineReducers({
    chatGPT: chatGPTSlice,
    user: userSlice,
    prompt: promptSlice,
    collection: collectionSlice,
    promptCollection: promptCollectionSlice,
    accountSetting: accountSettingSlice,
    categories: categorySlice,
    promptByCategory: promptByCategorySlice,
    presetTemplate: presetTemplateSlice,
    contentRepurposing: contentRepurposingSlice,
    playPen: playPenSlice,
    imageToPrompt: ImageToPromptSlice,
    imageCreation: imageCreationSlice,
    imageToAltText: imageToAltTextSlice,
    imageVariation: imageVariationSlice,
    imageInPainting: imageInPaintingSlice,
    aiTranscription: aiTranscriptionSlice,
    payment: paymentSlice,
    package: packageSlice,
    userPackage: userPackageSlice,
    paymentIntegration: paymentIntegrationSlice,
    userBalance: userBalanceSlice,
    userPayment: userPaymentSlice,
    userBilling: userBillingSlice,
    totalDashboardCount: totalDashboardCountSlice,
    balanceCheck: balanceCheckSlice,
    totalGraphData: totalGraphDataSlice,
    packageAccessFeature: packageAccessFeatureSlice,
    textToImage: textToImageSlice,
    memeMaker: memeMakerSlice,
    templateSearch: templateSearchSlice,
    aiGeneratedText: AIGeneratedTextSlice,
    gifDownload: gifDownloadSlice,
});

const rootReducer = (state, action) => {
    return combinedReducer(state, action);
};

export default rootReducer;
