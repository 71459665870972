import {Box, Typography} from '@mui/material';

import {Outlet} from 'react-router-dom';
import PublicLayout from '../PublicLayout';
import React from 'react';
import Styles from './AuthLayout.module.css';
import { useTheme } from "@mui/material/styles";

const AuthLayout = () => {
  const theme = useTheme();

  return (
    <Box className={Styles.auth_layout_container}sx={{backgroundColor:'#F8F8F8'}}>
      <Box className={Styles.auth_layout_right}>
        <PublicLayout>
          <Outlet />
        </PublicLayout>
      </Box>
    </Box>
  );
};

export default AuthLayout;
