import {createSlice} from '@reduxjs/toolkit';
import {USER_PACKAGE_LIMIT_KEY__DOLLAR_BALANCE} from '../../../helpers/constant/coreConstant';

const initialState = {
    dollarBalance: 0,
    freeCreditDollar: 0,
    rechargeCreditDollar: 0,
    dollarBalanceLimit: 0,
    dollarRechargeBalance: 0,
    teamMemberBalance: 0,
    teamMemberBalanceLimit: 0,
    smsBalance: 0,
    smsBalanceLimit: 0,
    emailBalance: 0,
    emailBalanceLimit: 0,
    locationBalance: 0,
    locationBalanceLimit: 0,

    loading: false,
    error: ''
};

const userBalanceSlice = createSlice({
    name: 'userBalance',
    initialState,
    reducers: {
        getSpecificBalanceData: state => {
            state.loading = true;
        },
        getSpecificBalanceDataSuccess: (state, action) => {
            state.dollarBalance = Number(action.payload.current_balance);
            state.freeCreditDollar = Number(action.payload.free_credit_amount);
            state.rechargeCreditDollar = Number(action.payload.recharge_credit_amount);
            state.loading = false;
            state.error = '';
        },
        getSpecificBalanceDataFailed: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },

        getAllBalanceData: state => {
            state.loading = true;
        },
        getAllBalanceDataSuccess: (state, action) => {
            action.payload.map(item => {
                if (item.key === USER_PACKAGE_LIMIT_KEY__DOLLAR_BALANCE) {
                    state.dollarBalance = Number(item.current_balance);
                    state.dollarBalanceLimit = Number(item.limit);
                }

                return item;
            });
            state.loading = false;
            state.error = '';
        },
        getAllBalanceDataFailed: (state, action) => {
            state.dollarBalance = 0;
            state.dollarBalanceLimit = 0;
            state.freeCreditDollar = 0;
            state.rechargeCreditDollar = 0;
            state.teamMemberBalance = 0;
            state.teamMemberBalanceLimit = 0;
            state.smsBalance = 0;
            state.smsBalanceLimit = 0;
            state.emailBalance = 0;
            state.emailBalanceLimit = 0;
            state.locationBalance = 0;
            state.locationBalanceLimit = 0;
            state.loading = false;
            state.error = action.payload;
        }
    }
});

export const {
    getSpecificBalanceData,
    getSpecificBalanceDataSuccess,
    getSpecificBalanceDataFailed,
    getAllBalanceData,
    getAllBalanceDataSuccess,
    getAllBalanceDataFailed
} = userBalanceSlice.actions;

export default userBalanceSlice.reducer;
