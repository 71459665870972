import React from 'react';
import {Box} from '@mui/material';
import InpaintingComponents from './InpaintingComponents';


const ImageInpainting = () => {
    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    height: '90vh',
                    paddingTop: '50px',
                    backgroundColor: '#f3f4f6',
                    overflow: 'auto'
                }}
            >

                <InpaintingComponents/>

            </Box>
        </>
    );
};

export default ImageInpainting;
