import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    presetTemplate: [],
    page: 1,
    perPage: 12,
    total: 12,
    isLoading: false,
    isError: false,
    error: '',
    specificPresetTemplate: null,
    isResponseSuccess: false,
    responseData: [],
    showGenerateButton: true,
    showReGenerateButton: false,
    isShowReCopyButton: false,
    promptInputText: "",
    filters: {
        title: "",
        category_id: "",
    },
};

const presetTemplateSlice = createSlice({
    name: 'presetTemplate',
    initialState: initialState,
    reducers: {
        getPresetTemplate: (state, action) => {
            state.isLoading = true;

            if (action.payload) {
                state.page = action.payload.page || 1;
                state.perPage = action.payload.limit || 12;
            }
        },
        getPresetTemplateSuccess: (state, action) => {
            state.presetTemplate = action.payload.data;
            state.total = action.payload.totalItems;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        getPresetTemplateFailed: (state, action) => {
            state.presetTemplate = [];
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },

        getSpecificPresetTemplate: (state, action) => {
            state.isLoading = true;
        },
        getSpecificPresetTemplateSuccess: (state, action) => {
            state.specificPresetTemplate = action.payload;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        getSpecificPresetTemplateFailed: (state, action) => {
            state.specificPresetTemplate = null;
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },

        getPresetTemplateGeneratedResponse: (state, action) => {
            state.isLoading = true;

            // if (action.payload) {
            //     state.promptInputText = action.payload.prompt || "";
            // }
        },
        getPresetTemplateGeneratedResponseSuccess: (state, action) => {
            state.responseData = action.payload;
            state.isLoading = false;
            state.isResponseSuccess = true;
            state.isShowReCopyButton = true;
            state.isError = false;
            state.error = '';
            state.showGenerateButton = false;
            state.showReGenerateButton = true;
        },
        getPresetTemplateGeneratedResponseFailed: (state, action) => {
            state.responseData = [];
            state.isLoading = false;
            state.isError = true;
            state.isResponseSuccess = false;
            state.isShowReCopyButton = false;
            state.error = action.payload;
        },

        clearPresetTemplateState: (state, action) => {
            state.presetTemplate = [];
            state.page = 1;
            state.perPage = 12;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
            // state.specificPresetTemplate = '';
            state.isResponseSuccess = false;
            state.responseData = [];
            state.showGenerateButton = true;
            state.showReGenerateButton = false;
            state.isShowReCopyButton = false;
            // state.promptInputText = "";
        },

        clearTemplateState: (state, action) => {
            state.presetTemplate = [];
        },

        setPresetTemplatePreviewText: (state, action) => {
            if (action.payload) {
                state.promptInputText = action.payload.prompt || "";
            }
        },

        filterBySearch: (state, action) => {
            state.filters.title = action.payload;
        },

        filterByCategory: (state, action) => {
            state.filters.category_id = action.payload;
        },
        resetFilter: (state) => {
            state.filters.title = "";
            state.filters.category_id = "";
            state.page = 1;
            state.perPage = 12;
        },
        resetPage: (state) => {
            state.page = 1;
            state.perPage = 12;
        },

        clearResponseDataState: (state, action) => {
            state.responseData = [];
        },
    }
});

export const {
    getPresetTemplate,
    getPresetTemplateSuccess,
    getPresetTemplateFailed,
    getSpecificPresetTemplate,
    getSpecificPresetTemplateSuccess,
    getSpecificPresetTemplateFailed,
    getPresetTemplateGeneratedResponse,
    getPresetTemplateGeneratedResponseSuccess,
    getPresetTemplateGeneratedResponseFailed,
    clearPresetTemplateState,
    setPresetTemplatePreviewText,
    filterBySearch,
    filterByCategory,
    resetFilter,
    resetPage,
    clearResponseDataState
} = presetTemplateSlice.actions;

export default presetTemplateSlice.reducer;
