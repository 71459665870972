import React, {useState} from 'react';
import Grid from "@mui/material/Grid";
import ThemedMemesSteps from "./ThemedMemeSteps";
import Box from '@mui/material/Box';
import EmptyPage from "../../assets/global/EmptyComponent/EmptyPage";
import {useDispatch, useSelector} from "react-redux";
import {selectAIGeneratedTextData} from "../../state/features/textToMeme/randomAIGeneratedText/AIGeneratedTextSelector";
import {useNavigate, useSearchParams} from "react-router-dom";
import {showErrorToaster} from "../../helpers/utility/toaster";
import MemeMakerHeader from "../../assets/global/MemeMaker/MemeMakerHeader";
import ResponseImage from "./ResponseImage";
import BalanceCheckService from "../../services/BalanceCheck.service";
import {
    creditSettingsImageContent256x256,
    PACKAGE_LIMIT__BALANCE__KEY__CREDIT_AMOUNT
} from "../../helpers/constant/coreConstant";
import {
    aiGeneratedText,
    aiGeneratedTextClearState
} from "../../state/features/textToMeme/randomAIGeneratedText/AIGeneratedTextSlice";
import {getSpecificBalanceData} from "../../state/features/userBalance/userBalanceSlice";

const ThemedMemes = () => {
        const [themedImage, setThemedImage] = useState([]);
        let [searchParams, setSearchParams] = useSearchParams();
        let imageUrl = searchParams.get('url');
        const dispatch = useDispatch();
        const {aiGeneratedTextData} = useSelector(selectAIGeneratedTextData);
        const [topicName, setTopicName] = useState('');
        const navigate = useNavigate();


        const setImageFile = (image) => {
            setThemedImage(image);
            if (image.length > 0) {
                setSearchParams('');
                if (aiGeneratedTextData?.length > 0) {
                    dispatch(aiGeneratedTextClearState())
                }
            }
        }

        const handleBack = () => {
            navigate(-1)
        }

        const searchTopic = (value) => {
            setTopicName(value)
        }


        let content = null;
        if (imageUrl && aiGeneratedTextData?.length === 0) {
            content = (
                <Box sx={{padding: '40px 20px', textAlign: 'center'}}>
                    <img height={400} width={500} src={imageUrl} style={{opacity: '0.5'}}/>
                </Box>
            );

        } else if (Object.keys(themedImage)?.length === 1 && Object.keys(aiGeneratedTextData)?.length == 0) {
            content = (
                <Box sx={{padding: '40px 20px', textAlign: 'center'}}>
                    <img height={400} width={500} src={themedImage[0]?.preview} style={{opacity: '0.5'}}/>
                </Box>
            );
        } else if (Array.isArray(aiGeneratedTextData) && aiGeneratedTextData?.length !== 0 && Object.keys(themedImage)?.length === 1) {
            content = (
                aiGeneratedTextData.map((item, index) => (
                    <Box p={2}>
                        <ResponseImage imageUrl={themedImage[0]?.preview} formFields={item} imgId={index}/>
                    </Box>
                ))
            );
        } else if (Array.isArray(aiGeneratedTextData) && aiGeneratedTextData?.length !== 0 && imageUrl) {
            content = (
                aiGeneratedTextData.map((item, index) => (
                    <Box p={2}>
                        <ResponseImage imageUrl={imageUrl} formFields={item} imgId={index}/>
                    </Box>
                ))
            );
        } else if (aiGeneratedTextData?.length === 0) {
            if (Object.keys(themedImage)?.length === 1 || imageUrl) {
                showErrorToaster("Text is not generated!")
            } else {
                content = (
                    <EmptyPage title="No theme choose yet..."/>
                );
            }
        } else {
            content = (
                <EmptyPage title="No theme choose yet..."/>
            );
        }

        const handleAIGeneratedText = () => {
            BalanceCheckService.getBalance({content_type: creditSettingsImageContent256x256, amount_data: 500})
                .then((response) => {
                    if (response.success) {
                        const GenerateAIText = async () => {
                            if (!topicName) {
                                showErrorToaster("Enter a topic");
                            } else if (topicName && Object.keys(themedImage).length === 1 || imageUrl) {
                                await dispatch(aiGeneratedText({
                                    topic: topicName,
                                }));
                                BalanceCheckService.reduceBalance({
                                    content_type: creditSettingsImageContent256x256,
                                    amount_data: 500
                                });
                                dispatch(getSpecificBalanceData({key: PACKAGE_LIMIT__BALANCE__KEY__CREDIT_AMOUNT}));
                            } else {
                                showErrorToaster("Something went wrong!");
                            }
                        }
                        GenerateAIText()
                    } else {
                        showErrorToaster('Insufficient balance')
                    }
                })
                .catch((err) => console.log(err));
        }

        return (
            <>
                <Box sx={{
                    margin: '20px 10px'
                }}>
                    <MemeMakerHeader
                        title={'themed-meme'}
                        handleBack={handleBack}
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={4}>
                            <Box
                                sx={{
                                    background: 'white',
                                    border: '2px solid #D3D3D3',
                                    borderRadius: '10px',
                                    margin: '10px'
                                }}
                            >
                                <ThemedMemesSteps
                                    setImageFile={setImageFile}
                                    searchTopic={searchTopic}
                                    handleAIGeneratedText={handleAIGeneratedText}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={8}>
                            <Box
                                sx={{
                                    border: '2px dashed #D3D3D3',
                                    borderRadius: '10px',
                                    margin: '10px',
                                    padding: '20px'
                                }}
                            >
                                {content}

                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </>
        );
    }
;

export default ThemedMemes;
