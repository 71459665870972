import React, {useEffect, useState} from 'react';
import {Box, Grid, StepLabel, Typography} from '@mui/material';
import Styles from '../../styles/OnBoard.module.css';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import PackageChoiceView from './PackageChoiceView';
import BillingInfoView from './BillingInfoView';
import OrderSummery from './OrderSummery';
import CongratulationsLogo from '../../../../assets/images/Icons/congratulations-logo.svg';
import {useDispatch, useSelector} from 'react-redux';
import {useTheme} from '@mui/material/styles';
import {useNavigate, useParams} from 'react-router-dom';
import PackageService from '../../../../services/Package.service';
import {getPackages} from '../../../../state/features/package/packageSlice';
import {showErrorToaster, showSuccessToaster} from '../../../../helpers/utility/toaster';
import InfoModal from '../../../../assets/global/Modal/InfoModal';
import SuccessModalContent from '../../../authUser/SuccessModalContent';
import {USER_HAS_NEW_CARD__YES} from '../../../../helpers/constant/coreConstant';
import {getUserHasPackageData} from '../../../../state/features/userPackage/userPackageSlice';
import {getAllBalanceData} from '../../../../state/features/userBalance/userBalanceSlice';
import HasPackageModal from "./HasPackageModal";
import {
    getAgencyStripePaymentIntegrationData
} from "../../../../state/features/paymentIntegration/paymentIntegrationSlice";

const PackageOnBoardMain = ({isRenew = false}) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {package_id} = useParams();
    const {packages} = useSelector(state => state.package);
    const {
        hasPackage,
        isSuccess: isSuccessHasPackage,
        isPackageExpire
    } = useSelector(state => state.userPackage);

    const [steps, setSteps] = useState(['Choose a Package & Billing Cycle', 'Billing Info']);
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [selectPackageData, setSelectPackageData] = useState({});
    const [billingCycleData, setBillingCycleData] = useState({});
    const [selectedPackageUid, setSelectedPackageUid] = useState('');
    const [billingCycleList, setBillingCycleList] = useState([]);
    const [billingCycleUid, setBillingCycleUid] = useState('');

    const isStepSkipped = step => {
        return skipped.has(step);
    };

    const handleNext = () => {
        if (!billingCycleUid) {
            showErrorToaster('You need to choose your billing cycle');
            return false;
        }
        setActiveStep(prevActiveStep => prevActiveStep + 1);

        if (activeStep === steps.length - 1) {
            setSuccessModalOpen(true);
        }
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const setSuccessModalClose = () => {
        setSuccessModalOpen(false);
    };

    useEffect(() => {
        dispatch(getPackages());
        dispatch(getUserHasPackageData());
    }, []);

    useEffect(() => {
        if (isRenew) {
            setSteps(['Choose a Package & Billing Cycle for Renew or upgrade ', 'Billing Info']);
        }
    }, [isRenew]);

    useEffect(() => {
        if (packages) {
            const filterPackageData = packages.filter(packageItem => packageItem.id === +package_id);

            if (filterPackageData && filterPackageData[0]) {
                setSelectPackageData(filterPackageData[0]);
            }
        }
    }, [packages]);

    // fetch Agency Stripe Primary Key
    useEffect(() => {
        dispatch(getAgencyStripePaymentIntegrationData());
    }, []);

    // handle fetch load billing cycle data by uid
    const getAllBillingCycleData = id => {
        PackageService.getPackageDiscountDataByUserPackageUid({package_id: id})
            .then(response => {
                setBillingCycleUid('');
                if (response.success) {
                    setBillingCycleList(response.data);
                    if (response.data && response.data[0]) {
                        const billingCycleData = response.data[0];
                        setBillingCycleData(billingCycleData);

                        if (billingCycleData.id) {
                            setBillingCycleUid(billingCycleData.id);
                        }
                    }
                } else {
                    setBillingCycleList([]);
                }
            })
            .catch(err => console.log(err));
    };

    // handle work first time and when params package_id change
    useEffect(() => {
        setSelectedPackageUid(package_id);
        getAllBillingCycleData(package_id);
    }, [package_id]);

    // onChange after choose package
    const onSelectPackage = packageData => {
        setSelectedPackageUid(packageData.id);
        setSelectPackageData(packageData);

        getAllBillingCycleData(packageData.id);
    };

    // onChange after billingCycle choose
    const onChangeBillingCycleUid = billing_Cycle_id => {
        setBillingCycleUid(billing_Cycle_id);
        const filterBillingCycle = billingCycleList.filter(
            billingItem => billingItem.id === billing_Cycle_id
        );

        if (filterBillingCycle && filterBillingCycle[0]) {
            setBillingCycleData(filterBillingCycle[0]);
        }
    };

    // handle package buy 0r renew payment
    const handleSubmitPayment = data => {
        const requestObj = {
            package_id: selectedPackageUid,
            user_billing_uid: billingCycleUid,
            user_payment_uid: data.user_payment_uid,
            is_new_card: data.is_new_card
        };

        if (data.is_new_card === USER_HAS_NEW_CARD__YES) {
            requestObj.token = data.token;
            requestObj.card_number = data.card_number;
            requestObj.card_holder_name = data.card_holder_name;
        } else {
            requestObj.user_payment_card_uid = data.user_payment_card_uid;
        }

        PackageService.buyPackage(requestObj)
            .then(response => {
                if (response.success) {
                    showSuccessToaster(response.message);
                    dispatch(getUserHasPackageData());
                    dispatch(getAllBalanceData());
                } else {
                    showErrorToaster(response.message);
                }
            })
            .catch(err => console.log(err));
    };

    useEffect(() => {
        if (isSuccessHasPackage && !isPackageExpire && hasPackage) {
            navigate('/chat');
        }
    }, [isSuccessHasPackage && hasPackage, isPackageExpire]);

    return (
        <Box className={Styles.container} sx={{my: 3}}>
            <Grid container spacing={3}>
                <Grid item lg={8} md={8} sm={12}>
                    <Box>
                        <Stepper
                            activeStep={activeStep}
                            sx={{background: theme.palette.background.paper, p: 3, borderRadius: '4px'}}
                        >
                            {steps.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                if (isStepSkipped(index)) {
                                    stepProps.completed = false;
                                }

                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>
                                            <Typography variant="subtitle2">{label}</Typography>
                                            <Typography variant="caption" color="text.primary">
                                                Take 2 minutes
                                            </Typography>
                                        </StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>

                        {activeStep === 0 && (
                            <PackageChoiceView
                                isRenew={isRenew}
                                activeStep={activeStep}
                                handleNext={handleNext}
                                handleBack={handleBack}
                                steps={steps}
                                selectedPackageUid={selectedPackageUid}
                                onSelectPackage={onSelectPackage}
                                billingCycleList={billingCycleList}
                                billingCycleUid={billingCycleUid}
                                onChangeBillingCycleUid={onChangeBillingCycleUid}
                            />
                        )}

                        {activeStep === 1 && (
                            <BillingInfoView
                                isPackageUpgrade={isRenew}
                                activeStep={activeStep}
                                handleBack={handleBack}
                                handleSubmitPayment={handleSubmitPayment}
                            />
                        )}
                    </Box>
                </Grid>

                <Grid item lg={4} md={4} sm={12}>
                    <OrderSummery
                        theme={theme}
                        packageData={selectPackageData}
                        billingCycleData={billingCycleData}
                        active={activeStep !== 2}
                    />
                </Grid>
            </Grid>

            <InfoModal open={successModalOpen} onClose={setSuccessModalClose}>
                <SuccessModalContent
                    logo={CongratulationsLogo}
                    title={'Congratulation!'}
                    subTitle={'Your account has been created successfully.'}
                    helperText={'Get More Leads and Sales Easily with Earn 5 Star.'}
                    buttonText={'Go to Dashboard'}
                    onClickButton={setSuccessModalClose}
                />
            </InfoModal>

            {isSuccessHasPackage && isRenew && <HasPackageModal/>}
        </Box>
    );
};

export default PackageOnBoardMain;
