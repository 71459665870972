import { all, call, put, takeEvery } from "redux-saga/effects";
import {
    getAgencyStripePaymentIntegrationDataFailed,
    getAgencyStripePaymentIntegrationDataSuccess
} from "./paymentIntegrationSlice";
import PaymentIntegrationService from "../../../services/PaymentIntegration.service";


function* paymentIntegrationWatcher() {
    yield takeEvery(
        "paymentIntegration/getAgencyStripePaymentIntegrationData",
        getAgencyStripePaymentIntegrationDataSaga
    );
}

function* getAgencyStripePaymentIntegrationDataSaga(action) {
    try {
        const response = yield call(PaymentIntegrationService.getAgencyStripePaymentIntegrationData, action.payload);

        if (response.status) {
            yield put(
                getAgencyStripePaymentIntegrationDataSuccess({
                    primaryKey: response.data.primary_key,
                    chargeAmount: response.data.charge_amount,
                })
            );
        } else {
            yield put(getAgencyStripePaymentIntegrationDataFailed(response.message));
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

export default function* paymentIntegrationSaga() {
    yield all([paymentIntegrationWatcher()]);
}
