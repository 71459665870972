import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {Box, Button, IconButton, Stack} from '@mui/material';
import styled from 'styled-components';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {useDropzone} from 'react-dropzone';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

const AvatarInput = styled.div`{
  useMemo
} margin-bottom: 32px;
  position: relative;
  align-self: center;

  img {
    width: 286px;
    height: 186px;
    object-fit: cover;
    border-radius: 20px;
  }

  .circle {
    width: 186px;
    height: 186px;
    border-radius: 20px;
  }

  label {
    right: 23em !important;
    position: absolute;
    width: 48px;
    height: 48px;
    background: #312e38;
    border-radius: 20px;
    right: 0;
    bottom: 0;
    border: 0;
    cursor: pointer;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      display: none;
    }

    svg {
      width: 20px;
      height: 20px;
      color: #f4ede8;
    }

    &:hover {
      background: blue;
    }
  }
`;

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '50px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#D3D3D3',
    borderStyle: 'dashed',
    color: 'black',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const afterImageStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#ffffff',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const StyledDropzone = ({setImageFile}) => {
    const [files, setFiles] = useState([]);
    const [fileExists, setFileExists] = useState(false);

    useEffect(() => {
        setImageFile(files)
    }, [files])

    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps,
        open,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        onDrop: acceptedFiles => {
            setFiles(
                acceptedFiles.map(file =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })
                )
            );
            setFileExists(true);
        }
    });

    const fileRejectionItems = fileRejections.map(({file, errors}) => (
        <Box sx={{
            borderColor: 'red',
            borderStyle: 'dashed',
            backgroundColor: '#ffffff',
            padding: '10px',
        }}>
            <Box sx={{textAlign: 'center'}}>
                <h3>Rejected Item</h3>
                <hr style={{border: '2px solid  #4169e1'}}/>
                {errors.map(e => (
                    <li key={e.code}>{e.message}</li>
                ))}
                <li key={file.path}>
                    {file.path} - {file.size} bytes
                </li>
                <Button sx={{marginTop: '5px'}} size="small" variant="contained" onClick={() => setFileExists(false)}>Reset
                    Image</Button>
            </Box>
        </Box>
    ));

    const resetImage = () => {
        setFileExists(false);
        setFiles([]);
    };

    const thumbs = files.map(file => (
        <Fragment>
            <Stack direction="row" justifyContent={'center'} sx={{padding: '10px'}}>
                <img src={file.preview} alt={file.name} style={{height: '200px'}}/>

                <Stack direction="row" alignItems="center">
                    <IconButton aria-label="delete" onClick={resetImage}>
                        <HighlightOffIcon sx={{fontSize: '40px', color: '#3944BC'}}/>
                    </IconButton>
                </Stack>
            </Stack>
        </Fragment>
    ));

    const style = useMemo(
        () => ({
            ...(fileExists ? afterImageStyle : baseStyle),
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {})
        }),
        [isFocused, isDragAccept, isDragReject, fileExists]
    );

    return (
        <Fragment>
            {fileExists ? (
                <Box className='container'>
                    <Box
                        sx={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            borderWidth: 2,
                            borderRadius: 2,
                            borderColor: '#eeeeee',
                            borderStyle: 'dashed',
                            backgroundColor: '#ffffff',
                            color: '#bdbdbd',
                            outline: 'none',
                            transition: 'border .24s ease-in-out'
                        }}
                    >
                        {thumbs}
                    </Box>
                    <Fragment>
                        <aside>
                            {
                                fileRejectionItems && <p>{fileRejectionItems}</p>
                            }
                        </aside>
                    </Fragment>
                </Box>
            ) : (
                <Box className='container'>
                    <Box {...getRootProps({style})}>
                        <input {...getInputProps()} />
                        <p><CloudUploadOutlinedIcon/></p><p> Add an Image</p>
                    </Box>
                </Box>
            )}
        </Fragment>
    );
};


export default StyledDropzone;
