import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    memeMakerData: null,
    isLoading: false,
    isError: false,
    error: ''
};

const memeMakerSlice = createSlice({
    name: 'memeMaker',
    initialState: initialState,
    reducers: {
        memeMaker: (state, action) => {
            state.isLoading = true;
        },
        memeMakerSuccess: (state, action) => {
            state.memeMakerData = action?.payload[0]?.text;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        memeMakerFailed: (state, action) => {
            state.imageToAltTextData = null;
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },
        memeMakerStateClear: (state, action) => {
            state.memeMakerData = null;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        }
    }
});

export const {
    memeMaker,
    memeMakerSuccess,
    memeMakerFailed,
    memeMakerStateClear,
} = memeMakerSlice.actions;

export default memeMakerSlice.reducer;