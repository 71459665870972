import React, {useState} from 'react';
import {Typography} from '@mui/material';
import Draggable from 'react-draggable';

const TextDraggable = ({text, size, transform, color, top, bottom, id}) => {

    const [position, setPosition] = useState();

    const handleDrag = (e, ui) => {
        const {x, y} = ui;
        setPosition({x, y});
    };
    return (
        <Draggable bounds="parent" onDrag={handleDrag}>
            <Typography
                variant="h5"
                id={id}
                sx={{
                    color: color,
                    cursor: 'pointer',
                    top: top,
                    bottom: bottom,
                    fontFamily: 'Roboto',
                    fontSize: size ? `${size}px` : '33px',
                    textShadow: 'rgb(0, 0, 0) -1px -1px 0px, rgb(0, 0, 0) 1px -1px 0px, rgb(0, 0, 0) -1px 1px 0px, rgb(0, 0, 0) 1px 1px 0px',
                    strokeWidth: '1px',
                    margin: '0',
                    position: 'absolute',
                    transform: transform,
                    overflow: 'auto',
                    // fontWeight: 'bold',
                    padding: '10px',
                }}
            >
                {text}
            </Typography>

        </Draggable>
    );
};

export default TextDraggable;
