import { all, call, put, takeEvery } from "redux-saga/effects";
import {
    addPaymentCardSuccess,
    addPaymentCardFailed,
    getPaymentCardSuccess,
    getPaymentCardFailed,
    getUserBillingHistorySuccess,
    getUserBillingHistoryFailed,
    getTopUpRechargeInvoiceReportSuccess,
    getTopUpRechargeInvoiceReportFailed,
} from "./userBillingSlice";
import UserBillingService from "../../../services/UserBilling.service";
import { debounce } from "@redux-saga/core/effects";
import UserInvoiceService from "../../../services/UserInvoice.service";
import {RESPONSE_LOADING_TIME} from "../../../helpers/constant/coreConstant";
import {showErrorToaster, showSuccessToaster} from "../../../helpers/utility/toaster";

function* userBillingWatcher() {
    yield takeEvery("userBilling/addPaymentCard", addPaymentCardSaga);
    yield takeEvery("userBilling/getPaymentCard", getPaymentCardSaga);
    yield takeEvery("userBilling/getUserBillingHistory", getUserBillingHistorySaga);
    yield debounce(RESPONSE_LOADING_TIME, "userBilling/getTopUpRechargeInvoiceReport", getTopUpRechargeInvoiceReport);
}

function* addPaymentCardSaga(action) {
    try {
        const response = yield call(UserBillingService.addPaymentCard, action.payload);

        if (response.success) {
            showSuccessToaster(response.message)
            yield put(addPaymentCardSuccess(response.data));
        } else {
            showErrorToaster(response.message)
            yield put(addPaymentCardFailed(response));
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* getPaymentCardSaga(action) {
    try {
        const response = yield call(UserBillingService.getPaymentCardList, action.payload);

        if (response.success) {
            showSuccessToaster(response.message)
            yield put(getPaymentCardSuccess(response.data));
        } else {
            showErrorToaster(response.message)
            yield put(getPaymentCardFailed(response));
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* getUserBillingHistorySaga(action) {
    try {
        const response = yield call(UserInvoiceService.getInvoiceReport, action.payload);

        if (response.success) {
            yield put(getUserBillingHistorySuccess(response.data));
        } else {
            yield put(getUserBillingHistoryFailed(response.message));
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* getTopUpRechargeInvoiceReport(action) {
    try {
        const response = yield call(UserInvoiceService.getTopUpRechargeInvoiceReport, action.payload);

        if (response.success) {
            yield put(getTopUpRechargeInvoiceReportSuccess(response.data));
        } else {
            yield put(getTopUpRechargeInvoiceReportFailed(response.message));
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

export default function* userBillingSaga() {
    yield all([userBillingWatcher()]);
}
