import React, {useMemo, useState} from 'react';
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {Box, CircularProgress, Collapse, Grid, Paper, Stack, Typography} from '@mui/material';
import UseResponsiveFontSize from '../../helper/utility/UseResponsiveFontSize';
import MasterCardLogo from '../../../../assets/images/Icons/mastercard.svg';
import VisaLogo from '../../../../assets/images/Icons/visasqure.svg';
import {useTheme} from '@mui/material/styles';
import {Add, Remove} from '@mui/icons-material';
import PackageUpgradeWithExistedCard from './PackageUpgradeWithExistedCard';
import {
    PAYMENT_METHOD__STRIPE,
    USER_HAS_NEW_CARD__NO,
    USER_HAS_NEW_CARD__YES
} from '../../../../helpers/constant/coreConstant';
import {CoreCardHeader} from '../../../../assets/global/CardHeader/CoreCardHeader';
import CoreButton from '../../../../assets/global/Button/CoreButton';
import CancelButton from '../../../../assets/global/Button/CancelButton';
import CoreTextField from "../../../../assets/global/TextField/CoreTextField";

const useOptions = () => {
    const fontSize = UseResponsiveFontSize();

    const options = useMemo(
        () => ({
            hidePostalCode: true,
            style: {
                base: {
                    fontSize,
                    color: '#424770',
                    letterSpacing: '0.025em',
                    fontFamily: 'Source Code Pro, monospace',
                    '::placeholder': {
                        color: '#aab7c4'
                    },
                    border: '1px solid gray'
                },
                invalid: {
                    color: '#9e2146'
                }
            }
        }),
        [fontSize]
    );

    return options;
};

const PackageBuyStripeForm = props => {
    const {
        activeStep,
        handleBack,
        handleSubmitPayment,
        hideBackButton = false,
        isPackageUpgrade = true
    } = props;

    const theme = useTheme();
    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();

    const [isLoading, setLoading] = useState(false);
    const [isCardAddComplete, setCardAddComplete] = useState(false);
    const [holderName, setHolderName] = useState('');
    const handleChangeHolderName = e => {
        setHolderName(e.target.value);
    };

    const [showExistCard, setShowExistCard] = useState(false);
    const [paymentCardUid, setPaymentCardUid] = useState('');

    const handleShowExistCardForm = () => {
        setPaymentCardUid('');
        setCardAddComplete(false);
        setShowExistCard(!showExistCard);
    };

    const onChangePaymentCard = paymentCardUid => {
        setPaymentCardUid(paymentCardUid);
        setCardAddComplete(true);
    };

    const handleSubmit = async event => {
        event.preventDefault();
        setLoading(true);

        if (paymentCardUid) {
            const data = {
                is_new_card: USER_HAS_NEW_CARD__NO,
                user_payment_uid: PAYMENT_METHOD__STRIPE,
                user_payment_card_uid: paymentCardUid
            };
            handleSubmitPayment(data);
        } else {
            if (!stripe || !elements) {
                // Stripe.js has not loaded yet. Make sure to disable
                // form submission until Stripe.js has loaded.
                setLoading(false);
                return;
            }

            const payload = await stripe.createToken(elements.getElement(CardElement));

            if (payload.token) {
                const data = {
                    is_new_card: USER_HAS_NEW_CARD__YES,
                    token: payload.token.id,
                    card_number: payload.token.card ? payload.token.card.last4 : '',
                    card_holder_name: holderName,
                    user_payment_uid: PAYMENT_METHOD__STRIPE
                };

                handleSubmitPayment(data);
            }
            // console.log("[PaymentMethod]", payload);
        }

        setTimeout(() => {
            setLoading(false);
        }, 5000);
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <Paper elevation={0} sx={{my: 3}}>
                    <CoreCardHeader title="Billing Info" isButtonShow={false}/>
                    <Paper
                        elevation={0}
                        sx={{
                            p: 3,
                            m: 3,
                            border: '1px solid',
                            borderColor: theme.palette.divider
                        }}
                    >
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={1}
                            mb={2}
                        >
                            <Typography variant="body1" color="text.primary">
                                Credit Card
                            </Typography>
                            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                                <Box>
                                    <img src={MasterCardLogo} alt="master logo"/>
                                </Box>
                                <Box>
                                    <img src={VisaLogo} alt="visa logo"/>
                                </Box>
                            </Stack>
                        </Stack>

                        <Collapse in={!showExistCard} timeout="auto" unmountOnExit>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <CoreTextField
                                        fullWidth
                                        size={'small'}
                                        label={'Card Holder Name'}
                                        name={'holder_name'}
                                        defaultValue={holderName}
                                        onBlur={handleChangeHolderName}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    sx={{
                                        '& .StripeElement': {
                                            backgroundColor: theme.palette.background.paper,
                                            border: '1px solid ' + theme.palette.action.hover
                                        }
                                    }}
                                >
                                    <label className={'stripe_input_wrapper'}>
                                        {/*Card details*/}
                                        <CardElement
                                            options={options}
                                            onReady={() => {
                                                console.log('CardElement [ready]');
                                            }}
                                            onChange={event => {
                                                console.log('CardElement [change]', event);
                                                if (event.complete) {
                                                    setCardAddComplete(true);
                                                } else {
                                                    setCardAddComplete(false);
                                                }
                                            }}
                                            onBlur={() => {
                                                console.log('CardElement [blur]');
                                            }}
                                            onFocus={() => {
                                                console.log('CardElement [focus]');
                                            }}
                                        />
                                    </label>
                                </Grid>
                            </Grid>
                        </Collapse>

                        {isPackageUpgrade && (
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <CoreButton
                                        onClick={handleShowExistCardForm}
                                        color={'primary'}
                                        variant={'text'}
                                        size={'medium'}
                                    >
                                        {!showExistCard ? <Add/> : <Remove/>} {'Payment with existed card'}
                                    </CoreButton>
                                    <Collapse in={showExistCard} timeout="auto" unmountOnExit>
                                        <PackageUpgradeWithExistedCard onChangePaymentCard={onChangePaymentCard}/>
                                    </Collapse>
                                </Grid>
                            </Grid>
                        )}
                    </Paper>

                    <Stack justifyContent={'flex-end'} direction={'row'} spacing={3} sx={{pb: 3, px: 3}}>
                        {!hideBackButton && (
                            <CancelButton color="inherit" disabled={activeStep === 0} onClick={handleBack}>
                                Previous
                            </CancelButton>
                        )}

                        <CoreButton
                            variant={'contained'}
                            color={'secondary'}
                            size={'large'}
                            type="submit"
                            disabled={!stripe || isLoading || !isCardAddComplete}
                            startIcon={isLoading && <CircularProgress size={16} color={'inherit'}/>}
                        >
                            Buy & Checkout
                        </CoreButton>
                    </Stack>
                </Paper>
            </form>
        </div>
    );
};

export default PackageBuyStripeForm;
