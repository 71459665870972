import {Box, Typography} from '@mui/material';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import Loader from "../../assets/global/Loader/Loader";
import React from 'react';
import img from '../../assets/images/loader.gif';

const PromptLoadingPage = () => {
    return (
        <>
            <Box>
                <Box sx={{p: 3}}>
                    <img style={{height: '200px', padding: '30px'}} src={img} alt="prompt" loading="lazy"/>
                </Box>

                <Box sx={{display:"flex", justifyContent:'center', alignItems:'center', gap:'10px', marginBottom:'10px'}}>
                    <Loader style={{height: '200px', padding: '30px'}} />
                    <Typography
                        sx={{fontWeight: '500', color: '#222'}}
                        variant={'body1'}
                        color={'text.secondary'}
                    >
                        Please be patient as we load the desired content. We value your patience as this could take anywhere between 5 to 20 seconds.
                    </Typography>
                </Box>

                <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                    <LinearProgress color="primary" />
                    <LinearProgress color="success" />
                    <LinearProgress color="warning" />
                </Stack>
            </Box>
        </>
    );
};

export default PromptLoadingPage;
