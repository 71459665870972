import React from 'react';
import {Box, Stack, styled, Typography} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import Loader from "../../assets/global/Loader/Loader";

const LoadingBox = styled(Box)(({theme}) => ({
    backgroundColor: '#fff',
    color:'#222'
}));

const PresetTemplateLoader = () => {
    return (
        <LoadingBox p={3}>
            <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                <LinearProgress color="secondary" />
                <LinearProgress color="success" />
                <LinearProgress color="inherit" />
            </Stack>

            <Stack
                direction={'row'}
                spacing={2}
                sx={{
                    width: {
                        xs: '200px',
                        sm: '400px',
                        md: '600px',
                        lg: '800px',
                        xxl: '900px',
                        margin: '0 auto',
                        paddingTop:'20px'
                    }
                }}
            >
                <Box sx={{display:"flex", justifyContent:'center', alignItems:'center', gap:'10px', marginBottom:'10px'}}>
                    {/*<Loader style={{height: '200px', padding: '30px'}} />*/}
                    <Typography
                        sx={{fontWeight: '500', color: '#222'}}
                        variant={'body1'}
                        color={'text.secondary'}
                    >
                        Please be patient as we load the desired content. We value your patience as this could take anywhere between 5 to 20 seconds.
                    </Typography>
                </Box>
            </Stack>
        </LoadingBox>
    );
};

export default PresetTemplateLoader;
