import { requests } from "./http.service";

class AccountSettingService {
    getAccountSettingData() {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/profile/get/profile/information");
    }

    updateAccountSettingInfo(body) {
        return requests.post(
            process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/profile/information/update",
            body
        );
    }

    updateUserPassword(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/profile/user/reset/password/update", body);
    }

    updateAgencyProfileImage(body) {
        return requests.post(
            process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "api/v1/update/agency/profile/image",
            body
        );
    }
}

export default new AccountSettingService();
