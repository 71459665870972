import React, {useEffect} from 'react';
import {Box, Button, Grid, Stack} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {useTheme} from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import {selectCollectionState} from '../../state/features/collection/collectionSelector';
import {clearCollectionState} from '../../state/features/collection/collectionSlice';
import {collectionPattern, registerPattern} from "../../helpers/constant/validationPattern";
import LoadingButton from "../../assets/global/Button/LoadingButton";

const CategoryForm = ({isEditMood=false, handleSubmitCategory, collectionData, handleDrawerClose}) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const {isCreateSuccess, isUpdateSuccess, isLoading} = useSelector(selectCollectionState);

    const {handleSubmit, watch, setValue, setError, control} = useForm();

    useEffect(() => {
        if (isCreateSuccess || isUpdateSuccess) {
            handleDrawerClose();
        }
    }, [isCreateSuccess, isUpdateSuccess]);

    useEffect(() => {
        if (collectionData) {
            setValue('name', collectionData.name || '');
        }
    }, [collectionData]);

    // ## Clear collection state when page leave
    useEffect(() => {
        return () => dispatch(clearCollectionState());
    }, []);

    // ## Form Submit Action
    const onSubmit = data => {
        if (isEditMood) data.collection_id = collectionData.id ;
        handleSubmitCategory(data);
    };

    return (
        <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
            sx={{p: 3, backgroundColor: theme.palette.background.paper, height: '100%'}}
        >
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Controller
                        control={control}
                        name="name"
                        defaultValue=""
                        render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                            <TextField
                                fullWidth
                                type={'text'}
                                size={'small'}
                                required
                                onChange={onChange}
                                value={value}
                                label={'Collection'}
                                placeholder={'Collection Name'}
                                error={!!(invalid && error.message)}
                                helperText={invalid && error.message}
                            />
                        )}
                        rules={collectionPattern.name}
                    />
                </Grid>
            </Grid>

            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} mt={2}>
                <Button
                    onClick={handleDrawerClose}
                    variant="contained"
                    size={'large'}
                    color={'error'}
                >
                    Cancel
                </Button>

                <LoadingButton
                    isLoading={isLoading}
                    loadingText={isEditMood ? "Update Collection" : "Create Collection"}
                    type={'submit'}
                    color={'primary'}
                    variant="contained"
                    sx={{mt: 3, mb: 2, backgroundColor:"rgb(0, 158, 247)"}}
                >
                    {isEditMood ? "Update Collection" : "Create Collection"}
                </LoadingButton>
            </Stack>
        </Box>
    );
};

export default CategoryForm;
