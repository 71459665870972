import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {RandomText} from "./RandomText";

const RandomTextGenerator = ({generatedRandomText}) => {
    const [randomText, setRandomText] = useState('');

    useEffect(() => {
        generatedRandomText(randomText)
    }, [randomText])

    const handleRandomText = () => {
        const randomIndex = Math.floor(Math.random() * RandomText.length);
        const randomtxt = RandomText[randomIndex];
        setRandomText(randomtxt);
    }
    return (
        <>
            <Button
                sx={{
                    fontSize: '12px',
                    color: 'gray'
                }}
                variant="text"
                size="small"
                onClick={handleRandomText}
            >
                <u>Use random text</u>
            </Button>
        </>
    );
}

export default RandomTextGenerator;