import {all, put} from 'redux-saga/effects';
import {call, takeEvery} from '@redux-saga/core/effects';
import {getBalanceFailed, getBalanceSuccess} from "./balanceCheckSlice";
import BalanceCheckService from "../../../services/BalanceCheck.service";

function* balanceCheckWatcher() {
    yield takeEvery('balanceCheck/getBalance', getBalanceSaga);
}

function* getBalanceSaga(action) {
    try {
        const response = yield call(BalanceCheckService.getBalance, action.payload);

        if (response.success) {
            yield put(getBalanceSuccess(response.data));
        } else {
            yield put(getBalanceFailed(response.message));
        }
    } catch (err) {
        console.log('Error: ', err);
        yield put(getBalanceFailed(err.message));
    }
}

export default function* balanceCheckSaga() {
    yield all([balanceCheckWatcher()]);
}
