import {Box, Paper, Typography} from '@mui/material';

import React from 'react';
import noDataImage from '../../images/no-data.svg';

const EmptyPage = ({img = '', title = '', subTitle = '', children = null}) => {
    return (
        <Paper elevation={0} sx={{width: '100%', margin: '0 auto', textAlign: 'center'}}>
            <Box
                sx={{
                    maxWidth: '496px',
                    width: '100%',
                    margin: '0 auto'
                }}
            >
                {noDataImage && <img src={noDataImage} width="100%" height="480px"/>}

                <Box>
                    <Typography
                        variant="h5"
                        color="text.primary"
                        sx={{
                            wordBreak: 'break-word',
                            fontFamily: 'Poppins',
                            fontSize: '20px',
                            fontWeight: '600',
                            color: 'rgba(51, 51, 51, 0.78)',
                            lineHeight: 1
                        }}
                    >
                        {title}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                            padding: '8px 0 24px 0',
                            fontFamily: 'Poppins',
                            fontSize: '12px',
                            fontWeight: '300',
                            color: 'rgb(51, 51, 51)',
                            lineHeight: '1.4'
                        }}
                    >
                        Please try refining your search terms or using different keywords to find the
                        information you're looking for.
                    </Typography>
                </Box>
            </Box>
            {children}
        </Paper>
    );
};

export default EmptyPage;
