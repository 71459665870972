import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import PromptLeft from './PromptLeft';
import PromptRight from './PromptRight';
import {getSpecificPromptTemplate} from '../../state/features/prompts/promptSlice';
import {selectPromptState} from '../../state/features/prompts/promptSelector';

const PromptDetails = ({templateDate, handleCloseDrawer}) => {
    const dispatch = useDispatch();
    const [specificTemplateData, setSpecificTemplateData] = useState(null);

    const {specificTemplate} = useSelector(selectPromptState);

    useEffect(() => {
        dispatch(getSpecificPromptTemplate({id: templateDate.id}));
    }, []);

    useEffect(() => {
      setSpecificTemplateData(specificTemplate);
    }, [specificTemplate]);

    return (
        <>
            <Box sx={{display: 'flex', minHeight: '70vh'}}>
                <Box sx={{flexBasis: '466px'}}>
                    <PromptLeft specificTemplateData={specificTemplateData}/>
                </Box>

                <Box sx={{flex: 1, padding: '10px 50px 10px 10px'}}>
                    <PromptRight templateDate={templateDate}/>
                </Box>
            </Box>
        </>
    );
};

export default React.memo(PromptDetails);
