import {Box, Typography} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import React from 'react';

const FeatureOutputNoResponse = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        margin: '10px 0px',
        p: 2,
        borderRadius: '5px',
        textAlign: 'center'
      }}
    >
      <ErrorOutlineIcon fontSize="large" sx={{color: '#6B7280'}} />

      <Typography fontSize={14} fontWeight="500" color="#6B7280">
          To achieve the best result, please select the appropriate input and output lists, and ensure that you provide valid URLs.
      </Typography>
    </Box>
  );
};

export default FeatureOutputNoResponse;
