import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import InfoModal from "../../../../assets/global/Modal/InfoModal";
import WarningModalContent from "../../../../assets/global/Modal/WarningModalContent";

const HasPackageModal = () => {
    const [open, setOpen] = useState(false);
    const toggleModalOpen = () => setOpen(!open);

    const { isPackageExpire } = useSelector((state) => state.userPackage);

    useEffect(() => {
        setOpen(isPackageExpire);
    }, [isPackageExpire]);

    const handleConfirm = () => {
        toggleModalOpen();
    };

    return (
        <React.Fragment>
            <InfoModal open={open} width='480px' onClose={toggleModalOpen}>
                <WarningModalContent
                    title={"Package Expired!"}
                    subTitle={"You need to, "}
                    subTitleSpan={"Renew or upgrade package"}
                    content={"You can't use your services until upgrade package"}
                    buttonName={"Okay, Got It"}
                    onConfirm={handleConfirm}
                    onClose={toggleModalOpen}
                />
            </InfoModal>
        </React.Fragment>
    );
};

export default HasPackageModal;
