import React from 'react';
import {Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Stack} from "@mui/material";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import Playpen from '../../assets/videos/playpen.mp4';


const PlayHelpertext = () => {


    return (
        <>
            <Stack spacing={2} sx={{
                backgroundColor: '#F5F5F5',
                padding: '20px',
                alignItems: "center",
                justifyContent: "center"
            }}
            >
                <List
                    sx={{
                        width: '90%',
                        bgcolor: '#e3f3e7',
                        marginBottom: '20px',
                    }}
                >

                    <ListItem>
                        <ListItemAvatar>
                            <Avatar>
                                <RecordVoiceOverIcon/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Playpen" secondary="Playpen Feature Description"/>
                    </ListItem>
                    <Divider variant="inset" component="li"/>
                    <Box sx={{
                        alignItems: "center",
                        padding: '20px',
                    }}>
                        <Typography component="div">
                            <Box sx={{fontFamily: 'Monospace', fontSize: 'h6.fontSize', m: 1, fontWeight: 'bold'}}>
                                Tone of Voice
                            </Box>
                            <Divider/>
                            <Box sx={{fontFamily: 'default', m: 1}}> Tone of Voice is actually the
                                way a person is speaking to someone. In here it will change the tone of writings.
                            </Box>
                            <Box sx={{fontFamily: 'default', m: 1}}>
                                You can add many more tone like Friendly, Optimistic, Engry, Sad, Happy... which you
                                want to add
                                in your writings.
                            </Box>
                        </Typography>

                        <Typography component="div">
                            <Box sx={{fontFamily: 'Monospace', fontSize: 'h6.fontSize', m: 1, fontWeight: 'bold'}}>
                                Re-write
                            </Box>
                            <Divider/>
                            <Box sx={{fontFamily: 'default', m: 1}}>Our AI-powered rewrite tool will enhance your
                                writing skill. Rewrite tool is powered by artificial intelligence and is easy to
                                operate. AI writing technology carefully scans your entered text and provides you with
                                rewritten fresh and unique content. If you’re looking for a free paraphrasing tool
                                online to help you with a paragraph you’re struggling with, the AI writer will provide
                                you with a plagiarism-free option to write a new content.
                            </Box>
                        </Typography>

                        <Typography component="div">
                            <Box sx={{fontFamily: 'Monospace', fontSize: 'h6.fontSize', m: 1, fontWeight: 'bold'}}>
                                Write More
                            </Box>
                            <Divider/>
                            <Box sx={{fontFamily: 'default', m: 1}}>
                                Write more tool will added more text with the existing one. You can also add tone here.
                            </Box>
                        </Typography>

                        <Typography component="div">
                            <Box sx={{fontFamily: 'Monospace', fontSize: 'h6.fontSize', m: 1, fontWeight: 'bold'}}>
                                Copy
                            </Box>
                            <Divider/>
                            <Box sx={{fontFamily: 'default', m: 1}}>
                                Copy tool will help you to copy the whole content of the text editor.
                            </Box>
                        </Typography>

                        <Typography component="div">
                            <Box sx={{fontFamily: 'Monospace', fontSize: 'h6.fontSize', m: 1, fontWeight: 'bold'}}>
                                Content Store
                            </Box>
                            <Divider/>
                            <Box sx={{fontFamily: 'default', m: 1}}>
                                Content store tool will help you to store AI generated content in your collection.
                            </Box>
                        </Typography>

                        <Typography component="div">
                            <Box sx={{fontFamily: 'Monospace', fontSize: 'h6.fontSize', m: 1, fontWeight: 'bold'}}>
                                Video Description
                            </Box>
                            <Divider/>
                            <Box sx={{fontFamily: 'Monospace', m: 1}}>
                                Here is a video description for Playpen.
                            </Box>
                        </Typography>

                        <Box sx={{
                            margin: '30px',
                        }}>
                            <div>
                                <video width="100%" height="100%" controls>
                                    <source src={Playpen} type="video/mp4"/>
                                    {/*<source src="https://assets.codepen.io/6093409/river.mp4" type="video/ogg"/>*/}
                                </video>
                            </div>
                        </Box>
                    </Box>
                </List>
            </Stack>
        </>
    );
};

export default PlayHelpertext;
