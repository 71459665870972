import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PackageBuyStripeForm from "./PackageBuyStripeForm";
import { useSelector } from "react-redux";

const PackageBuyStripe = ({ ...rest }) => {
    const { primaryKey, loading } = useSelector((state) => state.paymentIntegration);

    const [stripePromiseState, setStripePromiseState] = useState(null);

    useEffect(() => {
        if (primaryKey) {
            const stripePromise = loadStripe(primaryKey);
            setStripePromiseState(stripePromise);
        }
    }, [primaryKey]);

    return (
        <>
            {loading && !stripePromiseState ? (
                ""
            ) : (
                <Elements stripe={stripePromiseState}>
                    <PackageBuyStripeForm {...rest} />
                </Elements>
            )}
        </>
    );
};

export default PackageBuyStripe;
