import {all, put} from 'redux-saga/effects';
import {call, takeEvery} from '@redux-saga/core/effects';
import {
    getGeneratedResponseFailed,
    getGeneratedResponseSuccess,
    getPromptTemplateFailed,
    getPromptTemplateSuccess,
    getSpecificPromptTemplateFailed,
    getSpecificPromptTemplateSuccess
} from './promptSlice';
import PromptService from '../../../services/Prompt.service';
import {showErrorToaster} from "../../../helpers/utility/toaster";

function* promptWatcher() {
    yield takeEvery('prompt/getPromptTemplate', getPromptTemplateSaga);
    yield takeEvery('prompt/getSpecificPromptTemplate', getSpecificPromptTemplateSaga);
    yield takeEvery('prompt/getGeneratedResponse', getGeneratedResponseSaga);
}

function* getPromptTemplateSaga(action) {
    try {
        const response = yield call(PromptService.getPromptTemplateData, action.payload);

        if (response.success) {
            yield put(getPromptTemplateSuccess(response.data));
        } else {
            yield put(getPromptTemplateFailed(response.message));
        }
    } catch (err) {
        console.log('Error: ', err);
        yield put(getPromptTemplateFailed(err.message));
    }
}

function* getSpecificPromptTemplateSaga(action) {
    try {
        const response = yield call(PromptService.getSpecificPromptTemplateData, action.payload);

        if (response.success) {
            yield put(getSpecificPromptTemplateSuccess(response.data));
        } else {
            yield put(getSpecificPromptTemplateFailed(response.message));
        }
    } catch (err) {
        console.log('Error: ', err);
        yield put(getSpecificPromptTemplateFailed(err.message));
    }
}

function* getGeneratedResponseSaga(action) {
    try {
        const response = yield call(PromptService.getGeneratedResponseData, action.payload);

        let parseResponse = JSON.parse(response?.data);

        if (response.success) {
            yield put(getGeneratedResponseSuccess(parseResponse?.choices[0].message));
        } else {
            yield put(getGeneratedResponseFailed(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log('Error: ', err);
        yield put(getGeneratedResponseFailed(err.message));
    }
}

export default function* promptSaga() {
    yield all([promptWatcher()]);
}
