import React from 'react';
import {Avatar, Box, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Stack} from "@mui/material";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import SquareIcon from '@mui/icons-material/Square';
import ScreenshotMonitorIcon from '@mui/icons-material/ScreenshotMonitor';
import SwapVerticalCircleIcon from '@mui/icons-material/SwapVerticalCircle';
import HdIcon from '@mui/icons-material/Hd';
import TwoKIcon from '@mui/icons-material/TwoK';
import FourKIcon from '@mui/icons-material/FourK';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import CreationImage from '../../assets/videos/OLK9AI Image Creation.mp4';

const ImageCreationHelpertext = () => {


    return (
        <>
            <Stack spacing={2} sx={{
                backgroundColor: '#F5F5F5',
                padding: '20px',
                alignItems: "center",
                justifyContent: "center"
            }}
            >
                <List
                    sx={{
                        width: '90%',
                        bgcolor: '#e3f3e7',
                        marginBottom: '20px',
                    }}
                >

                    <ListItem>
                        <ListItemAvatar>
                            <Avatar>
                                <RecordVoiceOverIcon/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Image Creation"
                                      secondary="Image Creation Feature Description"/>
                    </ListItem>
                    <Divider variant="inset" component="li"/>
                    <Box sx={{
                        alignItems: "center",
                        padding: '20px',
                    }}>
                        <Typography component="div">
                            <Box sx={{fontFamily: 'Monospace', fontSize: 'h6.fontSize', m: 1, fontWeight: 'bold'}}>
                                Aspect Ratio
                            </Box>
                            <Divider/>
                            <Box sx={{fontFamily: 'Monospace', m: 1}}> You can select three types of Aspect Ratio here :
                            </Box>
                            <List>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <SquareIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Square"/>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <ScreenshotMonitorIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Widescreen"/>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <SwapVerticalCircleIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Vertical"/>
                                </ListItem>
                            </List>

                        </Typography>

                        <Typography component="div">
                            <Box sx={{fontFamily: 'Monospace', fontSize: 'h6.fontSize', m: 1, fontWeight: 'bold'}}>
                                Image Size
                            </Box>
                            <Divider/>
                            <Box sx={{fontFamily: 'Monospace', m: 1}}> You can select three types of Image Size here :
                            </Box>
                            <List>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <HdIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="HD"/>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <TwoKIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="2K"/>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <FourKIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="4K"/>
                                </ListItem>
                            </List>

                        </Typography>

                        <Typography component="div">
                            <Box sx={{fontFamily: 'Monospace', fontSize: 'h6.fontSize', m: 1, fontWeight: 'bold'}}>
                                Image Type
                            </Box>
                            <Divider/>
                            <Box sx={{fontFamily: 'Monospace', m: 1}}> You can select two types of Image here :
                            </Box>
                            <List>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <InsertPhotoIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Photo"/>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <ColorLensIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Art"/>
                                </ListItem>
                            </List>

                        </Typography>

                        <Typography component="div">
                            <Box sx={{fontFamily: 'Monospace', fontSize: 'h6.fontSize', m: 1, fontWeight: 'bold'}}>
                                Avoid Things
                            </Box>
                            <Divider/>
                            <Box sx={{fontFamily: 'Monospace', m: 1}}> If you want to avoid anything from the generated
                                image, you can write here.
                            </Box>
                        </Typography>

                        <Typography component="div">
                            <Box sx={{fontFamily: 'Monospace', fontSize: 'h6.fontSize', m: 1, fontWeight: 'bold'}}>
                                Image Description
                            </Box>
                            <Divider/>
                            <Box sx={{fontFamily: 'Monospace', m: 1}}> Give a brief description of the image that you
                                wanted to generate.
                            </Box>
                        </Typography>

                        <Typography component="div">
                            <Box sx={{fontFamily: 'Monospace', fontSize: 'h6.fontSize', m: 1, fontWeight: 'bold'}}>
                                Number of Image
                            </Box>
                            <Divider/>
                            <Box sx={{fontFamily: 'Monospace', m: 1}}>
                                You can generate maximum 3 number of image here.
                            </Box>
                        </Typography>

                        <Typography component="div">
                            <Box sx={{fontFamily: 'Monospace', fontSize: 'h6.fontSize', m: 1, fontWeight: 'bold'}}>
                                Video Description
                            </Box>
                            <Divider/>
                            <Box sx={{fontFamily: 'Monospace', m: 1}}>
                                Here is a video description for Image Creation.
                            </Box>
                        </Typography>

                        <Box sx={{
                            margin: '30px',
                        }}>
                            <div>
                                <video width="100%" height="100%" controls>
                                    <source src={CreationImage} type="video/mp4"/>
                                    {/*<source src="https://assets.codepen.io/6093409/river.mp4" type="video/ogg"/>*/}
                                </video>
                            </div>
                        </Box>
                    </Box>
                </List>

            </Stack>
        </>
    );
};

export default ImageCreationHelpertext;
