import React, {useState} from 'react';
import {Box, FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import {useDispatch} from 'react-redux';
import Skeleton from '@mui/material/Skeleton';

const PackageUpgradeWithExistedCard = ({onChangePaymentCard}) => {
    const dispatch = useDispatch();
    const [selectedCard, setSelectedCard] = useState('');

    const loading = false;

    const handleChangePaymentCard = event => {
        setSelectedCard(event.target.value);
        onChangePaymentCard(event.target.value);
    };

    return (
        <Box sx={{py: 2}}>
            {loading ? (
                <Skeleton variant={'rectangular'} height={'140'}/>
            ) : (
                <FormControl size="small" fullWidth>
                    <InputLabel id="demo-simple-select-label">Card Number</InputLabel>
                    <Select
                        sx={{textAlign: 'left'}}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedCard}
                        label="Card Number"
                        onChange={handleChangePaymentCard}
                    >
                        {/*{paymentCardList &&*/}
                        {/*    paymentCardList.map((card, index) => (*/}
                        {/*        <MenuItem key={index} value={card.uid}>*/}
                        {/*            **** **** **** {card.card_number}*/}
                        {/*        </MenuItem>*/}
                        {/*    ))}*/}

                        <MenuItem>**** **** ****</MenuItem>
                    </Select>
                </FormControl>
            )}
        </Box>
    );
};

export default PackageUpgradeWithExistedCard;
