import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    primaryKey: "",
    chargeAmount: 0,
    loading: false,
    error: "",
};

const paymentIntegrationSlice = createSlice({
    name: "paymentIntegration",
    initialState,
    reducers: {
        getAgencyStripePaymentIntegrationData: (state) => {
            state.loading = true;
        },
        getAgencyStripePaymentIntegrationDataSuccess: (state, action) => {
            state.primaryKey = action.payload.primaryKey;
            state.chargeAmount = action.payload.chargeAmount;
            state.loading = false;
            state.error = "";
        },
        getAgencyStripePaymentIntegrationDataFailed: (state, action) => {
            state.primaryKey = "";
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const {
    getAgencyStripePaymentIntegrationData,
    getAgencyStripePaymentIntegrationDataSuccess,
    getAgencyStripePaymentIntegrationDataFailed,
} = paymentIntegrationSlice.actions;

export default paymentIntegrationSlice.reducer;
