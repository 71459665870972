import {all, put} from 'redux-saga/effects';
import {call, takeEvery} from '@redux-saga/core/effects';
import {makePaymentFailed,} from "./paymentSlice";
import PaymentService from "../../../services/Payment.service";

function* paymentWatcher() {
    yield takeEvery('payment/makePayment', makePaymentSaga);
}

function* makePaymentSaga(action) {
    try {
        const response = yield call(PaymentService.makePayment, action.payload);


        // if (response.success) {
        //     yield put(makePaymentSuccess(response.data));
        // } else {
        //     yield put(makePaymentFailed(response.message));
        // }
    } catch (err) {
        console.log('Error: ', err);
        yield put(makePaymentFailed(err.message));
    }
}

export default function* paymentSaga() {
    yield all([paymentWatcher()]);
}
