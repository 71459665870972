import {all, put} from 'redux-saga/effects';
import {call, takeEvery} from '@redux-saga/core/effects';
import TextToMemeService from "../../../../services/TextToMeme.service";
import {aiGeneratedTextFailed, aiGeneratedTextSuccess} from "./AIGeneratedTextSlice";

function* AIGeneratedWatcher() {
    yield takeEvery('aiGeneratedText/aiGeneratedText', AIGeneratedText);
}

function* AIGeneratedText(action) {
    try {
        const response = yield call(TextToMemeService.aiGeneratedTextForTemplate, action.payload);

        if (response.data) {
            yield put(aiGeneratedTextSuccess(response.data));
        } else {
            yield put(aiGeneratedTextFailed(response?.results));
        }
    } catch (err) {
        yield put(aiGeneratedTextFailed(err.message));
    }
}

export default function* AIGeneratedTextSaga() {
    yield all([AIGeneratedWatcher()]);
}
