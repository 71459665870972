import React, {useEffect, useState} from 'react';
import {
    Alert,
    Box,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Typography
} from '@mui/material';
import warningLogo from '../../assets/images/Icons/warning-logo.svg';
import Switch from '@mui/material/Switch';
import {useDispatch, useSelector} from 'react-redux';
import UserBalanceService from '../../services/UserBalance.service';
import {currencyFormat} from '../../helpers/utility/currencyFormat';
import CancelButton from '../../assets/global/Button/CancelButton';
import {LoadingButton} from '@mui/lab';
import InfoModal from '../../assets/global/Modal/InfoModal';
import WarningModalContent from '../../assets/global/Modal/WarningModalContent';
import {
    changeStatusAutoRecharge,
    configureAutoRecharge,
    removeAutoRecharge
} from '../../state/features/userPayment/userPaymentSlice';

const BalanceRecharge = () => {
    const {balanceAmountList, isAdding, isChanging, isRemoving} = useSelector(
        state => state.userPayment
    );
    const {userPackage} = useSelector(state => state.userPackage);
    const dispatch = useDispatch();
    const [autoBalance, setAutoBalance] = useState('');
    const [chargeAmount, setChargeAmount] = useState(0);
    const [chargeInfoMessage, setChargeInfoMessage] = useState('');
    const [dollarBalance, setDollarBalance] = useState('');
    const [autoRechargeRemoveModalOpen, setAutoRechargeRemoveModalOpen] = useState(false);
    const [autoRechargeStatusModalOpen, setAutoRechargeStatusModalOpen] = useState(false);
    const [status, setStatus] = useState(false);
    const [userHasPackageUid, setUserHasPackageUid] = useState('');

    const [errors, setErrors] = useState({
        autoBalance: '',
        dollarBalance: '',
        status: ''
    });

    // get System Recharge charge amount
    useEffect(() => {
        if (autoBalance) {
            UserBalanceService.getSystemRechargeCharge({recharge_amount: Number(autoBalance)})
                .then(response => {
                    if (response.success && response.data) {
                        setChargeAmount(response.data);
                        const totalCost = currencyFormat(Number(response.data + autoBalance));
                        setChargeInfoMessage(
                            `$${autoBalance} + $${response.data} (with charge fee) = $${totalCost} will be deducted from your default accounts/card in every auto recharge`
                        );
                    }
                })
                .catch(err => {
                    console.log(err);
                    setChargeAmount(0);
                    setChargeInfoMessage('');
                });
        } else {
            setChargeAmount(0);
            setChargeInfoMessage('');
        }
    }, [autoBalance]);

    const handleOpenStatusModal = () => {
        if (dollarBalance && autoBalance) {
            setAutoRechargeStatusModalOpen(true);
        } else {
            setStatus(!status);
        }
    };

    const handleCloseStatusModal = () => {
        setAutoRechargeStatusModalOpen(false);
    };

    const handleOpenDeleteModal = () => {
        setAutoRechargeRemoveModalOpen(true);
    };

    const handleCloseDeleteModal = () => {
        setAutoRechargeRemoveModalOpen(false);
    };

    const handleChangeStatus = () => {
        setAutoRechargeStatusModalOpen(false);
        dispatch(
            changeStatusAutoRecharge({
                uid: userHasPackageUid,
                auto_load_status: !status
            })
        );
    };

    const handleRemoveAutoRecharge = () => {
        setAutoRechargeRemoveModalOpen(false);
        dispatch(
            removeAutoRecharge({
                uid: userHasPackageUid
            })
        );
    };

    const handleAutoBalanceChange = event => {
        setAutoBalance(event.target.value);
        setErrors({...errors, autoBalance: ''});
    };
    const handleDollarBalanceChange = event => {
        setDollarBalance(event.target.value);
        setErrors({...errors, dollarBalance: ''});
    };

    const submitAutoRecharge = () => {
        if (!dollarBalance) {
            setErrors({...errors, dollarBalance: 'Required'});
        }

        if (!autoBalance) {
            setErrors({...errors, autoBalance: 'Required'});
        }

        if (dollarBalance && autoBalance) {
            dispatch(
                configureAutoRecharge({
                    auto_load_status: status,
                    auto_load_credit_level: dollarBalance,
                    auto_load_credit_amount: autoBalance
                })
            );
        }
    };

    useEffect(() => {
        if (userPackage) {
            const prevAutoLevel = parseFloat(userPackage.auto_load_credit_level);
            const prevAutoLoad = parseFloat(userPackage.auto_load_credit_amount);

            if (prevAutoLoad > 0 && prevAutoLevel > 0) {
                setDollarBalance(prevAutoLevel);
                setAutoBalance(prevAutoLoad);
            } else {
                setDollarBalance('');
                setAutoBalance('');
            }

            setStatus(userPackage.auto_load_status);
            setUserHasPackageUid(userPackage.uid);
        }
    }, [userPackage]);

    return (
        <Grid container>
            <Grid item xl={6} lg={8} md={8}>
                <Box>
                    <Stack direction="row" spacing={2} alignItems={'center'}>
                        <Typography variant="body1" color="text.primary">
                            Auto Recharge
                        </Typography>
                        <FormControlLabel
                            control={
                                <>
                                    <Switch checked={status} onChange={handleOpenStatusModal}/>
                                    {isChanging && <CircularProgress color="primary" size={20}/>}
                                </>
                            }
                            label={''}
                        />
                    </Stack>
                    {status ? (
                        <>
                            {dollarBalance && autoBalance ? (
                                <Alert severity={'info'}>
                                    Your account will refill ${autoBalance} when your dollar reach below $
                                    {dollarBalance} .
                                </Alert>
                            ) : (
                                <div/>
                            )}

                            <Box sx={{py: 2}}>
                                <Typography color="text.primary" variant="body1" sx={{pb: 1}}>
                                    Recharge my account automatically to this amount
                                </Typography>
                                <FormControl error={errors.autoBalance.length > 0} required size="small" fullWidth>
                                    <InputLabel id="demo-simple-select-label" sx={{mb: 2}}>
                                        Select Amount
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={autoBalance}
                                        label="Select Amount"
                                        onChange={handleAutoBalanceChange}
                                    >
                                        {balanceAmountList &&
                                            balanceAmountList.map((balance, index) => (
                                                <MenuItem key={index} value={balance.recharge_amount}>
                                                    $ {balance.recharge_amount}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                    <FormHelperText color={'warning.main'}>{chargeInfoMessage} </FormHelperText>
                                </FormControl>
                            </Box>

                            <Box>
                                <Typography color="text.primary" variant="body1" sx={{pb: 1}}>
                                    When my dollar balance reaches
                                </Typography>
                                <FormControl
                                    error={errors.dollarBalance.length > 0}
                                    required
                                    size="small"
                                    fullWidth
                                >
                                    <InputLabel id="demo-simple-select-label" sx={{mb: 2}}>
                                        Select Amount
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={dollarBalance}
                                        label="Select Amount"
                                        onChange={handleDollarBalanceChange}
                                    >
                                        {balanceAmountList &&
                                            balanceAmountList.map((balance, index) => (
                                                <MenuItem key={index} value={balance.recharge_amount}>
                                                    $ {balance.recharge_amount}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                    {errors.dollarBalance.length > 0 ? (
                                        <FormHelperText error> {errors.dollarBalance} </FormHelperText>
                                    ) : (
                                        <div/>
                                    )}
                                </FormControl>
                            </Box>

                            <Box
                                sx={{
                                    pt: 3,
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                }}
                            >
                                <Stack spacing={3} direction={'row'}>
                                    {autoBalance && dollarBalance && (
                                        <CancelButton
                                            variant={'outlined'}
                                            size={'large'}
                                            onClick={handleOpenDeleteModal}
                                            disabled={isRemoving}
                                            startIcon={isRemoving && <CircularProgress size={16} color={'inherit'}/>}
                                        >
                                            {isRemoving ? 'Removing...' : 'Remove Auto Recharge'}
                                        </CancelButton>
                                    )}

                                    <LoadingButton
                                        onClick={submitAutoRecharge}
                                        loadingText={'Saving Auto Recharge...'}
                                        isLoading={isAdding}
                                        color={'secondary'}
                                        variant={'contained'}
                                        size={'large'}
                                    >
                                        Save Auto Recharge
                                    </LoadingButton>
                                </Stack>
                                <InfoModal
                                    open={autoRechargeRemoveModalOpen}
                                    width="480px"
                                    onClose={handleCloseDeleteModal}
                                >
                                    <WarningModalContent
                                        logo={warningLogo}
                                        title={'Warning'}
                                        subTitle={'Are you sure to remove auto recharge configuration?'}
                                        subTitleSpan={''}
                                        content={'Once you remove, you’ll not be able to recover it again.'}
                                        buttonName={'Submit'}
                                        onConfirm={handleRemoveAutoRecharge}
                                        onClose={handleCloseDeleteModal}
                                    />
                                </InfoModal>
                            </Box>
                        </>
                    ) : (
                        <div/>
                    )}
                    <InfoModal
                        open={autoRechargeStatusModalOpen}
                        width="480px"
                        onClose={handleCloseStatusModal}
                    >
                        <WarningModalContent
                            logo={warningLogo}
                            title={'Warning'}
                            subTitle={`Are you sure to ${
                                status ? 'disable' : 'enable'
                            } auto recharge configuration?`}
                            subTitleSpan={''}
                            buttonName={'Submit'}
                            onConfirm={handleChangeStatus}
                            onClose={handleCloseStatusModal}
                        />
                    </InfoModal>
                </Box>
            </Grid>
            <Grid item xl={6} lg={4} md={4}></Grid>
        </Grid>
    );
};

export default BalanceRecharge;
