import {all, put} from 'redux-saga/effects';
import {call, takeEvery} from '@redux-saga/core/effects';
import {templateSearchFailed, templateSearchSuccess} from "./templateSearchSlice";
import TextToMemeService from "../../../../services/TextToMeme.service";

function* templateSearchWatcher() {
    yield takeEvery('templateSearch/templateSearch', generateTemplates);
}

function* generateTemplates(action) {
    try {
        const response = yield call(TextToMemeService.templateSearch, action.payload);

        if (response?.results) {
            yield put(templateSearchSuccess(response));
        } else {
            yield put(templateSearchFailed(response?.results));
        }
    } catch (err) {
        yield put(templateSearchFailed(err.message));
    }
}

export default function* templateSearchSaga() {
    yield all([templateSearchWatcher()]);
}
