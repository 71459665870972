import React from "react";
import Styles from "../styles/Package.module.css";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {changeToOutlinedHoverBackground} from "../../../helpers/utility/colorCustomize";
import CoreButton from "../../../assets/global/Button/CoreButton";

const PackagePreviewCard = ({ title, description, packagePrice, buttonText, onClickButton, buttonDisable = false }) => {
    const theme = useTheme();

    const onClick = () => {
        if (!buttonDisable) onClickButton();
    };

    return (
        <Box
            className={Styles.package__header}
            sx={{ backgroundColor: changeToOutlinedHoverBackground(theme.palette.info.main) }}
        >
            <Box className={Styles.package__header_content}>
                <Typography variant='subtitle1' sx={{ textTransform: "uppercase" }} component='div'>
                    {title}
                </Typography>
                <Typography variant='badge' component='div'>
                    {description}
                </Typography>
                <Typography sx={{ mt: theme.spacing(2) }} variant='h4' component='div' color='info.main'>
                    {packagePrice > 0 ? `${packagePrice} $` : "Free"}
                </Typography>
                <Typography variant='body2' component='div'>
                    Per Month
                </Typography>
            </Box>

            <CoreButton onClick={onClick} disabled={buttonDisable} variant={"contained"} size={"large"} color='info'>
                {buttonText}
            </CoreButton>
        </Box>
    );
};

export default PackagePreviewCard;
