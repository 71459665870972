import { all, call, put, takeEvery } from "redux-saga/effects";
import BalanceService from "../../../services/Balance.service";
import {
    getAllBalanceDataFailed,
    getAllBalanceDataSuccess,
    getSpecificBalanceDataFailed,
    getSpecificBalanceDataSuccess
} from "./userBalanceSlice";
import BalanceCheckService from "../../../services/BalanceCheck.service";

function* userBalanceWatcher() {
    yield takeEvery("userBalance/getSpecificBalanceData", getSpecificBalanceDataSaga);
    yield takeEvery("userBalance/getAllBalanceData", getAllBalanceDataSaga);
}

function* getSpecificBalanceDataSaga(action) {
    try {
        const response = yield call(BalanceCheckService.getSpecificBalanceData, action.payload);

        if (response.success) {
            yield put(getSpecificBalanceDataSuccess(response.data));
        } else {
            yield put(getSpecificBalanceDataFailed(response));
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* getAllBalanceDataSaga(action) {
    try {
        const response = yield call(BalanceService.getAllBalanceData, action.payload);

        if (response.success) {
            yield put(getAllBalanceDataSuccess(response.data));
        } else {
            yield put(getAllBalanceDataFailed(response));
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

export default function* userBalanceSaga() {
    yield all([userBalanceWatcher()]);
}
