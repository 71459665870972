import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    template: [],
    page: 1,
    perPage: 9,
    total: 9,
    isLoading: false,
    isError: false,
    error: '',
    specificTemplate: null,
    isResponseSuccess: false,
    responseData: null,
    showGenerateButton: true,
    showReGenerateButton: false,
    isShowReCopyButton: false,
    promptInputText: ""
};

const promptByCategorySlice = createSlice({
    name: 'promptByCategory',
    initialState: initialState,
    reducers: {
        getPromptByCategoryTemplate: (state, action) => {
            state.isLoading = true;

            if (action.payload) {
                state.page = action.payload.page || 1;
                state.perPage = action.payload.per_page || 10;
            }
        },
        getPromptByCategoryTemplateSuccess: (state, action) => {
            state.template = action.payload.data;
            state.total = action.payload.total;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        getPromptByCategoryTemplateFailed: (state, action) => {
            state.template = [];
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },


        clearPromptByCategoryState: (state, action) => {
            state.template = [false];
            state.page = 1;
            state.perPage = 9;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
            state.specificTemplate = '';
            state.isResponseSuccess = false;
            state.responseData = null;
            state.showGenerateButton = true;
            state.showReGenerateButton = false;
            state.isShowReCopyButton = false;
            state.promptInputText = "";
        },
    }
});

export const {
    getPromptByCategoryTemplate,
    getPromptByCategoryTemplateSuccess,
    getPromptByCategoryTemplateFailed,
} = promptByCategorySlice.actions;

export default promptByCategorySlice.reducer;
