const getDurationType = (days = 1) => {
    let durationType = "month";
    if (days <= 7) {
        durationType = "week";
    } else if (days <= 30) {
        durationType = "month";
    } else if (days > 30) {
        durationType = "year";
    }

    return durationType;
};

export default getDurationType;
