import React, {useState} from 'react';
import {Add, Remove} from '@mui/icons-material';
import {Collapse, Paper, Stack, Typography} from '@mui/material';
import Styles from './Styles/PaymentMethodItem.module.css';
import './Styles/PaymentCard.css';
import PaymentCard from './PaymentCard';
import UserPaymentCard from './UserPaymentCard';
import {useSelector} from 'react-redux';
import CoreButton from '../../assets/global/Button/CoreButton';

export const PaymentMethodItem = ({
                                      paymentMethodName,
                                      paymentMethodIcon,
                                      paymentMethodBtnText,
                                      paymentMethodBtnIcon
                                  }) => {
    const [showPaymentForm, setShowPaymentForm] = useState(false);

    const handleShowPaymentForm = () => {
        setShowPaymentForm(!showPaymentForm);
    };

    return (
        <Paper elevation={0} className={Styles.paymentMethodBox}>
            <Stack direction={'row'} justifyContent="space-between" alignContent="center">
                <Typography variant="body1" color="text.primary">
                    {paymentMethodName}
                </Typography>
                <img src={paymentMethodIcon}/>
            </Stack>

            {/* ## Agency Payment Card List Show ## */}
            <UserPaymentCard/>

            <>
                <CoreButton
                    onClick={() => handleShowPaymentForm()}
                    color={'primary'}
                    variant={'text'}
                    size={'medium'}
                >
                    {!showPaymentForm ? <Add/> : <Remove/>} {paymentMethodBtnText}
                </CoreButton>
                <Collapse in={showPaymentForm} timeout="auto" unmountOnExit>
                    <PaymentCard onClosePaymentForm={handleShowPaymentForm}/>
                    {/*<AddNewCardForm />*/}
                </Collapse>
            </>
        </Paper>
    );
};
