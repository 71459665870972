import {Box, Card, IconButton, Stack, Typography} from '@mui/material';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CoreButton from '../../assets/global/Button/CoreButton';
import FullPageDrawer from '../../assets/global/Drawer/FullPageDrawer';
import InstagramIcon from '@mui/icons-material/Instagram';
import PromptCollectionForm from './PromptCollectionForm';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import {createPromptCollection} from '../../state/features/promptCollection/promptCollectionSlice';
import {selectPromptState} from '../../state/features/prompts/promptSelector';
import {showSuccessToaster} from "../../helpers/utility/toaster";

const PromptPage = ({templateDate, children}) => {
    const dispatch = useDispatch();
    const [viewDetailsPageShow, setViewDetailsPageShow] = useState(false);
    const {isShowReCopyButton, responseData, promptInputText} = useSelector(selectPromptState);

    const toggleViewDetailsPage = () => setViewDetailsPageShow(prevState => !prevState);
    const handleViewDetailsPage = data => {
        toggleViewDetailsPage();
    };

    const handlePromptCollectionSubmit = data => {
        const payload = {};
        payload.collection_id = data.collection_id || '';
        payload.template_id = templateDate?.id || '';
        payload.text = promptInputText || '';
        payload.response = responseData || '';

        dispatch(createPromptCollection(payload));
    };

    const handleCopy = data => {
        navigator.clipboard.writeText(data);
        showSuccessToaster('Text copied');
    };

    return (
        <>
            <Card
                variant="outlined"
                sx={{
                    padding: '20px',
                    mb: '3',
                    border: 'none',
                    overflow: 'visible',
                    backgroundColor: 'rgb(255, 255, 255)',
                    borderRadius: '5px',
                    boxShadow: 'rgba(0, 0, 0, 0.08) 0px 0px 8px 0px',
                    opacity:'1'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: `1px solid #dfe4ea`,
                        paddingBottom:"10px"
                    }}
                >
                    <Box sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                        <InstagramIcon sx={{marginRight: '8px'}}/>
                        <Typography  color={'text.primary'} sx={{fontSize:"18px",fontWeight:"500"}}>
                            {templateDate?.title}
                        </Typography>
                    </Box>

                    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                        {false && (
                            <>
                                <CoreButton
                                    sx={{
                                        padding:"0 !important"
                                    }}
                                    variant="contained"
                                    type={'submit'}
                                    size={'small'}
                                    color={'primary'}
                                    onClick={() => handleViewDetailsPage()}
                                >
                                    <IconButton sx={{color: '#fff',  padding :"6px 10px"}}>
                                        <SaveAsIcon />
                                    </IconButton>
                                </CoreButton>

                                <CoreButton
                                    sx={{
                                        padding:"0 !important"
                                    }}
                                    variant="contained"
                                    type={'submit'}
                                    size={'small'}
                                    color={'primary'}
                                    onClick={() => handleCopy(responseData)}
                                >
                                    <IconButton sx={{color: '#fff',  padding :"6px 10px"}}>
                                        <ContentCopyIcon />
                                    </IconButton>
                                </CoreButton>
                            </>
                        )}
                    </Stack>
                </Box>
                <Box sx={{height: 'auto !important', textAlign: 'center', alignSelf: 'center', p: '5px'}}>
                    {children}
                </Box>
            </Card>

            <FullPageDrawer
                title="Save to collection"
                open={viewDetailsPageShow}
                toggleDrawer={toggleViewDetailsPage}
                width="35%"
                height="60%"
            >
                <PromptCollectionForm
                    toggleDrawer={toggleViewDetailsPage}
                    handlePromptCollectionSubmit={handlePromptCollectionSubmit}
                />
            </FullPageDrawer>
        </>
    );
};

export default PromptPage;
