import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    imageInPaintingData: [],
    isLoading: false,
    isError: false,
    error: ''
};

const imageInPaintingSlice = createSlice({
    name: 'imageInPainting',
    initialState: initialState,
    reducers: {
        imageInPainting: (state, action) => {
            state.isLoading = true;
        },
        imageInPaintingSuccess: (state, action) => {

            state.imageInPaintingData = action?.payload;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        imageInPaintingFailed: (state, action) => {
            state.imageInPaintingData = null;
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },
        imageInPaintingStateClear: (state, action) => {
            state.imageInPaintingData = null;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
    }
});

export const {
    imageInPainting,
    imageInPaintingSuccess,
    imageInPaintingFailed,
    imageInPaintingStateClear,
} = imageInPaintingSlice.actions;

export default imageInPaintingSlice.reducer;
