import {requests} from "./http.service";

class PromptCollectionService {

    getPromptCollection(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/prompt/all/by/collection", body);
    }

    createPromptCollection(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/prompt/create", body);
    }

    createImageCollection(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/prompt/create/saved-image", body);
    }
}

export default new PromptCollectionService();
