import {requests} from "./http.node.service";

class BalanceCheckService {
    getSpecificBalanceData(params) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/balance/key/wise/data", params);
    }
    getBalance(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/balance/check/content/wise", body);
    }

    reduceBalance(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/balance/reduce/content/wise", body);
    }
}

export default new BalanceCheckService();
