import React, {useState} from 'react';
import {Box, Button, Typography} from "@mui/material";

const PresetTemplateDetailsRadioButton = ({handleRadioButtonValue}) => {
    const [value, setValue] = useState(4000);
    const handleChange = data => {
        setValue(data);
        handleRadioButtonValue(data)
    };

    return (
        <Box sx={{marginTop: '20px'}}>
            <Typography sx={{color: '#374151', mb: .7}}>Output length</Typography>

            <Button
                size="small"
                className={`radio-button ${value === 200 ? 'active' : ''}`}
                variant="outlined"
                sx={{marginRight:'5px'}}
                onClick={() => handleChange(200)}
            >
                Short
            </Button>

            <Button
                size="small"
                className={`radio-button ${value === 1000 ? 'active' : ''}`}
                variant="outlined"
                sx={{marginRight:'5px'}}
                onClick={() => handleChange(1000)}
            >
                Medium
            </Button>

            <Button
                size="small"
                className={`radio-button ${value === 4000 ? 'active' : ''}`}
                variant="outlined"
                sx={{marginRight:'5px'}}
                onClick={() => handleChange(4000)}
            >
                Long
            </Button>
        </Box>
    );
};

export default PresetTemplateDetailsRadioButton;