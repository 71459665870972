import React from "react";
import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

const CustomEditor = ({ theme = "snow", height = 150, placeholder = "Write your message...", ...rest }) => {
    return (
        <ReactQuill
            style={{ height: height, marginBottom: "48px", whiteSpace: 'pre-line' }}
            theme={theme}
            placeholder={placeholder}
            {...rest}
        />
    );
};

export default CustomEditor;
