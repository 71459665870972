import React, {useState} from 'react';
import {Box} from '@mui/material';
import '../../../node_modules/react-quill/dist/quill.snow.css';
import FeatureInput from '../common/featureInput/FeatureInput';
import PlayPenEditor from './PlayPenEditor';
import BalanceCheck from "../../assets/global/BalanceCheck/BalanceCheck";
import {creditSettingSTextContent} from "../../helpers/constant/coreConstant";

const PlayPen = () => {
    const [inputContent, setInputContent] = useState('');

    const handleInputText = text => {
        setInputContent(text);
    };

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    height: '90vh',
                    paddingTop: '50px',
                    backgroundColor: '#f3f4f6',
                    overflow: 'auto'
                }}
            >
                <FeatureInput headerTitle={"OLK9AI's Play Pen"} helpkey={"PLAYPEN"}>
                    <PlayPenEditor handleInputText={handleInputText}/>
                    <BalanceCheck content_type={creditSettingSTextContent} amount_data={1000} />
                </FeatureInput>
            </Box>
        </>
    );
};

export default PlayPen;
