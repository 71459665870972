import {Box, Card, CardActionArea, CardContent, Typography} from '@mui/material';
import {Link, useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EmptyPage from '../../assets/global/EmptyComponent/EmptyPage';
import FullPageDrawer from '../../assets/global/Drawer/FullPageDrawer';
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import PromptDetails from '../prompt/PromptDetails';
import PropTypes from 'prop-types';
import SearchAppBar from './SearchCategory';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import WindowIcon from '@mui/icons-material/Window';
import {getCategory} from '../../state/features/category/categorySlice';
import {clearState, getPromptTemplate} from '../../state/features/prompts/promptSlice';
import noDataImage from '../../assets/images/no_data.jpg';
import {selectCategoriesState} from '../../state/features/category/collectionSelector';
import {useForm} from 'react-hook-form';
import {useTheme} from '@mui/material/styles';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import {selectPromptByCategoryState} from '../../state/features/promptsByCategory/promptByCategorySelector';
import {getPromptByCategoryTemplate} from '../../state/features/promptsByCategory/promptByCategorySlice';

function RestartAltIcon(props) {
    return null;
}

RestartAltIcon.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.string
};
const ChatPromptCategory = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [viewDetailsPageShow, setViewDetailsPageShow] = useState(false);
    const [selectedTemplateObj, setSelectedTemplateObj] = useState(null);
    const [filterCategoryId, setFilterCategoryId] = useState('');
    const [searchInput, setSearchInput] = useState(null);
    const [searchCategory, setSearchLocation] = useState('');
    const [anchorElSwitchAc, setAnchorElSwitchAc] = useState(null);
    const [filteredCategory, setFilteredCategory] = useState(null);
    const [switchButtonShow, setSwitchButtonShow] = useState(true);
    const [categoryName, setCategoryName] = useState('');
    const [categoryInfo, setCategoryInfo] = useState({
        name: 'All'
    });

    let {categoryId} = useParams();
    categoryId = categoryId === 'undefined' || !categoryId ? '' : categoryId;

    const {page, perPage, total, template, isLoading} = useSelector(selectPromptByCategoryState);
    const {categories} = useSelector(selectCategoriesState);

    const pageCount = Math.ceil(total / perPage);

    const toggleViewDetailsPage = () => setViewDetailsPageShow(prevState => !prevState);

    useEffect(() => {
        if (categoryId) {
            setFilterCategoryId(categoryId);
        } else {
            setFilterCategoryId('');
        }
    }, [dispatch, categoryId]);

    useEffect(() => {
        if (Array.isArray(template) && template.length > 0) {
            setCategoryName(template[0]?.category?.name || '');
        } else {
            setCategoryName('');
        }
    }, [dispatch,categoryId,template.length]);

    useEffect(() => {
        dispatch(
            getPromptByCategoryTemplate({
                title: searchInput,
                category_id: categoryId,
                page: page,
                per_page: perPage
            })
        );
    }, [dispatch, categoryId, searchInput]);

    useEffect(() => {
        dispatch(getCategory());
    }, [dispatch]);

    useEffect(() => {
        return () => dispatch(clearState());
    }, []);

    const {handleSubmit, watch, setValue, setError, control} = useForm();

    const handleChange = (event, value) => {
        dispatch(
            getPromptByCategoryTemplate({
                title: searchInput,
                category_id: filterCategoryId,
                page: value,
                per_page: perPage
            })
        );
    };

    const handleSearch = data => {
        setSearchInput(data);
    };

    const handleClose = () => {
        setAnchorElSwitchAc(null);
        setSearchLocation('');
    };

    const handleClick = event => setAnchorElSwitchAc(event.currentTarget);

    const handleCategoryChange = location => {
        if (location) {
            setCategoryInfo({...categoryInfo, name: location.name});
            setFilterCategoryId(location.id);
        } else {
            setCategoryInfo({...categoryInfo, name: 'All'});
            setFilterCategoryId(null);

            dispatch(
                getPromptTemplate({
                    title: searchInput,
                    category_id: filteredCategory,
                    page: page,
                    per_page: perPage
                })
            );
        }

        handleClose();
    };

    const onChangeSearchCategory = event => {
        setSearchLocation(event.target.value);
    };

    const handleSearchCategory = location => {
        return !!location.name.toLowerCase().match(searchCategory.toLowerCase());
    };

    const switchPopperOpen = Boolean(anchorElSwitchAc);
    const id = switchPopperOpen ? 'switch-account-popover' : undefined;

    // decide to what to render on view
    let content = null;

    if (isLoading) {
        content = (
            <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}} sx={{p: 3}}>
                {[1, 2, 3, 4].map(collection => (
                    <Grid key={collection} item xs={3}>
                        <Box sx={{p: 3, marginRight: 0.5}}>
                            <Skeleton variant="rectangular" height={118}/>
                            <Skeleton/>
                            <Skeleton width="60%"/>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        );
    } else if (!isLoading && template.length > 0) {
        content = (
            <>
                <Grid
                    container
                    columnSpacing={{xs: 1, sm: 2, md: 1}}
                    sx={{p: 3}}
                    style={{paddingRight: '200px'}}
                >
                    {template.map(item => (
                        <Grid sx={{paddingLeft: '0px'}} key={item.id} item xs={4}>
                            <Link to={`/prompt/${item.id}`} style={{textDecoration: 'none'}}>
                                <Card
                                    sx={{
                                        background: '#FCFCFC',
                                        // background: 'rgb(230, 230, 230)',
                                        boxShadow: '0 0 0 0 #fff',
                                        borderRadius: '10px',
                                        ':hover': {
                                            background: 'rgb(230, 230, 230)'
                                        }
                                    }}
                                >
                                    <CardActionArea>
                                        <CardContent
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                                gap: '15px',
                                                paddingBottom: '0px',
                                                padding: '12px'
                                            }}
                                        >
                                            {/*<GoogleIcon sx={{fontSize: '24px', top: '0', paddingTop: '0'}}/>*/}
                                            {item?.icon ? (
                                                <img src={item?.icon || ''} width="40px" height="40px"/>
                                            ) : (
                                                <TipsAndUpdatesIcon sx={{width: '40px', height: '40px'}}/>
                                            )}
                                            <Box>
                                                <Typography
                                                    gutterBottom
                                                    variant="h6"
                                                    component="div"
                                                    className="max-1line-text"
                                                    sx={{
                                                        fontSize: '14px',
                                                        lineHeight: '1.4',
                                                        marginBottom: '5px',
                                                        fontWeight: '600',
                                                        color: 'rgb(51, 51, 51)',
                                                        opacity: 1,
                                                        wordBreak: 'break-word'
                                                    }}
                                                >
                                                    {item.title}
                                                </Typography>

                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    className="max-3line-text"
                                                    sx={{
                                                        marginBottom: '5px',
                                                        marginTop: '0px',
                                                        fontFamily: 'Poppins',
                                                        fontSize: '12px',
                                                        fontWeight: '350!important',
                                                        color: 'rgb(51, 51, 51)!important',
                                                        lineHeight: '1.4',
                                                        opacity: '1',
                                                        wordBreak: 'break-word'
                                                    }}
                                                >
                                                    <div
                                                        style={{marginTop: '0'}}
                                                        dangerouslySetInnerHTML={{__html: item.description}}
                                                    />
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Link>
                        </Grid>
                    ))}
                </Grid>

                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    sx={{p: 1}}
                    style={{
                        bottom: '5%',
                        position: 'absolute',
                        right: '5%'
                    }}
                >
                    <Pagination
                        onChange={handleChange}
                        count={pageCount}
                        page={page}
                        renderItem={item => (
                            <PaginationItem slots={{previous: ArrowBackIcon, next: ArrowForwardIcon}} {...item} />
                        )}
                    />
                </Stack>
            </>
        );
    } else if (!isLoading && template.length === 0) {
        content = (
            <>
                <EmptyPage title="No information was discovered." img={noDataImage}/>
            </>
        );
    }

    return (
        <>
            <Box py={1} px={2}>
                <Box
                    sx={{
                        width: '100%',
                        height: '87vh',
                        borderRadius: '5px',
                        transition: 'all 0.3s ease',
                        border: 'border: 1px solid #ebe9f1'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',

                            bgcolor: 'background.paper',
                            borderBottom: `1px solid #dfe4ea`,
                            minHeight: 'auto'
                        }}
                    >
                        <Box>
                            <Typography
                                gutterBottom
                                variant="h5"
                                component="h5"
                                sx={{font: 'bold', marginBottom: '0'}}
                            >
                                <WindowIcon sx={{fontSize: '20px'}}/> {categoryName || ''}
                            </Typography>
                        </Box>

                        <Box sx={{display: 'flex', width: '50%', justifyContent: 'end', alignItems: 'center'}}>
                            <></>
                            {/*End */}

                            <SearchAppBar handleSearch={handleSearch}></SearchAppBar>
                        </Box>
                    </Box>

                    {content}
                </Box>
            </Box>

            <FullPageDrawer
                title=""
                open={viewDetailsPageShow}
                width="75%"
                toggleDrawer={toggleViewDetailsPage}
            >
                <PromptDetails
                    templateDate={selectedTemplateObj}
                    handleCloseDrawer={toggleViewDetailsPage}
                />
            </FullPageDrawer>
        </>
    );
};

export default ChatPromptCategory;
