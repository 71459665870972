import {all, put} from 'redux-saga/effects';
import {call, select, takeEvery} from '@redux-saga/core/effects';
import {
    getChatGPTAnswerFailed,
    getChatGPTAnswerSuccess,
    getChatGPTHistoryFailed,
    getChatGPTHistorySuccess
} from './chatGPTSlice';
import ChatGPTService from "../../../services/ChatGPT.service";
import {showErrorToaster} from "../../../helpers/utility/toaster";
import {selectChatGPTState} from "./chatGPTSelector";
import BalanceCheckService from "../../../services/BalanceCheck.service";
import {creditSettingSTextContent} from "../../../helpers/constant/coreConstant";

function* chatGPTWatcher() {
    yield takeEvery('chatGPT/getChatGPTAnswer', getChatGPTAnswerSaga);
    yield takeEvery('chatGPT/getChatGPTHistory', getChatGPTHistorySaga);
}

function* getChatGPTAnswerSaga(action) {
    try {
        const chatGptState = yield select(selectChatGPTState)
        const stateMessages = chatGptState.messages

        const payload = {
            model: "gpt-3.5-turbo",
            messages: stateMessages
        }
        const apiKey = yield call(ChatGPTService.getApiKey, payload);

        if (apiKey.success) {
            payload.apiKey = apiKey.data
            const response = yield call(ChatGPTService.getChatGPTAnswer, payload);

            if (response.id) {
                const message = response.choices[0].message;

                const historyPayload = {
                    text: action.payload.messages[0]?.content,
                    reply: message?.content
                }

                yield call(ChatGPTService.createChatHistory, historyPayload);
                yield call(BalanceCheckService.reduceBalance, {content_type: creditSettingSTextContent, amount_data: response?.usage?.total_tokens});

                yield put(getChatGPTAnswerSuccess({message: message}));
            } else {
                yield put(getChatGPTAnswerFailed(response.error.message));
            }

        } else {
            yield put(getChatGPTAnswerFailed(apiKey.message));
            showErrorToaster(apiKey.message)
        }


    } catch (err) {
        console.log('Error: ', err);
        yield put(getChatGPTAnswerFailed(err.message));
    }
}

function* getChatGPTHistorySaga(action) {
    try {
        const response = yield call(ChatGPTService.getAllCollectionList, action.payload);

        if (response.status) {
            yield put(getChatGPTHistorySuccess(response.data));
        } else {
            yield put(getChatGPTHistoryFailed(response.message));
        }
    } catch (err) {
        console.log('Error: ', err);
        yield put(getChatGPTHistoryFailed(err.message));
    }
}

export default function* chatGPTSaga() {
    yield all([chatGPTWatcher()]);
}
