import {all, put} from 'redux-saga/effects';
import {call, takeEvery} from '@redux-saga/core/effects';
import PromptService from '../../../services/Prompt.service';
import {getPromptByCategoryTemplateFailed, getPromptByCategoryTemplateSuccess} from "./promptByCategorySlice";

function* promptByCategoryWatcher() {
    yield takeEvery('promptByCategory/getPromptByCategoryTemplate', getPromptByCategoryTemplateSaga);
}

function* getPromptByCategoryTemplateSaga(action) {
    try {
        const response = yield call(PromptService.getPromptTemplateData, action.payload);

        if (response.success) {
            yield put(getPromptByCategoryTemplateSuccess(response.data));
        } else {
            yield put(getPromptByCategoryTemplateFailed(response.message));
        }
    } catch (err) {
        console.log('Error: ', err);
        yield put(getPromptByCategoryTemplateFailed(err.message));
    }
}

export default function* promptByCategorySaga() {
    yield all([promptByCategoryWatcher()]);
}
