import React, {useEffect, useRef, useState} from 'react';
import ReactQuill from 'react-quill';
import '../../../node_modules/react-quill/dist/quill.snow.css';
import CustomToolbar from './CustomToolBar';
import {Box, Button, Stack} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {showErrorToaster, showSuccessToaster} from '../../helpers/utility/toaster';
import {useDispatch, useSelector} from 'react-redux';
import {rewriteText, writeMoreText} from '../../state/features/playPen/playPenSlice';
import {selectPlayPenState} from '../../state/features/playPen/playPenSelector';
import PromptCollectionForm from "../prompt/PromptCollectionForm";
import FullPageDrawer from "../../assets/global/Drawer/FullPageDrawer";
import {createPromptCollection} from "../../state/features/promptCollection/promptCollectionSlice";
import CloseIcon from "@mui/icons-material/Close";

const PlayPenEditor = ({value = null}) => {
    const dispatch = useDispatch();
    const [editorValue, setEditorValue] = useState(value);
    const editorRef = useRef(null);
    const {reWriteData, writeMoreData, isLoading} = useSelector(selectPlayPenState);
    const [viewDetailsPageShow, setViewDetailsPageShow] = useState(false);
    const [wordCount, setWordCount] = useState(0);
    const [tone, setTone] = useState('');
    const toggleViewDetailsPage = () => setViewDetailsPageShow(prevState => !prevState);

    const modules = {
        toolbar: {
            container: '#toolbar',
            handlers: {
                // insertStar: insertStar,
                // contentStore: contentStore
            }
        },
        clipboard: {
            matchVisual: false
        }
    };

    const formats = [
        'header',
        'font',
        // 'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
        'color',
        'direction',
        'align',
    ];

    useEffect(() => {
        setEditorValue(reWriteData);
    }, [dispatch, reWriteData]);

    useEffect(() => {
        setEditorValue(writeMoreData);
    }, [dispatch, writeMoreData]);

    useEffect(() => {
        setEditorValue(value);
    }, [dispatch, value]);

    useEffect(() => {
        setTimeout(() => {
            const editor = editorRef.current?.getEditor();
            const value = editor?.getText();

            let count = 0;
            const result = typeof value === 'string' ? value.trim() : 0;
            const resultCount = typeof result === 'string' ? result?.split(/\s+/) : 0;

            if (resultCount[0] === '') {
                count = resultCount.length - 1;
                setWordCount(count);
            } else {
                count = resultCount.length;
                setWordCount(count);
            }
        }, "1000");

    }, [editorValue]);


    const handleReWriteSelectedText = () => {
        const editor = editorRef.current?.getEditor();
        const selection = editor.getSelection();
        if(selection && selection.length){
            const selectedText = editor?.getText(selection.index, selection.length);
            dispatch(rewriteText({text: selectedText, tone: tone}));
        } else{
            showErrorToaster('Select text to rewrite');
            return false;
        }


        //----------------------------------------------------------------------------------------

        // const quillEditor = editorRef.current?.getEditor();
        // const selection = quillEditor.getSelection();
        // if (selection) {
        //     const [startIndex, endIndex] = [selection.index, selection.index + selection.length];
        //     const content = quillEditor?.getText(selection.index, selection.length);
        //     dispatch(rewriteText({text: content, tone:tone}));
        //
        //         quillEditor.deleteText(startIndex, selection.length);
        //         quillEditor.insertText(startIndex, reWriteText);
        //
        // }
        //----------------------------------------------------------------------------------------
    };


    const handleWriteMoreText = () => {
        const editor = editorRef.current?.getEditor();
        const value = editor?.getText();

        if (!value.trim()) {
            showErrorToaster('Text can not be empty');
            return false;
        }

        dispatch(writeMoreText({text: value, tone: tone}));
    };

    const handleTone = (e) => {
        setTone(e.target.value);
    }

    const handleCopy = () => {
        const editor = editorRef.current?.getEditor();
        const value = editor?.getText();
        if(value.trim()) {
            navigator.clipboard.writeText(value);
            showSuccessToaster('Text copied');
        } else {
            showErrorToaster('No text to copy!');
        }

    };

    const handelSaveContent = () => {
        const editor = editorRef.current?.getEditor();
        const value = editor?.getText();
        if (value.trim()) {
            toggleViewDetailsPage();
        } else {
            showErrorToaster("No content to save!")
        }
    }
    const handlePromptCollectionSubmit = (data) => {
        const payload = {};
        payload.collection_id = data.collection_id || '';
        const editor = editorRef.current?.getEditor();
        const value = editor?.getText();
        payload.text = value.split('. ', 1)[0];
        payload.response = value;
        dispatch(createPromptCollection(payload));
    }

    const handleResetClick = () => {
        setEditorValue('');
        setTone('')
    };

    return (
        <>
            <Backdrop sx={{color: '#fff', zIndex: theme => theme.zIndex.drawer + 1}} open={isLoading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Box className="text-editor">
                <CustomToolbar
                    handleReWriteSelectedText={handleReWriteSelectedText}
                    handleWriteMoreText={handleWriteMoreText}
                    handleCopy={handleCopy}
                    handelSaveContent={handelSaveContent}
                    wordCount={wordCount}
                    handleTone={handleTone}
                    tone={tone}
                />
                <ReactQuill
                    ref={editorRef}
                    placeholder="Write something here..."
                    modules={modules}
                    formats={formats}
                    onChange={setEditorValue}
                    theme={'snow'} // pass false to use minimal them
                    style={{whiteSpace: 'pre-line', fontWeight: '300'}}
                    value={editorValue}
                />
            </Box>
            {/* Footer*/}
            <Box
                sx={{
                    m: 'auto',
                    p: '12px 24px',
                    borderTop: '1px solid rgb(229 231 235/1)',
                    backgroundColor: 'rgb(249 250 251/1)',
                    borderBottomLeftRadius: 8,
                    borderBottomRightRadius: 8,
                    bgcolor: '#f9fafb'
                }}
            >
                <Stack direction="row" justifyContent="flex-end" alignItems="center">
                    <Button
                        sx={{
                            bgColor: 'white !important',
                            color: '#374151',
                            borderColor: '#374151',
                            ':hover': {borderColor: '#374151'}
                        }}
                        variant="outlined"
                        startIcon={<CloseIcon/>}
                        onClick={() => {
                            handleResetClick()
                        }}
                    >
                        Clear Inputs
                    </Button>
                </Stack>
            </Box>
            {/* Footer*/}


            <FullPageDrawer
                title="Save to collection"
                open={viewDetailsPageShow}
                toggleDrawer={toggleViewDetailsPage}
                width="35%"
                height="60%"
            >
                <PromptCollectionForm
                    toggleDrawer={toggleViewDetailsPage}
                    handlePromptCollectionSubmit={handlePromptCollectionSubmit}
                />
            </FullPageDrawer>
        </>
    );
};
export default PlayPenEditor;
