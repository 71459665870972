import React, {useEffect, useState} from 'react';
import MemeMakerHeader from "../../assets/global/MemeMaker/MemeMakerHeader";
import {Box} from "@mui/material";
import {useNavigate, useSearchParams} from "react-router-dom";
import GifEditForm from "./GifEditForm";
import GifEditItem from "./GifEditItem";

const GifEdit = () => {
    const [gifCaptions, setGifCaptions] = useState([{
        id: 1,
        text: 'Enter Your Text',
        color: '#ffffff',
        size: 35,
        position_x: 0,
        position_y: 0,
    },
        {
            id: 2,
            text: 'Enter Your Text',
            color: '#ffffff',
            size: 35,
            position_x: 0,
            position_y: 0,
        }]);
    let [searchParams, setSearchParams] = useSearchParams();
    let imgURL = searchParams.get('url');
    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1)
    }

    const textPosition = (index, position) => {
        let xAxis = position?.x ? position?.x : 9;
        let yAxis = position?.y ? position?.y : 10;
        setGifCaptions((prevFields) => {
            const updatedFields = [...prevFields];
            updatedFields[index].position_x = xAxis;
            updatedFields[index].position_y = yAxis;
            return updatedFields;
        });
    }


    return (
        <Box sx={{
            margin: '20px 10px'
        }}>
            <MemeMakerHeader
                title={'meme-edit'}
                handleBack={handleBack}
            />
            <Box sx={{display: 'flex', minHeight: '70vh', p: 3, gap: '30px'}}>
                <Box sx={{flexBasis: '750px'}}>
                    <GifEditForm gifCaptions={gifCaptions} setGifCaptions={setGifCaptions}/>
                </Box>
                <Box sx={{flex: 1, padding: '0px 0px 10px 10px'}}>
                    <GifEditItem imageUrl={imgURL} gifCaptions={gifCaptions} setGifCaptions={setGifCaptions} textPosition={textPosition}/>
                </Box>
            </Box>
        </Box>
    );
}

export default GifEdit;