import React, {useEffect, useMemo, useState} from 'react';
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {CircularProgress, Stack} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import Box from '@mui/material/Box';
import {useTheme} from '@mui/material/styles';
import {addUserPaymentCard, resetUserPaymentCardState} from '../../state/features/userPayment/userPaymentSlice';
import CoreTextField from '../../assets/global/TextField/CoreTextField';
import CoreButton from '../../assets/global/Button/CoreButton';
import '../../assets/global/css/payment-card.css';

const useOptions = () => {
    const fontSize = '14px';
    const options = useMemo(
        () => ({
            hidePostalCode: true,
            style: {
                base: {
                    fontSize,
                    color: '#424770',
                    letterSpacing: '0.025em',
                    fontFamily: 'Source Code Pro, monospace',
                    '::placeholder': {
                        color: '#aab7c4'
                    }
                },
                invalid: {
                    color: '#9e2146'
                }
            }
        }),
        [fontSize]
    );

    return options;
};

const StripeForm = ({onClosePaymentForm}) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const {isAdding, isAddCard} = useSelector(state => state.userPayment);
    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();

    const [holderName, setHolderName] = useState('');
    const handleChangeHolderName = e => {
        setHolderName(e.target.value);
    };

    const handleSubmit = async event => {
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        const payload = await stripe.createToken(elements.getElement(CardElement));

        if (payload.token) {
            const data = {
                token: payload.token.id,
                card_number: payload.token.card ? payload.token.card.last4 : '',
                card_holder_name: holderName
            };

            dispatch(addUserPaymentCard(data));
        }

        //    'token' => 'required|string',
        //    'card_number' => 'required|numeric',
    };

    useEffect(() => {
        if (isAddCard) {
            if (onClosePaymentForm) {
                onClosePaymentForm();
            }
        }
        return () => dispatch(resetUserPaymentCardState());
    }, [isAddCard]);

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <CoreTextField
                    sx={{margin: '24px 0 16px 0'}}
                    fullWidth
                    size={'small'}
                    label={'Holder Name'}
                    name={'holder_name'}
                    onChange={handleChangeHolderName}
                />
                <Box
                    sx={{
                        '& .StripeElement': {
                            backgroundColor: theme.palette.background.paper,
                            border: '1px solid ' + theme.palette.action.hover
                        }
                    }}
                >
                    <label className={'stripe_input_wrapper'}>
                        {/*Card details*/}
                        <CardElement
                            options={options}
                            onReady={() => {
                                console.log('CardElement [ready]');
                            }}
                            onChange={event => {
                                console.log('CardElement [change]', event);
                            }}
                            onBlur={() => {
                                console.log('CardElement [blur]');
                            }}
                            onFocus={() => {
                                console.log('CardElement [focus]');
                            }}
                        />
                    </label>
                </Box>

                <Stack justifyContent={'flex-end'} direction={'row'} spacing={3} sx={{mt: 4}}>
                    <CoreButton onClick={onClosePaymentForm} variant={'outlined'} size={'large'}>
                        Cancel
                    </CoreButton>

                    <CoreButton
                        variant={'contained'}
                        color={'secondary'}
                        size={'large'}
                        type="submit"
                        disabled={!stripe || isAdding}
                        startIcon={isAdding && <CircularProgress size={16} color={'inherit'}/>}
                    >
                        {isAdding ? 'Processing' : 'Add Card'}
                    </CoreButton>
                </Stack>
            </form>
        </div>
    );
};

export default StripeForm;
