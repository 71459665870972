import React from 'react';
import {Box, Grid, Paper} from '@mui/material';
import {AvailableBalance} from './AvailableBalance';
import {PaymentMethod} from './PaymentMethod';
import {useTheme} from '@mui/material/styles';
import Styles from './Styles/AddBilling.module.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PaymentsIcon from '@mui/icons-material/Payments';
import PropTypes from 'prop-types';
import TopUp from './TopUp';
import BalanceRecharge from './BalanceRecharge';
import BalanceHistory from './BalanceHistory';
import BillingHistory from './BillingHistory';
import Billing from './Billing';

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

function BasicTab() {
    const [value, setValue] = React.useState(0);
    const theme = useTheme();
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{width: '100%', mt: 3}}>
            <Paper
                elevation={0}
                sx={{
                    borderBottom: 1,
                    borderColor: 'rgba(148, 157, 178, 0.12)',
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    '& .MuiTabs-flexContainer': {
                        '& button': {
                            marginRight: '16px !important',
                            padding: '18px 22px',
                            minHeight: '74px',
                            '@media screen and (max-width: 1023px)': {
                                padding: '18px 19px'
                            }
                        }
                    }
                }}
            >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    sx={{
                        '& .Mui-selected': {
                            color: theme.palette.secondary
                        },
                        '& .MuiTabs-indicator': {
                            backgroundColor: theme.palette.secondary
                        }
                    }}
                >
                    <Tab
                        sx={{color: theme.palette.text.secondary}}
                        icon={<AccountBalanceWalletIcon/>}
                        iconPosition="start"
                        label="Topup"
                        {...a11yProps(0)}
                    />
                    <Tab
                        sx={{color: theme.palette.text.secondary}}
                        icon={<ExitToAppIcon/>}
                        iconPosition="start"
                        label="Balance Recharge"
                        {...a11yProps(1)}
                    />
                    <Tab
                        sx={{color: theme.palette.text.secondary}}
                        icon={<CreditCardIcon/>}
                        iconPosition="start"
                        label="Balance  History"
                        {...a11yProps(2)}
                    />
                    <Tab
                        sx={{color: theme.palette.text.secondary}}
                        icon={<PaymentsIcon/>}
                        iconPosition="start"
                        label="Billing History"
                        {...a11yProps(3)}
                    />
                </Tabs>
            </Paper>
            <Paper
                elevation={0}
                sx={{
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    minHeight: '30vh'
                }}
            >
                <TabPanel value={value} index={0}>
                    <TopUp/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <BalanceRecharge/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <BalanceHistory/>
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <BillingHistory/>
                </TabPanel>
            </Paper>
        </Box>
    );
}

const UserBilling = () => {
    return (
        <Box
            sx={{
                width: '100%',
                height: '90vh',
                backgroundColor: '#f3f4f6',
                overflow: 'auto',
                padding: '10px'
            }}
        >
            <Box>
                <Grid spacing={3} container className={Styles.add_billing_wrapper}>
                    <Billing/>
                    <AvailableBalance/>
                </Grid>
                <Grid pt={'24px'} spacing={3} container className={Styles.add_billing_wrapper}>
                    <PaymentMethod/>
                </Grid>
                <BasicTab/>
            </Box>
        </Box>
    );
};

export default UserBilling;
