import React from 'react';
import {Box, Stack} from '@mui/material';
import Typography from '@mui/material/Typography';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SaveIcon from '@mui/icons-material/Save';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const PresetTemplateDetailsHistory = () => {
    return (
        <Box>
            <Typography sx={{color: 'rgb(55 65 81)', fontSize: '18px', fontWeight: 500, mb: 3}}>History</Typography>
            
            {[1, 2, 3, 4].map(item => (
                <>
                    <Box sx={{backgroundColor: '#fff', margin: '10px 0px', p: 2, borderRadius: '5px'}}>
                        <Stack direction="row" justifyContent="space-between" mb={2}>
                            <Stack direction="row" spacing={2} alignItems="center" sx={{cursor: 'pointer'}}>
                                <Box sx={{p: '2px', bgcolor: '#f0fdf4', borderRadius: 1, height: "28px", width: "28px"}}>
                                    <LinkedInIcon />
                                </Box>

                                <Typography fontSize={16} fontWeight={500} sx={{color: '#374151', mb: .7}}>Paragraph Writer</Typography>

                                <Typography fontSize={12} color="#9ca3af">2 days ago</Typography>
                            </Stack>
                                                 
                            <Typography fontSize={12} sx={{color: '#9ca3af', mb: .7}}>127 words</Typography>                         
                        </Stack>  

                        <Typography>
                            Hey guys, can we take a moment to appreciate the little things that make us happy?
                            Whether it's the warmth of the sun on our skin, the laughter of our loved ones, or the
                            taste of our favorite meal, happiness is truly all around us. Let's make sure to
                            cherish these moments and hold onto them for whenever we need a little boost!
                            Personally, I find so much joy in spending time with my pets, taking long walks in
                            nature, and chatting with my best friends over a cup of coffee. These moments may seem
                            small, but they add up and make life so much more meaningful. So, let's spread some
                            positivity and share our own happy moments in the comments below! Let's make this
                            world a brighter and happier place for ourselves and those around us. ❤️
                        </Typography>

                        <Box sx={{display: 'flex', justifyContent: 'space-between', marginTop: '20px'}}>
                            <SaveIcon sx={{cursor: 'pointer'}} />
                            <ContentCopyIcon sx={{cursor: 'pointer'}}/>
                        </Box>
                    </Box>
                </>
            ))}
        </Box>
    );
};

export default PresetTemplateDetailsHistory;
