import React, {useEffect, useState} from 'react';
import {Box, Divider, FormControl, InputLabel, MenuItem, Paper, Select, Typography} from '@mui/material';
import StylesBillingHistory from './Styles/BillingHistory.module.css';
import DataTable from 'react-data-table-component';
import {useTheme} from '@mui/material/styles';
import './Styles/BillingHistory.css';
import {useDispatch, useSelector} from 'react-redux';
import NoBillingImage from '../../assets/images/Vectors/lady-seat.svg';
import BillingTransactionDetailsModalContent from './BillingTransactionDetailsModalContent';
import {getUserBillingHistory} from '../../state/features/userBilling/userBillingSlice';
import {invoiceForConstant} from '../../helpers/utility/invoiceForConstant';
import {isInvoiceAmountCurrency} from '../../helpers/utility/isInvoiceAmountCurrency';
import {currencyFormat} from '../../helpers/utility/currencyFormat';
import CoreButton from '../../assets/global/Button/CoreButton';
import {
    USER_INVOICE__FOR_EMAIL_SEND,
    USER_INVOICE__FOR_LOCATION_REVIEW_REQUEST,
    USER_INVOICE__FOR_PACKAGE_BUY,
    USER_INVOICE__FOR_PACKAGE_FREE,
    USER_INVOICE__FOR_RECHARGE_TOP_UP_AMOUNT,
    USER_INVOICE__FOR_SMS_SEND
} from '../../helpers/constant/coreConstant';
import TableSkeletonLoader from '../../assets/global/SkeletonLoader/components/TableSkeletonLoader';
import EmptyPage from '../../assets/global/EmptyComponent/EmptyPage';
import BasicDrawer from '../../assets/global/Drawer/BasicDrawer';

const BillingHistory = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [selectedInvoiceObj, setSelectedInvoiceObj] = useState(null);
    const {billingHistory, page, perPage, total, loading} = useSelector(state => state.userBilling);
    const [billingHistorySearchQuery, setBillingHistorySearchQuery] = useState({
        name: '',
        invoice_for: '',
        status: null
    });

    const handleTransactionDetails = row => {
        setSelectedInvoiceObj(row);
        setIsDrawerOpen(true);
    };

    const toggleDrawerOpen = () => {
        setIsDrawerOpen(false);
    };

    const handleChangeBillingHistory = event => {
        setBillingHistorySearchQuery({...billingHistorySearchQuery, invoice_for: event.target.value});
    };

    const handleSearch = query => {
        setBillingHistorySearchQuery({...billingHistorySearchQuery, name: query});
    };

    useEffect(() => {
        dispatch(getUserBillingHistory({extra: {...billingHistorySearchQuery}}));
    }, [billingHistorySearchQuery]);

    const onChangePerPage = (perPage, newPage) => {
        dispatch(
            getUserBillingHistory({
                page: newPage,
                per_page: perPage,
                extra: {...billingHistorySearchQuery}
            })
        );
    };

    const onChangePage = newPage => {
        dispatch(
            getUserBillingHistory({
                page: newPage,
                per_page: perPage,
                extra: {...billingHistorySearchQuery}
            })
        );
    };
    const Columns = [
        {
            minWidth: '156px',
            name: (
                <Typography className="truncate" color="text.primary" variant="TableHeader">
                    Invoice For
                </Typography>
            ),
            selector: row => {
                return (
                    <Typography
                        className="truncate global__table__body__font"
                        color="text.primary"
                        variant={'body2'}
                    >
                        {invoiceForConstant(row.invoice_for)}
                    </Typography>
                );
            }
        },
        {
            minWidth: '156px',
            name: (
                <Typography className="truncate" color="text.primary" variant="TableHeader">
                    Card Number
                </Typography>
            ),
            selector: row => {
                return (
                    <Typography
                        className="truncate global__table__body__font"
                        color="text.primary"
                        variant={'body2'}
                    >
                        {row.card_number ? '**** **** **** ' + row.card_number : ''}
                    </Typography>
                );
            }
        },
        {
            minWidth: '156px',
            name: (
                <Typography className="truncate" color="text.primary" variant="TableHeader">
                    Amount
                </Typography>
            ),
            selector: row => {
                let paymentAmount = 0;
                if (isInvoiceAmountCurrency(row.invoice_for)) {
                    paymentAmount = '$' + currencyFormat(row.payment_amount);
                } else paymentAmount = row.payment_amount;

                return (
                    <Typography
                        className="truncate global__table__body__font"
                        color="text.primary"
                        variant={'body2'}
                    >
                        {paymentAmount}
                    </Typography>
                );
            },
            sortable: false
        },
        {
            minWidth: '202px',
            name: (
                <Typography className="truncate" color="text.primary" variant="TableHeader">
                    Billing Date/ Time
                </Typography>
            ),
            selector: row => {
                return (
                    <Typography
                        className="truncate global__table__body__font"
                        color="text.primary"
                        variant={'body2'}
                    >
                        {row.local_created_at && row.local_created_at.formatted_time}
                    </Typography>
                );
            }
        },
        {
            minWidth: '80px',
            name: (
                <Typography className="truncate" color="text.primary" variant="TableHeader">
                    Transaction Details
                </Typography>
            ),
            selector: row => {
                return (
                    <CoreButton
                        color={'secondary'}
                        variant={'contained'}
                        size={'large'}
                        onClick={e => handleTransactionDetails(row)}
                    >
                        View Details
                    </CoreButton>
                );
            }
        }
    ];

    return (
        <>
            <Paper elevation={0}>
                <Box className={`${StylesBillingHistory.billing__history}`}>
                    <Box className={StylesBillingHistory.billing__history__left}>
                        <Typography variant="h6">Billing History</Typography>
                    </Box>

                    <Box className={StylesBillingHistory.billing__history__right}>
                        {/*<FormControl className={StylesBillingHistory.billing__history__search}>*/}
                        {/*    <TextField*/}
                        {/*        size='small'*/}
                        {/*        InputProps={{*/}
                        {/*            endAdornment: (*/}
                        {/*                <InputAdornment position='end'>*/}
                        {/*                    {billingHistorySearchQuery.name.toString().length === 0 ? (*/}
                        {/*                        <Search fontSize='small' />*/}
                        {/*                    ) : (*/}
                        {/*                        <CloseIcon*/}
                        {/*                            sx={{ cursor: "pointer", "&:hover": { color: "#4b6193" } }}*/}
                        {/*                            onClick={() => handleSearch("")}*/}
                        {/*                            fontSize='small'*/}
                        {/*                        />*/}
                        {/*                    )}*/}
                        {/*                </InputAdornment>*/}
                        {/*            ),*/}
                        {/*            position: "end",*/}
                        {/*        }}*/}
                        {/*        placeholder='Search on billing history'*/}
                        {/*        value={billingHistorySearchQuery.name}*/}
                        {/*        onChange={(event) => {*/}
                        {/*            handleSearch(event.target.value);*/}
                        {/*        }}*/}
                        {/*    />*/}
                        {/*</FormControl>*/}
                        <FormControl
                            required
                            size="small"
                            fullWidth
                            className={StylesBillingHistory.billing__history__select}
                        >
                            <InputLabel id="demo-simple-select-label">All</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={billingHistorySearchQuery.invoice_for}
                                label="All"
                                onChange={handleChangeBillingHistory}
                            >
                                <MenuItem value={''}>All</MenuItem>
                                <MenuItem value={USER_INVOICE__FOR_PACKAGE_BUY}>
                                    {invoiceForConstant(USER_INVOICE__FOR_PACKAGE_BUY)}
                                </MenuItem>
                                <MenuItem value={USER_INVOICE__FOR_PACKAGE_FREE}>
                                    {invoiceForConstant(USER_INVOICE__FOR_PACKAGE_FREE)}
                                </MenuItem>
                                <MenuItem value={USER_INVOICE__FOR_SMS_SEND}>
                                    {invoiceForConstant(USER_INVOICE__FOR_SMS_SEND)}
                                </MenuItem>
                                <MenuItem value={USER_INVOICE__FOR_EMAIL_SEND}>
                                    {invoiceForConstant(USER_INVOICE__FOR_EMAIL_SEND)}
                                </MenuItem>
                                <MenuItem value={USER_INVOICE__FOR_LOCATION_REVIEW_REQUEST}>
                                    {invoiceForConstant(USER_INVOICE__FOR_LOCATION_REVIEW_REQUEST)}
                                </MenuItem>
                                <MenuItem value={USER_INVOICE__FOR_RECHARGE_TOP_UP_AMOUNT}>
                                    {invoiceForConstant(USER_INVOICE__FOR_RECHARGE_TOP_UP_AMOUNT)}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                <Divider/>

                <Box
                    className="balance__history__table"
                    sx={{
                        pt: 2,
                        '& .rdt_TableHeadRow': {
                            backgroundColor: 'rgba(148, 157, 178, 0.24)',
                        },
                        '& .rdt_TableBody': {
                            '& .rdt_TableRow': {
                                borderBottomColor: 'rgba(148, 157, 178, 0.24)',
                                backgroundColor: theme.palette.background.paper
                            }
                        },
                        '& .rdt_Pagination': {
                            backgroundColor: theme.palette.background.paper
                        }
                    }}
                >
                    <DataTable
                        theme={theme.palette.mode === 'dark' ? 'dark' : ''}
                        persistTableHead
                        columns={Columns}
                        data={billingHistory}
                        pagination={true}
                        paginationServer
                        progressPending={loading}
                        progressComponent={<TableSkeletonLoader columns={Columns} numberOfRows={6}/>}
                        noDataComponent={
                            <EmptyPage
                                img={NoBillingImage}
                                title={'No Billing History Found!'}
                                subTitle={"You don't have any billing history"}
                            />
                        }
                        // paginationPerPage={}
                        paginationTotalRows={total}
                        paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100]}
                        onChangeRowsPerPage={onChangePerPage}
                        onChangePage={onChangePage}
                    />
                    <BasicDrawer
                        open={isDrawerOpen}
                        title={'Transaction Details'}
                        toggleDrawer={toggleDrawerOpen}
                    >
                        <BillingTransactionDetailsModalContent invoiceDetails={selectedInvoiceObj}/>
                    </BasicDrawer>
                </Box>
            </Paper>
        </>
    );
};

export default BillingHistory;
