import React, {useEffect, useRef, useState} from 'react';
import {Box, Button, Card, CardActions, CardMedia, Stack, Typography} from '@mui/material';
import {makeStyles} from "@mui/styles";
import TextDraggable from './TextDraggable';
import DownloadIcon from "@mui/icons-material/Download";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import * as htmlToImage from "html-to-image";
import {showErrorToaster, showSuccessToaster} from "../../helpers/utility/toaster";
import PromptCollectionForm from "../prompt/PromptCollectionForm";
import FullPageDrawer from "../../assets/global/Drawer/FullPageDrawer";
import {createImageCollection} from "../../state/features/promptCollection/promptCollectionSlice";
import {useDispatch} from "react-redux";
import BalanceCheckService from "../../services/BalanceCheck.service";
import {
    creditSettingsImageContent256x256,
    PACKAGE_LIMIT__BALANCE__KEY__CREDIT_AMOUNT
} from "../../helpers/constant/coreConstant";
import {getSpecificBalanceData} from "../../state/features/userBalance/userBalanceSlice";

const EditItem = ({imageUrl, formFields, header, footer}) => {
    const dispatch = useDispatch();
    const imageRef = useRef(null);
    const [viewDetailsPageShow, setViewDetailsPageShow] = useState(false);

    const useStyles = makeStyles({
        card: {
            width: '650px', // Set the desired fixed width
            height: 'auto', // Adjust the height as per your requirements
            margin: '0 auto', // Center the card horizontally
            // Add any other styles you need
        },

        cardActions: {
            justifyContent: 'center',
            alignItems: 'center',
        }
    });

    const toggleViewDetailsPage = () => setViewDetailsPageShow(prevState => !prevState);

    const handleViewDetailsPage = data => {
        toggleViewDetailsPage();
    };

    function convertBase64ToImage(base64String, outputFormat, data) {
        // Create a new Image object
        var img = new Image();

        // When the image is loaded, create a canvas and draw the image on it
        img.onload = function () {
            var canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;

            var ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);

            // Convert the canvas content to the specified output format
            var dataURL = canvas.toDataURL('image/' + outputFormat);


            const payload = {};
            payload.collection_id = data.collection_id || '';
            payload.type = 2;
            payload.text = "Image";
            payload.response = dataURL;
            dispatch(createImageCollection(payload));
        };

        // Set the source of the image to the Base64 string
        img.src = base64String;
    }

    const handlePromptCollectionSubmit = data => {
        var node = document.getElementById('my-node');
        htmlToImage.toPng(node, {quality: 0.95}).then(function (dataUrl) {
            const payload = {};
            payload.collection_id = data.collection_id || '';
            payload.type = 2;
            payload.text = "Image";
            payload.response = dataUrl;
            convertBase64ToImage(dataUrl, 'jpeg', data);
            // dispatch(createImageCollection(payload));
        });
    };
    const handleDownload = (imageUrl) => {
        if (imageUrl) {
            BalanceCheckService.getBalance({content_type: creditSettingsImageContent256x256, amount_data: 500})
                .then((response) => {
                    if (response.success) {
                        var node = document.getElementById('my-node');
                        htmlToImage.toPng(node, {quality: 0.95}).then(function (dataUrl) {
                            var link = document.createElement('a');
                            link.download = 'meme.jpeg';
                            link.href = dataUrl;
                            link.click();
                        });
                        BalanceCheckService.reduceBalance({
                            content_type: creditSettingsImageContent256x256,
                            amount_data: 500
                        });
                        dispatch(getSpecificBalanceData({key: PACKAGE_LIMIT__BALANCE__KEY__CREDIT_AMOUNT}));
                    } else {
                        showErrorToaster('Insufficient balance')
                    }
                })
                .catch((err) => console.log(err));
        } else {
            showErrorToaster("There is no meme to download!");
        }
    }

    const handleCopy = () => {
        var node = document.getElementById('my-node');
        htmlToImage.toPng(node, {quality: 0.95}).then(function (dataUrl) {
            console.log(dataUrl);
            fetch(dataUrl)
                .then((res) => res.blob())
                .then((blob) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        const dataUrl = reader.result;
                        const item = new ClipboardItem({'image/png': blob});
                        navigator.clipboard.write([item]);
                        showSuccessToaster('Image copied');
                    };
                    reader.readAsDataURL(blob);
                })
                .catch((err) => console.error(err));
        });
    }

    useEffect(() => {
        if (header) {
            return 'translate(-50%, 55%)';
        } else
            return () => {
                // Code to run before component unmounts (cleanup)
            };
    }, []); // Dependency array


    const classes = useStyles();

    return (
        <>
            <Card className={classes.card}>
                <Box ref={imageRef} id="my-node" sx={{position: 'relative', width: '100%', height: '100%'}}>
                    {header && (
                        <Box
                        >
                            <Typography
                                sx={{
                                    color: 'rgb(0, 0, 0)',
                                    fontFamily: 'Inter, system-ui, sans-serif, Arial, Helvetica',
                                    fontSize: '34px',
                                    padding: '0px 10px',
                                    textAlign: 'justify',
                                    backgroundColor: 'white',
                                    letterSpacing: '0.2rem',
                                    margin: '0',
                                    fontWeight: 'bold',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {header}
                            </Typography>
                        </Box>
                    )}

                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        {formFields.map((field, index) => (
                            header ? (
                                <TextDraggable key={index}
                                               text={field.value}
                                               size={field.size}
                                               color={field.color}
                                    // transform={index === 0 ? 'translate(-50%, 80%)' : 'translate(-50%, 630px)'}
                                               top={index === 0 ? '0' : '100'}
                                               bottom={index === 0 ? '100' : '0'}
                                               id={'text' + index}
                                />
                            ) : (
                                <TextDraggable key={index}
                                               text={field.value}
                                               size={field.size}
                                               color={field.color}
                                               top={index === 0 ? '0' : '100'}
                                               bottom={index === 0 ? '100' : '0'}
                                               id={'text' + index}
                                />
                            )
                        ))}
                        <CardMedia component="img" height="650" image={imageUrl} style={{overflow: 'hidden'}}
                                   alt="Image"/>
                    </Box>

                    {footer && (
                        <Box
                        >
                            <Typography
                                sx={{
                                    color: 'rgb(0, 0, 0)',
                                    fontFamily: 'Inter, system-ui, sans-serif, Arial, Helvetica',
                                    fontSize: '34px',
                                    padding: '0px 10px',
                                    textAlign: 'justify',
                                    backgroundColor: 'white',
                                    letterSpacing: '0.2rem',
                                    margin: '0',
                                    fontWeight: 'bold',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {footer}
                            </Typography>
                        </Box>
                    )}
                </Box>
                <CardActions className={classes.cardActions}>
                    <Stack
                        direction={'row'}
                        spacing={1}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <Button startIcon={<DownloadIcon/>} variant="contained" onClick={handleDownload}>
                            Download
                        </Button>

                        <Button startIcon={<ContentCopyIcon/>} variant="outlined" onClick={handleCopy}>
                            Copy
                        </Button>

                        <Button startIcon={<StarBorderIcon/>} variant="outlined"
                                onClick={() => handleViewDetailsPage()}>
                            Save
                        </Button>
                    </Stack>
                </CardActions>
            </Card>

            <FullPageDrawer
                title="Save Image collection"
                open={viewDetailsPageShow}
                toggleDrawer={toggleViewDetailsPage}
                width="35%"
                height="60%"
            >
                <PromptCollectionForm
                    helperKey={'IMAGE'}
                    toggleDrawer={toggleViewDetailsPage}
                    handlePromptCollectionSubmit={handlePromptCollectionSubmit}
                />
            </FullPageDrawer>
        </>
    );
};

export default EditItem;
