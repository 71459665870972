import {requests} from "./http.node.service";

class PlayPenService {
    rewriteText(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_NODE_SERVER + "/playpen/re/write/text", body);
    }

    writeMoreText(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_NODE_SERVER + "/playpen/write/more/text", body);
    }
}

export default new PlayPenService();
