import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Box} from '@mui/material';
import PromptLeft from './PromptLeft';
import PromptRight from './PromptRight';
import {
    getGeneratedResponse,
    getSpecificPromptTemplate,
    setTemplatePreviewText
} from '../../state/features/prompts/promptSlice';
import {selectPromptState} from '../../state/features/prompts/promptSelector';
import {useParams} from "react-router-dom";

const PromptDetailsTwo = () => {

    let { promptId } = useParams();

    const dispatch = useDispatch();

    const {specificTemplate} = useSelector(selectPromptState);


    useEffect(() => {
        dispatch(getSpecificPromptTemplate({id: promptId}));
    }, []);

    useEffect(() => {
        dispatch(setTemplatePreviewText({prompt: specificTemplate?.description}));
    }, [dispatch,specificTemplate]);


    return (
        <>
            <Box sx={{display: 'flex', minHeight: '70vh',p:3, gap:'30px'}}>
                <Box sx={{flexBasis: '350px'}}>
                    <PromptLeft specificTemplateData={specificTemplate}/>
                </Box>

                <Box sx={{flex: 1, padding: '0px 0px 10px 10px'}}>
                    <PromptRight templateDate={specificTemplate}/>
                </Box>
            </Box>
        </>
    );
};

export default React.memo(PromptDetailsTwo);
