import React, {useEffect, useState} from 'react';
import {Box, InputAdornment, OutlinedInput, Stack, Typography} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Button from "@mui/material/Button";
import LoopIcon from "@mui/icons-material/Loop";
import RandomTextGenerator from "../../assets/global/RandromTextForMemeMaker/RandomTextGenerator";
import ToggleSwitch from "./ToggleSwitch";
import {useForm} from "react-hook-form";

const TextToMemeSearchBar = ({searchValue, handleTextToMeme}) => {
    const [memeSearchValue, setMemeSearchValue] = useState('');
    const [selectItem, setSelectItem] = useState('');
    const {handleSubmit, control} = useForm();


    useEffect(() => {
        searchValue(memeSearchValue, selectItem);
    }, [memeSearchValue, selectItem]);

    const generatedRandomText = (value) => {
        setMemeSearchValue(value)
    }
    const searchImageOrMeme = (value) => {
        setSelectItem(value);
    }


    return (
        <>
            <Stack
                direction="row"
                alignItems={'start'}
                component="form"
                onSubmit={handleSubmit(handleTextToMeme)}
                noValidate
                autoComplete="off"
            >
                {/*<PresetTemplateSearch/>*/}
                <Stack
                    sx={{
                        flex: 1,
                        transition: 'all 0.3s ease',
                        position: 'relative',
                        marginTop: '40px'
                    }}
                >
                    <OutlinedInput
                        fullWidth
                        id="send"
                        size="small"
                        sx={{
                            color: '#222',
                            border: 'none',
                            boxShadow: '0 4px 24px 0 rgba(34, 41, 47, 0.1)',
                            borderTopRightRadius: '0 ',
                            borderBottomRightRadius: '0 ',
                            '&.MuiInputBase-root': {
                                backgroundColor: '#fff',
                                position: 'absolute',
                                bottom: '0'
                            }
                        }}
                        placeholder="Search"
                        value={memeSearchValue}
                        onChange={e => setMemeSearchValue(e.target.value)}
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchIcon sx={{fontSize: 20}}/>
                            </InputAdornment>
                        }
                    />
                </Stack>
                <Button
                    variant="contained"
                    size="small"
                    sx={{
                        py: 1,
                        px: 2,
                        borderTopLeftRadius: '0',
                        borderBottomLeftRadius: '0'
                    }}
                    onClick={e => handleTextToMeme(e)}
                    disableElevation
                >
                    <LoopIcon sx={{mr: 1}}/>
                    Generate
                </Button>
            </Stack>

            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <Box>
                    <RandomTextGenerator generatedRandomText={generatedRandomText}/>
                </Box>
                <Stack direction={'row'} alignItems={'center'} spacing={10}>
                    <ToggleSwitch searchImageOrMeme={searchImageOrMeme}/>
                    <Typography component={'p'} variant="caption" sx={{color: 'text.secondary'}}>
                        0/300
                    </Typography>
                </Stack>
            </Stack>
        </>
    );
};

export default TextToMemeSearchBar;
