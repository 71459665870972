import * as React from 'react';
import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import {Controller, useForm} from 'react-hook-form';
import {FormHelperText, InputLabel, Select, styled, TextareaAutosize} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import {contentRepurposingPattern} from '../../helpers/constant/validationPattern';
import {useDispatch, useSelector} from 'react-redux';
import {
    contentRepurposing,
    getContentRepurposingInputOutputList,
    contentRepurposingStateClear
} from '../../state/features/contentRepurposing/contentRepurposingSlice';
import PlayPenEditor from '../playpen/PlayPenEditor';
import {selectContentRepurposingState} from '../../state/features/contentRepurposing/contentRepurposingSelector';
import FeatureInput from '../common/featureInput/FeatureInput';
import FeatureOutput from '../common/featureOutput/FeatureOutput';
import FeatureOutputNoResponse from '../common/featureOutput/FeatureOutputNoResponse';
import BalanceCheck from "../../assets/global/BalanceCheck/BalanceCheck";
import {creditSettingSTextContent} from "../../helpers/constant/coreConstant";

const StyledTextArea = styled(TextareaAutosize)(({theme, invalid}) => ({
    width: '100%',
    padding: '10px',
    border: `1px solid ${invalid ? theme.palette.error.main : theme.palette.grey[400]}`,
    borderRadius: '4px',
    boxShadow: '0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(0,0,0,.05)',
    '&:focus-visible': {
        outlineColor: '#1976d2'
    },
    '& .MuiInputBase-input': {
        color: 'inherit'
    },
    '&::placeholder': {
        color: invalid ? theme.palette.error.main : theme.palette.grey[400]
    }
}));

const ImageRepurposing = () => {
    const dispatch = useDispatch();
    const [inputList, setInputList] = useState([]);
    const [outputList, setOutputList] = useState([]);
    const [inputTypeState, setPreviousInputTypeState] = useState(3);

    const {inputOutputList, contentRepurposingData, isLoading} = useSelector(
        selectContentRepurposingState
    );
    const inputListArray = [];
    const outputListArray = [];

    const {
        handleSubmit,
        control,
        reset,
        formState: {errors},
        clearErrors
    } = useForm({
        defaultValues: {
            input_type: '',
            output_type: '',
            data: ''
        }
    });

    const handleInputTypeChange = e => {
        const {value} = e.target;
        setPreviousInputTypeState(value);
        clearErrors(['data']);
    };

    const getPlaceholderText = inputType => {
        let placeholder = 'Enter youtube/website url or text *';
        if (inputType === 1) {
            placeholder = 'Enter youtube url  *';
        } else if (inputType === 2) {
            placeholder = 'Enter website url  *';
        }  else {
            placeholder = 'Enter youtube/website url or text *';
        }
        return placeholder;
    };
    const getDataComponent = inputType => {
        let rule = null;

        if (inputType === 1) {
            rule = contentRepurposingPattern.youtubeUrl;
        } else if (inputType === 2) {
            rule = contentRepurposingPattern.websitebeUrl;
        } else {
            rule = contentRepurposingPattern.text;
        }

        return (
            <Controller
                control={control}
                name="data"
                defaultValue=""
                render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                    <FormControl fullWidth error={!!(invalid && error.message)}>
                        <StyledTextArea
                            minRows={4}
                            // placeholder={invalid && error.message ? `Enter youtube/website url or text *` : 'Enter youtube/website url or text'}
                            placeholder={getPlaceholderText(inputType)}
                            value={value}
                            onChange={value => {
                                onChange(value);
                            }}
                            invalid={invalid}
                        />
                        <FormHelperText>{invalid && error.message}</FormHelperText>
                    </FormControl>
                )}
                rules={rule}
            />
        );
    };

    useEffect(() => {
        dispatch(getContentRepurposingInputOutputList());
    }, [dispatch]);

    useEffect(() => {
        if (inputOutputList) {
            for (const key in inputOutputList) {
                if (inputOutputList.hasOwnProperty(key)) {
                    const item = inputOutputList[key];
                    if (item.type === 1) {
                        inputListArray.push(item);
                    } else if (item.type === 2) {
                        outputListArray.push(item);
                    }
                }
            }

            setInputList(inputListArray);
            setOutputList(outputListArray);
        }
    }, [inputOutputList]);

    const handleClearInput = () => {
        reset();
    };

    const onSubmit = data => {
        // return false;
        dispatch(
            contentRepurposing({
                data: data.data,
                input_type: data.input_type,
                output_type: data.output_type
            })
        );
    };

    useEffect(() => {
       return () => dispatch(contentRepurposingStateClear());
    },[]);

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    height: '90vh',
                    paddingTop: '50px',
                    backgroundColor: '#f3f4f6',
                    overflow: 'auto'
                }}
            >
                <FeatureInput
                    headerTitle={'Content Repurposing'}
                    helpkey={'REPURPOSING'}
                    subtitle="Create new content from existing content"
                    footer={{generate: handleSubmit(onSubmit), isLoading, handleClearInput}}
                >
                    <Box component="form">
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Controller
                                    control={control}
                                    name="input_type"
                                    defaultValue=""
                                    render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                        <FormControl size="small" fullWidth error={!!(invalid && error.message)}>
                                            <InputLabel id="collection-label" required>
                                                Input
                                            </InputLabel>
                                            <Select
                                                labelId="input-label"
                                                id="input-select"
                                                value={value}
                                                label="Input"
                                                onChange={e => {
                                                    onChange(e);
                                                    handleInputTypeChange(e);
                                                }}
                                            >
                                                {inputList.length &&
                                                    inputList.map((input, index) => (
                                                        <MenuItem key={index} value={input.id}>
                                                            {input.text}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                            <FormHelperText>{invalid && error?.message}</FormHelperText>
                                        </FormControl>
                                    )}
                                    rules={contentRepurposingPattern.inputType}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <Controller
                                    control={control}
                                    name="output_type"
                                    defaultValue=""
                                    render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                        <FormControl size="small" fullWidth error={!!(invalid && error.message)}>
                                            <InputLabel id="collection-label" required>
                                                Output
                                            </InputLabel>
                                            <Select
                                                labelId="input-label"
                                                id="input-select"
                                                value={value}
                                                label="Output"
                                                onChange={onChange}
                                            >
                                                {outputList.map((output, index) => (
                                                    <MenuItem key={index} value={output.id}>
                                                        {output.text}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText>{invalid && error?.message}</FormHelperText>
                                        </FormControl>
                                    )}
                                    rules={contentRepurposingPattern.outputType}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                {getDataComponent(inputTypeState)}
                            </Grid>
                        </Grid>
                    </Box>
                    <BalanceCheck content_type={creditSettingSTextContent} amount_data={1000} />
                </FeatureInput>

                <FeatureOutput
                    newOutput={
                        <>
                            {!contentRepurposingData ? (
                                <FeatureOutputNoResponse/>
                            ) : (
                                <PlayPenEditor value={contentRepurposingData}/>
                            )}
                        </>
                    }
                />
            </Box>
        </>
    );
};

export default ImageRepurposing;
