import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    templateSearchData: [],
    isLoading: false,
    isError: false,
    error: ''
};

const templateSearchSlice = createSlice({
    name: 'templateSearch',
    initialState: initialState,
    reducers: {
        templateSearch: (state, action) => {
            state.isLoading = true;
        },
        templateSearchSuccess: (state, action) => {
            state.templateSearchData = action?.payload;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        templateSearchFailed: (state, action) => {
            state.templateSearchData = null;
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },
        templateSearchClearState: (state, action) => {
            state.templateSearchData = [];
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },
    }
});

export const {
    templateSearch,
    templateSearchSuccess,
    templateSearchFailed,
    templateSearchClearState,
} = templateSearchSlice.actions;

export default templateSearchSlice.reducer;
