import React, {useEffect, useState} from 'react';
import {InputAdornment, OutlinedInput, Stack} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import LoopIcon from "@mui/icons-material/Loop";

const TemplateSearchBar = ({handleGenerateTemplate}) => {
    const [templateSearchValue, setTemplateSearchValue] = useState('');

    return (
        <>
            <Stack direction="row" alignItems={'start'}>
                {/*<PresetTemplateSearch/>*/}
                <Stack
                    sx={{
                        flex: 1,
                        transition: 'all 0.3s ease',
                        position: 'relative',
                        marginTop: '40px'
                    }}
                    component="form"
                    noValidate
                    autoComplete="off"
                >
                    <OutlinedInput
                        fullWidth
                        size="small"
                        sx={{
                            color: '#222',
                            border: 'none',
                            boxShadow: '0 4px 24px 0 rgba(34, 41, 47, 0.1)',
                            borderTopRightRadius: '0 ',
                            borderBottomRightRadius: '0 ',
                            '&.MuiInputBase-root': {
                                backgroundColor: '#fff',
                                position: 'absolute',
                                bottom: '0'
                            }
                        }}
                        placeholder="Search"
                        value={templateSearchValue}
                        onChange={e => setTemplateSearchValue(e.target.value)}
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchIcon sx={{fontSize: 20}}/>
                            </InputAdornment>
                        }
                    />
                </Stack>
                <Button
                    variant="contained"
                    size="small"
                    sx={{
                        py: 1,
                        px: 2,
                        borderTopLeftRadius: '0',
                        borderBottomLeftRadius: '0'
                    }}
                    onClick={e => handleGenerateTemplate(e, templateSearchValue)}
                    disableElevation
                >
                    <LoopIcon sx={{mr: 1}}/>
                    Generate
                </Button>
            </Stack>
        </>
    );
}
export default TemplateSearchBar;