import React from 'react';
import {Box, Grid, Paper} from '@mui/material';
import {PaymentMethodItem} from './PaymentMethodItem';
import StripeIcon from '../../assets/images/integration-logos/stripe.svg';
import Styles from './Styles/PaymentMethodItem.module.css';
import {CoreCardHeader} from '../../assets/global/CardHeader/CoreCardHeader';

export const PaymentMethod = ({showPaymentForm}) => {
    return (
        <Grid className={Styles.add_billing_item} item md={12} lg={12} xl={12}>
            <Paper elevation={0} className={Styles.add_billing_item_paper}>
                <CoreCardHeader title={'Payment Methods'}/>
                <Box sx={{paddingBottom: '5px'}}>
                    <PaymentMethodItem
                        paymentMethodName={'Stripe'}
                        paymentMethodIcon={StripeIcon}
                        paymentMethodBtnText={'Add new accounts/card'}
                    />
                </Box>
            </Paper>
        </Grid>
    );
};
