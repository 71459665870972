import * as React from 'react';
import {useState} from 'react';

import {deleteCollection, updateCollection} from '../../state/features/collection/collectionSlice';

import BasicDrawer from '../../assets/global/Drawer/BasicDrawer';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CategoryForm from './CategoryForm';
import CloseModal from '../../assets/global/Modal/CloseModal';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PopHoverCommonMenu from '../../assets/global/Menu/PopHoverCommonMenu';
import PopHoverCommonMenuItem from '../../assets/global/Menu/PopHoverCommonMenuItem';
import Typography from '@mui/material/Typography';
import WarningModalContent from '../../assets/global/Modal/WarningModalContent';
import {styled} from '@mui/system';
import useBoolean from '../../hooks/useBoolean';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';

const StyledCardHeader = styled(CardHeader)(({theme}) => ({
    '.MuiCardHeader-title': {
        fontSize: '14px',
        fontWeight: '600',
        padding: '0!important'
    }
}));

export default function Folder({handleViewDetailsPage, collection, handleSelectedCollection}) {
    const dispatch = useDispatch();

    const [anchorElAction, setAnchorElAction] = useState(null);
    const [viewBasicDrawer, setViewBasicDrawer] = useState(false);
    const [selectedClientObj, setSelectedClientObj] = useState(null);
    const {
        value: openDeleteModal,
        setTrue: onOpenDeleteModal,
        setFalse: onCloseDeleteModal
    } = useBoolean(false);

    const toggleViewBasicDrawer = () => setViewBasicDrawer(prevState => !prevState);
    const handleOpenActionMenu = event => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorElAction(event.currentTarget);
    };
    const handleCloseActionMenu = event => {
        event.stopPropagation();
        setAnchorElAction(null);
    };

    const handleOpenEditModal = event => {
        event.stopPropagation();
        toggleViewBasicDrawer();
    };

    const handleDeleteConfirm = event => {
        event.stopPropagation();
        dispatch(deleteCollection({collection_id: selectedClientObj.id}));
        onCloseDeleteModal();
    };

    const handleSubmitEditCollection = data => {
        dispatch(updateCollection(data));
    };

    return (
        <>
            <Link to={`/collection/${collection.id}`} style={{textDecoration: 'none'}}>
                <Card
                    sx={{
                        maxWidth: 345,
                        backgroundColor: '#f1f2f6',
                        boxShadow: 'rgb(197 180 180) 3px 4px 10px 0px',
                        cursor: 'pointer',
                        padding: '16px',
                        ':hover': {
                            background: 'rgb(230, 230, 230)'
                        }
                    }}
                >
                    <StyledCardHeader
                        sx={{
                            padding: '0!important'
                        }}
                        avatar={
                            <CreateNewFolderIcon
                                height="30px"
                                width="30px"
                                sx={{fontSize: '30px', color: '#686de0', marginBottom: '10px'}}
                            />
                        }
                        action={
                            <>
                                <IconButton aria-label="settings" onClick={e => handleOpenActionMenu(e)}>
                                    <MoreVertIcon/>
                                </IconButton>
                                <PopHoverCommonMenu
                                    elevation={4}
                                    anchorEl={anchorElAction}
                                    open={Boolean(anchorElAction)}
                                    onClose={e => {
                                        handleCloseActionMenu(e);
                                    }}
                                >
                                    <PopHoverCommonMenuItem
                                        name="Edit"
                                        icon={<EditIcon/>}
                                        onClick={e => {
                                            setSelectedClientObj(collection);
                                            handleCloseActionMenu(e);
                                            handleOpenEditModal(e);
                                        }}
                                    />

                                    <PopHoverCommonMenuItem
                                        name="Delete"
                                        icon={<DeleteIcon/>}
                                        onClick={e => {
                                            setSelectedClientObj(collection);
                                            handleCloseActionMenu(e);
                                            onOpenDeleteModal(e);
                                        }}
                                    />
                                </PopHoverCommonMenu>
                            </>
                        }
                        // title={title}
                        // subheader={createdAt}
                    />
                    <CardContent sx={{padding: '0!important'}}>
                        <Typography
                            variant="body2"
                            color="text.primary"
                            // sx={{fontSize: '14px', fontWeight: '500', lineHeight: '1.5', color: '#222'}}
                            sx={{
                                fontFamily: 'Poppins',
                                fontSize: '14px',
                                fontWeight: '600',
                                color: 'rgb(51, 51, 51)',
                                lineHeight: '1.4',
                                borderRadius: '0px',
                                opacity: 1,
                                wordBreak: 'break-word',
                                marginBottom: '3px'
                            }}
                        >
                            {collection.name}
                        </Typography>

                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                                fontFamily: 'Poppins',
                                fontSize: '12px',
                                fontWeight: '300',
                                color: 'rgb(51, 51, 51)',
                                lineHeight: '1.4',
                                borderRadius: '0px',
                                opacity: 1,
                                wordBreak: 'break-word'
                            }}
                        >
                            <span style={{fontWeight: 'bold'}}>Collection created: </span>
                            {/*{collection.created_at && new Date(collection.created_at).toLocaleDateString()}*/}
                            {new Date(collection.created_at).toLocaleDateString('en-us', {
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric'
                            })}
                        </Typography>
                    </CardContent>
                </Card>
            </Link>

            <BasicDrawer
                title="Update Collection"
                open={viewBasicDrawer}
                toggleDrawer={toggleViewBasicDrawer}
            >
                <CategoryForm
                    isEditMood={true}
                    collectionData={selectedClientObj}
                    handleSubmitCategory={handleSubmitEditCollection}
                    handleDrawerClose={toggleViewBasicDrawer}
                />
            </BasicDrawer>

            <CloseModal open={openDeleteModal} onClose={onCloseDeleteModal}>
                <WarningModalContent
                    title={'Warning'}
                    subTitle={'Are you sure to delete this collection!!'}
                    description={'If you delete this collection, you’ll not be able to recover it again.'}
                    buttonName={'Okay, Delete'}
                    onConfirm={handleDeleteConfirm}
                    onClose={onCloseDeleteModal}
                />
            </CloseModal>
        </>
    );
}
