import React from "react";
import PackageOnBoardNavbar from "./PackageOnBoardNavbar";
import PackageOnBoardMain from "./PackageOnBoardMain";

const PackageOnBoard = () => {
    return (
        <>
            <PackageOnBoardNavbar />
            <PackageOnBoardMain />
        </>
    );
};

export default PackageOnBoard;
