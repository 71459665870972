import '../../../assets/global/css/MaxText.css';
import {Box, Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, styled} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {attemptLogout} from '../../../state/features/user/userSlice';
import {clearChatGPTState} from '../../../state/features/chatGPT/chatGPTSlice';
import {clearStorageWhenLogOut} from '../../../helpers/utility/clearStorageWhenLogOut';
import {getCategory} from '../../../state/features/category/categorySlice';
import {selectAccountSettingState} from '../../../state/features/accountSetting/accountSettingSelector';
import {selectCategoriesState} from '../../../state/features/category/collectionSelector';
import Divider from '@mui/material/Divider';
import LogoutIcon from '@mui/icons-material/Logout';
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SettingsIcon from '@mui/icons-material/Settings';
import {appSideBar} from "../../../helpers/utility/appSidebarHelper";
import {
    AI_TRANSCRIPTION_COLLAPSE_MENU,
    MEME_MAKER_COLLAPSE_MENU,
    PACKAGE_FEATURE__KEY__AUDIO,
    PACKAGE_FEATURE__KEY__BILLING,
    PACKAGE_FEATURE__KEY__COLLECTION,
    PACKAGE_FEATURE__KEY__CONTENT_REPURPOSING,
    PACKAGE_FEATURE__KEY__DASHBOARD,
    PACKAGE_FEATURE__KEY__IMAGE_CREATION,
    PACKAGE_FEATURE__KEY__IMAGE_IN_PAINTING,
    PACKAGE_FEATURE__KEY__IMAGE_TO_ALT,
    PACKAGE_FEATURE__KEY__IMAGE_TO_PROMPT,
    PACKAGE_FEATURE__KEY__IMAGE_VARIATION,
    PACKAGE_FEATURE__KEY__MEME_MAKER,
    PACKAGE_FEATURE__KEY__PLAY_PEN,
    PACKAGE_FEATURE__KEY__PROMPT,
    PACKAGE_FEATURE__KEY__SEARCH_TEMPLATES,
    PACKAGE_FEATURE__KEY__TEMPLATE,
    PACKAGE_FEATURE__KEY__TEXT_TO_MEME,
    PACKAGE_FEATURE__KEY__THEMED_MEMES
} from "../../../helpers/constant/coreConstant";
import {
    selectPackageAccessFeatureState
} from "../../../state/features/packageAccessFeature/packageAccessFeatureSelector";
import {hasFeatureAccess} from "../../../state/features/packageAccessFeature/packageAccessFeatureSlice";

const Sidebar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [profileData, setProfileData] = useState({});
    const [searchCategory, setSearchCategory] = useState('');
    const [categoryList, setCategoryList] = useState({});
    const [selectedIndex, setSelectedIndex] = useState('');
    const [open, setOpen] = useState(false);

    const {accountSetting} = useSelector(selectAccountSettingState);
    const {categories} = useSelector(selectCategoriesState);
    const {featureAccess} = useSelector(selectPackageAccessFeatureState);

    const [selectedSubMenu, setSelectedSubMenu] = useState("");

    const handleClickMenu = (menuItem) => {

        if (menuItem.subMenu) {
            if (selectedSubMenu === menuItem.item) {
                setSelectedSubMenu("");
            } else {
                setSelectedSubMenu(menuItem.item);
            }
        } else {
            handleListItemClick(menuItem.item)
        }
    };

    useEffect(() => {
        dispatch(getCategory());
    }, [dispatch]);

    useEffect(() => {
        setCategoryList(categories);
    }, [categories]);

    useEffect(() => {
        if (accountSetting) {
            setProfileData(accountSetting);
        }
    }, [accountSetting]);

    const handleLogOut = () => {
        clearStorageWhenLogOut();
        dispatch(attemptLogout());
        navigate('/login');
    };

    useEffect(() => {
        dispatch(hasFeatureAccess());
    }, [])

    let viewNavItem = [];

    viewNavItem = appSideBar.filter(navItem => {
        return featureAccess.some(featureAccess => {
            return featureAccess === navItem.item;
        });
    });

    const handleListItemClick = (index) => {
        const item = Number(index);
        setSelectedIndex(item);

        if (item === PACKAGE_FEATURE__KEY__DASHBOARD) {
            navigate('/dashboard');
        } else if (item === PACKAGE_FEATURE__KEY__TEMPLATE) {
            navigate('/presets/templates');
        } else if (item === PACKAGE_FEATURE__KEY__COLLECTION) {
            navigate('/collections');
        } else if (item === PACKAGE_FEATURE__KEY__CONTENT_REPURPOSING) {
            navigate('/image-repurposing');
        } else if (item === PACKAGE_FEATURE__KEY__PLAY_PEN) {
            navigate('/play-pen');
        } else if (item === PACKAGE_FEATURE__KEY__IMAGE_CREATION) {
            navigate('/image-creation');
        } else if (item === PACKAGE_FEATURE__KEY__IMAGE_IN_PAINTING) {
            navigate('/image-in-painting');
        } else if (item === PACKAGE_FEATURE__KEY__IMAGE_VARIATION) {
            navigate('/image-variation');
        } else if (item === PACKAGE_FEATURE__KEY__IMAGE_TO_PROMPT) {
            navigate('/image-to-prompt');
        } else if (item === PACKAGE_FEATURE__KEY__IMAGE_TO_ALT) {
            navigate('/image-to-alt-text');
        } else if (item === PACKAGE_FEATURE__KEY__AUDIO) {
            navigate('/audio-transcription');
        } else if (item === PACKAGE_FEATURE__KEY__PROMPT) {
            navigate('/prompt');
        } else if (item === PACKAGE_FEATURE__KEY__BILLING) {
            navigate('/settings/billing');
        } else if (item === PACKAGE_FEATURE__KEY__TEXT_TO_MEME) {
            navigate('/text-to-meme');
        } else if (item === PACKAGE_FEATURE__KEY__THEMED_MEMES) {
            navigate('/themed-memes');
        } else if (item === PACKAGE_FEATURE__KEY__SEARCH_TEMPLATES) {
            navigate('/search-template');
        } else if (item === PACKAGE_FEATURE__KEY__MEME_MAKER) {
            navigate('/meme-maker');
        } else if (item === MEME_MAKER_COLLAPSE_MENU) {
            setOpen(!open)
        } else if (item === AI_TRANSCRIPTION_COLLAPSE_MENU) {
            setOpen(!open)
        }
    };

    const StyledListItemButton = styled(ListItemButton)(({theme}) => ({
        color: 'rgb(75, 85, 99)',
        gap: '12px',

        svg: {
            fill: 'rgb(156, 163, 175)'
        },

        '&.Mui-selected': {
            color: theme.palette.primary.main,
            backgroundColor: 'rgb(238, 242, 255) !important'
            //   borderLeft: `4px solid ${theme.palette.primary.main}`
        },
        '&.Mui-selected::before': {
            content: '""',
            position: 'absolute',
            left: 0,
            top: 0,
            height: '100%',
            width: '4px',
            backgroundColor: theme.palette.primary.main /* Set your desired border color here */
        },

        '&.Mui-selected svg ': {
            fill: theme.palette.primary.main
        },

        '&.Mui-focusVisible': {
            backgroundColor: 'rgb(238, 242, 255) !important'
        },
        '&:hover': {
            backgroundColor: 'rgb(238, 242, 255) !important'
        }
    }));

    const StyledListItemIcon = styled(ListItemIcon)(({theme}) => ({
        minWidth: 'auto'
    }));

    return (
        <div>
            <Box
                sx={{
                    width: '300px',
                    height: '100%',
                    marginLeft: '12px',
                    backgroundColor: '#ffffff',
                    boxShadow: 'rgba(0, 0, 0, 0.08) 0px 0px 8px 0px',
                    position: 'relative'
                }}
            >
                <Stack
                    direction={'column'}
                    spacing={2}
                    sx={{
                        width: '100%',
                        color: '#222'
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            maxWidth: 360,
                            bgcolor: 'background.paper',
                            maxHeight: 'calc(100vh - 300px)',
                            overflowY: 'auto'
                        }}
                    >
                        <List component="nav" aria-label="main mailbox folders" sx={{p: 0}}>
                            {/*<StyledListItemButton*/}
                            {/*    selected={selectedIndex === 0}*/}
                            {/*    onClick={event => handleListItemClick(event, 0)}*/}
                            {/*>*/}
                            {/*    <StyledListItemIcon>*/}
                            {/*        <HomeIcon/>*/}
                            {/*    </StyledListItemIcon>*/}
                            {/*    <ListItemText primary="Home"/>*/}
                            {/*</StyledListItemButton>*/}

                            {
                                appSideBar.map((items) => (
                                    <Box>
                                        <StyledListItemButton
                                            selected={selectedIndex === items.item}
                                            selectedSubMenu={selectedSubMenu}
                                            onClick={() => handleClickMenu(items)}
                                        >
                                            <StyledListItemIcon>
                                                {items.icon}
                                            </StyledListItemIcon>
                                            <ListItemText primary={items.name}/>

                                            {
                                                items.subMenu ? items.item === selectedSubMenu ?
                                                    <ExpandLess sx={{transition: 'transform 0.3s ease', transform: items.item === selectedSubMenu ? 'none' : 'rotate(180deg)'}}/> :
                                                    <ExpandMore sx={{ transition: 'transform 0.3s ease' }} /> : ""
                                            }
                                        </StyledListItemButton>
                                        {
                                            items.subMenu && (
                                                items.subMenu.map((subMenuItems) => (
                                                    <Collapse in={subMenuItems.parentItem === selectedSubMenu}
                                                              timeout={500}
                                                              unmountOnExit>
                                                        <StyledListItemButton
                                                            sx={{paddingLeft: '48px'}}
                                                            selected={selectedIndex == subMenuItems.item}
                                                            onClick={() => handleListItemClick(`${subMenuItems.item}`)}
                                                        >
                                                            <StyledListItemIcon>
                                                                {/*<HomeIcon/>*/} {subMenuItems.icon}
                                                            </StyledListItemIcon>
                                                            <ListItemText primary={subMenuItems.name}/>
                                                        </StyledListItemButton>
                                                    </Collapse>
                                                ))
                                            )
                                        }
                                    </Box>
                                ))
                            }
                        </List>
                    </Box>

                    <List sx={{color: '#222', position: 'absolute', bottom: '0', left: '10px', width: '90%'}}>
                        {/*<NavLink*/}
                        {/*    style={{margin: '10px 0',color:'#222',textDecoration:'none'}}*/}
                        {/*    to="/accountSetting"*/}
                        {/*    // className={({isActive}) => (isActive ? 'active' : 'inactive')}*/}
                        {/*>*/}
                        {/*    <ListItem disablePadding>*/}
                        {/*        <ListItemButton>*/}
                        {/*            <ListItemIcon sx={{minWidth: '30px', paddingRight: '5px'}}>*/}
                        {/*                <Avatar sx={{width:'35px',height:'35px'}}/>*/}
                        {/*            </ListItemIcon>*/}
                        {/*            <ListItemText sx={{color:'#222'}} primary={profileData?.first_name || ''}/>*/}
                        {/*        </ListItemButton>*/}
                        {/*    </ListItem>*/}
                        {/*</NavLink>*/}

                        {/*<ListItem disablePadding>*/}
                        {/*    <ListItemButton component="a">*/}
                        {/*        <ListItemIcon sx={{minWidth: '30px', paddingRight: '5px'}}>*/}
                        {/*            <SettingsIcon sx={{marginLeft: '5px'}}/>*/}
                        {/*        </ListItemIcon>*/}
                        {/*        <ListItemText primary="Settings"/>*/}
                        {/*    </ListItemButton>*/}
                        {/*</ListItem>*/}

                        <Divider/>

                        <ListItem disablePadding>
                            <ListItemButton component="a">
                                <ListItemIcon sx={{minWidth: '30px', paddingRight: '5px'}}>
                                    <LogoutIcon sx={{marginLeft: '5px'}}/>
                                </ListItemIcon>
                                <ListItemText primary="Log out" onClick={handleLogOut}/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Stack>
            </Box>
        </div>
    );
};

export default Sidebar;
