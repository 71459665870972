import React, {useRef, useState} from 'react';
import {Box, Paper, Typography} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import LoadingButton from '../../assets/global/Button/LoadingButton';
import CoreTextField from '../../assets/global/TextField/CoreTextField';
import Grid from '@mui/material/Grid';
import AccountSettingService from '../../services/AcccountSetting.service';
import {RESPONSE_LOADING_TIME} from '../../helpers/constant/coreConstant';
import {showErrorToaster, showSuccessToaster} from '../../helpers/utility/toaster';
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import {registerPattern} from "../../helpers/constant/validationPattern";

const AccountSettingPassword = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        formState: {errors},
        watch,
        setValue,
        control
    } = useForm({
        mode: 'onSubmit'
    });

    const password = useRef(null);
    password.current = watch('new_password', '');

    // ## Form Submit Action
    const onSubmit = data => {
        setLoading(true);

        AccountSettingService.updateUserPassword(data)
            .then(response => {
                setTimeout(() => {
                    setLoading(false);
                    if (response.success) {
                        setValue('new_password', '');
                        setValue('confirm_password', '');
                        showSuccessToaster(response.message);
                    } else {
                        if (typeof response.message === 'string') {
                            showErrorToaster(response.message);
                        }
                    }
                }, RESPONSE_LOADING_TIME);
            })
            .catch(err => console.log(err));
    };

    return (
        <Paper elevation={0}>
            <Box sx={{padding: '20px', m: 1, borderBottom: `1px solid #dfe4ea`, minHeight: 'auto'}}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Typography level="h2" fontSize="lg" fontWeight="bold" id="card-description" mb={0.5}>
                        Change Password
                    </Typography>
                </Grid>
            </Box>

            <Box
                sx={{
                    p: '24px',
                    '& .MuiFormControl-root': {
                        width: '100%',
                    }
                }}
                component="form"
                onSubmit={handleSubmit(onSubmit)}
            >
                <input type="hidden" autoComplete={'off'} value="prayer"/>

                <Controller
                    control={control}
                    name="new_password"
                    defaultValue=""
                    render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                        <FormControl fullWidth>
                            <TextField
                                type="password"
                                size="small"
                                margin="normal"
                                value={value}
                                onChange={onChange}
                                fullWidth
                                id="password"
                                label="Password"
                                autoComplete="nope"
                                error={!!(invalid && error.message)}
                                helperText={invalid && error.message}
                            />
                        </FormControl>
                    )}
                    rules={registerPattern.password}
                />

                <Controller
                    control={control}
                    name="confirm_password"
                    defaultValue=""
                    render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                        <FormControl fullWidth>
                            <TextField
                                size="small"
                                margin="normal"
                                value={value}
                                onChange={onChange}
                                fullWidth
                                type={'password'}
                                label="Confirm Password"
                                placeholder={'Retype Password'}
                                autoComplete="nope"
                                error={!!(invalid && error.message)}
                                helperText={invalid && error.message}
                            />
                        </FormControl>
                    )}
                    rules={registerPattern.confirm_password}
                />

                {/*{...register('confirm_password', {*/}
                {/*  validate: value => value === password.current || 'passwords do not match'*/}
                {/*})}*/}

                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                    <LoadingButton
                        isLoading={loading}
                        loadingText={'Updating...'}
                        size={'large'}
                        color={'secondary'}
                        type={'submit'}
                    >
                        Update Password
                    </LoadingButton>
                </Box>
            </Box>
        </Paper>
    );
};

export default AccountSettingPassword;
