import {
    USER_INVOICE__FOR_PACKAGE_BUY,
    USER_INVOICE__FOR_PACKAGE_FREE,
    USER_INVOICE__FOR_SMS_SEND,
    USER_INVOICE__FOR_EMAIL_SEND,
    USER_INVOICE__FOR_LOCATION_REVIEW_REQUEST,
    USER_INVOICE__FOR_RECHARGE_TOP_UP_AMOUNT,
} from "../constant/coreConstant";

export const invoiceForConstant = (emailType) => {
    switch (emailType) {
        case USER_INVOICE__FOR_PACKAGE_BUY:
            return "Buy Package";
        case USER_INVOICE__FOR_PACKAGE_FREE:
            return "Free Package";
        case USER_INVOICE__FOR_EMAIL_SEND:
            return "Email Send";
        case USER_INVOICE__FOR_SMS_SEND:
            return "SMS Send";
        case USER_INVOICE__FOR_LOCATION_REVIEW_REQUEST:
            return "Location Review Request";
        case USER_INVOICE__FOR_RECHARGE_TOP_UP_AMOUNT:
            return "Recharge Top-up Amount";
        default:
            return "N/A";
    }
};
