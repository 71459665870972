import { all, call, put, takeEvery } from "redux-saga/effects";
import userService from "../../../services/User.service";
import { delay } from "@redux-saga/core/effects";

import {
    attemptLoginFailed,
    attemptLoginSuccess,
    attemptResetPasswordFailed,
    attemptResetPasswordSuccess,
    attemptResetPasswordWithTokenFailed,
    attemptResetPasswordWithTokenSuccess,
    attemptSetNewPasswordFailed,
    attemptSetNewPasswordSuccess,
    attemptSignUpFailed,
    attemptSignUpSuccess,
    attemptSignUpWithTokenFailed,
    attemptSignUpWithTokenSuccess,
} from "./userSlice";
import CookieService from "../../../services/cookie/cookie.service";
import { RESPONSE_LOADING_TIME } from "../../../helpers/constant/coreConstant";
import {showErrorToaster, showSuccessToaster} from "../../../helpers/utility/toaster";

function* userWatcher() {
    yield takeEvery("user/attemptLogin", attemptLoginSaga);

    yield takeEvery("user/attemptResetPassword", attemptResetPasswordSaga);
    yield takeEvery("user/attemptResetPasswordWithToken", attemptResetPasswordWithTokenSaga);
    yield takeEvery("user/attemptSetNewPassword", attemptSetNewPasswordSaga);

    yield takeEvery("user/attemptSignUp", attemptSignUpSaga);
    yield takeEvery("user/attemptSignUpWithToken", attemptSignUpWithTokenSaga);
    yield takeEvery("user/attemptSwitchAccount", attemptSwitchAccountSaga);
}

function* attemptLoginSaga(action) {
    try {
        const response = yield call(userService.attemptLogin, action.payload);

        if (response.success) {
            if (response.data) {
                CookieService.setCookie(process.env.REACT_APP_ACCESS_TOKEN, response.data.access_token, "");
                CookieService.setCookie(process.env.REACT_APP_AUTH_ACCESS_TOKEN, response.data.user.auth_token, "");
            }

            yield delay(RESPONSE_LOADING_TIME);
            yield put(
                attemptLoginSuccess({
                    user: response.data.user,
                })
            );
        } else {
            yield put(attemptLoginFailed(response.message));
            showErrorToaster(response.message);
        }
    } catch (err) {
        console.log("Error: ", err);
        yield put(attemptLoginFailed(""));
    }
}

function* attemptResetPasswordSaga(action) {
    try {
        const response = yield call(userService.attemptResetPassword, action.payload);

        yield delay(RESPONSE_LOADING_TIME);
        if (response.success) {
            yield put(attemptResetPasswordSuccess());
            showSuccessToaster(response.message)
        } else {
            yield put(attemptResetPasswordFailed(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* attemptResetPasswordWithTokenSaga(action) {
    try {
        const response = yield call(userService.attemptResetPasswordWithToken, action.payload);

        yield delay(RESPONSE_LOADING_TIME);
        if (response.success) {
            yield put(attemptResetPasswordWithTokenSuccess());
            showSuccessToaster(response.message)
        } else {
            yield put(attemptResetPasswordWithTokenFailed(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* attemptSetNewPasswordSaga(action) {
    try {
        const response = yield call(userService.attemptSetNewPassword, action.payload);

        yield delay(RESPONSE_LOADING_TIME);
        if (response.success) {
            yield put(attemptSetNewPasswordSuccess());
            showSuccessToaster(response.message)
        } else {
            yield put(attemptSetNewPasswordFailed(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* attemptSignUpSaga(action) {
    try {
        const response = yield call(userService.attemptSignUp, action.payload);

        delay(RESPONSE_LOADING_TIME);
        if (response.success) {
            yield put(attemptSignUpSuccess(response));
            showSuccessToaster(response.message)
        } else {
            yield put(attemptSignUpFailed(response));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* attemptSignUpWithTokenSaga(action) {
    try {
        const response = yield call(userService.attemptSignUpWithToken, action.payload);

        if (response.success) {
            yield put(attemptSignUpWithTokenSuccess());

            delay(RESPONSE_LOADING_TIME);
            const userData = response.data.original;
            if (userData) {
                CookieService.setCookie(process.env.REACT_APP_ACCESS_TOKEN, userData.data.access_token, "");
                attemptLoginSuccess({
                    user: response.data.user,
                });
            }

            showSuccessToaster(response.message)
        } else {
            yield put(attemptSignUpWithTokenFailed(response));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* attemptSwitchAccountSaga(action) {
    try {
        yield delay(1000);
        const response = yield call(userService.attemptSwitchAccount, action.payload);

        if (response.success && response.data) {
            CookieService.setCookie(process.env.REACT_APP_ACCESS_TOKEN, response.data.access_token, "");
            yield delay(yield delay(1000));

            yield put(
                attemptLoginSuccess({
                    user: response.data.user,
                    roleName: response.data.role_name,
                    hiddenKeys: response.data.hidden_modules_key,
                })
            );
            showSuccessToaster(response.message)
        } else {
            yield put(attemptLoginFailed(response.message));
        }
    } catch (err) {
        console.log("Error: ", err);
        yield put(attemptLoginFailed(""));
    }
}

export default function* userSaga() {
    yield all([userWatcher()]);
}
