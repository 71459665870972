import React from "react";
import Box from "@mui/material/Box";
import { Backdrop, Modal, Paper, Stack, styled } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Close } from "@mui/icons-material";

const PaperStyle = styled(Paper)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
}));

const CloseModal = ({ open, onClose, width = "480px", disableOutSideClick = false, children, ...rest }) => {
    return (
        <React.Fragment>
            <Modal
                open={open}
                onClose={!disableOutSideClick ? onClose : () => false}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                {...rest}
            >
                <PaperStyle elevation={0} sx={{ width: width,height:'60%' }}>
                    <Stack direction={"row"} justifyContent={"flex-end"} px={1} pt={1}>
                        <IconButton color={"error"} onClick={onClose}>
                            <Close fontSize={"small"} />
                        </IconButton>
                    </Stack>

                    <Box sx={{ px: 2, pb: 2, textAlign: "center" }}>{children}</Box>
                </PaperStyle>
            </Modal>
        </React.Fragment>
    );
};

export default CloseModal;
