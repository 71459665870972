import React, {useEffect, useState} from 'react';
import {Box, FormHelperText} from '@mui/material';
import PlayPenEditor from '../playpen/PlayPenEditor';
import StyledDropzone from '../../assets/global/ImageUpload/StyledDropzone';
import {useDispatch, useSelector} from 'react-redux';
import {selectImageToAltTextState} from '../../state/features/imageToAltText/imageToAltTextSelector';
import {useForm} from 'react-hook-form';
import {imageToAltText, imageToAltTextStateClear} from '../../state/features/imageToAltText/imageToAltTextSlice';
import FeatureInput from '../common/featureInput/FeatureInput';
import FeatureOutputNoResponse from '../common/featureOutput/FeatureOutputNoResponse';
import FeatureOutput from '../common/featureOutput/FeatureOutput';
import {showErrorToaster} from "../../helpers/utility/toaster";
import {creditSettingSTextContent} from "../../helpers/constant/coreConstant";
import BalanceCheck from "../../assets/global/BalanceCheck/BalanceCheck";

const MemeMaker = () => {
    const dispatch = useDispatch();
    const [imageInput, setImageInput] = useState([]);

    const {imageToAltTextData, isLoading} = useSelector(selectImageToAltTextState);

    const {handleSubmit, control, reset} = useForm();

    const onSubmit = data => {
        if(!imageInput[0]){
            showErrorToaster("Add an image")
        }else {
            let formData = new FormData();
            formData.append('image_file', imageInput[0]);
            formData.append('text_type', 1);
            dispatch(imageToAltText(formData));
        }

    };

    const setImageFile = image => {
        setImageInput(image);
    };

    const handleClearInput = () => {
        setImageInput(null);
    }

    useEffect(() => {
        return () => dispatch(imageToAltTextStateClear());
    }, [])

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    height: '90vh',
                    paddingTop: '50px',
                    backgroundColor: '#f3f4f6',
                    overflow: 'auto'
                }}
            >

                <FeatureInput
                    headerTitle={'Image to Alt Text'}
                    helpkey={'IMAGETOALTTEXT'}
                    clearInput={'none'}
                    subtitle="Generate Image to Alt Text"
                    footer={{generate: handleSubmit(onSubmit), isLoading, handleClearInput}}
                >
                    <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                        <StyledDropzone setImageFile={setImageFile}/>
                        <FormHelperText sx={{color: '#FF8800'}}>This is a test release. On occasion, you could get an
                            inappropriate answer.</FormHelperText>
                    </Box>
                    <BalanceCheck content_type={creditSettingSTextContent} amount_data={1000} />
                </FeatureInput>

                <FeatureOutput
                    newOutput={
                        <>
                            {imageToAltTextData ? (
                                <PlayPenEditor value={imageToAltTextData}/>
                            ) : (
                                <FeatureOutputNoResponse/>
                            )}
                        </>
                    }
                />
            </Box>
        </>
    );
};

export default MemeMaker;
