import * as React from 'react';
import {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
// drop-down import
import {alpha, styled, useTheme} from '@mui/material/styles';
import AppNavbarRight from './AppNavbarRight';
import {Link, NavLink} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {selectAccountSettingState} from '../../../state/features/accountSetting/accountSettingSelector';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {clearChatGPTState} from "../../../state/features/chatGPT/chatGPTSlice";
import Menu from '@mui/material/Menu';
import {appSideBar} from "../../../helpers/utility/appSidebarHelper";
import {
    selectPackageAccessFeatureState
} from "../../../state/features/packageAccessFeature/packageAccessFeatureSelector";
import {hasFeatureAccess} from "../../../state/features/packageAccessFeature/packageAccessFeatureSlice";


// custom styed menu -----------------
const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({theme}) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

const AppNavbar = ({handleDrawerToggle}) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [profileData, setProfileData] = useState({});

    const {accountSetting} = useSelector(selectAccountSettingState);
    const {featureAccess} = useSelector(selectPackageAccessFeatureState);

    useEffect(() => {
        if (accountSetting) {
            setProfileData(accountSetting);
        }
    }, [accountSetting]);

    const handleClearChatGptState = () => {
        dispatch(clearChatGPTState());
    };

    useEffect(() => {
        dispatch(hasFeatureAccess());
    }, [])

    // drop-down code ----------------------------
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    let viewNavItem = [];
    viewNavItem = appSideBar.filter(navItem => {
        return featureAccess.some(featureAccess => {
            return featureAccess === navItem.key;
        });
    });

    return (
        <AppBar
            position="fixed"
            sx={{
                padding: '0 20px',
                bgcolor: theme.palette.background.paper,
                color: theme.palette.text.primary,
                boxShadow: 'none',
                '& .MuiToolbar-root': {
                    minHeight: '60px'
                }
            }}
        >
            <Toolbar disableGutters>
                <Box sx={{mr: 2, display: 'flex', alignItems: 'center', gap: '20px'}}>
                    <Link to="/chat" sx={{mr: 2}}>
                        <img
                            height={'40'}
                            src="https://app.pusholk9.com/images/logo/site-logo.png"
                            alt={'logo'}
                        />
                    </Link>

                    <NavLink
                        style={{
                            marginLeft: '86px',
                            fontSize: '14px',
                            fontWeight: '400',
                            textDecoration: 'none',
                            border: "1px solid gray",
                            padding: "6px 12px",
                            borderRadius: '5px'
                        }}
                        to="/chat"
                        onClick={e => handleClearChatGptState(e)}
                        className={({isActive}) => (isActive ? 'app_navbar_active' : 'app_navbar_in_active')}
                    >
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <AddCircleIcon sx={{fontSize: '20px', mr: '5px', fontWeight: '400'}}/>
                            New Chat
                        </Box>
                    </NavLink>

                    {/* ------------------------drop-down code ----------------------
                    <Box>
                        <Button
                            id="demo-customized-button"
                            aria-controls={open ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClick}
                            endIcon={<KeyboardArrowDownIcon/>}
                        >
                            OLK9AI's Features
                        </Button>
                        <StyledMenu
                            id="demo-customized-menu"
                            MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                        >
                            {
                                viewNavItem.map((navItem) =>
                                    <MenuItem onClick={handleClose} disableRipple>
                                        <NavLink to={navItem.route}
                                                 style={{fontSize: '18px', fontWeight: '400'}}
                                                 className={({isActive}) => (isActive ? 'app_navbar_active' : 'app_navbar_in_active')}
                                        >
                                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                                {navItem.icon}
                                                {navItem.name}
                                            </Box>
                                        </NavLink>
                                    </MenuItem>
                                )
                            }

                            <MenuItem onClick={handleClose} disableRipple>
                                <NavLink to="/settings/billing"
                                         style={{fontSize: '18px', fontWeight: '400'}}
                                         className={({isActive}) => (isActive ? 'app_navbar_active' : 'app_navbar_in_active')}
                                >
                                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        <MonetizationOnIcon sx={{fontSize: '20px', mr: '5px', fontWeight: '400'}}/>
                                        Billing
                                    </Box>
                                </NavLink>
                            </MenuItem>
                            <MenuItem onClick={handleClose} disableRipple>
                                <NavLink to="/dashboard"
                                         style={{fontSize: '18px', fontWeight: '400'}}
                                         className={({isActive}) => (isActive ? 'app_navbar_active' : 'app_navbar_in_active')}
                                >
                                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        <DashboardIcon sx={{fontSize: '20px', mr: '5px', fontWeight: '400'}}/>
                                        Dashboard
                                    </Box>
                                </NavLink>
                            </MenuItem>
                            <MenuItem onClick={handleClose} disableRipple>
                                <NavLink to="/text-to-meme"
                                         style={{fontSize: '18px', fontWeight: '400'}}
                                         className={({isActive}) => (isActive ? 'app_navbar_active' : 'app_navbar_in_active')}
                                >
                                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        <DashboardIcon sx={{fontSize: '20px', mr: '5px', fontWeight: '400'}}/>
                                        Text to meme
                                    </Box>
                                </NavLink>
                            </MenuItem>
                            <MenuItem onClick={handleClose} disableRipple>
                                <NavLink to="/themed-memes"
                                         style={{fontSize: '18px', fontWeight: '400'}}
                                         className={({isActive}) => (isActive ? 'app_navbar_active' : 'app_navbar_in_active')}
                                >
                                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        <DashboardIcon sx={{fontSize: '20px', mr: '5px', fontWeight: '400'}}/>
                                        Themed Memes
                                    </Box>
                                </NavLink>
                            </MenuItem>
                            <MenuItem onClick={handleClose} disableRipple>
                                <NavLink to="/search-template"
                                         style={{fontSize: '18px', fontWeight: '400'}}
                                         className={({isActive}) => (isActive ? 'app_navbar_active' : 'app_navbar_in_active')}
                                >
                                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        <DashboardIcon sx={{fontSize: '20px', mr: '5px', fontWeight: '400'}}/>
                                        Search Templates
                                    </Box>
                                </NavLink>
                            </MenuItem>
                            <MenuItem onClick={handleClose} disableRipple>
                                <NavLink to="/meme-maker"
                                         style={{fontSize: '18px', fontWeight: '400'}}
                                         className={({isActive}) => (isActive ? 'app_navbar_active' : 'app_navbar_in_active')}
                                >
                                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        <DashboardIcon sx={{fontSize: '20px', mr: '5px', fontWeight: '400'}}/>
                                        Meme Maker
                                    </Box>
                                </NavLink>
                            </MenuItem>

                        </StyledMenu>
                    </Box>
*/}
                </Box>
                <Box sx={{flexGrow: 1}}/>

                <AppNavbarRight profileData={profileData}/>
            </Toolbar>
        </AppBar>
    );
};
export default AppNavbar;
