import {Box, IconButton} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CachedIcon from '@mui/icons-material/Cached';
import CoreTextField from '../../assets/global/TextField/CoreTextField';
import Grid from '@mui/material/Grid';
import LoadingButton from '../../assets/global/Button/LoadingButton';
import React from 'react';
import {clearState} from '../../state/features/prompts/promptSlice';
import {selectPromptState} from '../../state/features/prompts/promptSelector';
import {useNavigate} from 'react-router-dom';
import {useTheme} from '@mui/material/styles';
import {promptInputPattern, registerPattern} from "../../helpers/constant/validationPattern";

const PromptForm = ({specificTemplateData, handleGenerateResponse, handlePreview}) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const navigate = useNavigate();
    const {isLoading, showGenerateButton, showReGenerateButton} = useSelector(selectPromptState);

    const {handleSubmit, register, control, reset} = useForm();
    const onSubmit = data => {
        if (handleGenerateResponse) handleGenerateResponse(data);
    };
    const handleReGenerate = () => {
        reset();
        dispatch(clearState());
    };

    const handleChangeInput = (tagFormat, value) => {
        const formData = {
            [tagFormat]: value.target.value
        };
        handlePreview(formData);
    };

    return (
        <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
            sx={{backgroundColor: theme.palette.background.paper, height: '100%'}}
        >
            <Grid
                container
                spacing={2}
                sx={{
                    margin: '0px',
                    padding: '10px',
                    width:'auto !important',
                    boxShadow: 'rgba(0, 0, 0, 0.08) 0px 0px 8px 0px'
                }}
            >
                <Grid xs={12} sm={12}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: `1px solid #dfe4ea`
                        }}
                    >
                        <Box sx={{margin: '0px 0px 10px 10px'}}>
                            <IconButton
                                sx={{border: '1px solid gray', padding: '9px', borderRadius: '5px'}}
                                onClick={() => navigate(-1)}
                            >
                                <ArrowBackIosNewIcon sx={{width: '18x', height: '18px'}}/>
                            </IconButton>
                        </Box>

                        {showReGenerateButton && (
                            <IconButton sx={{border: '1px solid gray'}} onClick={handleReGenerate}>
                                <CachedIcon/>
                            </IconButton>
                        )}
                    </Box>
                </Grid>

                {specificTemplateData &&
                    specificTemplateData?.tags.map(tag => (
                        <Grid key={tag.id} item xs={12} sm={12} sx={{paddingLeft: '0px!important'}}>
                            <Controller
                                control={control}
                                name={tag.tag_format}
                                defaultValue=""
                                render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                    <CoreTextField
                                        required
                                        fullWidth
                                        multiline={tag?.type !== 1}
                                        type={'text'}
                                        size={'small'}
                                        onChange={value => {
                                            onChange(value);
                                        }}
                                        onKeyUp={value => {
                                            handleChangeInput(tag.tag_format, value);
                                        }}
                                        value={value}
                                        label={tag.tag_name}
                                        sx={{marginBottom: '5px'}}
                                        error={!!(invalid && error.message)}
                                        helperText={invalid && error.message}
                                    />
                                )}
                                rules={promptInputPattern.input}
                            />
                        </Grid>
                    ))}

                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}></Box>

                {showGenerateButton && (
                    <Grid container justifyContent="flex-end">
                        <LoadingButton
                            isLoading={isLoading}
                            loadingText={'Generating'}
                            type={'submit'}
                            size={'large'}
                            color={'primary'}
                            sx={{margin: '10px 0px'}}
                        >
                            Generate
                        </LoadingButton>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};
export default PromptForm;
