import {Controller, useForm} from 'react-hook-form';
import {Link, useNavigate} from 'react-router-dom';
import React, {useEffect} from 'react';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {attemptClearLogin, attemptLogin, attemptSignUpStateClear} from '../../state/features/user/userSlice';
import {useDispatch, useSelector} from 'react-redux';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import hasAuthenticatedUser from '../../helpers/utility/hasAuthenticateUser';
import {registerPattern} from "../../helpers/constant/validationPattern";
import LoadingButton from "../../assets/global/Button/LoadingButton";

const Login = () => {
    const theme = createTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {isLogin, error, pending} = useSelector(state => state.user);

    const {
        register,
        control,
        handleSubmit,
        formState: {errors},
        setValue
    } = useForm({
        mode: 'onSubmit'
    });

    useEffect(() => {
        if (isLogin) {
            const hasAccessToken = hasAuthenticatedUser();
            if (hasAccessToken) {
                navigate('/dashboard');
                dispatch(attemptClearLogin());
            }
        }
    }, [isLogin]);

    // ## Clear Reset Password State When Leave the page
    useEffect(() => {
        return () => dispatch(attemptSignUpStateClear());
    }, []);

    const onSubmit = data => {
        const payload = {};
        payload.email = data.email;
        payload.password = data.password;

        dispatch(attemptLogin(payload));
    };

    return (
        <ThemeProvider theme={theme}>
            <Container
                component="main"
                maxWidth="xs"
                sx={{
                    backgroundColor: '#fff',
                    boxShadow: '0 4px 24px 0 rgba(34, 41, 47, 0.1)',
                    borderRadius: '8px'
                }}
            >
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: '0',
                        paddingTop: '30px',
                        paddingBottom: '30px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: '15px'
                        }}
                    >
                        <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                            <LockOutlinedIcon/>
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                    </Box>

                    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{mt: 1}}>
                        <Box sx={{margin: '0', padding: '0'}}>
                            <Typography component="h6" variant="h6" sx={{textAlign: 'left'}}>
                                Welcome to OLK9AI! 👋
                            </Typography>
                            <Typography component="p" variant="body2">
                                Please log in to your account to begin your adventure.
                            </Typography>
                        </Box>
                        <Controller
                            control={control}
                            name="email"
                            defaultValue=""
                            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                <FormControl fullWidth required error={!!(invalid && error.message)}>
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        focused
                                        required
                                        value={value}
                                        onChange={onChange}
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        autoComplete="email"
                                        height="10px"
                                        error={!!(invalid && error.message)}
                                        helperText={invalid && error.message}
                                    />
                                </FormControl>
                            )}
                            rules={registerPattern.email}
                        />

                        <Controller
                            control={control}
                            name="password"
                            defaultValue=""
                            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                <FormControl fullWidth required error={!!(invalid && error.message)}>
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        focused
                                        required
                                        value={value}
                                        onChange={onChange}
                                        fullWidth
                                        id="password"
                                        label="Password"
                                        type="password"
                                        error={!!(invalid && error.message)}
                                        helperText={invalid && error.message}
                                    />
                                </FormControl>
                            )}
                            rules={registerPattern.password}
                        />
                        <FormControlLabel
                            sx={{fontSize: '12px'}}
                            control={<Checkbox value="remember" color="primary"/>}
                            label="Remember me"
                        />

                        <LoadingButton
                            isLoading={pending}
                            fullWidth
                            loadingText={'Signing'}
                            type={'submit'}
                            color={'primary'}
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                        >
                            Sign In
                        </LoadingButton>

                        <Grid container>
                            <Grid item xs>
                                <Link
                                    to="/forgot"
                                    variant="body2"
                                    style={{fontSize: '14px', textDecoration: 'none', color: '#1976d2'}}
                                >
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link
                                    to="/register"
                                    variant="body2"
                                    style={{fontSize: '14px', textDecoration: 'none', color: '#1976d2'}}
                                >
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
};

export default Login;
