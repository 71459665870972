import React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import PresetTemplateDetailsGeneratedOutput from "./PresetTemplateDetailsGeneratedOutput";
import PresetTemplateDetailsHistory from "./PresetTemplateDetailsHistory";
import { styled } from '@mui/material';

const StyledTabContext = styled(TabContext)(({theme}) => ({
    padding: '0 !important'
  }));

const PresetTemplateDetailsOutput = () => {
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box
            sx={{
                width: '80%',
                m: 'auto',
                py: 3,
                shadow: 1
            }}
        >
            <Box sx={{width: '100%', typography: 'body1'}}>
                <TabContext value={value}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="New outputs" value="1"/>
                            {/*<Tab label="Dashboard" value="2"/>*/}
                        </TabList>
                    </Box>
                    <TabPanel sx={{padding: '24px 0'}} value="1" >
                        <PresetTemplateDetailsGeneratedOutput />
                    </TabPanel>
                    <TabPanel sx={{padding: '24px 0'}} value="2">
                        <PresetTemplateDetailsHistory />
                    </TabPanel>
                </TabContext>
            </Box>
        </Box>
    );
};

export default PresetTemplateDetailsOutput;
