import React from 'react';
import {styled} from "@mui/system";
import {Button, Container, Grid, TextField, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";

const StyledContainer = styled(Container)({
    marginTop: '2rem',
    border: `2px solid Gray`, // Border color
    borderRadius: '10px', // Border radius
    padding: '1rem', // Add padding as needed
});

const colorPicker = {
    /* Adjust the width and height as needed */
    width: '25px',
    height: '100%',
    /* Customize the border */
    border: 'none',
    borderRadius: '4px',
    // /* Add box shadow for a nice effect */
    boxShadow: '0 0 4px rgba(0, 0, 0, 0.1)',
    /* Center the color picker vertically and horizontally */
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
};
const sizePicker = {
    /* Adjust the width and height as needed */
    width: '50px',
    paddingLeft: '8px',
    height: '100%',
    /* Customize the border */
    border: 'none',
    borderRadius: '4px',
    // /* Add box shadow for a nice effect */
    boxShadow: '0 0 4px rgba(0, 0, 0, 0.1)',
    /* Center the color picker vertically and horizontally */
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
};
const deleteBtnStyle = {
    border: '1px solid #b1b1b1 !important',
    borderInline: 0,
    px: 1,
    py: 0.5,
    display: 'grid',
    alignItems: 'center',
    borderTopRightRadius: 3,
    borderBottomRightRadius: 3,
    cursor: 'pointer',
    '&:hover': {
        background: '#f5f5f5'
    }
};


const GifEditForm = ({
                         gifCaptions,
                         setGifCaptions,
                     }) => {
    const handleInputChange = (index, event) => {
        setGifCaptions((prevFields) => {
            const updatedFields = [...prevFields];
            updatedFields[index].text = event.target.value;
            return updatedFields;
        });
    };

    const handleColorSelect = (index, e) => {
        console.log(e.target.value);
        setGifCaptions((prevFields) => {
            const updatedFields = [...prevFields];
            updatedFields[index].color = e.target.value;
            return updatedFields;
        });
    };

    const handleSizeSelect = (index, e) => {
        console.log(e.target.value);
        setGifCaptions((prevFields) => {
            const updatedFields = [...prevFields];
            updatedFields[index].size = e.target.value;
            return updatedFields;
        });
    };

    const handleAddField = () => {
        setGifCaptions((prevFields) => {
            const newIndex = prevFields.length + 1;
            return [...prevFields, {
                id: newIndex,
                value: 'Enter Your Text',
                color: '#ffffff',
                size: 35,
                position_x: 0,
                position_y: 0,
            }];
        });
    };

    const handleRemoveField = (index) => {
        setGifCaptions((prevFields) => {
            const updatedFields = [...prevFields];
            updatedFields.splice(index, 1);
            return updatedFields;
        });
    };


    return (
        <StyledContainer maxWidth="sm">
            <Typography variant="h4" component="h1" align="center" gutterBottom>
                Make a Meme
            </Typography>
            <Grid container spacing={2}>
                {
                    gifCaptions.map((field, index) => (
                        <Grid item xs={12} style={{display: 'flex'}}>
                            <TextField
                                label={`Caption ${index + 1}`}
                                variant="outlined"
                                fullWidth
                                value={field.text}
                                onChange={(e) => handleInputChange(index, e)}
                                size="small"
                            />

                            <Box sx={{border: '1px solid #b1b1b1', borderLeft: 0, borderInline: 0, px: 1, py: 0.5}}>
                                <Tooltip title="Font Color">
                                    <input
                                        type="color"
                                        value={field.color}
                                        style={colorPicker}
                                        onChange={(e) => handleColorSelect(index, e)}
                                    />
                                </Tooltip>
                            </Box>
                            <Box sx={{border: '1px solid #b1b1b1'}}>
                                <Tooltip title="Font Size">
                                    <input
                                        type="number"
                                        style={sizePicker}
                                        value={field.size}
                                        onChange={(e) => handleSizeSelect(index, e)}
                                    />
                                </Tooltip>
                            </Box>

                            <Box sx={deleteBtnStyle} size="small" onClick={() => handleRemoveField(index)}>
                                <CloseIcon sx={{fontSize: 20, fontWeight: 300}}/>
                            </Box>

                        </Grid>
                    ))
                }
            </Grid>

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '20px',
                    marginBottom: '20px'
                }}
            >
                <Box spacing={5}>
                    {/*<Button*/}
                    {/*    variant="contained"*/}
                    {/*    onClick={handleAddField}*/}
                    {/*    startIcon={<AddCircleOutlineOutlinedIcon/>}*/}
                    {/*    sx={{marginTop: '20px', marginRight: '10px'}}*/}
                    {/*>*/}
                    {/*    Add Caption*/}
                    {/*</Button>*/}

                </Box>
            </Box>
        </StyledContainer>
    );
}
export default GifEditForm;