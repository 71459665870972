import {all, put} from 'redux-saga/effects';
import {call, takeEvery} from '@redux-saga/core/effects';
import {showErrorToaster} from "../../../helpers/utility/toaster";
import {
    getPresetTemplateFailed,
    getPresetTemplateGeneratedResponseFailed,
    getPresetTemplateGeneratedResponseSuccess,
    getPresetTemplateSuccess,
    getSpecificPresetTemplateFailed,
    getSpecificPresetTemplateSuccess
} from "./presetTemplateSlice";
import PresetTemplateService from "../../../services/PresetTemplate.service";

function* presetTemplateWatcher() {
    yield takeEvery('presetTemplate/getPresetTemplate', getPresetTemplateSaga);
    yield takeEvery('presetTemplate/getSpecificPresetTemplate', getSpecificPresetTemplateSaga);
    yield takeEvery('presetTemplate/getPresetTemplateGeneratedResponse', getPresetTemplateGeneratedResponseSaga);
}

function* getPresetTemplateSaga(action) {
    try {
        const response = yield call(PresetTemplateService.getPromptTemplateData, action.payload);

        if (response.status) {
            yield put(getPresetTemplateSuccess(response.data));
        } else {
            yield put(getPresetTemplateFailed(response.message));
        }
    } catch (err) {
        console.log('Error: ', err);
        yield put(getPresetTemplateFailed(err.message));
    }
}

function* getSpecificPresetTemplateSaga(action) {
    try {
        const response = yield call(PresetTemplateService.getSpecificPromptTemplateData, action.payload);

        if (response.status) {
            yield put(getSpecificPresetTemplateSuccess(response.data));
        } else {
            yield put(getSpecificPresetTemplateFailed(response.message));
        }
    } catch (err) {
        console.log('Error: ', err);
        yield put(getSpecificPresetTemplateFailed(err.message));
    }
}

function* getPresetTemplateGeneratedResponseSaga(action) {
    try {
        const response = yield call(PresetTemplateService.getGeneratedResponseData, action.payload);

        if (response.status) {
            yield put(getPresetTemplateGeneratedResponseSuccess(response?.data));
        } else {
            yield put(getPresetTemplateGeneratedResponseFailed(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log('Error: ', err);
        yield put(getPresetTemplateGeneratedResponseFailed(err.message));
    }
}

export default function* presetTemplateSaga() {
    yield all([presetTemplateWatcher()]);
}
