import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    promptCollections: [],
    page: 1,
    perPage: 12,
    total: 12,
    isLoading: false,
    isError: false,
    error: '',
    isCreateSuccess: false,
};

const promptCollectionSlice = createSlice({
    name: 'promptCollection',
    initialState: initialState,
    reducers: {
        getPromptCollection: (state, action) => {
            state.isLoading = true;
        },
        getPromptCollectionSuccess: (state, action) => {
            state.promptCollections = action.payload.data;
            state.isLoading = false;
            state.total = action.payload.total;
            state.isError = false;
            state.error = '';
        },
        getPromptCollectionFailed: (state, action) => {
            state.promptCollections = [];
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },

        createPromptCollection: (state, action) => {
            state.isLoading = true;
        },
        createPromptCollectionSuccess: (state, action) => {
            state.isLoading = false;
            state.isCreateSuccess = true;
            state.isError = false;
            state.error = '';
        },
        createPromptCollectionFailed: (state, action) => {
            state.isLoading = false;
            state.isCreateSuccess = false;
            state.isError = true;
            state.error = action.payload;
        },

        clearPromptCollectionState: (state, action) => {
            state.isLoading = false;
            state.promptCollections = [];
            state.isCreateSuccess = false;
            state.isError = true;
            state.error = action.payload;
        },

        createImageCollection: (state, action) => {
            state.isLoading = true;
        },
        createImageCollectionSuccess: (state, action) => {
            state.isLoading = false;
            state.isCreateSuccess = true;
            state.isError = false;
            state.error = '';
        },
        createImageCollectionFailed: (state, action) => {
            state.isLoading = false;
            state.isCreateSuccess = false;
            state.isError = true;
            state.error = action.payload;
        },

        clearImageCollectionState: (state, action) => {
            state.isLoading = false;
            state.promptCollections = [];
            state.isCreateSuccess = false;
            state.isError = true;
            state.error = action.payload;
        },

        clearIsCreateSuccessState: (state, action) => {
            state.isCreateSuccess = false;
        },
    }
});

export const {
    getPromptCollection,
    getPromptCollectionSuccess,
    getPromptCollectionFailed,
    createPromptCollection,
    createPromptCollectionSuccess,
    createPromptCollectionFailed,
    clearPromptCollectionState,
    createImageCollection,
    createImageCollectionSuccess,
    createImageCollectionFailed,
    clearImageCollectionState,
    clearIsCreateSuccessState
} = promptCollectionSlice.actions;

export default promptCollectionSlice.reducer;
