import React, {useEffect, useState} from 'react';
import {Box, FormHelperText} from '@mui/material';
import PlayPenEditor from '../playpen/PlayPenEditor';
import StyledDropzone from '../../assets/global/ImageUpload/StyledDropzone';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import {imageToPrompt, imageToPromptStateClear} from '../../state/features/imageToPrompt/imageToPromptSlice';
import {selectImageToPromptState} from '../../state/features/imageToPrompt/imageToPromptSelector';
import FeatureInput from '../common/featureInput/FeatureInput';
import FeatureOutput from '../common/featureOutput/FeatureOutput';
import FeatureOutputNoResponse from '../common/featureOutput/FeatureOutputNoResponse';
import {showErrorToaster} from "../../helpers/utility/toaster";
import {creditSettingsImageContent1024x1024, creditSettingSTextContent} from "../../helpers/constant/coreConstant";
import BalanceCheck from "../../assets/global/BalanceCheck/BalanceCheck";

const ImageToPrompt = () => {
    const dispatch = useDispatch();
    const [imageInput, setImageInput] = useState([]);

    const {imageToPromptData, isLoading} = useSelector(selectImageToPromptState);

    const {
        handleSubmit,
    } = useForm();

    const onSubmit = data => {
        if(!imageInput[0]){
            showErrorToaster("Add an image")
        } else{
            let formData = new FormData();
            formData.append('image_file', imageInput[0]);
            dispatch(imageToPrompt(formData));
        }

    };

    const setImageFile = image => {
        setImageInput(image);
    };
    const handleClearInput = () => {
        setImageInput(null);
    };

    useEffect(() => {
        return () => dispatch(imageToPromptStateClear());
    }, []);

    return (
        <Box
            sx={{
                width: '100%',
                height: '90vh',
                paddingTop: '50px',
                backgroundColor: '#f3f4f6',
                overflow: 'auto'
            }}
        >
            <FeatureInput
                headerTitle={'Image to Prompt'}
                helpkey={'IMAGETOPROMPT'}
                clearInput={'none'}
                subtitle="Generate Image to Prompt"
                footer={{generate: handleSubmit(onSubmit), isLoading, handleClearInput}}
            >
                <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                    <StyledDropzone setImageFile={setImageFile}/>
                    <FormHelperText sx={{color: '#FF8800'}}>This is a test release. On occasion, you could get an
                        inappropriate answer.</FormHelperText>

                </Box>
                <BalanceCheck content_type={creditSettingSTextContent} amount_data={1000} />
            </FeatureInput>

            <FeatureOutput
                newOutput={
                    <>
                        {imageToPromptData ? (
                            <PlayPenEditor value={imageToPromptData}/>
                        ) : (
                            <FeatureOutputNoResponse/>
                        )}
                    </>
                }
            />

            {/* {imageToPromptData && (
        <Box
          component="form"
          sx={{
            backgroundColor: '#ffffff',
            margin: 'auto',
            marginTop: '40px',
            padding: '20px',
            borderRadius: '5px'
          }}
          noValidate
          autoComplete="off"
        >
          {imageToPromptData && (
            <Grid container>
              <PlayPenEditor value={imageToPromptData} />
            </Grid>
          )}
        </Box>
      )} */}
        </Box>
    );
};

export default ImageToPrompt;
