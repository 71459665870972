import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import store from "./state/store";
import './assets/scss/main.scss';
import Routes from "./router";
import { BrowserRouter } from "react-router-dom";
import GlobalCheckLayout from "./components/layout/GlobalCheckLayout";

ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            <React.StrictMode>
                <GlobalCheckLayout />
                <Routes/>
            </React.StrictMode>
        </Provider>
    </BrowserRouter>,
    document.getElementById('root')
);
