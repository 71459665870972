import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import Styles from "../../styles/OnBoard.module.css";

const PackageOnBoardHeader = ({ title = "", subtitle = "" }) => {
    return (
        <Box sx={{ pt: 3, pb: 3 }}>
            <Box className={Styles.container}>
                <Typography variant='h5' color='text.primary'>
                    {title}
                </Typography>
                <Typography variant='body1' color='text.secondary'>
                    {subtitle}
                </Typography>
            </Box>
            <Divider />
        </Box>
    );
};

export default PackageOnBoardHeader;
