import AppLayout from "../components/layout/AppLayout/AppLayout";
import {Navigate} from "react-router-dom";
import ChatGptMain from "../components/chat/ChatGptMain";
import Prompt from "../components/prompt/Prompt";
import Collection from "../components/category/Collection";
import AccountSettings from "../components/accountSetting/AccountSettings";
import PromptDetailsTwo from "../components/prompt/PromptDetailsTwo";
import CollectionDetails from "../components/category/CollectionDetails";
import ChatPromptCategory from "../components/chat/ChatPromptCategory";
import PresetTemplate from "../components/PresetTemplate/PresetTemplate";
import PresetTemplateDetails from "../components/PresetTemplateDetails/PresetTemplateDetails";
import ImageInpainting from "../components/imageInpainting/ImageInpainting";
import PlayPen from "../components/playpen/PlayPen";
import ImageCreation from "../components/ImageTricks/ImageCreation"
import ImageVariation from "../components/ImageVariation/ImageVariation"
import ImageRepurposing from "../components/ImageRepurposing/ImageRepurposing"
import AiTranscription from "../components/AiTranscription/AiTranscription"
import ImageToPrompt from "../components/imageToPrompt/ImageToPrompt";
import ImageToAltText from "../components/imageToAltText/ImageToAltText";
import PaymentCard from "../components/Payment/PaymentCard";
import ImageCollection from "../components/category/ImageCollection";
import Packages from "../components/packages/components/Packages";
import UserBilling from "../components/userBilling/UserBilling";
import Dashboard from "../components/Dashboard/Dashboard";
import MemeMaker from "../components/memeMaker/MemeMaker";
import TextToMeme from "../components/TextToMeme/TextToMeme";
import TextToMemeEdit from "../components/TextToMeme/TextToMemeEdit";
import ThemedMemes from "../components/ThemedMemes/ThemedMemes";
import SearchTemplatesForMemes from "../components/SearchTemplatesForMemes/SearchTemplateForMemes";
import EditDetails from "../components/memeMaker/EditDetails";
import MemeEdit from "../components/common/MemeEdit/MemeEdit";
import GifEdit from "../components/GifEdit/GifEdit";

const MainRoutes = {
    path: "/",
    element: <AppLayout/>,
    children: [
        {
            path: "",
            element: <Dashboard element={<Navigate to="dashboard" replace={true}/>}/>,
        },
        {
            path: "/chat",
            element: <ChatGptMain/>,
        },
        {
            path: "/prompt",
            element: <Prompt/>,
        },
        {
            path: "/collections",
            element: <Collection/>,
        },
        {
            path: "category/:categoryId",
            element: <ChatPromptCategory/>,
        },
        {
            path: "prompt/:promptId",
            element: <PromptDetailsTwo/>,
        },
        {
            path: "/accountSetting",
            element: <AccountSettings/>,
        },
        {
            path: "/collection/:collectionId",
            element: <CollectionDetails/>,
        },
        {
            path: "/image-collection/:collectionId",
            element: <ImageCollection/>,
        },
        {
            path: "/presets/templates",
            element: <PresetTemplate/>,
        },
        {
            path: "/presets/templates/:templateId",
            element: <PresetTemplateDetails/>,
        },
        {
            path: "/play-pen",
            element: <PlayPen/>,
        },
        {
            path: "/image-in-painting",
            element: <ImageInpainting/>,

        },
        {
            path: "/image-creation",
            element: <ImageCreation/>,
        },
        {
            path: "image-variation",
            element: <ImageVariation/>,
        },
        {
            path: "/image-repurposing",
            element: <ImageRepurposing/>
        },
        {
            path: "/audio-transcription",
            element: <AiTranscription/>
        },
        {
            path: "/image-to-prompt",
            element: <ImageToPrompt/>
        },
        {
            path: "/image-to-alt-text",
            element: <ImageToAltText/>
        },
        {
            path: "/payment",
            element: <PaymentCard/>
        },
        {
            path: "/settings/packages",
            element: <Packages/>
        },
        {
            path: "/settings/billing",
            element: <UserBilling/>,
        },
        {
            path: "/dashboard",
            element: <Dashboard/>
        },
        {
            path: "/text-to-meme",
            element: <TextToMeme/>
        },
        {
            path: "/text-to-meme/edit",
            element: <TextToMemeEdit/>
        },
        {
            path: "/themed-memes",
            element: <ThemedMemes/>
        },
        {
            path: "/search-template",
            element: <SearchTemplatesForMemes/>
        },
        // {
        //     path: "/meme-maker",
        //     element: <MemeMaker />
        // },
        {
            path: "/meme-maker",
            element: <MemeMaker/>
        },
        {
            path: "/meme-edit",
            element: <EditDetails/>
        },
        {
            path: "/gif-edit",
            element: <GifEdit/>
        },

    ],
};

export default MainRoutes;
