import React, {useEffect} from 'react';
import {Typography} from '@mui/material';
import CookieService from '../../services/cookie/cookie.service';
import {useDispatch, useSelector} from 'react-redux';
import {attemptSwitchAccount} from '../../state/features/user/userSlice';
import {useNavigate} from "react-router-dom";
import hasAuthenticatedUser from "../../helpers/utility/hasAuthenticateUser";
import {showErrorToaster} from "../../helpers/utility/toaster";
import SwitchAccountLoader from "./SwitchAccountLoader";

const SwitchUserAccount = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {isLogin} = useSelector(state => state.user);

    useEffect(() => {
        if (isLogin) {
            const hasAccessToken = hasAuthenticatedUser();
            if (hasAccessToken) navigate('/chat');
        }
    }, [isLogin]);

    useEffect(() => {
        try {
            localStorage.clear();
            const parsedResponse = JSON.parse(window.name.substring('SWITCH_ACCOUNT_PREFIX'.length));

            if (parsedResponse) {
                CookieService.setCookie(
                    process.env.REACT_APP_ACCESS_TOKEN,
                    parsedResponse?.access_token,
                    ''
                );

                const data = {
                    user_id : parsedResponse.user_id
                }
                dispatch(attemptSwitchAccount(data));
            } else {
                showErrorToaster("Switch Account Failed")
                navigate("/login");
            }
        } catch (err) {
            console.log(err, 'err');
        }
    }, []);

    return (
        <>
            <SwitchAccountLoader />
            <Typography textAlign={'center'} variant={'h4'} color={'text.secondary'}>
                Account Switching...
            </Typography>
        </>
    );
};

export default SwitchUserAccount;
