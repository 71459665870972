import React, {useEffect, useState} from 'react';
import {Box} from "@mui/material";
import EditForm from "./EditForm";
import EditItem from "./EditItem";
import {useNavigate, useSearchParams} from "react-router-dom";
import MemeMakerHeader from "../../assets/global/MemeMaker/MemeMakerHeader";


const EditDetails = ({image}) => {
    let [searchParams, setSearchParams] = useSearchParams();
    let imgUrl = searchParams.get('url');
    let textArray = searchParams.get('text');
    let topBottomText = textArray ? JSON.parse(decodeURIComponent(textArray)) : [
        {
            id: 1,
            value: 'Enter Your Text',
            color: '#ffffff',
            size: 35,
            position_x: 0,
            position_y: 0,
        },
        {
            id: 2,
            value: 'Enter Your Text',
            color: '#ffffff',
            size: 35,
            position_x: 0,
            position_y: 0,
        }];
    const [imageUrl, setImageUrl] = useState('');
    const navigate = useNavigate();
    const [formFields, setFormFields] = useState(topBottomText);
    const [header, setHeader] = useState('');
    const [showHeader, setShowHeader] = useState(false);
    const [footer, setFooter] = useState('');
    const [showFooter, setShowFooter] = useState(false);

    useEffect(() => {
        if (image) {
            const imageLink = URL.createObjectURL(image[0]);
            setImageUrl(imageLink);
        } else if (!image && imgUrl) {
            setImageUrl(imgUrl);
        }
    }, [image, imgUrl]);

    const handleBack = () => {
        navigate(-1)
    }

    const textPosition = (index, position) => {
        let x_axis = position?.x;
        let y_axis = position?.y;
        setFormFields((prevFields) => {
            const updatedFields = [...prevFields];
            updatedFields[index].position_x = x_axis;
            updatedFields[index].position_y = y_axis;
            return updatedFields;
        });
    }

    return (
        <Box sx={{
            margin: '20px 10px'
        }}>
            <MemeMakerHeader
                title={'meme-edit'}
                handleBack={handleBack}
            />
            <Box sx={{display: 'flex', minHeight: '70vh', p: 3, gap: '30px'}}>
                <Box sx={{flexBasis: '750px'}}>
                    <EditForm formFields={formFields} setFormFields={setFormFields} header={header}
                              setHeader={setHeader} showHeader={showHeader} setShowHeader={setShowHeader}
                              footer={footer} setFooter={setFooter} showFooter={showFooter}
                              setShowFooter={setShowFooter}/>
                </Box>

                <Box sx={{flex: 1, padding: '0px 0px 10px 10px'}}>
                    <EditItem imageUrl={imageUrl} formFields={formFields} header={header} footer={footer} textPosition={textPosition}/>
                </Box>
            </Box>
        </Box>
    );
};

export default EditDetails;
