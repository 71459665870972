import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    paymentCardList: [],
    balanceAmountList: [],
    loading: false,
    error: "",
    isAdding: false,
    isChanging: false,
    isRemoving: false,
    isRemoved: false,
    isAddCard: false,
    isUpdating: false,
};

const userPaymentSlice = createSlice({
    name: "userPayment",
    initialState,
    reducers: {
        getUserPaymentCardList: (state) => {
            state.loading = true;
        },
        getUserPaymentCardListSuccess: (state, action) => {
            state.paymentCardList = action.payload;
            state.loading = false;
            state.error = "";
        },
        getUserPaymentCardListFailed: (state, action) => {
            state.paymentCardList = [];
            state.loading = false;
            state.error = action.payload;
        },

        addUserPaymentCard: (state) => {
            state.isAdding = true;
        },
        addUserPaymentCardSuccess: (state, action) => {
            state.isAdding = false;
            state.error = "";
            state.isAddCard = true;
        },
        addUserPaymentCardFailed: (state, action) => {
            state.isAdding = false;
            state.isAddCard = false;
            state.error = action.payload;
        },

        resetUserPaymentCardState: (state) => {
            state.isAddCard = false;
            state.error = "";
        },

        updateUserDefaultPaymentCard: (state) => {
            state.isUpadating = true;
        },
        updateUserDefaultPaymentCardSuccess: (state, action) => {
            state.isUpadating = false;
            state.error = "";
        },
        updateUserDefaultPaymentCardFailed: (state, action) => {
            state.isUpadating = false;
            state.error = action.payload;
        },

        updateUserPaymentCardActiveStatus: (state) => {
            state.isUpadating = true;
        },
        updateUserPaymentCardActiveStatusSuccess: (state, action) => {
            state.isUpadating = false;
            state.error = "";
        },
        updateUserPaymentCardActiveStatusFailed: (state, action) => {
            state.isUpadating = false;
            state.error = action.payload;
        },

        addRechargeBalance: (state) => {
            state.isAdding = true;
        },
        addRechargeBalanceSuccess: (state, action) => {
            state.isAdding = false;
            state.error = "";
            state.isAddCard = true;
        },
        addRechargeBalanceFailed: (state, action) => {
            state.isAdding = false;
            state.isAddCard = false;
            state.error = action.payload;
        },

        configureAutoRecharge: (state) => {
            state.isAdding = true;
        },
        configureAutoRechargeSuccess: (state, action) => {
            state.isAdding = false;
            state.error = "";
            state.isAddCard = true;
        },
        configureAutoRechargeFailed: (state, action) => {
            state.isAdding = false;
            state.isAddCard = false;
            state.error = action.payload;
        },

        changeStatusAutoRecharge: (state) => {
            state.isChanging = true;
        },
        changeStatusAutoRechargeSuccess: (state, action) => {
            state.isChanging = false;
            state.error = "";
        },
        changeStatusAutoRechargeFailed: (state, action) => {
            state.isChanging = false;
            state.error = action.payload;
        },

        removeAutoRecharge: (state) => {
            state.isRemoving = true;
        },
        removeAutoRechargeSuccess: (state, action) => {
            state.isRemoving = false;
            state.error = "";
        },
        removeAutoRechargeFailed: (state, action) => {
            state.isRemoving = false;
            state.error = action.payload;
        },

        getBalanceAmountList: (state) => {
            state.loading = true;
        },
        getBalanceAmountListSuccess: (state, action) => {
            state.balanceAmountList = action.payload;
            state.loading = false;
            state.error = "";
        },
        getBalanceAmountListFailed: (state, action) => {
            state.balanceAmountList = [];
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const {
    getUserPaymentCardList,
    getUserPaymentCardListSuccess,
    getUserPaymentCardListFailed,
    addUserPaymentCard,
    addUserPaymentCardSuccess,
    addUserPaymentCardFailed,
    resetUserPaymentCardState,
    updateUserDefaultPaymentCard,
    updateUserDefaultPaymentCardSuccess,
    updateUserDefaultPaymentCardFailed,
    updateUserPaymentCardActiveStatus,
    updateUserPaymentCardActiveStatusSuccess,
    updateUserPaymentCardActiveStatusFailed,
    addRechargeBalance,
    addRechargeBalanceSuccess,
    addRechargeBalanceFailed,
    configureAutoRecharge,
    configureAutoRechargeSuccess,
    configureAutoRechargeFailed,
    changeStatusAutoRecharge,
    changeStatusAutoRechargeSuccess,
    changeStatusAutoRechargeFailed,
    removeAutoRecharge,
    removeAutoRechargeSuccess,
    removeAutoRechargeFailed,
    getBalanceAmountList,
    getBalanceAmountListSuccess,
    getBalanceAmountListFailed,
} = userPaymentSlice.actions;

export default userPaymentSlice.reducer;
