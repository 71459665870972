import React, {useState} from 'react';
import Button from "@mui/material/Button";
import {templateSearch} from "../../../state/features/textToMeme/templateSearch/templateSearchSlice";
import TemplateSearch from "../../../components/ThemedMemes/TemplateSearch";
import FullPageDrawer from "../Drawer/FullPageDrawer";
import {useDispatch} from "react-redux";

const TemplateSearchModal = ({title=''}) => {
    const [openTemplateDrawer, setOpenTemplateDrawer] = useState(false);
    const dispatch = useDispatch();
    const toggleViewTemplates = () => setOpenTemplateDrawer(prevState => !prevState);

    const handleTemplateModalOpen = () => {
        toggleViewTemplates();
        // const API_KEY = 'AIzaSyDdchK3f7lWyo1AVNdY64CaH5JG7MQ9EjM';
        const API_KEY = process.env.REACT_APP_TENOR_API_KEY;
        dispatch(
            templateSearch({
                q: 'hello',
                key: API_KEY,
                pos: '20',
                limit: '20'
            })
        );
    }
    const handleSearchModalClose = () => {
        toggleViewTemplates();
    }

    return(
        <>
            <Button variant="outlined" color="primary" onClick={handleTemplateModalOpen}>
                Pickup from Templates
            </Button>

            <FullPageDrawer
                title="Select Theme"
                open={openTemplateDrawer}
                toggleDrawer={toggleViewTemplates}
                width="75%"
                height="90%"
            >
                <TemplateSearch handleSearchModalClose={handleSearchModalClose} title={title}/>

            </FullPageDrawer>
        </>
    );
}
export default TemplateSearchModal;