import {Controller, useForm} from 'react-hook-form';
import {Link, useNavigate} from 'react-router-dom';
import React, {useEffect} from 'react';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {attemptResetPassword, clearResetPasswordExceptEmailState} from '../../state/features/user/userSlice';
import {useDispatch, useSelector} from 'react-redux';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {registerPattern} from '../../helpers/constant/validationPattern';
import LoadingButton from "../../assets/global/Button/LoadingButton";

const Login = () => {
    const theme = createTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {resetPasswordState, pending} = useSelector(state => state.user);


    const {
        register,
        control,
        handleSubmit,
        formState: {errors},
        setValue
    } = useForm({
        mode: 'onSubmit'
    });

    useEffect(() => {
        if (resetPasswordState.isMailSend) navigate("/reset-password");
    }, [resetPasswordState.isMailSend]);

    // ## Clear Reset Password State When Leave the page
    useEffect(() => {
        return () => dispatch(clearResetPasswordExceptEmailState());
    }, []);

    const onSubmit = data => {
        dispatch(attemptResetPassword(data));
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs"
                       sx={{backgroundColor: '#fff', boxShadow: '0 4px 24px 0 rgba(34, 41, 47, 0.1)'}}>
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: '0',
                        paddingTop: '30px',
                        paddingBottom: '30px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                        // marginTop: 8,
                        // display: 'flex',
                        // flexDirection: 'column',
                        // alignItems: 'center'
                    }}
                >
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '15px'}}>
                        <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                            <LockOutlinedIcon/>
                        </Avatar>
                        <Typography component="h6" variant="h6">
                            Forgot your password?
                        </Typography>
                    </Box>
                    <Box sx={{margin: '0', padding: '0'}}>
                        <Typography component="h6" variant="h6" sx={{textAlign: 'left'}}>
                            Welcome to OLK9AI! 👋
                        </Typography>
                        <Typography component="p" variant="body2">
                            Please provide your email address here.
                        </Typography>
                    </Box>
                    {/* <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Forgot your password?
                    </Typography> */}
                    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{mt: 1}}>
                        <Controller
                            control={control}
                            name="email"
                            defaultValue=""
                            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                <FormControl fullWidth required error={!!(invalid && error.message)}>
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        required
                                        value={value}
                                        onChange={onChange}
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        autoComplete="nope"
                                        error={!!(invalid && error.message)}
                                        helperText={invalid && error.message}
                                    />
                                </FormControl>
                            )}
                            rules={registerPattern.email}
                        />

                        <LoadingButton
                            isLoading={pending}
                            fullWidth
                            loadingText={'Continue'}
                            type={'submit'}
                            color={'primary'}
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                        >
                            Continue
                        </LoadingButton>

                        <Grid container>
                            <Grid item>
                                <Link to="/login" variant="body2"
                                      style={{fontSize: '14px', textDecoration: 'none', color: '#1976d2'}}>
                                    {'Back to sign in'}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
};

export default Login;
