class ImagePNGConvater {
    getUrlExtension = (url) => {
        const path = url.split('/').pop();
        const extension = path.split('.').pop().toLowerCase();

        return extension;
    };

    convertToPng = async (imageUrl) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = 'anonymous';
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const context = canvas.getContext('2d');
                context.drawImage(img, 0, 0);

                try {
                    const pngDataUrl = canvas.toDataURL('image/png');
                    resolve(pngDataUrl);
                } catch (error) {
                    reject(error);
                }
            };
            img.onerror = (error) => reject(error);
            img.src = imageUrl;
        });
    };
}

export default new ImagePNGConvater();