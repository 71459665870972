import React from "react";
import { Box } from "@mui/material";
import PackageBuyStripe from "../Payment/PackageBuyStripe";

const BillingInfoView = ({ ...rest }) => {
    return (
        <Box>
            <PackageBuyStripe {...rest} />
        </Box>
    );
};

export default BillingInfoView;
