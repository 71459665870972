import React, {useEffect, useState} from 'react';
import {TypeAnimation} from 'react-type-animation';
import {Avatar, Box, IconButton, Stack, styled, Typography} from '@mui/material';
import MessageIcon from '@mui/icons-material/Message';
import CoreButton from '../../assets/global/Button/CoreButton';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {showSuccessToaster} from '../../helpers/utility/toaster';
import PromptCollectionForm from '../prompt/PromptCollectionForm';
import FullPageDrawer from '../../assets/global/Drawer/FullPageDrawer';
import {createPromptCollection} from '../../state/features/promptCollection/promptCollectionSlice';
import {useDispatch, useSelector} from 'react-redux';
import {selectChatGPTState} from '../../state/features/chatGPT/chatGPTSelector';

const AnswerBox = styled(Box)(({theme}) => ({
    backgroundColor: '#2c3e50',
    borderRadius:'15px'
}));

const AnswerContent = ({message, question, arrayIndex, onScrollBottom}) => {
    const dispatch = useDispatch();
    const {content = ''} = message || {};
    const [viewDetailsPageShow, setViewDetailsPageShow] = useState(false);
    const [selectedAnswerContent, setSelectedAnswerContent] = useState(null);

    const {messages = [], isLoading} = useSelector(selectChatGPTState);

    const toggleViewDetailsPage = () => setViewDetailsPageShow(prevState => !prevState);

    useEffect(() => {
        onScrollBottom();
    }, [content]);

    const cursor = !content;

    const handleViewDetailsPage = data => {
        setSelectedAnswerContent(data);
        toggleViewDetailsPage();
    };

    const handleCopyAnswerContent = data => {
        navigator.clipboard.writeText(data);
        showSuccessToaster('Text copied');
    };

    const handlePromptCollectionSubmit = data => {
        const payload = {};
        payload.collection_id = data.collection_id || '';

        let valueAtMessagesIndex = null

        if (arrayIndex > 0) {
            valueAtMessagesIndex = messages[arrayIndex - 1];
        } else {
            valueAtMessagesIndex = messages[arrayIndex];
        }

        payload.text = valueAtMessagesIndex?.content || 'N/A';
        payload.response = selectedAnswerContent?.content || '';

        dispatch(createPromptCollection(payload));
    };

    return (
        <>
            <AnswerBox p={3}>
                <Stack
                    direction={'row'}
                    spacing={2}
                    alignItems={'flex-start'}
                    sx={{
                        width: {
                            xs: '300px',
                            sm: '500px',
                            md: '700px',
                            lg: '800px',
                            xxl: '900px',
                            margin: '0 auto'
                        }
                    }}
                >
                    <Avatar sx={{mt: -1}}>
                        <MessageIcon/>
                    </Avatar>
                    <Typography variant={'body1'} color={'common.white'}>
                        <TypeAnimation
                            sequence={[content, () => onScrollBottom()]}
                            style={{whiteSpace: 'pre-line', padding: '10px'}}
                            speed={85} // Custom Speed from 1-99 - Default Speed: 40
                            wrapper="span" // Animation will be rendered as a <span>
                            repeat={0} // Repeat this Animation Sequence infinitely
                            cursor={cursor}
                        />
                    </Typography>

                    <Box sx={{display: 'flex'}}>
                        <CoreButton
                            sx={{
                                padding: '0 !important'
                            }}
                            type={'submit'}
                            size={'small'}
                            onClick={() => handleViewDetailsPage(message)}
                        >
                            <IconButton sx={{color: '#fff', padding: '6px 10px'}}>
                                <SaveAsIcon/>
                            </IconButton>
                        </CoreButton>

                        <CoreButton
                            sx={{
                                padding: '0 !important'
                            }}
                            type={'submit'}
                            size={'small'}
                            onClick={() => handleCopyAnswerContent(content)}
                        >
                            <IconButton sx={{color: '#fff', padding: '6px 10px'}}>
                                <ContentCopyIcon/>
                            </IconButton>
                        </CoreButton>
                    </Box>
                </Stack>
            </AnswerBox>

            <FullPageDrawer
                title="Save to collection"
                open={viewDetailsPageShow}
                toggleDrawer={toggleViewDetailsPage}
                width="35%"
                height="60%"
            >
                <PromptCollectionForm
                    toggleDrawer={toggleViewDetailsPage}
                    handlePromptCollectionSubmit={handlePromptCollectionSubmit}
                />
            </FullPageDrawer>
        </>
    );
};

export default AnswerContent;
