import {all, put} from 'redux-saga/effects';
import {call, takeEvery} from '@redux-saga/core/effects';
import {imageCreationFailed, imageCreationSuccess} from "./imageCreationSlice";
import imageCreationService from "../../../services/ImageCreation.service";
import {showSuccessToaster} from "../../../helpers/utility/toaster";

function* imageCreationWatcher() {
    yield takeEvery('imageCreation/imageCreation', generateImageCreationSaga);
}

function* generateImageCreationSaga(action) {
    try {
        const response = yield call(imageCreationService.ImageCreation, action.payload);

        if (response.status) {
            yield put(imageCreationSuccess(response.data));
            showSuccessToaster(response.message)
        } else {
            yield put(imageCreationFailed(response.message));
        }
    } catch (err) {
        yield put(imageCreationFailed(err.message));
    }
}


export default function* imageCreationSaga() {
    yield all([imageCreationWatcher()]);
}
