import * as React from 'react';
import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import ResponseImage from './../ImageTricks/ResponseImage';
import StyledDropzone from '../../assets/global/ImageUpload/StyledDropzone';
import {useDispatch, useSelector} from 'react-redux';
import {Controller, useForm} from 'react-hook-form';
import {selectImageVariationState} from '../../state/features/imageVariation/imageVariationSelector';
import FormControl from '@mui/material/FormControl';
import {FormHelperText, InputLabel, Select} from '@mui/material';
import {imageCreationPattern, imageVariationPattern} from '../../helpers/constant/validationPattern';
import {imageVariation, imageVariationStateClear} from '../../state/features/imageVariation/imageVariationSlice';
import {showErrorToaster} from '../../helpers/utility/toaster';
import FeatureInput from '../common/featureInput/FeatureInput';
import FeatureOutputNoResponse from '../common/featureOutput/FeatureOutputNoResponse';
import FeatureOutput from '../common/featureOutput/FeatureOutput';
import {
    creditSettingsImageContent1024x1024,
    creditSettingsImageContent256x256, creditSettingsImageContent512x512
} from "../../helpers/constant/coreConstant";
import BalanceCheck from "../../assets/global/BalanceCheck/BalanceCheck";

const ImageVariation = () => {
    const dispatch = useDispatch();
    const [imageInput, setImageInput] = useState([]);
    const [imageSize, setImageSize] = useState('1024x1024');
    const [contentType, setContentType] = useState(creditSettingsImageContent1024x1024);
    const [amount, setAmount] = useState(3);

    const {imageVariationData, isLoading} = useSelector(selectImageVariationState);

    const {
        handleSubmit,
        control,
        reset,
        formState: {errors},
        clearErrors
    } = useForm({
        defaultValues: {
            total_image: '',
            image_size: '',
            image_shape: '',
            image_keyword: ''
        }
    });

    useEffect(() => {
        if (imageSize === '256x256') {
            setContentType(creditSettingsImageContent256x256);
        } else if (imageSize === '512x512') {
            setContentType(creditSettingsImageContent512x512);
        } else if (imageSize === '1024x1024') {
            setContentType(creditSettingsImageContent1024x1024);
        }
    }, [imageSize]);

    const handleImageSize = data => {
        setImageSize(data.target.value);
    };
    const handleAmount = data => {
        setAmount(data.target.value);
    };

    const onSubmit = data => {
        if (!imageInput[0]) {
            showErrorToaster('Add an Image');
        } else {
            const img = new Image();
            img.src = URL.createObjectURL(imageInput[0]);
            img.onload = function () {
                if (img.height !== img.width && !imageInput[0].name.endsWith('.png')) {
                    showErrorToaster('Image dimensions should be equal and extension should be PNG');
                    return;
                } else if (img.height !== img.width) {
                    showErrorToaster('Image dimensions should be equal');
                    return;
                } else if (!imageInput[0].name.endsWith('.png')) {
                    showErrorToaster('Image extension should be PNG');
                    return;
                } else {
                    let formData = new FormData();
                    formData.append('image_file', imageInput[0]);
                    formData.append('total_image', data.total_image);
                    formData.append('image_size', data.image_size);
                    formData.append('image_shape', data.image_shape);
                    formData.append('image_keyword', data.image_keyword);
                    dispatch(imageVariation(formData));
                }
            };
        }
    };

    const setImageFile = image => {
        setImageInput(image);
    };

    const handleClearInput = () => {
        reset();
    };

    let imageGrid = null;

    switch (imageVariationData?.length) {
        case 1:
            imageGrid = 12;
            break;

        case 2:
            imageGrid = 6;
            break;

        default:
            imageGrid = 4;
            break;
    }

    useEffect(() => {
        return () => dispatch(imageVariationStateClear());
    }, []);

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    height: '90vh',
                    paddingTop: '50px',
                    backgroundColor: '#f3f4f6',
                    overflow: 'auto'
                }}
            >
                <FeatureInput
                    headerTitle={'Image Variation'}
                    helpkey={'IMAGEVARIATION'}
                    subtitle="Generate Image Variation"
                    footer={{generate: handleSubmit(onSubmit), isLoading, handleClearInput}}
                >
                    <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Controller
                                    control={control}
                                    name="image_shape"
                                    defaultValue=""
                                    render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                        <FormControl size="small" fullWidth error={!!(invalid && error.message)}>
                                            <InputLabel id="collection-label" required>
                                                Aspect ratio
                                            </InputLabel>
                                            <Select
                                                labelId="input-label"
                                                id="input-select"
                                                defaultValue="square"
                                                size="small"
                                                value={value}
                                                label="Aspect ratio"
                                                onChange={onChange}
                                            >
                                                <MenuItem key="SQUARE" value="square">
                                                    Square
                                                </MenuItem>
                                                <MenuItem key="WIDESCREEN" value="widescreen">
                                                    Widescreen
                                                </MenuItem>
                                                <MenuItem key="VERTICAL" value="vertical">
                                                    Vertical
                                                </MenuItem>
                                            </Select>
                                            <FormHelperText>{invalid && error?.message}</FormHelperText>
                                        </FormControl>
                                    )}
                                    rules={imageVariationPattern.imageShape}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <Controller
                                    control={control}
                                    name="image_size"
                                    defaultValue=""
                                    render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                        <FormControl size="small" fullWidth error={!!(invalid && error.message)}>
                                            <InputLabel id="collection-label" required>
                                                Image Size
                                            </InputLabel>
                                            <Select
                                                labelId="input-label"
                                                id="input-select"
                                                defaultValue="1024x1024"
                                                size="small"
                                                value={value}
                                                label="Image Size"
                                                onChange={value => {
                                                    onChange(value);
                                                    handleImageSize(value);
                                                }}
                                            >
                                                <MenuItem key="HD" value="256x256">
                                                    HD
                                                </MenuItem>
                                                <MenuItem key="2K" value="512x512">
                                                    2K
                                                </MenuItem>
                                                <MenuItem key="4K" value="1024x1024">
                                                    4K
                                                </MenuItem>
                                            </Select>
                                            <FormHelperText>{invalid && error?.message}</FormHelperText>
                                        </FormControl>
                                    )}
                                    rules={imageVariationPattern.imageSize}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Box>
                                    <StyledDropzone setImageFile={setImageFile}/>
                                    <FormHelperText sx={{color: '#FF8800'}}>
                                        Image dimensions should be equal and transparent. Image Extension should be PNG
                                    </FormHelperText>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="image_keyword"
                                    defaultValue=""
                                    render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                        <TextField
                                            id="outlined-textarea"
                                            label="What do you want to avoid? *"
                                            placeholder="Please enter phrases as keywords"
                                            style={{width: '100%'}}
                                            size="small"
                                            value={value}
                                            onChange={value => {
                                                onChange(value);
                                            }}
                                            error={!!(invalid && error.message)}
                                            helperText={invalid && error.message}
                                        />
                                    )}
                                    rules={imageVariationPattern.imageKeyword}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="total_image"
                                    defaultValue=""
                                    render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                        <FormControl size="small" fullWidth error={!!(invalid && error.message)}>
                                            <TextField
                                                id="outlined-select-currency"
                                                select
                                                label="Number of Image *"
                                                value={value}
                                                onChange={value => {
                                                    onChange(value);
                                                    handleAmount(value);
                                                }}
                                                defaultValue="HD"
                                                size="small"
                                                sx={{width: '100%!important'}}
                                            >
                                                <MenuItem key="1" value="1">
                                                    1
                                                </MenuItem>
                                                <MenuItem key="2" value="2">
                                                    2
                                                </MenuItem>
                                                <MenuItem key="3" value="3">
                                                    3
                                                </MenuItem>
                                            </TextField>

                                            <FormHelperText>{invalid && error?.message}</FormHelperText>
                                        </FormControl>
                                    )}
                                    rules={imageCreationPattern.number_of_image}
                                />

                                {/*<Alert sx={{mt: 2}} severity="info">This is an info alert — check it out!</Alert>*/}
                            </Grid>
                        </Grid>
                    </Box>
                    <BalanceCheck content_type={contentType} amount_data={amount}/>

                </FeatureInput>

                <FeatureOutput
                    newOutput={
                        <>
                            {Array.isArray(imageVariationData) && imageVariationData.length > 0 ? (
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': {m: 1, width: '25ch'}
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <Grid container spacing={4}>
                                        {imageVariationData.map(output => (
                                            <ResponseImage imageGrid={imageGrid} image={output.url}/>
                                        ))}
                                    </Grid>
                                </Box>
                            ) : (
                                <FeatureOutputNoResponse/>
                            )}
                        </>
                    }
                />
            </Box>
        </>
    );
};

export default ImageVariation;
