import React, {useEffect} from 'react';
import {Box} from '@mui/material';
import PresetTemplateHeader from './PresetTemplateHeader';
import PresetTemplateSearch from './PresetTemplateSearch';
import PresetTemplateCategory from './PresetTemplateCategory';
import PresetTemplateList from './PresetTemplateList';
import {useDispatch} from 'react-redux';
import {resetFilter} from '../../state/features/presetTemplate/presetTemplateSlice';

const PresetTemplate = () => {
    const dispatch = useDispatch();
    // unsubscribe or reset state
    useEffect(() => {
        return () => dispatch(resetFilter());
    }, []);

    return (
        // backgroundColor: '#f3f4f6'
        <Box sx={{width: '100%', height: '96%', maxHeight: 'calc(100vh - 100px)', overflowY: 'auto' }}>
            <Box sx={{m: 'auto', p: 3}}>
                <PresetTemplateHeader/>
                <PresetTemplateSearch/>
                <PresetTemplateCategory/>
                <PresetTemplateList/>
            </Box>
        </Box>
    );
};

export default PresetTemplate;
