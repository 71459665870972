import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    categories: [],
    presetTemplateCategories: [],
    page: 1,
    perPage: 10,
    total: 10,
    isLoading: false,
    isError: false,
    error: '',
    isEdited: false,
};

const categorySlice = createSlice({
    name: 'categories',
    initialState: initialState,
    reducers: {
        getCategory: (state, action) => {
            state.isLoading = true;
        },
        getCategorySuccess: (state, action) => {
            state.categories = action.payload;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        getCategoryFailed: (state, action) => {
            state.categories = [];
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },

        getPresetTemplateCategory: (state, action) => {
            state.isLoading = true;
        },
        getPresetTemplateCategorySuccess: (state, action) => {
            state.presetTemplateCategories = action.payload;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        getPresetTemplateCategoryFailed: (state, action) => {
            state.presetTemplateCategories = [];
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },
    }
});

export const {
    getCategory,
    getCategorySuccess,
    getCategoryFailed,
    getPresetTemplateCategory,
    getPresetTemplateCategorySuccess,
    getPresetTemplateCategoryFailed
} = categorySlice.actions;

export default categorySlice.reducer;
