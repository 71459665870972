// ## region [Agency Package table constant]

//For Basic
export const USER_PACKAGE_LIMIT_KEY__ADD_DOLLAR_BALANCE = 1;
export const USER_PACKAGE_LIMIT_KEY__TEAM_MEMBER = 2;
export const USER_PACKAGE_LIMIT_KEY__CLIENT = 3;

//For Reputation Manage
export const USER_PACKAGE_LIMIT_KEY__LOCATION = 4;
export const USER_PACKAGE_LIMIT_KEY__EMAIL_REQUEST = 5;
export const USER_PACKAGE_LIMIT_KEY__SMS_REQUEST = 6;
export const USER_PACKAGE_LIMIT_KEY__REVIEW_REQUEST = 7;

// #region [Packages table constants]
export const PACKAGE_FEATURE__KEY__TEMPLATE = 1;
export const PACKAGE_FEATURE__KEY__COLLECTION = 2;
export const PACKAGE_FEATURE__KEY__CONTENT_REPURPOSING = 3;
export const PACKAGE_FEATURE__KEY__PLAY_PEN = 4;
export const PACKAGE_FEATURE__KEY__IMAGE_CREATION = 5;
export const PACKAGE_FEATURE__KEY__IMAGE_IN_PAINTING = 6;
export const PACKAGE_FEATURE__KEY__IMAGE_VARIATION = 7;
export const PACKAGE_FEATURE__KEY__IMAGE_TO_PROMPT = 8;
export const PACKAGE_FEATURE__KEY__IMAGE_TO_ALT = 9;
export const PACKAGE_FEATURE__KEY__AUDIO = 10;


