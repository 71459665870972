import {Box, Button, Stack, Typography} from '@mui/material';
import React, {useState} from 'react';
import {HelpOutline} from "@mui/icons-material";
import FullPageDrawer from "../../../assets/global/Drawer/FullPageDrawer";
import PlayHelpertext from "../../Helpertext/PlaypenHelpertext";
import ContentRepurposingHelpertext from "../../Helpertext/ContentRePurposingHelperText";
import ImageCreationHelpertext from "../../Helpertext/ImageCreationHelperText";
import ImageToPromptHelperText from "../../Helpertext/ImageToPromptHelperText";
import ImageToAltTextHelperText from "../../Helpertext/ImageToAltTextHelperText";
import ImageInpaintingHelperText from "../../Helpertext/ImageInpaintingHelperText";
import ImageVariationHelperText from "../../Helpertext/ImageVariationHelperText";
import AITranscriptionHelpertext from "../../Helpertext/AITranscriptionHelperText";

const FeatureInputHeader = ({title = '', helpkey = '', subtitle = 'This will be a short description'}) => {

    const [viewPlaypenDetailsPageShow, setViewPlaypenDetailsPageShow] = useState(false);
    const toggleViewDetailsPlayPenPage = () => setViewPlaypenDetailsPageShow(prevState => !prevState);

    const [viewContentRepurposingDetailsPageShow, setViewContentRepurposingDetailsPageShow] = useState(false);
    const toggleViewDetailsContentRepurposingPage = () => setViewContentRepurposingDetailsPageShow(prevState => !prevState);

    const [viewImageInpaintingDetailsPageShow, setViewImageInpaintingDetailsPageShow] = useState(false);
    const toggleViewImageInpaintingDetailsPageShow = () => setViewImageInpaintingDetailsPageShow(prevState => !prevState);

    const [viewImageCreationDetailsPageShow, setViewImageCreationDetailsPageShow] = useState(false);
    const toggleViewImageCreationDetailsPageShow = () => setViewImageCreationDetailsPageShow(prevState => !prevState);

    const [viewImageToPromptDetailsPageShow, setViewImageToPromptDetailsPageShow] = useState(false);
    const toggleViewImageToPromptDetailsPageShow = () => setViewImageToPromptDetailsPageShow(prevState => !prevState);

    const [viewImageToAltTextDetailsPageShow, setViewImageToAltTextDetailsPageShow] = useState(false);
    const toggleViewImageToAltTextDetailsPageShow = () => setViewImageToAltTextDetailsPageShow(prevState => !prevState);

    const [viewImageVariationDetailsPageShow, setViewImageVariationDetailsPageShow] = useState(false);
    const toggleViewImageVariationDetailsPageShow = () => setViewImageVariationDetailsPageShow(prevState => !prevState);

    const [viewAITranscriptionDetailsPageShow, setViewAITranscriptionDetailsPageShow] = useState(false);
    const toggleViewAITranscriptionDetailsPageShow = () => setViewAITranscriptionDetailsPageShow(prevState => !prevState);


    const handelHelpContent = (helpkey) => {
        if (helpkey.helpkey === "PLAYPEN") {
            toggleViewDetailsPlayPenPage();
        } else if (helpkey.helpkey === "REPURPOSING") {
            toggleViewDetailsContentRepurposingPage()
        } else if (helpkey.helpkey === "IMAGEINPAINTING") {
            toggleViewImageInpaintingDetailsPageShow()
        } else if (helpkey.helpkey === "IMAGECREATION") {
            toggleViewImageCreationDetailsPageShow()
        } else if (helpkey.helpkey === "IMAGETOPROMPT") {
            toggleViewImageToPromptDetailsPageShow()
        } else if (helpkey.helpkey === "IMAGETOALTTEXT") {
            toggleViewImageToAltTextDetailsPageShow()
        } else if (helpkey.helpkey === "IMAGEVARIATION") {
            toggleViewImageVariationDetailsPageShow()
        } else if (helpkey.helpkey === "AITRANSCRIPTION") {
            toggleViewAITranscriptionDetailsPageShow()
        }
    }

    return (
        <Box>
            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start'}}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2} mb={3}>
                    <Box
                        sx={{
                            backgroundColor: false
                                ? 'f0fdf4'
                                : // ? JSON.parse(specificPresetTemplate?.extra).color
                                '#f0fdf4',
                            padding: '12px 12px 6px',
                            borderRadius: '8px'
                        }}
                    >
                        <img
                            src="https://s3.amazonaws.com/olk9ai/upload/user/template/icon/57yG2K16817904171681790417.svg"
                            width="40px"
                            height="40px"
                            alt="ChatGPT Prompt Generator"
                        ></img>
                    </Box>

                    <Box>
                        <Typography
                            gutterBottom
                            variant="h6"
                            component="div"
                            className="max-1line-text"
                            sx={{
                                fontSize: '24px',
                                lineHeight: '1.4',
                                marginBottom: 0,
                                color: 'rgb(17 24 39)',
                                wordBreak: 'break-word'
                            }}
                        >
                            {title}
                        </Typography>

                        <Typography
                            variant="body2"
                            color="text.secondary"
                            className="max-3line-text"
                            sx={{
                                marginBottom: '5px',
                                marginTop: '0px',
                                fontSize: '18px',
                                fontWeight: '350!important',
                                color: 'rgb(107 114 128)',
                                lineHeight: '1.4',
                                wordBreak: 'break-word'
                            }}
                        >
                            {subtitle}
                        </Typography>
                    </Box>
                </Stack>

                <Button
                    sx={{backgroundImage: 'linear-gradient(to right, #9333ea, #4f46e5)'}}
                    variant="contained"
                    onClick={() => handelHelpContent({helpkey})}
                >
                    <HelpOutline sx={{marginRight: '2px'}}/>
                     Help
                </Button>
            </Box>


            {/*for playpen*/}
            <FullPageDrawer
                title="PlayPen Feature Description"
                open={viewPlaypenDetailsPageShow}
                toggleDrawer={toggleViewDetailsPlayPenPage}
                width="75%"
                height="90%"
            >
                <PlayHelpertext/>

            </FullPageDrawer>

            {/*for Content Repourposing*/}
            <FullPageDrawer
                title="Content Repurposing Features Description"
                open={viewContentRepurposingDetailsPageShow}
                toggleDrawer={toggleViewDetailsContentRepurposingPage}
                width="75%"
                height="90%"
            >
                <ContentRepurposingHelpertext/>

            </FullPageDrawer>

            {/*For Image Inpainting*/}
            <FullPageDrawer
                title="Image Inpainting Features Description"
                open={viewImageInpaintingDetailsPageShow}
                toggleDrawer={toggleViewImageInpaintingDetailsPageShow}
                width="75%"
                height="90%"
            >
                <ImageInpaintingHelperText/>

            </FullPageDrawer>

            {/*For Image Creation*/}
            <FullPageDrawer
                title="Image Creation Features Description"
                open={viewImageCreationDetailsPageShow}
                toggleDrawer={toggleViewImageCreationDetailsPageShow}
                width="75%"
                height="90%"
            >
                <ImageCreationHelpertext/>

            </FullPageDrawer>

            {/*For Image to prompt*/}
            <FullPageDrawer
                title="Image to Prompt Features Description"
                open={viewImageToPromptDetailsPageShow}
                toggleDrawer={toggleViewImageToPromptDetailsPageShow}
                width="75%"
                height="90%"
            >
                <ImageToPromptHelperText/>

            </FullPageDrawer>

            {/*For Image to Alt text*/}
            <FullPageDrawer
                title="Image to Alt Text Features Description"
                open={viewImageToAltTextDetailsPageShow}
                toggleDrawer={toggleViewImageToAltTextDetailsPageShow}
                width="75%"
                height="90%"
            >
                <ImageToAltTextHelperText/>

            </FullPageDrawer>

            {/*For Image Variation*/}
            <FullPageDrawer
                title="Image Variation Features Description"
                open={viewImageVariationDetailsPageShow}
                toggleDrawer={toggleViewImageVariationDetailsPageShow}
                width="75%"
                height="90%"
            >
                <ImageVariationHelperText/>

            </FullPageDrawer>

            {/*For AI Transcription*/}
            <FullPageDrawer
                title="AI Transcription Features Description"
                open={viewAITranscriptionDetailsPageShow}
                toggleDrawer={toggleViewAITranscriptionDetailsPageShow}
                width="75%"
                height="90%"
            >
                <AITranscriptionHelpertext/>
            </FullPageDrawer>

        </Box>
    );
};

export default FeatureInputHeader;
