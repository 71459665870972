import React, {useEffect} from 'react';
import {Box} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {Controller, useForm} from 'react-hook-form';
import LoadingButton from '../../assets/global/Button/LoadingButton';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import {registerPattern} from '../../helpers/constant/validationPattern';
import {getAccountSettingData, updateAccountSettingInfo} from '../../state/features/accountSetting/accountSettingSlice';
import {selectAccountSettingState} from '../../state/features/accountSetting/accountSettingSelector';

const AccountSettingForm = () => {
    const dispatch = useDispatch();

    const {accountSetting, formSubmitLoader} = useSelector(selectAccountSettingState);

    const {
        control,
        register,
        handleSubmit,
        formState: {errors},
        setValue
    } = useForm({
        mode: 'onSubmit'
    });

    // ## input value set with the help of react-hook-form setValue method
    useEffect(() => {
        if (accountSetting) {
            setValue('first_name', accountSetting.first_name);
            setValue('last_name', accountSetting.last_name);
            setValue('email', accountSetting.email);
            setValue('phone', accountSetting.phone);
        }
    }, [accountSetting]);

    useEffect(() => {
        if (!accountSetting) {
            dispatch(getAccountSettingData());
        }
    }, []);

    // ## Form Submit Action
    const onSubmit = data => {
        dispatch(updateAccountSettingInfo(data));
    };

    return (
        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Controller
                control={control}
                name="first_name"
                defaultValue=""
                render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                    <FormControl fullWidth required error={!!(invalid && error.message)}>
                        <TextField
                            size="small"
                            margin="normal"
                            required
                            value={value}
                            onChange={onChange}
                            fullWidth
                            id="first_name"
                            label="First Name"
                            autoComplete="nope"
                            error={!!(invalid && error.message)}
                            helperText={invalid && error.message}
                        />
                    </FormControl>
                )}
                rules={registerPattern.first_name}
            />
            <Controller
                control={control}
                name="last_name"
                defaultValue=""
                render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                    <FormControl fullWidth>
                        <TextField
                            size="small"
                            margin="normal"
                            value={value}
                            onChange={onChange}
                            fullWidth
                            id="last_name"
                            label="Last Name"
                            autoComplete="nope"
                        />
                    </FormControl>
                )}
                rules={registerPattern.last_name}
            />

            <Controller
                control={control}
                name="email"
                defaultValue=""
                render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                    <FormControl fullWidth required error={!!(invalid && error.message)}>
                        <TextField
                            size="small"
                            margin="normal"
                            required
                            value={value}
                            onChange={onChange}
                            fullWidth
                            id="email"
                            label="Email"
                            autoComplete="nope"
                            error={!!(invalid && error.message)}
                            helperText={invalid && error.message}
                        />
                    </FormControl>
                )}
                rules={registerPattern.email}
            />

            <Controller
                control={control}
                name="phone"
                defaultValue=""
                render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                    <FormControl fullWidth required={true} error={!!(invalid && error.message)}>
                        <TextField
                            size="small"
                            margin="normal"
                            required
                            value={value}
                            onChange={onChange}
                            fullWidth
                            id="phone"
                            label="phone"
                            autoComplete="nope"
                            error={!!(invalid && error.message)}
                            helperText={invalid && error.message}
                        />
                    </FormControl>
                )}
                rules={registerPattern.phone}
            />

            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                <LoadingButton
                    isLoading={formSubmitLoader}
                    loadingText={'Updating...'}
                    type={'submit'}
                    size={'large'}
                    color={'secondary'}
                >
                    Update
                </LoadingButton>
            </Box>
        </Box>
    );
};

export default AccountSettingForm;
