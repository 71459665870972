import React, {useEffect, useState} from 'react';
import {Box, Typography} from '@mui/material';
import EditDetails from './EditDetails';
import StyledDropzone from '../../assets/global/ImageUpload/StyledDropzone';
import {useDispatch, useSelector} from 'react-redux';
import {selectMemeMakerState} from '../../state/features/memeMaker/memeMakerSelector';
import {useForm} from 'react-hook-form';
import {memeMakerStateClear} from '../../state/features/memeMaker/memeMakerSlice';
import TemplateSearchModal from "../../assets/global/MemeMaker/TemplateSearchModal";
import MemeMakerHeader from "../../assets/global/MemeMaker/MemeMakerHeader";
import {aiGeneratedTextClearState} from "../../state/features/textToMeme/randomAIGeneratedText/AIGeneratedTextSlice";

const MemeMaker = () => {
    const dispatch = useDispatch();
    const [imageInput, setImageInput] = useState([]);
    const [showEditor, setShowEditor] = useState(false);

    const {memeMakerData, isLoading} = useSelector(selectMemeMakerState);

    const {handleSubmit, control, reset} = useForm();

    // const onSubmit = data => {
    //     if (!imageInput[0]) {
    //         showErrorToaster("Add an image");
    //     } else {
    //         let formData = new FormData();
    //         formData.append('image_file', imageInput[0]);
    //         formData.append('text_type', 1);
    //         dispatch(memeMaker(formData));
    //     }
    // };

    const setImageFile = image => {
        setImageInput(image);
    };

    // const handleClearInput = () => {
    //     setImageInput(null);
    //     setShowEditor(false);
    // };

    useEffect(() => {
        console.log(imageInput[0]);
        return () => dispatch(memeMakerStateClear());
    }, []);

    useEffect(() => {
        if (imageInput.length > 0) {
            setShowEditor(true);
        } else {
            setShowEditor(false);
        }
    }, [imageInput]);

    const handleBack = () => {

    }

    useEffect(() => {
        dispatch(aiGeneratedTextClearState())
    },[]);

    return (
        <>

            {!showEditor ? (
                <Box sx={{
                    margin: '20px 10px',
                    backgroundColor: '#f3f4f6',
                    overflow: 'auto',
                }}>
                    <MemeMakerHeader
                        title={'meme-maker'}
                        handleBack={handleBack}
                    />
                    <Box
                        sx={{
                            width: '100%',
                            height: '600px',
                            padding: '60px 0px',
                            borderRadius: '10px',
                            textAlign: 'center',
                            bgcolor: 'white',
                            margin: '0px, 20px',
                        }}
                    >
                        <Typography variant="h4" py={3} fontWeight={700}>
                            Upload an image to get started
                        </Typography>
                        <Box
                            sx={{
                                width: '400px',
                                textAlign: 'center',
                                margin: '20px auto'
                            }}
                        >
                            <StyledDropzone setImageFile={setImageFile}/>

                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItem: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                    marginTop: '30px',
                                    fontSize: '12px'
                                }}
                            >
                                <Box
                                    sx={{
                                        borderBottom: '1px solid black',
                                        width: '50%',
                                        marginBottom: '12px',
                                        fontWeight: 500
                                    }}
                                ></Box>
                                <Typography
                                    varient="p"
                                    sx={{
                                        letterSpacing: '1px',
                                        fontSize: '18px',
                                        fontWeight: 500,
                                        px: 1
                                    }}
                                >
                                    OR
                                </Typography>
                                <Box
                                    sx={{
                                        borderBottom: '1px solid black',
                                        width: '50%',
                                        marginBottom: '12px'
                                    }}
                                ></Box>
                            </Box>

                            <Box sx={{marginTop: '30px'}}>
                                <TemplateSearchModal title={'meme-maker'}/>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <EditDetails image={imageInput}/>
            )}
        </>
    );
};

export default MemeMaker;
