import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    collections: [],
    imageCollectionList: [],
    specificCollections: null,
    allCollectionList: [],
    page: 1,
    perPage: 10,
    total: 10,
    isLoading: false,
    isError: false,
    error: '',
    isCreateSuccess: false,
    isUpdateSuccess: false,
    isEdited: false,
};

const collectionSlice = createSlice({
    name: 'collection',
    initialState: initialState,
    reducers: {
        getCollection: (state, action) => {
            state.isLoading = true;

            if (action.payload) {
                state.page = action.payload.page || 1;
                state.perPage = action.payload.per_page || 10;
            }
        },
        getCollectionSuccess: (state, action) => {
            state.collections = action.payload;
            state.isLoading = false;
            state.total = action.payload.total;
            state.isError = false;
            state.error = '';
        },
        getCollectionFailed: (state, action) => {
            state.collections = [];
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },
        createCollection: (state, action) => {
            state.isLoading = true;
        },
        createCollectionSuccess: (state, action) => {
            state.isLoading = false;
            state.isCreateSuccess = true;
            state.isError = false;
            state.error = '';
        },
        createCollectionFailed: (state, action) => {
            state.isLoading = false;
            state.isCreateSuccess = false;
            state.isError = true;
            state.error = action.payload;
        },
        updateCollection: (state, action) => {
            state.isLoading = true;
        },
        updateCollectionSuccess: (state, action) => {
            state.isLoading = false;
            state.isUpdateSuccess = true;
            state.isError = false;
            state.error = '';
        },
        updateCollectionFailed: (state, action) => {
            state.isLoading = false;
            state.isUpdateSuccess = false;
            state.isError = true;
            state.error = action.payload;
        },
        deleteCollection: (state, action) => {
            state.isLoading = true;
        },
        deleteCollectionSuccess: (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        deleteCollectionFailed: (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },
        promptDelete: (state, action) => {
            state.isLoading = true;
        },
        promptDeleteSuccess: (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        promptDeleteFailed: (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },
        getAllCollectionList: (state, action) => {
            state.isLoading = true;
        },
        getAllCollectionListSuccess: (state, action) => {
            state.allCollectionList = action.payload;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        getAllCollectionListFailed: (state, action) => {
            state.allCollectionList = [];
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },

        getSpecificCollection: (state, action) => {
            state.isLoading = true;
        },
        getSpecificCollectionSuccess: (state, action) => {
            state.specificCollections = action.payload;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        getSpecificCollectionFailed: (state, action) => {
            state.specificCollections = null;
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },
        clearCollectionState: (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.error = '';
            state.isCreateSuccess = false;
            state.isUpdateSuccess = false;
            state.isEdited = false;
        },
        createImageCollection: (state, action) => {
            state.isLoading = true;
        },
        imageCreateCollectionSuccess: (state, action) => {
            state.isLoading = false;
            state.isCreateSuccess = true;
            state.isError = false;
            state.error = '';
        },
        imageCreateCollectionFailed: (state, action) => {
            state.isLoading = false;
            state.isCreateSuccess = false;
            state.isError = true;
            state.error = action.payload;
        },
        getAllImageCollection: (state, action) => {
            state.isLoading = true;

            if (action.payload) {
                state.page = action.payload.page || 1;
                state.perPage = action.payload.per_page || 10;
            }
        },
        getAllImageListSuccess: (state, action) => {
            state.imageCollectionList = action.payload;
            // state.isCreateSuccess = true;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        getAllImageListFailed: (state, action) => {
            state.imageCollectionList = [];
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },
    }
});

export const {
    getCollection,
    getCollectionSuccess,
    getCollectionFailed,
    createCollection,
    createCollectionSuccess,
    createCollectionFailed,
    clearCollectionState,
    updateCollection,
    updateCollectionSuccess,
    updateCollectionFailed,
    deleteCollection,
    deleteCollectionSuccess,
    deleteCollectionFailed,
    getAllCollectionList,
    getAllCollectionListSuccess,
    getAllCollectionListFailed,
    getSpecificCollection,
    getSpecificCollectionSuccess,
    getSpecificCollectionFailed,
    createImageCollection,
    imageCreateCollectionSuccess,
    imageCreateCollectionFailed,

    getAllImageCollection,
    getAllImageListSuccess,
    getAllImageListFailed,

    promptDelete,
    promptDeleteSuccess,
    promptDeleteFailed
} = collectionSlice.actions;

export default collectionSlice.reducer;
