import React, {useState} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import {Box, Button, CircularProgress, Stack} from '@mui/material';
import '../../../../node_modules/react-quill/dist/quill.snow.css';
import LoadingButton from '../../../assets/global/Button/LoadingButton';

const FeatureInputFooter = ({footer, clearInput=''}) => {
    const [outputValue, setOutputValue] = useState(1);
    const [language, setLanguage] = useState('english');

    const {isLoading, generate, handleClearInput} = footer || {};

    const handleOutputSelectedValue = data => {
        setOutputValue(data.target.value);
        // handleOutputValue(data.target.value);
    };
    const handleLanguageSelectedValue = data => {
        setLanguage(data.target.value);
        // handleLanguageValue(data.target.value);
    };

    return (
        <Box
            sx={{
                width: '80%',
                m: 'auto',
                p: '12px 24px',
                borderTop: '1px solid rgb(229 231 235/1)',
                backgroundColor: 'rgb(249 250 251/1)',
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8,
                bgcolor: '#f9fafb'
            }}
        >
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                {
                    clearInput === 'none' ? (
                        <Box></Box>
                    ) : (
                        <Button
                            sx={{
                                bgColor: 'white !important',
                                color: '#374151',
                                borderColor: '#374151',
                                ':hover': {borderColor: '#374151'}
                            }}
                            variant="outlined"
                            startIcon={<CloseIcon/>}
                            onClick={handleClearInput}
                        >
                            Clear Inputs
                        </Button>
                    )
                }

                <Stack direction="row" spacing={2} justifyContent="flex-end">
                    {/*<FormControl size="small">*/}
                    {/*  <InputLabel>Output</InputLabel>*/}
                    {/*  <Select*/}
                    {/*    id="demo-simple-select"*/}
                    {/*    value={outputValue}*/}
                    {/*    label="Output"*/}
                    {/*    sx={{minWidth: 100, bgcolor: 'white'}}*/}
                    {/*    onChange={handleOutputSelectedValue}*/}
                    {/*  >*/}
                    {/*    <MenuItem value={1}>1</MenuItem>*/}
                    {/*    <MenuItem value={2}>2</MenuItem>*/}
                    {/*    <MenuItem value={3}>3</MenuItem>*/}
                    {/*  </Select>*/}
                    {/*</FormControl>*/}

                    {/*<FormControl size="small">*/}
                    {/*  <InputLabel id="demo-simple-select-label">Language</InputLabel>*/}
                    {/*  <Select*/}
                    {/*    labelId="demo-simple-select-label"*/}
                    {/*    id="demo-simple-select"*/}
                    {/*    value={language}*/}
                    {/*    label="Language"*/}
                    {/*    sx={{minWidth: 150, bgcolor: 'white'}}*/}
                    {/*    onChange={handleLanguageSelectedValue}*/}
                    {/*  >*/}
                    {/*    <MenuItem value="english">English</MenuItem>*/}
                    {/*    <MenuItem value="bangla">Bangla</MenuItem>*/}
                    {/*  </Select>*/}
                    {/*</FormControl>*/}

                    {/*<Button*/}
                    {/*  sx={{backgroundImage: 'linear-gradient(to right, #9333ea, #4f46e5)'}}*/}
                    {/*  variant="contained"*/}
                    {/*  startIcon={*/}
                    {/*    isLoading ? <CircularProgress size={16} color={'inherit'} /> : <SmartToyIcon />*/}
                    {/*  }*/}
                    {/*  onClick={generate}*/}
                    {/*  type="submit"*/}
                    {/*  disabled={isLoading}*/}
                    {/*>*/}
                    {/*  Generate*/}
                    {/*</Button>*/}

                    <LoadingButton
                        isLoading={isLoading}
                        loadingText={'Generating'}
                        type={'submit'}
                        color={'primary'}
                        sx={{backgroundImage: 'linear-gradient(to right, #9333ea, #4f46e5)'}}
                        startIcon={
                            isLoading ? <CircularProgress size={16} color={'inherit'}/> : <SmartToyIcon/>
                        }
                        onClick={generate}
                        disabled={isLoading}
                    >
                        Generate
                    </LoadingButton>
                </Stack>
            </Stack>
        </Box>
    );
};

export default FeatureInputFooter;
