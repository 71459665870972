import { requests } from "./http.service";

class UserBalanceService {
    getBalanceAmountList() {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/recharge/get/amount/data");
    }

    configureAutoRecharge(body) {
        return requests.post(
            process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/recharge/configuration/auto/recharge/amount",
            body
        );
    }

    changeStatusAutoRecharge(body) {
        return requests.post(
            process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/recharge/change/status/auto/recharge/amount",
            body
        );
    }

    removeAutoRecharge(body) {
        return requests.post(
            process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/recharge/remove/auto/recharge/amount",
            body
        );
    }

    addRechargeBalance(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/recharge/balance", body);
    }

    //  ## get system payment charge percentage amount
    getSystemRechargeCharge(params) {
        return requests.get(
            process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/recharge/get/charge/amount/data",
            params
        );
    }
}

export default new UserBalanceService();
