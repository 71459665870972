import React, {useState} from 'react';
import StylesCard from './Styles/Card.module.css';
import {Box, Stack, Typography} from '@mui/material';
import visaWhiteLogo from '../../assets/images/integration-logos/visasqure-white.svg';
import visaLogo from '../../assets/images/integration-logos/visasqure.svg';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DeleteIcon from '@mui/icons-material/Delete';
import {useTheme} from '@mui/material/styles';
import {useDispatch} from 'react-redux';
import warningLogo from '../../assets/images/Icons/warning-logo.svg';
import {Visibility} from '@mui/icons-material';
import {
    getUserPaymentCardList,
    updateUserDefaultPaymentCard,
    updateUserPaymentCardActiveStatus
} from '../../state/features/userPayment/userPaymentSlice';
import UserPaymentService from '../../services/UserPayment.service';
import {showErrorToaster, showSuccessToaster} from '../../helpers/utility/toaster';
import InfoModal from "../../assets/global/Modal/InfoModal";
import WarningModalContent from "../../assets/global/Modal/WarningModalContent";
import PopHoverCommonMenuItem from "../../assets/global/Menu/PopHoverCommonMenuItem";
import PopHoverCommonMenu from "../../assets/global/Menu/PopHoverCommonMenu";

const UserPaymentCardItem = ({cardData}) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [actionState, setActionState] = useState({});
    const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
    const [isOpenStatusModal, setOpenStatusModal] = useState(false);
    const [anchorElAction, setAnchorElAction] = useState(null);

    const handleOpenActionMenu = event => {
        setAnchorElAction(event.currentTarget);
    };
    const handleCloseActionMenu = () => {
        setAnchorElAction(null);
    };

    const handleMakeDefault = cardInfo => {
        handleCloseActionMenu();
        dispatch(updateUserDefaultPaymentCard({user_payment_id: cardInfo.id}));
    };

    const handleCardActiveStatus = cardInfo => {
        handleCloseActionMenu();
        setActionState(cardInfo);
        onOpenStatusModal();
    };

    const onOpenDeleteModal = () => setOpenDeleteModal(true);
    const onCloseDeleteModal = () => setOpenDeleteModal(false);

    const handleCardDelete = cardInfo => {
        handleCloseActionMenu();
        setActionState(cardInfo);
        onOpenDeleteModal();
    };

    const onOpenStatusModal = () => setOpenStatusModal(true);
    const onCloseStatusModal = () => setOpenStatusModal(false);

    const onConfirmStatusChange = () => {
        onCloseStatusModal();
        dispatch(
            updateUserPaymentCardActiveStatus({
                user_payment_id: actionState.id,
                status: !actionState.status
            })
        );
    };

    const onConfirmVisibleChange = () => {
        onCloseDeleteModal();

        UserPaymentService.deleteUserPaymentCard({
            user_payment_id: actionState.id
        })
            .then(response => {
                if (response.success) {
                    showSuccessToaster(response.message);
                    dispatch(getUserPaymentCardList());
                } else {
                    showErrorToaster(response.message);
                }
            })
            .catch(err => console.log(err));
    };

    return (
        <Box
            className={`${StylesCard.col} ${cardData.type ? 'default__card' : ''}`}
            sx={{borderColor: theme.palette.info.main, p: 1}}
        >
            <Stack direction={'row'} spacing={1} justifyContent="space-between" alignContent="center">
                {/*for default */}
                <img src={cardData.type ? visaWhiteLogo : visaLogo} alt={''}/>

                <Stack direction={'row'} justifyContent="space-between" alignContent="center">
                    {/*for default*/}
                    {cardData.type ? (
                        <Typography sx={{mr: 0.5}} variant="buttonSmall" color="text.primary">
                            Default
                        </Typography>
                    ) : (
                        <CheckCircleIcon
                            fontSize="small"
                            sx={{
                                color: `${
                                    cardData.status ? theme.palette.success.main : theme.palette.action.disabled
                                }`,
                                mr: 0.5
                            }}
                        />
                    )}

                    {!cardData.type ? (
                        <MoreVertIcon
                            sx={{cursor: 'pointer', color: theme.palette.action.active}}
                            fontSize="small"
                            onClick={handleOpenActionMenu}
                        />
                    ) : (
                        ''
                    )}
                </Stack>
            </Stack>
            <Typography variant="subtitle2" color="text.primary" sx={{pt: 3}}>
                **** **** **** {cardData.card_number}
            </Typography>
            <Stack direction={'row'} justifyContent="space-between" alignContent="center">
                <Box sx={{flex: '1', overflow: 'hidden'}}>
                    <Typography variant="tooltip" color="text.secondary">
                        Card Holder Name
                    </Typography>
                    <Typography
                        sx={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}
                        variant="body2"
                        color="text.primary"
                    >
                        {cardData.card_holder_name || 'N/A'}
                    </Typography>
                </Box>
                <Box sx={{textAlign: 'right'}}>
                    {/*<Typography variant='tooltip' color='text.secondary'>*/}
                    {/*    Expiry Date*/}
                    {/*</Typography>*/}
                    {/*<Typography variant='body2' color='text.primary'>*/}
                    {/*    02/30*/}
                    {/*</Typography>*/}
                </Box>
            </Stack>
            <PopHoverCommonMenu
                elevation={4}
                anchorEl={anchorElAction}
                open={Boolean(anchorElAction)}
                onClose={handleCloseActionMenu}
            >
                <PopHoverCommonMenuItem
                    name="Make Default"
                    icon={<EditIcon/>}
                    onClick={() => handleMakeDefault(cardData)}
                />
                <PopHoverCommonMenuItem
                    name={cardData.status ? 'Inactive' : 'Make Active'}
                    icon={cardData.status ? <VisibilityOffIcon/> : <Visibility/>}
                    onClick={() => handleCardActiveStatus(cardData)}
                />
                <PopHoverCommonMenuItem
                    name="Remove"
                    icon={<DeleteIcon/>}
                    onClick={() => handleCardDelete(cardData)}
                />
            </PopHoverCommonMenu>

            <InfoModal open={isOpenDeleteModal} width="480px" onClose={onCloseDeleteModal}>
                <WarningModalContent
                    logo={warningLogo}
                    title={'Warning'}
                    subTitle={'Are you Sure to delete'}
                    subTitleSpan={'Payment Card?'}
                    content={'If you delete this data, you’ll not be able to recover it again.'}
                    buttonName={'Delete'}
                    onConfirm={onConfirmVisibleChange}
                    onClose={onCloseDeleteModal}
                />
            </InfoModal>

            <InfoModal open={isOpenStatusModal} width="480px" onClose={onCloseStatusModal}>
                <WarningModalContent
                    logo={warningLogo}
                    title={'Warning'}
                    subTitle={'Are you Sure to update status'}
                    subTitleSpan={'Payment Card?'}
                    // content={"If you update this data, you’ll not be able to recover it again."}
                    buttonName={'Update'}
                    onConfirm={onConfirmStatusChange}
                    onClose={onCloseStatusModal}
                />
            </InfoModal>
        </Box>
    );
};

export default UserPaymentCardItem;

