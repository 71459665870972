import React, {useEffect, useState} from 'react';
import {Box, Button, FormHelperText, Grid, InputLabel, Select, Stack} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import {useTheme} from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import {useDispatch, useSelector} from 'react-redux';
import {
    clearCollectionState,
    getAllCollectionList,
    getAllImageCollection
} from '../../state/features/collection/collectionSlice';
import {selectCollectionState} from '../../state/features/collection/collectionSelector';
import {selectPromptCollectionState} from '../../state/features/promptCollection/promptCollectionSelector';
import {promptCollectionPattern} from '../../helpers/constant/validationPattern';
import LoadingButton from '../../assets/global/Button/LoadingButton';

const PromptCollectionForm = ({toggleDrawer, handlePromptCollectionSubmit, helperKey=''}) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const {allCollectionList, imageCollectionList} = useSelector(selectCollectionState);
    const {isCreateSuccess, isLoading} = useSelector(selectPromptCollectionState);

    const {handleSubmit, watch, setValue, setError, control} = useForm();

    useEffect(() => {
        dispatch(getAllCollectionList());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getAllImageCollection());
    }, [dispatch]);


    // ## Clear State When Leave the page
    // useEffect(() => {
    //     return () => dispatch(clearCollectionState());
    // }, []);

    const onSubmit = data => {
        handlePromptCollectionSubmit(data);
        toggleDrawer();
    };

    return (
        <>
            <Box
                component="form"
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
                sx={{p: 3, backgroundColor: theme.palette.background.paper}}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <Controller
                            control={control}
                            name="collection_id"
                            defaultValue=""
                            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                <FormControl size="small" fullWidth error={!!(invalid && error.message)}>
                                    <InputLabel id="collection-label" required>
                                        Collection
                                    </InputLabel>
                                    <Select

                                        labelId="collection-label"
                                        id="collection-select"
                                        value={value}
                                        label="Collection"
                                        onChange={onChange}
                                    >
                                        {helperKey=='IMAGE' ? (imageCollectionList && Array.isArray(imageCollectionList) && imageCollectionList.map(collection => (
                                            <MenuItem key={collection.id} value={collection.id}>
                                                {collection?.name}
                                            </MenuItem>
                                        ))) : (allCollectionList && Array.isArray(allCollectionList) && allCollectionList.map(collection => (
                                            <MenuItem key={collection.id} value={collection.id}>
                                                {collection?.name}
                                            </MenuItem>
                                        )))}
                                    </Select>
                                    <FormHelperText>{invalid && error?.message}</FormHelperText>
                                </FormControl>
                            )}
                            rules={promptCollectionPattern.collection}
                        />
                    </Grid>
                </Grid>

                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} mt={2}>
                    <Button
                        onClick={toggleDrawer}
                        variant="contained"
                        size={'small'}
                        color={'error'}
                    >
                        Cancel
                    </Button>

                    <LoadingButton
                        isLoading={isLoading}
                        loadingText={'Saving'}
                        type={'submit'}
                        color={'primary'}
                        variant="contained"
                        size={'small'}
                        sx={{backgroundColor: "rgb(0, 158, 247)"}}
                    >
                        Save
                    </LoadingButton>
                </Stack>
            </Box>
        </>
    );
};

export default PromptCollectionForm;
