import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    imageToAltTextData: null,
    isLoading: false,
    isError: false,
    error: ''
};

const imageToAltTextSlice = createSlice({
    name: 'imageToAltText',
    initialState: initialState,
    reducers: {
        imageToAltText: (state, action) => {
            state.isLoading = true;
        },
        imageToAltTextSuccess: (state, action) => {
            state.imageToAltTextData = action?.payload[0]?.text;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        imageToAltTextFailed: (state, action) => {
            state.imageToAltTextData = null;
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },
        imageToAltTextStateClear: (state, action) => {
            state.imageToAltTextData = null;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        }
    }
});

export const {
    imageToAltText,
    imageToAltTextSuccess,
    imageToAltTextFailed,
    imageToAltTextStateClear,
} = imageToAltTextSlice.actions;

export default imageToAltTextSlice.reducer;