import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    billingHistory: [],
    topUpRechargeInvoices: [],
    userBilling: null,
    paymentCard: null,
    loading: false,
    page: 1,
    perPage: 10,
    total: 10,
    error: "",
    submitLoader: false,
};

const userBillingSlice = createSlice({
    name: "userBilling",
    initialState,
    reducers: {
        addPaymentCard: (state) => {
            state.submitLoader = true;
        },
        addPaymentCardSuccess: (state, action) => {
            state.submitLoader = false;
            state.userBilling = action.payload;
            state.error = "";
        },
        addPaymentCardFailed: (state, action) => {
            state.submitLoader = false;
            state.error = action.payload.error;
        },
        getPaymentCard: (state) => {
            state.loading = true;
        },
        getPaymentCardSuccess: (state, action) => {
            state.loading = false;
            state.paymentCard = action.payload;
            state.error = "";
        },
        getPaymentCardFailed: (state, action) => {
            state.loading = false;
            state.error = action.payload.error;
        },
        getUserBillingHistory: (state, action) => {
            state.loading = true;
            if (action.payload) {
                state.page = action.payload.page || 1;
                state.perPage = action.payload.per_page || 10;
            }
        },
        getUserBillingHistorySuccess: (state, action) => {
            state.billingHistory = action.payload.data;
            state.total = action.payload.total;
            state.loading = false;
            state.error = "";
        },
        getUserBillingHistoryFailed: (state, action) => {
            state.billingHistory = [];
            state.loading = false;
            state.error = action.payload;
        },
        getTopUpRechargeInvoiceReport: (state, action) => {
            state.loading = true;
            if (action.payload) {
                state.page = action.payload.page || 1;
                state.perPage = action.payload.per_page || 10;
            }
        },
        getTopUpRechargeInvoiceReportSuccess: (state, action) => {
            state.topUpRechargeInvoices = action.payload.data;
            state.total = action.payload.total;
            state.loading = false;
            state.error = "";
        },
        getTopUpRechargeInvoiceReportFailed: (state, action) => {
            state.topUpRechargeInvoices = [];
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const {
    addPaymentCard,
    addPaymentCardSuccess,
    addPaymentCardFailed,
    getPaymentCard,
    getPaymentCardSuccess,
    getPaymentCardFailed,
    getUserBillingHistory,
    getUserBillingHistorySuccess,
    getUserBillingHistoryFailed,
    getTopUpRechargeInvoiceReport,
    getTopUpRechargeInvoiceReportSuccess,
    getTopUpRechargeInvoiceReportFailed,
} = userBillingSlice.actions;

export default userBillingSlice.reducer;
