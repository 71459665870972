import {createSlice} from '@reduxjs/toolkit';
import {nanoid} from "nanoid";

const initialState = {
    messages: [],
    chatHistories: [],
    isLoading: false,
    isError: false,
    error: '',
    selectedDescription: "[[email_format]] is a type of [[first_name]] that uses the principles" +
        " of quantum mechanics to perform certain types of calculations much faster than" +
        " classical computers selected.",
    selectedDescriptionUpdated: null,
    selectedGlobalDescriptionUpdated: null,
    renderId: ""
};

const chatGPTSlice = createSlice({
    name: 'chatGPT',
    initialState: initialState,
    reducers: {
        getChatGPTAnswer: (state, action) => {
            state.isLoading = true;
            if (Array.isArray(action.payload.messages)) {
                state.messages = [...state.messages, ...action.payload.messages]
            }
        },
        getChatGPTAnswerSuccess: (state, action) => {
            state.messages = [...state.messages, action.payload.message]
            state.isLoading = false;
            state.isError = false;
            state.error = '';
            state.renderId = nanoid(5)
        },
        getChatGPTAnswerFailed: (state, action) => {
            // state.messages = [];
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },
        setDescription: (state, action) => {
            state.selectedDescriptionUpdated = action.payload.description || "";
        },

        setGlobalDescription: (state, action) => {
            state.selectedGlobalDescriptionUpdated = action.payload.description || "";
        },

        setChatGPTQuestionByClick: (state, action) => {
            state.isLoading = true;
            if (Array.isArray(action.payload.messages)) {
                state.messages = [...state.messages, ...action.payload.messages]
            }
        },

        setChatGPTAnswerByClick: (state, action) => {
            state.messages = [...state.messages, action.payload.message]
            state.isLoading = false;
            state.isError = false;
            state.error = '';
            state.renderId = nanoid(5)
        },

        getChatGPTHistory: (state, action) => {
            state.isLoading = true;
        },
        getChatGPTHistorySuccess: (state, action) => {
            state.chatHistories = action.payload
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        getChatGPTHistoryFailed: (state, action) => {
            // state.messages = [];
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },

        clearChatGPTState: (state, action) => {
            state.messages = []
            state.isLoading = false;
            state.isError = false;
            state.error = '';
            state.renderId = ''
        },
    }
});

export const {
    getChatGPTAnswer,
    getChatGPTAnswerSuccess,
    getChatGPTAnswerFailed,
    setDescription,
    setChatGPTQuestionByClick,
    setChatGPTAnswerByClick,
    clearChatGPTState,
    getChatGPTHistory,
    getChatGPTHistorySuccess,
    getChatGPTHistoryFailed,
} = chatGPTSlice.actions;

export default chatGPTSlice.reducer;
