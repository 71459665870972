import {all, put} from 'redux-saga/effects';
import {call, takeEvery} from '@redux-saga/core/effects';
import {memeMakerFailed, memeMakerSuccess} from "./memeMakerSlice";
import MemeMakerService from "../../../services/MemeMaker.service";
import {showSuccessToaster} from "../../../helpers/utility/toaster";

function* memeMakerWatcher() {
    yield takeEvery('memeMaker/memeMaker', generateMemeMakerSaga);
}

function* generateMemeMakerSaga(action) {
    try {
        const response = yield call(MemeMakerService.memeMaker, action.payload);

        if (response.status) {
            yield put(memeMakerSuccess(response.data));
            showSuccessToaster(response.message)
        } else {
            yield put(memeMakerFailed(response.message));
        }
    } catch (err) {
        console.log('Error: ', err);
        yield put(memeMakerFailed(err.message));
    }
}

export default function* memeMakerSaga() {
    yield all([memeMakerWatcher()]);
}
