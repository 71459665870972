import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    balanceData: [],
    isLoading: false,
    isError: false,
    error: '',
};

const balanceCheckSlice = createSlice({
    name: 'balanceCheck',
    initialState: initialState,
    reducers: {
        getBalance: (state, action) => {
            state.isLoading = true;
        },
        getBalanceSuccess: (state, action) => {
            state.balanceData = action.payload;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        getBalanceFailed: (state, action) => {
            state.balanceData = [];
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },
    }
});

export const {
    getBalance,
    getBalanceSuccess,
    getBalanceFailed,
} = balanceCheckSlice.actions;

export default balanceCheckSlice.reducer;
