import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    downloadGifData: [],
    isLoading: false,
    isError: false,
    error: '',
};

const gifDownloadSlice = createSlice({
    name: 'gifDownload',
    initialState: initialState,
    reducers: {
        DownloadGif: (state, action) => {
            state.isLoading = true;
        },
        DownloadGifSuccess: (state, action) => {
            state.textToGifData = action?.payload;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        DownloadGifFailed: (state, action) => {
            state.textToGifData = null;
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },
    }
});

export const {
    DownloadGif,
    DownloadGifSuccess,
    DownloadGifFailed,
} = gifDownloadSlice.actions;

export default gifDownloadSlice.reducer;
