import {
    USER_INVOICE__FOR_PACKAGE_BUY,
    USER_INVOICE__FOR_PACKAGE_FREE,
    USER_INVOICE__FOR_SMS_SEND,
    USER_INVOICE__FOR_EMAIL_SEND,
    USER_INVOICE__FOR_LOCATION_REVIEW_REQUEST,
    USER_INVOICE__FOR_RECHARGE_TOP_UP_AMOUNT,
} from "../constant/coreConstant";

export const isInvoiceAmountCurrency = (invoiceType) => {
    switch (invoiceType) {
        case USER_INVOICE__FOR_PACKAGE_BUY:
            return true;
        case USER_INVOICE__FOR_PACKAGE_FREE:
            return true;
        case USER_INVOICE__FOR_EMAIL_SEND:
            return false;
        case USER_INVOICE__FOR_SMS_SEND:
            return false;
        case USER_INVOICE__FOR_LOCATION_REVIEW_REQUEST:
            return false;
        case USER_INVOICE__FOR_RECHARGE_TOP_UP_AMOUNT:
            return true;
        default:
            return true;
    }
};
