import {Box, Card, CardContent, Typography} from '@mui/material';

import CardHeader from '@mui/material/CardHeader';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import {showSuccessToaster} from "../../helpers/utility/toaster";
import {styled} from '@mui/system';

const StyledCardHeader = styled(CardHeader)(({theme}) => ({
    '.MuiCardHeader-title': {
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: '600',
        color: 'rgb(51, 51, 51)',
        lineHeight: '1.4',
        borderRadius: '0px',
        opacity: 1,
        wordBreak: 'break-word',
    }
}));

const CollectionResponseDetails = ({collectionResponseObj}) => {
    const handleCopy = data => {
        navigator.clipboard.writeText(data);
        showSuccessToaster('Text copied');
    };

    return (
        <>
            <Box>
                <Card sx={{width: '80% !important', boxShadow: '0 0 0 0 gray',}}>
                    <StyledCardHeader
                        sx={{marginRight: '5px', borderBottom: '1px solid #dfe4ea'}}
                        action={
                            <Box>
                                <IconButton
                                    aria-label="settings"
                                    onClick={() => handleCopy(collectionResponseObj?.response)}
                                >
                                    <ContentCopyIcon/>
                                </IconButton>
                            </Box>
                        }
                        title={collectionResponseObj?.text}
                    />
                    <CardContent  sx={{padding:'0!important',}}>
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                                fontFamily: 'Poppins',
                                fontSize: '14px',
                                fontWeight: '300',
                                color: 'rgb(51, 51, 51)',
                                lineHeight: '1.4',
                                borderRadius: '0px',
                                opacity: 1,
                                 wordBreak: 'break-word',
                                 paddingTop:'10px'
                            }}
                        >
                            {collectionResponseObj?.response}
                        </Typography>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default CollectionResponseDetails;
