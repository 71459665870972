import axios from "axios";
import {errorResponseBody, responseBody} from "../api/httpRequest";
import {requests} from "./http.service";

class ChatGPTService {

    getChatGPTAnswer(body) {
        const payload = {
            model: "gpt-3.5-turbo",
            messages: body.messages
        }

        return axios
            .post("https://api.openai.com/v1/chat/completions", payload, {
                headers: {
                    Authorization: `Bearer ${body.apiKey}`,
                }
            })
            .then(responseBody)
            .catch(errorResponseBody)
    }

    getAllCollectionList(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/chat-history/all", body);
    }

    createChatHistory(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/chat-history/create", body);
    }

    getApiKey(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/get/api/key", body);
    }
}

export default new ChatGPTService();
