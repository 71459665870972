import generateRandomString from '../../helper/utility/generateRandomString';
import {
    PACKAGE_FEATURE__KEY__AUDIO,
    PACKAGE_FEATURE__KEY__COLLECTION,
    PACKAGE_FEATURE__KEY__CONTENT_REPURPOSING,
    PACKAGE_FEATURE__KEY__IMAGE_CREATION,
    PACKAGE_FEATURE__KEY__IMAGE_IN_PAINTING,
    PACKAGE_FEATURE__KEY__IMAGE_TO_ALT,
    PACKAGE_FEATURE__KEY__IMAGE_TO_PROMPT,
    PACKAGE_FEATURE__KEY__IMAGE_VARIATION,
    PACKAGE_FEATURE__KEY__PLAY_PEN,
    PACKAGE_FEATURE__KEY__TEMPLATE,
    USER_PACKAGE_LIMIT_KEY__LOCATION
} from '../constant/packageFeatureConstant';

// eslint-disable-next-line camelcase
const generateFeatureObject = (name, key, limit) => {
    return {
        id: generateRandomString(10),
        key: key,
        feature_name: name,
        status: true,
        limit: limit
    };
};

const formatFeatureLimitList = featureList => {
    const length = featureList.length;
    const dollarBalance = [];
    const teamMemberFeatures = [];
    const reviewFeatures = [];

    for (let i = 0; i < length; i++) {
        const key = featureList[i].key;
        const limit = featureList[i].value;

        if (key === PACKAGE_FEATURE__KEY__TEMPLATE && limit === 1) {
          reviewFeatures.push(generateFeatureObject('Template', key, limit));
        } else if (key === PACKAGE_FEATURE__KEY__COLLECTION && limit === 1) {
            reviewFeatures.push(generateFeatureObject('Collection', key, limit));
        } else if (key === USER_PACKAGE_LIMIT_KEY__LOCATION && limit === 1) {
            reviewFeatures.push(generateFeatureObject('Location', key, limit));
        } else if (key === PACKAGE_FEATURE__KEY__CONTENT_REPURPOSING && limit === 1) {
            reviewFeatures.push(generateFeatureObject('Content Repurposing', key, limit));
        } else if (key === PACKAGE_FEATURE__KEY__PLAY_PEN && limit === 1) {
            reviewFeatures.push(generateFeatureObject('Play Pen', key, limit));
        } else if (key === PACKAGE_FEATURE__KEY__IMAGE_CREATION && limit === 1) {
            reviewFeatures.push(generateFeatureObject('Image Creation', key, limit));
        } else if (key === PACKAGE_FEATURE__KEY__IMAGE_IN_PAINTING && limit === 1) {
            reviewFeatures.push(generateFeatureObject('Image In-painting', key, limit));
        } else if (key === PACKAGE_FEATURE__KEY__IMAGE_VARIATION && limit === 1) {
            reviewFeatures.push(generateFeatureObject('Image Variation', key, limit));
        } else if (key === PACKAGE_FEATURE__KEY__IMAGE_TO_PROMPT && limit === 1) {
            reviewFeatures.push(generateFeatureObject('Image to prompt', key, limit));
        } else if (key === PACKAGE_FEATURE__KEY__IMAGE_TO_ALT && limit === 1) {
            reviewFeatures.push(generateFeatureObject('Image to Alt', key, limit));
        } else if (key === PACKAGE_FEATURE__KEY__AUDIO && limit === 1) {
            reviewFeatures.push(generateFeatureObject('AI transcription', key, limit));
        }
    }

    return {
        dollarBalance,
        teamMemberFeatures,
        reviewFeatures
    };
};

export default formatFeatureLimitList;
