import React, {useState} from 'react';
import {Box} from '@mui/system';
import {Stack, Typography} from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import {useDispatch, useSelector} from 'react-redux';
import {textToImage} from '../../state/features/textToMeme/textToImage/textToImageSlice';
import {selectTextToImagedate} from '../../state/features/textToMeme/textToImage/textToImageSelector';
import Skeleton from '@mui/material/Skeleton';
import EmptyPage from '../../assets/global/EmptyComponent/EmptyPage';
import DownloadIcon from '@mui/icons-material/Download';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import {Link, useNavigate} from "react-router-dom";
import MemeMakerHeader from "../../assets/global/MemeMaker/MemeMakerHeader";
import TextToMemeSearchBar from "./TextToMemeSearchBar";
import {showErrorToaster, showSuccessToaster} from "../../helpers/utility/toaster";
import {createImageCollection} from "../../state/features/promptCollection/promptCollectionSlice";
import FullPageDrawer from "../../assets/global/Drawer/FullPageDrawer";
import PromptCollectionForm from "../prompt/PromptCollectionForm";
import ImagePNGConvater from "../../helpers/ImagePNGConvater";
import BalanceCheckService from "../../services/BalanceCheck.service";
import {
    creditSettingsImageContent256x256,
    PACKAGE_LIMIT__BALANCE__KEY__CREDIT_AMOUNT
} from "../../helpers/constant/coreConstant";
import {getSpecificBalanceData} from "../../state/features/userBalance/userBalanceSlice";
import {selectPromptCollectionState} from "../../state/features/promptCollection/promptCollectionSelector";

const TextToMeme = () => {
    const [imageOrGifGenerate, setImageOrGifGenerate] = useState('');
    const [searchText, setSearchText] = useState('');
    const dispatch = useDispatch();
    const {textToImageData, isLoading} = useSelector(selectTextToImagedate);
    const {isCreateSuccess} = useSelector(selectPromptCollectionState);
    const navigate = useNavigate();
    const [viewDetailsPageShow, setViewDetailsPageShow] = useState(false)
    const toggleViewDetailsPage = () => setViewDetailsPageShow(prevState => !prevState);
    ;
    const [imageUrlForCollection, setImageUrlForCollection] = useState('');

    const [viewGifSavePageShow, setViewGifSavePageShow] = useState(false)
    const toggleViewGifSavePage = () => setViewGifSavePageShow(prevState => !prevState);
    ;
    const [gifUrlForCollection, setGifUrlForCollection] = useState('');

    const handleTextToMeme = (data) => {
        const API_KEY = process.env.REACT_APP_TENOR_API_KEY;

        if(!searchText){
            showErrorToaster("Search with a topic or select a random topic!")
        } else {
            dispatch(
                textToImage({
                    q: searchText,
                    key: API_KEY,
                    pos: '20',
                    limit: '20'
                })
            );
        }

    };

    const handleBack = () => {
        navigate(-1)
    }

    const searchValue = (value, imgOrGif) => {
        setSearchText(value);
        setImageOrGifGenerate(imgOrGif);
    };


    const handleDownloadMeme = async (memeUrl, type) => {

        BalanceCheckService.getBalance({content_type: creditSettingsImageContent256x256, amount_data: 500})
            .then((response) => {
                if (response.success) {
                    if (type === 'image') {
                        const ImageDownload = async () => {
                            let imageName = "Meme.png"
                            let type = "png";
                            const currentUrl = process.env.REACT_APP_API_URL_MAIN_USER_SERVER + `/download/image?url=${memeUrl}&name=${imageName}&type=${type}`;
                            await window.open(currentUrl, '_blank');
                            BalanceCheckService.reduceBalance({
                                content_type: creditSettingsImageContent256x256,
                                amount_data: 500
                            });
                            dispatch(getSpecificBalanceData({key: PACKAGE_LIMIT__BALANCE__KEY__CREDIT_AMOUNT}));
                        }
                        ImageDownload()
                    } else if (type === 'gif') {
                        try {
                            const _GifDownload = async () => {
                                const response = await fetch(memeUrl); // Replace with your GIF URL
                                const arrayBuffer = await response.arrayBuffer();
                                const blob = new Blob([arrayBuffer], {type: 'image/gif'});
                                const url = window.URL.createObjectURL(blob);

                                const link = document.createElement('a');
                                link.href = url;
                                link.download = 'animation.gif'; // Specify the desired file name
                                link.click();
                                window.URL.revokeObjectURL(url);
                                showSuccessToaster('Image downloaded');
                            }
                            _GifDownload();
                        } catch (error) {
                            showErrorToaster('Image download failed');
                        }
                    }

                    BalanceCheckService.reduceBalance({
                        content_type: creditSettingsImageContent256x256,
                        amount_data: 500
                    });
                    dispatch(getSpecificBalanceData({key: PACKAGE_LIMIT__BALANCE__KEY__CREDIT_AMOUNT}));
                } else {
                    showErrorToaster('Insufficient balance')
                }
            })
            .catch((err) => console.log(err));

    };

    const handleCopyMeme = async (imageUrl) => {

        let image = await ImagePNGConvater.convertToPng(imageUrl);

        fetch(image)
            .then((response) => response.blob())
            .then((blob) => {
                const clipboardItems = [
                    new ClipboardItem({[blob.type]: blob}),
                ];

                navigator.clipboard.write(clipboardItems)
                    .then(() => {
                        showSuccessToaster('Meme Copy to clipboard!')
                    })
                    .catch((error) => {
                        console.error('Failed to copy image:', error);
                    });
            });

    };


    const handleViewDetailsPage = imageUrl => {
        toggleViewDetailsPage();
        setImageUrlForCollection(imageUrl);
    };

    const handleViewGifSavePage = gifUrl => {
        toggleViewGifSavePage();
        setGifUrlForCollection(gifUrl);
    };

    const handleImageCollectionSubmit = (data) => {
        const payload = {};
        payload.collection_id = data.collection_id || '';
        payload.type = 2;
        payload.text = "response_image";
        payload.response = imageUrlForCollection;
        dispatch(createImageCollection(payload));

    };

    const handleGifCollectionSubmit = data => {
        const payload = {};
        payload.collection_id = data.collection_id || '';
        payload.type = 3;
        payload.text = "response_image";
        payload.response = gifUrlForCollection;
        dispatch(createImageCollection(payload));
    };

    let content = null;
    if (isLoading) {
        content = (
            <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}} sx={{p: 3}}>
                {[1, 2, 3, 4].map(collection => (
                    <Grid key={collection} item xs={3}>
                        <Box sx={{p: 3, marginRight: 0.5}}>
                            <Skeleton variant="rectangular" height={118}/>
                            <Skeleton/>
                            <Skeleton width="60%"/>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        );
    } else if (textToImageData?.results?.length > 0 && imageOrGifGenerate === 'on') {
        content = (
            <Grid container spacing={2}>
                {
                    textToImageData?.results?.map((items, key) => (
                        <Grid item xs={12} sm={6} md={4}>
                            <Box
                                key={key}
                                sx={{
                                    background: 'white',
                                    borderRadius: 2,
                                    px: 3,
                                    py: 2
                                }}
                            >
                                <img src={items?.media_formats?.gif?.url} width={300} height={300}/>

                                <Stack
                                    direction={'row'}
                                    spacing={1}
                                    pt={2}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                >
                                    <Button
                                        startIcon={<DownloadIcon/>}
                                        variant="contained"
                                        onClick={() => handleDownloadMeme(items?.media_formats?.gif?.url, 'gif')}
                                    >
                                        Download
                                    </Button>

                                    <Link to={`/gif-edit/?url=${items?.media_formats?.gif?.url}`}>
                                        <Button
                                            startIcon={<BorderColorIcon/>}
                                            variant="outlined"
                                        >
                                            Edit
                                        </Button>
                                    </Link>

                                    <Button
                                        startIcon={<StarBorderIcon/>}
                                        variant="outlined"
                                        onClick={() => handleViewGifSavePage(items?.media_formats?.gif?.url)}
                                    >
                                        Save
                                    </Button>
                                </Stack>

                                <Typography pt={2} color={'text.secondary'} fontSize={12}>
                                    Drag and drop meme text to change text position and size
                                </Typography>
                            </Box>
                        </Grid>
                    ))}
            </Grid>
        );
    } else if (textToImageData?.results?.length > 0 && (imageOrGifGenerate === 'off')) {
        content = (
            <Grid container spacing={2}>
                {textToImageData?.results?.map((items, key) => (
                    <Grid item xs={12} sm={6} md={4}>
                        <Box
                            key={key}
                            sx={{
                                background: 'white',
                                borderRadius: 2,
                                px: 3,
                                py: 2
                            }}
                        >
                            <img src={items?.media_formats?.gifpreview?.url} width={300} height={300}/>

                            <Stack
                                direction={'row'}
                                spacing={1}
                                pt={2}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                <Button startIcon={<DownloadIcon/>} variant="contained"
                                        onClick={() => handleDownloadMeme(items?.media_formats?.gifpreview?.url, 'image')}>
                                    Download
                                </Button>

                                <Link to={`/meme-edit/?url=${items?.media_formats?.gifpreview?.url}`}>
                                    <Button
                                        startIcon={<BorderColorIcon/>}
                                        variant="outlined"
                                    >
                                        Edit
                                    </Button>
                                </Link>
                                <Button
                                    startIcon={<ContentCopyIcon/>}
                                    variant="outlined"
                                    onClick={() => handleCopyMeme(items?.media_formats?.gifpreview?.url)}
                                >
                                    Copy
                                </Button>

                                <Button
                                    startIcon={<StarBorderIcon/>}
                                    variant="outlined"
                                    onClick={() => handleViewDetailsPage(items?.media_formats?.gifpreview?.url)}
                                >
                                    Save
                                </Button>
                            </Stack>

                            <Typography pt={2} color={'text.secondary'} fontSize={12}>
                                Drag and drop meme text to change text position and size
                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        );
    } else {
        content = (
            <>
                <EmptyPage title="Search something..."/>
            </>
        );
    }

    return (
        <>
            <Box sx={{
                margin: '20px 10px'
            }}>
                <MemeMakerHeader
                    title={'text-to-meme'}
                    handleBack={handleBack}
                />

                {/* Heading */}
                <Box textAlign="center">
                    <Typography variant="h4" fontWeight={800} fontSize={36} marginBottom={3}>
                        Convert any text to a meme or GIF
                    </Typography>
                    <Typography variant="p" fontSize={20} fontWeight={500}>
                        Enter any text (tweet, blog post intro, etc.) and hit "Generate" to get relevant memes
                    </Typography>
                </Box>

                {/* Search */}
                <Box maxWidth={920} marginX={'auto'} pt={2} textAlign="center" mb={3}>
                    <TextToMemeSearchBar
                        searchValue={searchValue}
                        handleTextToMeme={handleTextToMeme}
                    />
                </Box>

                {/* Output */}
                <Box
                    sx={{
                        border: '2px dashed gray',
                        width: '100%',
                        borderRadius: '10px',
                        display: 'block',
                        textAlign: 'center',
                        padding: '20px'
                    }}
                >
                    <Box>{content}</Box>
                </Box>
            </Box>

            <FullPageDrawer
                title="Save Image collection"
                open={viewDetailsPageShow}
                toggleDrawer={toggleViewDetailsPage}
                width="35%"
                height="60%"
            >
                <PromptCollectionForm
                    helperKey={'IMAGE'}
                    toggleDrawer={toggleViewDetailsPage}
                    handlePromptCollectionSubmit={handleImageCollectionSubmit}
                />
            </FullPageDrawer>

            <FullPageDrawer
                title="Save Gif collection"
                open={viewGifSavePageShow}
                toggleDrawer={toggleViewGifSavePage}
                width="35%"
                height="60%"
            >
                <PromptCollectionForm
                    helperKey={'IMAGE'}
                    toggleDrawer={toggleViewGifSavePage}
                    handlePromptCollectionSubmit={handleGifCollectionSubmit}
                />
            </FullPageDrawer>
        </>
    );
};

export default TextToMeme;
