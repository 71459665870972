import React, {useState} from 'react';
import {Box, InputAdornment, OutlinedInput} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {useDispatch} from 'react-redux';
import {filterBySearch} from "../../state/features/presetTemplate/presetTemplateSlice";

const PresetTemplateSearch = () => {
    const dispatch = useDispatch();
    const [searchInput, setSearchInput] = useState(null);
    const handleSearch = data => {
        setSearchInput(data.target.value);
        if (!data.target.value.length) {
            dispatch(filterBySearch(null));
        } else {
            dispatch(filterBySearch(searchInput));
        }
    };

    return (
        <Box
            sx={{
                width: '100%',
                border: 'none',
                transition: 'all 0.3s ease',
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '40px',
                padding: '10px 0px'
            }}
            component="form"
            noValidate
            autoComplete="off"
        >
            <OutlinedInput
                fullWidth
                id="send"
                size="small"
                sx={{
                    color: '#222',
                    border: 'none',
                    boxShadow: '0 4px 24px 0 rgba(34, 41, 47, 0.1)',
                    '&.MuiInputBase-root': {
                        backgroundColor: '#fff',
                        position: 'absolute',
                        bottom: '0'
                    }
                }}
                value={searchInput}
                placeholder="Search"
                onChange={handleSearch}
                startAdornment={
                    <InputAdornment position="start">
                        <SearchIcon sx={{fontSize: 20}}/>
                    </InputAdornment>
                }
            />
        </Box>
    );
};

export default PresetTemplateSearch;
