import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import GraphReport from "./GraphReport";
import DashboardHeader from "./DashboardHeader";
import AppWidgetSummary from "./AppWidgetSummary";
import ThemeProvider from "../../assets/global/theme";
import PhotoCameraRoundedIcon from '@mui/icons-material/PhotoCameraRounded';
import AppTrafficBySite from "./AppTrafficBySite";
import {useDispatch, useSelector} from "react-redux";
import {selectTotalDashboardCountState} from "../../state/features/dashboard/totalDashboardCountSelector";
import {totalDashboardCount} from "../../state/features/dashboard/totalDashboardCountSlice";
import {totalGraphData} from "../../state/features/dashboard/graph/totalGraphDataSlice";
import {selectGraphDataState} from "../../state/features/dashboard/graph/totalGraphDataSelector";
import {
    PACKAGE_FEATURE__KEY__COLLECTION,
    PACKAGE_FEATURE__KEY__IMAGE_CREATION,
    PACKAGE_FEATURE__KEY__IMAGE_IN_PAINTING,
    PACKAGE_FEATURE__KEY__IMAGE_TO_ALT,
    PACKAGE_FEATURE__KEY__IMAGE_TO_PROMPT,
    PACKAGE_FEATURE__KEY__IMAGE_VARIATION,
    PACKAGE_FEATURE__KEY__MEME_MAKER,
    PACKAGE_FEATURE__KEY__SEARCH_TEMPLATES,
    PACKAGE_FEATURE__KEY__TEXT_TO_MEME,
    PACKAGE_FEATURE__KEY__THEMED_MEMES
} from "../../helpers/constant/coreConstant";
import EmptyPage from "../../assets/global/EmptyComponent/EmptyPage";
import {CoreCardHeader} from "../../assets/global/CardHeader/CoreCardHeader";
import {Paper} from "@mui/material";
import {selectPackageAccessFeatureState} from "../../state/features/packageAccessFeature/packageAccessFeatureSelector";
import {appWidgetSummaryHelper} from "../../helpers/utility/appWidgetSummaryHelper";

const Dashboard = () => {
    const dispatch = useDispatch();
    const [subTitle, setSubTitle] = useState("");
    const [dateDifference, setDateDifference] = useState('');
    const [formatedGraphData, setFormatedGraphData] = useState([]);
    const [data, setData] = useState('');
    const {totalDashboardCountData} = useSelector(selectTotalDashboardCountState);
    const {graphData} = useSelector(selectGraphDataState);
    const {featureAccess} = useSelector(selectPackageAccessFeatureState);

    const {
        total_ai_transcription_count = 0,
        total_content_collection_count = 0,
        total_image_collection_count = 0,
        total_image_edit_count = 0,
        total_image_generate_count = 0,
        total_image_to_alt_text_count = 0,
        total_image_to_prompt_count = 0,
        total_image_variant_count = 0,
        total_platform_cost = 0,
        total_user_cost = 0,

    } = totalDashboardCountData || {};


    // fetch data every time when date range change
    const datePickerCallback = (data) => {
        const reqObj = {
            from_date: data._fromDate,
            to_date: data._toDate,
            duration_type: data.durationType,
            difference: data.difference + 1,
        };
        setDateDifference({from_date: data._fromDate, to_date: data._toDate});

        if (data.isFirstTime) {
            setSubTitle("(Last Month)");
        } else {
            setSubTitle("");
        }

        setData(reqObj);
        dispatch(totalGraphData(data));
    };

    useEffect(() => {
        if (graphData) {
            const formatGraphData = [];
            graphData.forEach((graphData) => {
                const obj = {
                    "Date": graphData.date,
                    "AI Transcription": graphData.total_ai_transcription_count,
                    "Content Collection": graphData.total_content_collection_count,
                    "Image Collection": graphData.total_image_collection_count,
                    "Image Edit": graphData.total_image_edit_count,
                    "Image Generate": graphData.total_image_generate_count,
                    "Image to Alt Text": graphData.total_image_to_alt_text_count,
                    "Image to Prompt": graphData.total_image_to_prompt_count,
                    "Image Varient": graphData.total_image_variant_count,
                    "Platform Cost": graphData.total_platform_cost,
                };
                formatGraphData.push(obj);
            });

            setFormatedGraphData(formatGraphData);
        }
    }, [graphData]);

    useEffect(() => {
        dispatch(totalDashboardCount());
    }, [])


    let imageGraph = null;
    if (formatedGraphData.length > 0) {
        imageGraph = (
            <>
                <GraphReport formatedGraphData={formatedGraphData}/>
            </>
        );
    } else {
        imageGraph = (
            <>
                <EmptyPage title="No graph was discovered."/>
            </>
        );
    }

    let appWidgetSummary = [];
    appWidgetSummary = appWidgetSummaryHelper.filter(item => {
        return featureAccess.some(featureAccess => {
            return featureAccess === item.key;
        });
    });

    let exceptAppWidgetSummary = [];
    exceptAppWidgetSummary = appWidgetSummaryHelper.filter(item => {
        return !featureAccess.some(featureAccess => {
            return featureAccess === item.key;
        });
    });


    return (
        <>
            <ThemeProvider>
                <Box component="div" sx={{
                    width: '100%',
                    height: '90vh',
                    backgroundColor: '#f3f4f6',
                    overflow: 'auto',
                    padding: '10px',
                    marginTop: '15px'
                }}>
                    <DashboardHeader datePickerCallback={datePickerCallback} data={data}/>

                    <Box sx={{marginTop: '15px',}}>
                        <Grid container spacing={2}>
                            {
                                appWidgetSummary.map((item) =>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <AppWidgetSummary title={item.title}
                                                          helperKey={item.key}
                                                          icon={item.icon}
                                                          color={item.color}
                                        />
                                    </Grid>
                                )
                            }

                            {
                                exceptAppWidgetSummary.map((item) =>
                                    <Grid item xs={12} sm={6} md={3} sx={{opacity: '0.5'}}>
                                        <AppWidgetSummary title={item.title}
                                                          icon={item.icon}
                                                          color={item.color}
                                                          message={'To access this feature you need to upgrade your package.'}
                                        />
                                    </Grid>
                                )
                            }

                        </Grid>
                    </Box>

                    <Box component="div" sx={{marginTop: '15px', overflow: 'auto'}}>
                        <Grid container wrap="nowrap" spacing={2}>
                            <Grid item xs={8}>
                                <Paper elevation={0} sx={{height: '100%'}}>
                                    <CoreCardHeader title={'Image Counts Graph Report'}/>
                                    {imageGraph}
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <AppTrafficBySite
                                    title="Total Image Counts"
                                    list={[
                                        {
                                            name: 'Image Generate',
                                            value: total_image_generate_count ? total_image_generate_count : '0',
                                            icon: <PhotoCameraRoundedIcon color="info"/>,
                                            key: PACKAGE_FEATURE__KEY__IMAGE_CREATION,
                                        },
                                        {
                                            name: 'Image Edit',
                                            value: total_image_edit_count ? total_image_edit_count : '0',
                                            icon: <PhotoCameraRoundedIcon color="info"/>,
                                            key: PACKAGE_FEATURE__KEY__IMAGE_IN_PAINTING,
                                        },
                                        {
                                            name: 'Image Varient',
                                            value: total_image_variant_count ? total_image_variant_count : '0',
                                            icon: <PhotoCameraRoundedIcon color="info"/>,
                                            key: PACKAGE_FEATURE__KEY__IMAGE_VARIATION,
                                        },
                                        {
                                            name: 'Image to Prompt',
                                            value: total_image_to_prompt_count ? total_image_to_prompt_count : '0',
                                            icon: <PhotoCameraRoundedIcon color="info"/>,
                                            key: PACKAGE_FEATURE__KEY__IMAGE_TO_PROMPT,
                                        },
                                        {
                                            name: 'Image to Alt Text',
                                            value: total_image_to_alt_text_count ? total_image_to_alt_text_count : '0',
                                            icon: <PhotoCameraRoundedIcon color="info"/>,
                                            key: PACKAGE_FEATURE__KEY__IMAGE_TO_ALT,
                                        },
                                        {
                                            name: 'Image Collection',
                                            value: total_image_collection_count ? total_image_collection_count : '0',
                                            icon: <PhotoCameraRoundedIcon color="info"/>,
                                            key: PACKAGE_FEATURE__KEY__COLLECTION,
                                        },
                                    ]}
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    <Box component="div" sx={{marginTop: '15px', overflow: 'auto'}}>
                        <Grid container wrap="nowrap" spacing={2}>
                            <Grid item xs={8}>
                                <Paper elevation={0} sx={{height: '100%'}}>
                                    <CoreCardHeader title={'Meme Counts Graph Report'}/>
                                    {imageGraph}
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <AppTrafficBySite
                                    title="Total Meme Counts"
                                    list={[
                                        {
                                            name: 'Text to Meme',
                                            value: total_image_generate_count ? total_image_generate_count : '0',
                                            icon: <PhotoCameraRoundedIcon color="info"/>,
                                            key: PACKAGE_FEATURE__KEY__TEXT_TO_MEME,
                                        },
                                        {
                                            name: 'Themed Meme',
                                            value: total_image_edit_count ? total_image_edit_count : '0',
                                            icon: <PhotoCameraRoundedIcon color="info"/>,
                                            key: PACKAGE_FEATURE__KEY__THEMED_MEMES,
                                        },
                                        {
                                            name: 'Search Template',
                                            value: total_image_variant_count ? total_image_variant_count : '0',
                                            icon: <PhotoCameraRoundedIcon color="info"/>,
                                            key: PACKAGE_FEATURE__KEY__SEARCH_TEMPLATES,
                                        },
                                        {
                                            name: 'Meme Maker',
                                            value: total_image_to_prompt_count ? total_image_to_prompt_count : '0',
                                            icon: <PhotoCameraRoundedIcon color="info"/>,
                                            key: PACKAGE_FEATURE__KEY__MEME_MAKER,
                                        },
                                    ]}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </ThemeProvider>
        </>
    );
};

export default Dashboard;
