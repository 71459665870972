import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeForm from "./StripeForm";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "@mui/material/Skeleton";

const PaymentCard = ({ onClosePaymentForm }) => {
    const dispatch = useDispatch("");
    const primaryKey =
        'pk_test_51LWCp6SJDvMs7LXsdSjz1RYB1ZptIwhVXW1rQm8jAs5u0AbUWIsPz0FD1Pjkaf72bklZpe4FyNnB1mCyKe3A0DUw00g8DfXM6e';
    const [loading, setLoading] = useState(true);

    const [stripePromiseState, setStripePromiseState] = useState(null);

    useEffect(() => {
        if (primaryKey) {
            const stripePromise = loadStripe(primaryKey);
            setStripePromiseState(stripePromise);
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }, [primaryKey]);

    return (
        <div>
            {loading ? (
                <>
                    <Skeleton variant={"rectangular"} height={40} sx={{ my: 3 }} />
                    <Skeleton variant={"rectangular"} height={40} sx={{ mb: 2 }} />
                </>
            ) : (
                <Elements stripe={stripePromiseState}>
                    <StripeForm onClosePaymentForm={onClosePaymentForm} />
                </Elements>
            )}
        </div>
    );
};

export default PaymentCard;
