import React from "react";
import PackagePricing from "../components/packages/components/PackagePricing/PackagePricing";
import PackageOnBoard from "../components/packages/components/PackageOnBoard/PackageOnBoard";

const PublicRoutes = [
    {
        path: 'client/package/pricing',
        element: <PackagePricing/>,
    },
    {
        path: "client/package/buy/:package_id",
        element: <PackageOnBoard />,
    },
];

export default PublicRoutes;
