import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {Stack, TextField, Typography} from '@mui/material';
import StyledDropzone from '../../assets/global/ImageUpload/StyledDropzone';
import LoopIcon from '@mui/icons-material/Loop';
import {useDispatch, useSelector} from "react-redux";
import {templateSearch} from "../../state/features/textToMeme/templateSearch/templateSearchSlice";
import RandomTextGenerator from "../../assets/global/RandromTextForMemeMaker/RandomTextGenerator";
import TemplateSearchModal from "../../assets/global/MemeMaker/TemplateSearchModal";
import LoadingButton from "../../assets/global/Button/LoadingButton";
import {selectAIGeneratedTextData} from "../../state/features/textToMeme/randomAIGeneratedText/AIGeneratedTextSelector";

const ThemedMemesSteps = ({setImageFile, searchTopic, handleAIGeneratedText}) => {
    const [searchValue, setSearchValue] = useState('');
    const dispatch = useDispatch();
    const [topicText, setTopicText] = useState('');
    const {isLoading} = useSelector(selectAIGeneratedTextData);

    useEffect(() => {
        searchTopic(topicText)
    }, [topicText])


    const generatedRandomText = (value) => {
        setTopicText(value)
    }

    useEffect(() => {
        const API_KEY = process.env.REACT_APP_TENOR_API_KEY;
        dispatch(
            templateSearch({
                q: searchValue ? searchValue : 'hello',
                key: API_KEY,
                pos: '20',
                limit: '20'
            })
        );
    }, [searchValue]);

    return (
        <>
            <Box
                sx={{
                    padding: '20px',
                    textAlign: 'center'
                }}
            >
                <Typography
                    varient="h4"
                    sx={{
                        fontWeight: 700,
                        fontSize: '18px'
                        // letterSpacing: '1px'
                    }}
                >
                    Generate a meme with AI in three simple steps
                </Typography>

                <Stack direction="row" alignItems={'center'} mt={4} fontSize={12}>
                    <Box
                        sx={{
                            borderBottom: '1px dashed black',
                            // width: '100%'
                            flex: 1
                        }}
                    ></Box>
                    <Typography
                        varient="p"
                        sx={{
                            letterSpacing: '1px',
                            fontSize: '14px',
                            fontWeight: 500,
                            whiteSpace: 'nowrap',
                            px: 2
                        }}
                    >
                        1. Enter Theme
                    </Typography>
                    <Box
                        sx={{
                            borderBottom: '1px dashed black',
                            flex: 1
                        }}
                    ></Box>
                </Stack>

                <Box mt={4}>
                    <TextField
                        fullWidth
                        size="small"
                        placeholder="Enter any topic here e.g. product management, crypto"
                        value={topicText}
                        onChange={(e) => setTopicText(e.target.value)}
                    />
                    <Box sx={{textAlign: 'left'}}>
                        <RandomTextGenerator generatedRandomText={generatedRandomText}/>
                    </Box>
                </Box>

                <Stack direction="row" alignItems={'center'} mt={4} fontSize={12}>
                    <Box
                        sx={{
                            borderBottom: '1px dashed black',
                            // width: '100%'
                            flex: 1
                        }}
                    ></Box>
                    <Typography
                        varient="p"
                        sx={{
                            letterSpacing: '1px',
                            fontSize: '14px',
                            fontWeight: 500,
                            whiteSpace: 'nowrap',
                            px: 2
                        }}
                    >
                        2. Choose Template
                    </Typography>
                    <Box
                        sx={{
                            borderBottom: '1px dashed black',
                            flex: 1
                        }}
                    ></Box>
                </Stack>

                <Box sx={{marginTop: '30px' /* padding: '20px, 0px' */}}>
                    <StyledDropzone setImageFile={setImageFile}/>
                </Box>

                <Box sx={{marginTop: '20px'}}>
                    <Typography
                        varient="h6"
                        sx={{
                            //   letterSpacing: '1px',
                            fontSize: '18px',
                            fontWeight: 600
                        }}
                    >
                        OR
                    </Typography>
                </Box>

                <Box sx={{marginTop: '20px'}}>
                    <TemplateSearchModal title={'themed-meme'}/>
                </Box>

                <Stack direction="row" alignItems={'center'} mt={4} fontSize={12}>
                    <Box
                        sx={{
                            borderBottom: '1px dashed black',
                            // width: '100%'
                            flex: 1
                        }}
                    ></Box>
                    <Typography
                        varient="p"
                        sx={{
                            letterSpacing: '1px',
                            fontSize: '14px',
                            fontWeight: 500,
                            whiteSpace: 'nowrap',
                            px: 2
                        }}
                    >
                        3. Generate meme
                    </Typography>
                    <Box
                        sx={{
                            borderBottom: '1px dashed black',
                            flex: 1
                        }}
                    ></Box>
                </Stack>

                <Box sx={{margin: '30px 0px 10px 0px'}}>
                    <LoadingButton
                        startIcon={<LoopIcon/>}
                        variant="contained"
                        color="primary"
                        size={'medium'}
                        isLoading={isLoading}
                        loadingText={'Regenerate...'}
                        onClick={handleAIGeneratedText}
                    >
                        Regenerate
                    </LoadingButton>
                </Box>
            </Box>

        </>
    );
};

export default ThemedMemesSteps;
