import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    textToImageData: [],
    textToGifData: [],
    isLoading: false,
    isError: false,
    error: ''
};

const textToImageSlice = createSlice({
    name: 'textToMeme',
    initialState: initialState,
    reducers: {
        textToImage: (state, action) => {
            state.isLoading = true;
        },
        textToImageSuccess: (state, action) => {
            state.textToImageData = action?.payload;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        textToImageFailed: (state, action) => {
            state.textToImageData = null;
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },
        textToImageClearState: (state, action) => {
            state.textToImageData = [];
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },

        textToGif: (state, action) => {
            state.isLoading = true;
        },
        textToGifSuccess: (state, action) => {
            state.textToGifData = action?.payload;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        textToGifFailed: (state, action) => {
            state.textToGifData = null;
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },
        textToGifClearState: (state, action) => {
            state.textToGifData = [];
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },
    }
});

export const {
    textToImage,
    textToImageSuccess,
    textToImageFailed,
    textToImageClearState,
    textToGif,
    textToGifSuccess,
    textToGifFailed,
    textToGifClearState
} = textToImageSlice.actions;

export default textToImageSlice.reducer;
