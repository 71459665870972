// --- Imports --- //
import React, {useRef, useState} from "react";

// --- Material Ui Imports --- //
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import {makeStyles} from '@mui/styles';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SaveIcon from '@mui/icons-material/Save';
import FullPageDrawer from "../../assets/global/Drawer/FullPageDrawer";

// --- Fill Image Card Component Imports --- //
import {FiCard, FiCardActions, FiCardMedia} from "./FullImageCard.js";
import PromptCollectionForm from "../prompt/PromptCollectionForm";
import {createImageCollection} from "../../state/features/promptCollection/promptCollectionSlice";
import {useDispatch} from "react-redux";
import {showErrorToaster, showSuccessToaster} from "../../helpers/utility/toaster";

// --- Style --- //
const useStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 0
    },
    /**
     * Max Card with for demo
     * same values used in Material-Ui Card Demos
     */
    // card: {
    //     maxWidth: 345
    // },

    /**
     * Applied to Orginal Card demo
     * Same vale used in Material-ui Card Demos
     */
    media: {
        height: 140
    },

    /**
     * Demo stlying to inclrease text visibility
     * May verry on implementation
     */
    fiCardContent: {
        color: "#ffffff",
        backgroundColor: "rgba(0,0,0,.24)",
        display: 'flex',
        justifyContent: 'center', // center the child elements horizontally
        alignItems: 'center', // center the child elements vertically
    },
    fiCardContentTextSecondary: {
        color: "rgba(255,255,255,0.78)"
    },
    '& .MuiBox-root': {
        width: '100% !important'
    }
});

function ResponseImage({image, imageGrid}) {
    const dispatch = useDispatch();
    const [viewDetailsPageShow, setViewDetailsPageShow] = useState(false);
    const toggleViewDetailsPage = () => setViewDetailsPageShow(prevState => !prevState);

    const [anchorEl, setAnchorEl] = useState(null);

    const handleImagePreview = (event, type) => {
        type === "enter" ? setAnchorEl(anchorEl ? null : event.currentTarget) : setAnchorEl(null)
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const handleViewDetailsPage = data => {
        toggleViewDetailsPage();
    };

    const handlePromptCollectionSubmit = data => {

        const payload = {};
        payload.collection_id = data.collection_id || '';
        payload.text = "response_image";
        payload.response = image;

        dispatch(createImageCollection(payload));
    };

    const classes = useStyles();
    const canvasRef = useRef(null);


    const handleDownload = async () => {
        try {
            const response = await fetch(image);
            const blob = await response.blob();
            const downloadUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = 'image.png';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            showSuccessToaster('Image downloaded');
        } catch (error) {
            showErrorToaster('Image download failed')
        }
    };

    function handleCopyClick(imageUrl) {
        fetch(imageUrl)
            .then((res) => res.blob())
            .then((blob) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const dataUrl = reader.result;
                    const item = new ClipboardItem({'image/png': blob});
                    navigator.clipboard.write([item]);

                    showSuccessToaster('Image copied');
                };
                reader.readAsDataURL(blob);
            })
            .catch((err) => console.error(err));
    }

    return (
        <>
            <Grid item sx={12} md={6} lg={imageGrid}>
                <Container className={classes.container}>
                    {/* Full Image Card with action buttons Demo */}
                    <Box width={imageGrid === 12 ? '70%' : '100%'} sx={{marginBottom: '20px'}}>
                        <FiCard className={classes.card}>
                            <canvas ref={canvasRef} style={{display: 'none'}}></canvas>
                            <FiCardMedia
                                media="picture"
                                alt="Response Image"
                                image={image}
                                title="Contemplative Reptile"
                                onMouseEnter={(event) => handleImagePreview(event, "enter")}
                                onMouseLeave={(event) => handleImagePreview(event, 'leave')}
                            />

                            <FiCardActions className={classes.fiCardContent}>

                                <Button size="small" color="inherit" onClick={() => handleCopyClick(image)}>
                                    <ContentCopyIcon/>
                                </Button>
                                <Button size="small" color="inherit" onClick={handleDownload}>
                                    <FileDownloadIcon/>
                                </Button>
                                <Button size="small" color="inherit" onClick={() => handleViewDetailsPage()}>
                                    <SaveIcon/>
                                </Button>

                            </FiCardActions>
                        </FiCard>
                    </Box>

                </Container>
            </Grid>

            <FullPageDrawer
                title="Save Image collection"
                open={viewDetailsPageShow}
                toggleDrawer={toggleViewDetailsPage}
                width="35%"
                height="60%"
            >
                <PromptCollectionForm
                    helperKey={'IMAGE'}
                    toggleDrawer={toggleViewDetailsPage}
                    handlePromptCollectionSubmit={handlePromptCollectionSubmit}
                />
            </FullPageDrawer>
        </>
    );
}

export default ResponseImage;