import {requests} from "./http.node.service";

class PresetTemplateService {
    getPromptTemplateData(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_NODE_SERVER + "/template/get/template/datatable/list", body);
    }

    getSpecificPromptTemplateData(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_NODE_SERVER + "/template/get/specific/template/data", body);
    }

    getGeneratedResponseData(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_NODE_SERVER + "/template/generate-response", body);
    }
}

export default new PresetTemplateService();
