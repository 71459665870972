import React from 'react';
import {Box} from "@mui/material";
import {CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis} from 'recharts';


const CustomTick = ({x, y, payload}) => {

    // Show tick every three values
    // if (payload.index % 2 !== 0) {
    //     return null;
    // }

    // Customize the appearance of the tick
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-45)" style={{fontSize: '10px'}}>
                {payload.value}
            </text>
        </g>
    );
};


const GraphReport = ({formatedGraphData = {formatedGraphData}}) => {

    const graphobject = formatedGraphData.reduce((result, item) => {
        result[item.key] = item.value;
        return result;
    }, {});

    return (
        <>
            <Box p={3}>
                <LineChart
                    width={850}
                    height={300}
                    data={formatedGraphData}
                    style={{padding: '15px', margin: '10px'}}
                >
                    <CartesianGrid strokeDasharray="2 2"/>
                    <XAxis dataKey="Date" tick={<CustomTick/>}/>
                    <YAxis style={{fontSize: '10px'}}/>
                    <Tooltip/>
                    <Legend/>
                    <Line
                        type="monotone"
                        dataKey="Image Collection"
                        stroke="#8884d8"
                        activeDot={{r: 8}}
                    />
                    <Line type="monotone" dataKey="Image Edit" stroke="#82ca9d"/>
                    <Line type="monotone" dataKey="Image Generate" stroke="#800080"/>
                    <Line type="monotone" dataKey="Image Varient" stroke="#FF00FF"/>
                    <Line type="monotone" dataKey="Image to Alt Text" stroke="#123456"/>
                    <Line type="monotone" dataKey="AI Transcription" stroke="#123456"/>
                </LineChart>
            </Box>
        </>
    );
};

export default GraphReport;
