import React from 'react';
import {Box, Typography} from "@mui/material";
import PromptPreview from "./PromptPreview";

const PromptInitialPage = () => {
    return (
        <>
            <Box>


                <Box sx={{p: 3}}>

                    <PromptPreview/>
                    {/*<img*/}
                    {/*    style={{height: "254px", padding: '10px'}}*/}
                    {/*    src={img}*/}
                    {/*    alt="prompt"*/}
                    {/*    loading="lazy"*/}
                    {/*/>*/}
                </Box>
                <Typography variant={"body1"} color={"text.secondary"}
                            sx={{
                                paddingTop: '30px',
                                paddingBottom: '30px',
                                fontWeight: '500',
                                color: '#222',
                                borderTop: '1px solid rgb(230, 230, 230)'
                            }}>
                    "Amateurs sit and wait for inspiration, the rest of us just get up and go to work" - Stephen King
                </Typography>

            </Box>
        </>
    );
};

export default PromptInitialPage;