import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userPackage: null,
    loading: false,
    isSuccess: false,
    isError: false,
    error: "",
    hasPackage: true,
    isPackageExpire: false,
};

const userPackageSlice = createSlice({
    name: "userPackage",
    initialState,
    reducers: {
        getUserHasPackageData: (state) => {
            state.loading = true;
        },
        getUserHasPackageDataSuccess: (state, action) => {
            state.userPackage = action.payload.data;
            state.isPackageExpire = action.payload.isPackageExpire;
            state.hasPackage = true;
            state.loading = false;
            state.isSuccess = true;
            state.isError = false;
            state.error = "";
        },
        getUserHasPackageDataFailed: (state, action) => {
            state.userPackage = null;
            state.isPackageExpire = false;
            state.hasPackage = action.payload.hasPackage;
            state.loading = false;
            state.isSuccess = false;
            state.isError = true;
            state.error = action.payload.message;
        },
    },
});

export const { getUserHasPackageData, getUserHasPackageDataSuccess, getUserHasPackageDataFailed } =
    userPackageSlice.actions;

export default userPackageSlice.reducer;
