import {Stack} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {
  clearChatGPTState,
  setChatGPTAnswerByClick,
  setChatGPTQuestionByClick
} from '../../state/features/chatGPT/chatGPTSlice';
import {useDispatch, useSelector} from 'react-redux';

import ChatContent from './ChatContent';
import ChatList from './ChatList';
import InputSection from './InputSection';
import {nanoid} from 'nanoid';
import {selectChatGPTState} from '../../state/features/chatGPT/chatGPTSelector';

const ChatGptMain = () => {
  const dispatch = useDispatch();
  const {messages = [], isLoading} = useSelector(selectChatGPTState);
  const [scrollRenderId, setScrollRenderId] = useState('');
  const onScrollBottom = () => setScrollRenderId(nanoid(5));

  useEffect(() => {
    const scrollArea = document.getElementById('chat_gpt_scroll_area');
    if (scrollArea) {
        scrollArea.scrollTo(0, scrollArea.scrollHeight);
    }
  }, [scrollRenderId]);

  useEffect(() => {
    dispatch(clearChatGPTState());
  }, []);

  const handleChatClick = chat => {
    const message = {
      role: 'assistant',
      content: chat?.reply
    };

    const messages = [{role: 'user', content: chat?.text}];

    dispatch(setChatGPTQuestionByClick({messages: messages}));
    dispatch(setChatGPTAnswerByClick({message: message}));
  };

  return (
    <>
      <Stack
        direction={'column'}
        sx={{backgroundColor: '#fff', height: '90vh',maxHeight:'90vh', width: '100%'}}
      >
        {!messages.length ? (
          <ChatList handleChatClick={handleChatClick} />
        ) : (
          <ChatContent messages={messages} isLoading={isLoading} onScrollBottom={onScrollBottom} />
        )}
        <InputSection />
        {/* <box sx={{bottom:'0',position:'absolute', backgroundColor:'red', marginBottom:'30px'}}>
                <InputSection />
                </box> */}
      </Stack>
    </>
  );
};

export default ChatGptMain;
