import {all, put} from 'redux-saga/effects';
import {call, takeEvery} from '@redux-saga/core/effects';
import {textToGifFailed, textToGifSuccess, textToImageFailed, textToImageSuccess} from "./textToImageSlice";
import TextToMemeService from "../../../../services/TextToMeme.service";

function* textToImageWatcher() {
    yield takeEvery('textToMeme/textToImage', generateTextToImageSaga);
    yield takeEvery('textToMeme/templateSearch', generateTextToGifSaga);
}

function* generateTextToImageSaga(action) {
    try {
        const response = yield call(TextToMemeService.generateTextToMeme, action.payload);

        if (response?.results) {
            yield put(textToImageSuccess(response));
        } else {
            yield put(textToImageFailed(response?.results));
        }
    } catch (err) {
        yield put(textToImageFailed(err.message));
    }
}

function* generateTextToGifSaga(action) {
    try {
        const response = yield call(TextToMemeService.generateTextToMeme, action.payload);

        if (response?.results) {
            console.log("=======mem response======", response?.results);
            yield put(textToGifSuccess(response));
        } else {
            yield put(textToGifFailed(response?.results));
        }
    } catch (err) {
        yield put(textToImageFailed(err.message));
    }
}

export default function* textToImageSaga() {
    yield all([textToImageWatcher()]);
}
