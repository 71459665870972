export const registerPattern = {
    email: {
        required: "Email is required!",
        pattern: {
            value: /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
            message: "Email is invalid!",
        },
        maxLength: {
            value: 50,
            message: "Email must have less than 50 characters!",
        },
        setValueAs: (v) => (v ? v.trim() : ""),
    },
    password: {
        required: "Password is required",
        minLength: {
            value: 6,
            message: "Password must have at least 6 characters",
        },
        setValueAs: (v) => (v ? v.trim() : ""),
    },
    confirm_password: {
        required: "Confirm password is required",
        minLength: {
            value: 6,
            message: "Confirm password must have at least 6 characters",
        },
        setValueAs: (v) => (v ? v.trim() : ""),
    },
    first_name: {
        required: "Name is required",
        maxLength: {
            value: 50,
            message: "Firstname must have less than 50 characters!",
        },
        setValueAs: (v) => (v ? v.trim() : ""),
    },
    last_name: {
        required: "Lastname is required",
        maxLength: {
            value: 50,
            message: "Lastname must have less than 50 characters!",
        },
        setValueAs: (v) => (v ? v.trim() : ""),
    },
    phone: {
        required: "Phone number is required",
        pattern: {
            value: /^[0-9]+$/,
            message: "Invalid phone number!",
        },
        minLength: {
            value: 11,
            message: "Phone number must have minimum 11 digits!",
        },
        maxLength: {
            value: 16,
            message: "Phone number must have less than 16 digits!",
        },
        setValueAs: (v) => (v ? v.trim() : ""),
    },

    token: {
        required: "Token is required",
        minLength: {
            value: 6,
            message: "Token must have at least 6 characters",
        },
        setValueAs: (v) => (v ? v.trim() : ""),
    },
};
export const loginRegisterPattern = {
    email: {
        required: "Email is required!",
        pattern: {
            value: /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
            message: "Email is invalid!",
        },
        maxLength: {
            value: 50,
            message: "Email must have less than 50 characters!",
        },
        setValueAs: (v) => (v ? v.trim() : ""),
    },
    password: {
        required: "Password is required",
        minLength: {
            value: 6,
            message: "Password must have at least 6 characters",
        },
        setValueAs: (v) => (v ? v.trim() : ""),
    },
};

export const collectionPattern = {
    name: {
        required: "Collection name is required",
        setValueAs: (v) => (v ? v.trim() : ""),
    },
};

export const promptCollectionPattern = {
    collection: {
        required: "Collection is required",
        setValueAs: (v) => (v ? v.trim() : ""),
    },
};

export const promptInputPattern = {
    input: {
        required: "Input field required",
        setValueAs: (v) => (v ? v.trim() : ""),
    },
};

export const contentRepurposingPattern = {
    inputType: {
        required: "Input type is required",
        setValueAs: (v) => (v ? v.trim() : ""),
    },
    outputType: {
        required: "Output type is required",
        setValueAs: (v) => (v ? v.trim() : ""),
    },
    youtubeUrl: {
        required: "URL / Text field is required",
        pattern: {
            value: /^https?:\/\/(?:www\.)?youtube\.com\/(?:watch\?(?=.*v=\w+)(?:\S+)?|embed\/\w+|v\/\w+|shorts\/\w+)$/,
            message: "Youtube url is invalid!",
        },
        setValueAs: (v) => (v ? v.trim() : ""),
    },
    websitebeUrl: {
        required: "URL / Text field is required",
        pattern: {
            value: /^(?:https?:\/\/)?(?:www\.)?[a-z0-9]+(?:[.\-][a-z0-9]+)*\.[a-z]{2,}(?:\/.*)?$/i,
            message: "Website url is invalid!",
        },
        setValueAs: (v) => (v ? v.trim() : ""),
    },
    text: {
        required: "URL / Text field is required",
        pattern: {
        },
        setValueAs: (v) => (v ? v.trim() : ""),
    },
};
export const imageToPromptPattern = {
    inputImage: {
        required: "Select an image to upload",
        setValueAs: (v) => (v ? v.trim() : ""),
    },
};

export const imageVariationPattern = {
    imageShape: {
        required: "Aspect ratio is required",
        setValueAs: (v) => (v ? v.trim() : ""),
    },
    imageSize: {
        required: "Image size is required",
        setValueAs: (v) => (v ? v.trim() : ""),
    },
    imageKeyword: {
        required: "Image keywords are required",
        setValueAs: (v) => (v ? v.trim() : ""),
    },
    totalImage: {
        required: "Response image amount is required",
        setValueAs: (v) => (v ? v.trim() : ""),
    }
};
export const imageCreationPattern = {
    aspect_ratio: {
        required: "Aspect ratio is required",
        setValueAs: (v) => (v ? v.trim() : ""),
    },
    image_size: {
        required: "Image size is required",
        setValueAs: (v) => (v ? v.trim() : ""),
    },
    image_type: {
        required: "Image type is required",
        setValueAs: (v) => (v ? v.trim() : ""),
    },
    image_keyword: {
        required: "Keyword is required",
        setValueAs: (v) => (v ? v.trim() : ""),
    },
    image_description: {
        required: "Image description is required",
        setValueAs: (v) => (v ? v.trim() : ""),
    },
    number_of_image: {
        required: "Number of Image is required",
        setValueAs: (v) => (v ? v.trim() : ""),
    }
};

export const imageInPaintingPattern = {
    replace_item: {
        required: "Replace field is required",
        setValueAs: (v) => (v ? v.trim() : ""),
    },
    replacement_item: {
        required: "Replacement field is required",
        setValueAs: (v) => (v ? v.trim() : ""),
    },
    prompt: {
        required: "Description of the image is required",
        setValueAs: (v) => (v ? v.trim() : ""),
    },
    image: {
        required: "Image is required",
        setValueAs: (v) => (v ? v.trim() : ""),
    },
    imageSize: {
        required: "Image size is required",
        setValueAs: (v) => (v ? v.trim() : ""),
    },
    total_image: {
        required: "Number of image is required",
        setValueAs: (v) => (v ? v.trim() : ""),
    },
};

export const aiTranscriptionPattern = {
    audio_types: {
        required: "Types of audio is required",
        setValueAs: (v) => (v ? v.trim() : ""),
    },
    spoken_language: {
        required: "Spoken language is required",
        setValueAs: (v) => (v ? v.trim() : ""),
    },
    prompt: {
        required: "Audio description is required",
        setValueAs: (v) => (v ? v.trim() : ""),
    },
};
