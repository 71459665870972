import React from 'react';
import FeatureInputHeader from './FeatureInputHeader';
import FeatureInputFooter from './FeatureInputFooter';
import {Box} from '@mui/material';

const FeatureInput = ({
  headerTitle = '',
  helpkey='',
  clearInput='',
  subtitle = '',
  children,
  footer = false,
  handleGenerate,
  handleClearInput,
}) => {
  return (
    <>
      <Box
        sx={{
          width: '80%',
          m: 'auto',
          p: 3,
          backgroundColor: '#FFF',
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8
        }}
      >
        {/* Header */}
        <FeatureInputHeader title={headerTitle} helpkey={helpkey} subtitle={subtitle} />
        {/* Body / Children */}
        {children}
      </Box>
      {/* Footer */}
      {footer && <FeatureInputFooter footer={footer} handleGenerate={handleGenerate} handleClearInput={handleClearInput} clearInput={clearInput} />}
    </>
  );
};

export default FeatureInput;
