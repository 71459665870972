import React, {useEffect, useState} from 'react';
import {Box, Card, CardContent, Stack} from '@mui/material';
import {RateReview} from '@mui/icons-material';
import {useTheme} from '@mui/material/styles';
import Styles from '../styles/Package.module.css';
import PackagePreviewCard from './PackagePreviewCard';
import PackagePreviewFeatureHeader from './PackagePreviewFeatureHeader';
import PackagePreviewFeatureItem from './PackagePreviewFeatureItem';
import PackageBadge from './PackageBadge';
import formatFeatureLimitList from '../helper/utility/formatFeatureLimitList';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {USER__HAS__PACKAGE__TYPE__FREE} from "../../../helpers/constant/coreConstant";

export const SingleUpgradePackageItem = ({packageData}) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const {userPackage} = useSelector(state => state.userPackage);
    const {dollarBalance, reviewFeatures} = formatFeatureLimitList(
        packageData.feature_access
    );

    const [isSamePackage, setSamePackage] = useState(false);
    const [buttonText, setButtonText] = useState('');

    const onClickPackageCard = packageDataInfo => {
        navigate('/client/settings/package/upgrade', {
            state: {...packageDataInfo}
        });
    };

    useEffect(() => {
        if (userPackage && packageData.id === userPackage?.user_package_id) {
            setButtonText('Already Using');
            setSamePackage(true);
        } else {
            if (Number(packageData?.package_price) > Number(userPackage?.package_price)) {
                setButtonText(`Upgrade to ${packageData.title}`);
            } else {
                setButtonText(`Downgrade to ${packageData.title}`);
            }
        }
    }, [userPackage]);

    return (
        <Card
            className={Styles.package__item}
            sx={{boxShadow: '0px 4px 0px ' + theme.palette.info.main}}
        >
            <CardContent sx={{p: 0}}>
                <Box className={Styles.package__header} sx={{backgroundColor: '#20a4f314'}}>
                    {USER__HAS__PACKAGE__TYPE__FREE === packageData?.package_type || isSamePackage ? (
                        ''
                    ) : (
                        <PackageBadge
                            title={`Get Balance $ ${
                                dollarBalance && dollarBalance[0] ? dollarBalance[0].limit : 0
                            }`}
                            bgColor={theme.palette.primary.main}
                        />
                    )}
                </Box>

                <PackagePreviewCard
                    title={packageData.title}
                    description={packageData.description}
                    packagePrice={packageData?.package_price}
                    buttonText={buttonText}
                    onClickButton={() => onClickPackageCard(packageData)}
                    buttonDisable={isSamePackage}
                />

                <Box>

                    {/* ## Review Generator Section ## */}
                    <PackagePreviewFeatureHeader
                        icon={<RateReview fontSize="small" color="action"/>}
                        title={'Feature'}
                    />
                    <Stack
                        className={Styles.package__list_group}
                        bgcolor={'background.default'}
                        sx={{py: theme.spacing(2)}}
                    >
                        {reviewFeatures.map(item => (
                            <PackagePreviewFeatureItem
                                key={item.id}
                                visibility={item.status}
                                title={`${item.limit} ${item.feature_name}`}
                            />
                        ))}
                    </Stack>
                </Box>
            </CardContent>
        </Card>
    );
};
