import React from 'react';
import {Box, FormControl, Grid, InputLabel, Paper, Select, Stack} from '@mui/material';
import OnBoardPackage from './OnBoardPackage';
import MenuItem from '@mui/material/MenuItem';
import {useSelector} from 'react-redux';
import Skeleton from '@mui/material/Skeleton';
import {CoreCardHeader} from '../../../../assets/global/CardHeader/CoreCardHeader';
import CoreButton from '../../../../assets/global/Button/CoreButton';

const PackageChoiceView = props => {
    const {
        isRenew,
        activeStep,
        handleNext,
        handleBack,
        steps,
        selectedPackageUid,
        onSelectPackage,
        billingCycleList,
        billingCycleUid,
        onChangeBillingCycleUid
    } = props;

    const {userPackage, isSuccess: isSuccessHasPackage} = useSelector(state => state.userPackage);
    const {packages, loading} = useSelector(state => state.package);

    const onChangeBillingCycle = event => {
        onChangeBillingCycleUid(event.target.value);
    };

    // decide what to render on package list view
    let packagesView = null;
    if (loading) {
        packagesView = [1, 2, 3].map(number => (
            <Skeleton key={number} variant="rectangular" height={180} width={160} sx={{m: 2}}/>
        ));
    } else if (!loading && packages?.length === 0) {
        packagesView = '';
    } else if (!loading && packages?.length > 0 && isRenew) {
        packagesView =
            isSuccessHasPackage &&
            packages
                .slice(0)
                .filter(
                    packageItem => Number(packageItem.package_price) >= Number(userPackage.package_price)
                )
                .map(packageItem => (
                    <Grid key={packageItem.id} item lg={3} md={4} sm={3}>
                        <OnBoardPackage
                            checked={+selectedPackageUid === packageItem.id}
                            packageItem={packageItem}
                            onSelectPackage={onSelectPackage}
                        />
                    </Grid>
                ));
    } else if (!loading && packages?.length > 0 && !isRenew) {
        packagesView = packages.map(packageItem => (
            <Grid key={packageItem.uid} item lg={3} md={4} sm={3}>
                <OnBoardPackage
                    checked={+selectedPackageUid === packageItem.id}
                    packageItem={packageItem}
                    onSelectPackage={onSelectPackage}
                />
            </Grid>
        ));
    }

    return (
        <Paper elevation={0} sx={{mt: 3, mb: 3}}>
            <Stack>
                <CoreCardHeader title="Choose a Billing Cycle" isButtonShow={false}/>

                <Grid container spacing={2} sx={{p: 3}}>
                    {packagesView}
                </Grid>

                <Box sx={{padding: '0 24px 8px 24px'}}>
                    <FormControl required size="small" fullWidth>
                        <InputLabel id="demo-simple-select-label">Select Billing Cycle</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={billingCycleUid}
                            label="Choose Billing Cycle"
                            onChange={onChangeBillingCycle}
                        >
                            {billingCycleList.map((billingCycle, index) => (
                                <MenuItem key={index} value={billingCycle.id}>
                                    {`${billingCycle.billing_cycle} Months @ $${billingCycle.discount_monthly_price}/mo - ${billingCycle.discount_percentage}% Off!`}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                <Stack
                    justifyContent={'flex-end'}
                    direction={'row'}
                    spacing={3}
                    sx={{pt: '16px', px: '24px', pb: '24px'}}
                >
                    <CoreButton size="large" variant="contained" onClick={handleNext} color="secondary">
                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </CoreButton>
                </Stack>
            </Stack>
        </Paper>
    );
};

export default PackageChoiceView;
