import { all, call, takeEvery } from "redux-saga/effects";
import { put } from "@redux-saga/core/effects";
import {getPackagesFailed, getPackagesSuccess} from "./packageSlice";
import PackageService from "../../../services/Package.service";

function* packageWatcher() {
    yield takeEvery("package/getPackages", getPackagesSaga);
}

function* getPackagesSaga(action) {
    try {
        const response = yield call(PackageService.getPackages, action.payload);

        if (response.success) {
            yield put(getPackagesSuccess(response.data));
        } else {
            yield put(getPackagesFailed(response.error));
        }
    } catch (err) {
        console.log("Error: ", err);
        yield put(getPackagesFailed(err.message));
    }
}

export default function* packageSaga() {
    yield all([packageWatcher()]);
}
