import React, {useEffect} from 'react';
import {Box, CircularProgress, Stack, styled, Typography} from '@mui/material';

const LoadingBox = styled(Box)(({theme}) => ({
    backgroundColor: '#2c3e50'
}));

const LoadingContent = () => {
    return (
        <LoadingBox p={3}>
            <Stack
                direction={'row'}
                spacing={2}
                alignItems={'flex-start'}
                sx={{
                    width: {
                        xs: '300px',
                        sm: '500px',
                        md: '700px',
                        lg: '800px',
                        xxl: '900px',
                        margin: '0 auto'
                    }
                }}
            >
                <CircularProgress sx={{color:"#fff"}} />
                <Typography variant={'body1'} color={'common.white'}>
                    Please be patient while we investigate your request.
                </Typography>
            </Stack>
        </LoadingBox>
    );
};

export default LoadingContent;
