import {all, put} from 'redux-saga/effects';
import {call, takeEvery} from '@redux-saga/core/effects';
import {totalGraphDataFailed, totalGraphDataSuccess} from "./totalGraphDataSlice";
import TotalDashboardCountService from "../../../../services/TotalDashboardCount.service";

function* totalGraphDataWatcher() {
    yield takeEvery('totalGraphData/totalGraphData', generateTotalGraphDataSaga);
}

function* generateTotalGraphDataSaga(action) {
    try {

        const response = yield call(TotalDashboardCountService.totalGraphData, action.payload);

        if (response.status) {
            yield put(totalGraphDataSuccess(response.data));
        } else {
            yield put(totalGraphDataFailed(response.message));
        }
    } catch (err) {
        yield put(totalGraphDataFailed(err.message));
    }
}


export default function* totalGraphDataSaga() {
    yield all([totalGraphDataWatcher()]);
}
