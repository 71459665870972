import * as React from 'react';

import {styled} from '@mui/material/styles';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Toolbar from '@mui/material/Toolbar';

// import Typography from '@mui/material/Typography';

// import IconButton from '@mui/material/IconButton';

const SearchCategory = styled('div')(({theme}) => ({
    position: 'relative',
    //   borderRadius: theme.shape.borderRadius,
    //   backgroundColor: alpha(theme.palette.common.white, 0.15),

    //   '&:hover': {
    //     backgroundColor: alpha(theme.palette.common.white, 0.25),
    //   },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto'
    }
}));

const SearchIconWrapper = styled('div')(({theme}) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({theme}) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch'
            }
        }
    }
}));


export default function SearchAppBar({handleSearch}) {

    const handleSearchInput = data => {
        handleSearch(data.target.value)
    };

    return (
        <Box sx={{}}>
            <AppBar
                position="static"
                sx={{backgroundColor: 'transparent', boxShadow: '0 0 0 0 rgba(0,0,0,0.12)'}}
            >
                <Toolbar sx={{backgroundColor: '#eef1ee00', minHeight: 'auto', padding: '0px'}}>
                    <SearchCategory
                        sx={{backgroundColor: '#f1f2f6', color: '#57606f', borderRadius: '5px'}}
                        onChange={handleSearchInput}
                    >
                        <SearchIconWrapper>
                            <SearchIcon/>
                        </SearchIconWrapper>
                        <StyledInputBase placeholder="Search…" inputProps={{'aria-label': 'search'}}/>
                    </SearchCategory>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
