import React, {useEffect} from 'react';
import {Button} from '@mui/material';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import {Paid} from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import {useTheme} from '@mui/material/styles';
import {useDispatch, useSelector} from 'react-redux';
import {getSpecificBalanceData} from '../../../state/features/userBalance/userBalanceSlice';
import CoreLink from '../../../assets/global/Link/CoreLink';
import {PACKAGE_LIMIT__BALANCE__KEY__CREDIT_AMOUNT} from "../../../helpers/constant/coreConstant";

const AppNavbarBalance = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const {dollarBalance, dollarRechargeBalance, loading} = useSelector(state => state.userBalance);

    useEffect(() => {
        dispatch(getSpecificBalanceData({key: PACKAGE_LIMIT__BALANCE__KEY__CREDIT_AMOUNT}));
    }, []);

    return (
        <CoreLink to="settings/billing">
            <Tooltip title="Remaining Dollar Balance">
                <Button
                    sx={{
                        backgroundColor: '#f0f2f5',
                        borderRadius: 8,
                        px: 2
                    }}
                >
                    <Typography
                        variant={'subtitle2'}
                        sx={{
                            fontWeight: 'bold',
                            color: theme.palette.action.active
                        }}
                    >
                        Dollar Balance
                    </Typography>
                    <Paid sx={{mx: '6px'}}/>
                    <Typography
                        variant={'subtitle2'}
                        sx={{
                            fontWeight: 'bold',
                            color: theme.palette.text.primary
                        }}
                    >
                        {loading ? <CircularProgress size=".8rem"/> : dollarBalance + dollarRechargeBalance}
                    </Typography>
                </Button>
            </Tooltip>
        </CoreLink>
    );
};

export default AppNavbarBalance;
