import React, {useRef, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {InputLabel, Stack, TextField, Typography} from '@mui/material';
import InputFieldRepeater from './InputFieldRepeater';
import Button from '@mui/material/Button';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Draggable from 'react-draggable';
import * as htmlToImage from 'html-to-image';
import {Resizable} from 'react-resizable';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import 'react-resizable/css/styles.css';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import DownloadIcon from '@mui/icons-material/Download';

const TextToMemeEdit = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    let imageUrl = searchParams.get('url');
    const divRef = useRef(null);
    const [captions, setCaptions] = useState([]);
    const [header, setHeader] = useState('');
    const [showHeader, setShowHeader] = useState(false);
    const [footer, setFooter] = useState('');
    const [showFooter, setShowFooter] = useState(false);

    const [textSize, setTextSize] = useState(20);
    const [showResizeHandles, setShowResizeHandles] = useState(false);
    const [isResizing, setIsResizing] = useState(false);
    const [position, setPosition] = useState({x: 0, y: 0});
    const [size, setSize] = useState({
        width: '100%',
        height: '100%',
    });

    const onStateUpdate = (values) => {
        setCaptions(values)
    }

    const handleRemoveHeader = () => {
        setShowHeader(false);
        setHeader('');
    };

    const handleRemoveFooter = () => {
        setShowFooter(false);
        setFooter('');
    };
    const handleDrag = (e, ui) => {
        const {x, y} = ui;
        setPosition({x, y});
        console.log('==========position======', position);
    };
    const handleResize = (event, {node, size, handle}) => {
        setSize({width: size.width, height: size.height});
        adjustTextSize(size.height);
    };
    const adjustTextSize = divHeight => {
        const newSize = Math.round(divHeight / 10); // Adjust the division factor as needed
        setTextSize(newSize);
    };

    const handleDownload = () => {
        var node = document.getElementById('my-node');
        htmlToImage.toJpeg(node, {quality: 0.95}).then(function (dataUrl) {
            var link = document.createElement('a');
            link.download = 'my-image-name.jpeg';
            link.href = dataUrl;
            link.click();
        });
    };

    return (
        <>
            <Box p={8}>
                <Button variant="outlined" sx={{mb: 2}} startIcon={<ArrowBackIcon/>}>
                    Back
                </Button>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={6}>
                        <Box bgcolor={'white'} border={`1px solid #d4d4d4`} borderRadius={3} p={3}>
                            <Box textAlign={'center'} mb={2}>
                                <Typography variant="h4">Make a meme</Typography>
                            </Box>
                            <Box>
                                <InputFieldRepeater onStateUpdate={onStateUpdate}/>

                                <Box mt={4}>
                                    {showHeader && (
                                        <Box sx={{marginTop: '10px'}}>
                                            <InputLabel htmlFor="component-disabled">Header Caption</InputLabel>
                                            <Stack direction="row">
                                                <TextField
                                                    fullWidth
                                                    id="fullWidth"
                                                    size="small"
                                                    value={header}
                                                    onChange={event => setHeader(event.target.value)}
                                                />
                                                <Button size="small" onClick={handleRemoveHeader}>
                                                    <HighlightOffIcon/>
                                                </Button>
                                            </Stack>
                                        </Box>
                                    )}

                                    {showFooter && (
                                        <Box sx={{marginTop: '10px'}}>
                                            <InputLabel htmlFor="component-disabled">Footer Caption</InputLabel>
                                            <Stack direction="row">
                                                <TextField
                                                    fullWidth
                                                    id="fullWidth"
                                                    size="small"
                                                    value={footer}
                                                    onChange={event => setFooter(event.target.value)}
                                                />
                                                <Button size="small" onClick={handleRemoveFooter}>
                                                    <HighlightOffIcon/>
                                                </Button>
                                            </Stack>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                    marginBottom: '20px'
                                }}
                            >
                                <Stack direction="row" spacing={5}>
                                    <Button
                                        disabled={showHeader}
                                        variant="contained"
                                        startIcon={<AddCircleOutlineOutlinedIcon/>}
                                        onClick={() => setShowHeader(true)}
                                    >
                                        Add header
                                    </Button>
                                    <Button
                                        disabled={showFooter}
                                        variant="contained"
                                        startIcon={<AddCircleOutlineOutlinedIcon/>}
                                        onClick={() => setShowFooter(true)}
                                    >
                                        Add Footer
                                    </Button>
                                </Stack>
                            </Box>
                            <Box
                                sx={{
                                    textAlign: 'center',
                                    marginTop: '20px',
                                    marginBottom: '10px'
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        color: 'gray'
                                    }}
                                >
                                    Drag and drop meme text to change text position and size
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={6} sm={6} md={6}>
                        <Box
                            id="my-node"
                            sx={{
                                margin: '0 auto',
                                width: '400px',
                                height: 'auto',
                                backgroundColor: 'white'
                            }}
                        >
                            {header && (
                                <Box
                                    sx={{
                                        padding: '10px 15px'
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: 'black',
                                            backgroundColor: 'white',
                                            textShadow:
                                                'rgb(0, 0, 0) -1px -1px 0px, rgb(0, 0, 0) 1px -1px 0px, rgb(0, 0, 0) -1px 1px 0px, rgb(0, 0, 0) 1px 1px 0px',
                                            letterSpacing: '0.2rem'
                                        }}
                                    >
                                        {header}
                                    </Typography>
                                </Box>
                            )}
                            <Box
                                sx={{
                                    position: 'relative'
                                }}
                            >
                                <img src={imageUrl} style={{width: '100%', height: '100%'}}/>
                                {captions.map((value, index) => (
                                    <Box
                                        onMouseEnter={() => setShowResizeHandles(true)}
                                        onMouseLeave={() => setShowResizeHandles(false)}
                                        key={index}
                                        sx={{
                                            width: '100%',
                                            height: '100%',
                                            position: 'absolute',
                                            top: '0',
                                            left: '0',
                                            // display: 'flex',
                                            // justifyContent: 'center',
                                            // alignItems: 'center',
                                            cursor: 'move',
                                            // textShadow: 'rgb(0, 0, 0) -1px -1px 0px, rgb(0, 0, 0) 1px -1px 0px, rgb(0, 0, 0) -1px 1px 0px, rgb(0, 0, 0) 1px 1px 0px',
                                            letterSpacing: '0.1rem'
                                        }}
                                    >
                                        <Draggable
                                            disabled={isResizing}
                                            onDrag={handleDrag}
                                            bounds="parent"
                                            // defaultPosition={{x: 50, y: 50}}
                                        >
                                            <Resizable
                                                height={size.height}
                                                width={size.width}
                                                onResize={handleResize}
                                                onResizeStart={() => setIsResizing(true)}
                                                onResizeStop={() => setIsResizing(false)}
                                                resizeHandles={
                                                    showResizeHandles ? ['n', 'e', 's', 'w', 'ne', 'se', 'sw', 'nw'] : []
                                                }
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    cursor: 'se-resize'
                                                }}
                                            >
                                                <Box ref={divRef} className="box" style={{width: size.width + 'px'}}>
                                                    <Typography
                                                        key={index}
                                                        sx={{
                                                            fontSize: `${textSize}px`,
                                                            color: value.color,
                                                            transition: 'fontSize 0.2s ease-in-out'
                                                        }}
                                                    >
                                                        {value.value}
                                                    </Typography>
                                                </Box>
                                            </Resizable>
                                        </Draggable>
                                    </Box>
                                ))}
                            </Box>
                            {footer && (
                                <Box
                                    sx={{
                                        padding: '10px 15px'
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: 'black',
                                            backgroundColor: 'white',
                                            textShadow:
                                                'rgb(0, 0, 0) -1px -1px 0px, rgb(0, 0, 0) 1px -1px 0px, rgb(0, 0, 0) -1px 1px 0px, rgb(0, 0, 0) 1px 1px 0px',
                                            letterSpacing: '0.2rem'
                                        }}
                                    >
                                        {footer}
                                    </Typography>
                                </Box>
                            )}
                        </Box>

                        <Stack
                            direction={'row'}
                            spacing={1}
                            pt={2}
                            justifyContent={'center'}
                            alignItems={'center'}
                        >
                            <Button startIcon={<DownloadIcon/>} variant="contained" onClick={handleDownload}>
                                Download
                            </Button>

                            <Button startIcon={<ContentCopyIcon/>} variant="outlined">
                                Copy
                            </Button>

                            <Button startIcon={<StarBorderIcon/>} variant="outlined">
                                Save
                            </Button>
                        </Stack>
                        {/* <Button sx={{textAlign: 'center'}} onClick={handleDownload}>
              Download
            </Button> */}
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default TextToMemeEdit;
