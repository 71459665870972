import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    featureAccess: [],
    isLoading: false,
    isError: false,
    error: '',
};

const packageAccessFeatureSlice = createSlice({
    name: 'hasFeatureAccess',
    initialState: initialState,
    reducers: {
        hasFeatureAccess: (state, action) => {
            state.isLoading = true;
        },
        hasFeatureAccessSuccess: (state, action) => {
            state.featureAccess = action.payload;
            state.isError = false;
            state.error = '';
        },
        hasFeatureAccessFailed: (state, action) => {
            state.featureAccess = [];
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },
        clearFeatureAccessState: (state, action) => {
            state.featureAccess = [];
        },
    }
});

export const {
    hasFeatureAccess,
    hasFeatureAccessSuccess,
    hasFeatureAccessFailed,
    clearFeatureAccessState,
} = packageAccessFeatureSlice.actions;

export default packageAccessFeatureSlice.reducer;
