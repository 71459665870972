import * as React from 'react';
import {useEffect} from 'react';

import {Controller, useForm} from 'react-hook-form';
import {Link, useNavigate} from 'react-router-dom';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {useDispatch, useSelector} from 'react-redux';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {attemptSignUp} from '../../state/features/user/userSlice';
import {registerPattern} from '../../helpers/constant/validationPattern';
import LoadingButton from "../../assets/global/Button/LoadingButton";

const theme = createTheme();

export default function Register() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {signUpState, resetPasswordState} = useSelector(state => state.user);

    const {pending} = resetPasswordState || "";

    const {
        control,
        handleSubmit,
        formState: {errors},
    } = useForm({
        mode: 'onSubmit'
    });

    // ## After sing up success
    useEffect(() => {
        if (signUpState.isSignUpSuccess) navigate('/login');
    }, [signUpState.isSignUpSuccess]);

    const onSubmit = data => {
        dispatch(attemptSignUp(data));
    };

    return (
        <ThemeProvider theme={theme}>
            <Container
                component="main"
                maxWidth="xs"
                sx={{
                    backgroundColor: '#fff',
                    boxShadow: '0 4px 24px 0 rgba(34, 41, 47, 0.1)',
                    borderRadius: '8px'
                }}
            >
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: '0',
                        paddingTop: '30px',
                        paddingBottom: '30px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: '15px'
                        }}
                    >
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: '15px'
                        }}>
                            <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                                <LockOutlinedIcon/>
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Sign up
                            </Typography>
                        </Box>


                    </Box>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{mt: 1}}>
                        <Box sx={{margin: '0', padding: '0'}}>
                            <Typography component="h6" variant="h6" sx={{textAlign: 'left'}}>
                                Welcome to OLK9AI! 👋
                            </Typography>
                            <Typography component="p" variant="body2">
                                Please sign-up to your account and start the adventure
                            </Typography>
                        </Box>
                        <Box>
                            <Controller
                                control={control}
                                name="first_name"
                                defaultValue=""
                                render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                    <FormControl fullWidth required error={!!(invalid && error.message)}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            required
                                            value={value}
                                            onChange={onChange}
                                            fullWidth
                                            id="first_name"
                                            label="Your Name"
                                            autoComplete="nope"
                                            error={!!(invalid && error.message)}
                                            helperText={invalid && error.message}
                                        />
                                    </FormControl>
                                )}
                                rules={registerPattern.first_name}
                            />


                            {/*<Controller*/}
                            {/*    control={control}*/}
                            {/*    name="last_name"*/}
                            {/*    defaultValue=""*/}
                            {/*    render={({field: {value, onChange}, fieldState: {invalid, error}}) => (*/}
                            {/*        <FormControl fullWidth>*/}
                            {/*            <TextField*/}
                            {/*                size="small"*/}
                            {/*                margin="normal"*/}
                            {/*                value={value}*/}
                            {/*                onChange={onChange}*/}
                            {/*                fullWidth*/}
                            {/*                id="last_name"*/}
                            {/*                label="Last Name"*/}
                            {/*                autoComplete="nope"*/}
                            {/*            />*/}
                            {/*        </FormControl>*/}
                            {/*    )}*/}
                            {/*/>*/}

                            <Controller
                                control={control}
                                name="email"
                                defaultValue=""
                                render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                    <FormControl fullWidth required error={!!(invalid && error.message)}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            required
                                            value={value}
                                            onChange={onChange}
                                            fullWidth
                                            id="email"
                                            label="Email"
                                            autoComplete="nope"
                                            error={!!(invalid && error.message)}
                                            helperText={invalid && error.message}
                                        />
                                    </FormControl>
                                )}
                                rules={registerPattern.email}
                            />

                            {/*<Controller*/}
                            {/*    control={control}*/}
                            {/*    name="phone"*/}
                            {/*    defaultValue=""*/}
                            {/*    render={({field: {value, onChange}, fieldState: {invalid, error}}) => (*/}
                            {/*        <FormControl fullWidth required={true} error={!!(invalid && error.message)}>*/}
                            {/*            <TextField*/}
                            {/*                size="small"*/}
                            {/*                margin="normal"*/}
                            {/*                required*/}
                            {/*                value={value}*/}
                            {/*                onChange={onChange}*/}
                            {/*                fullWidth*/}
                            {/*                id="phone"*/}
                            {/*                label="phone"*/}
                            {/*                autoComplete="nope"*/}
                            {/*                error={!!(invalid && error.message)}*/}
                            {/*                helperText={invalid && error.message}*/}
                            {/*            />*/}
                            {/*        </FormControl>*/}
                            {/*    )}*/}
                            {/*    rules={registerPattern.phone}*/}
                            {/*/>*/}

                            <Controller
                                control={control}
                                name="password"
                                defaultValue=""
                                render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                    <FormControl fullWidth required={true} error={!!(invalid && error.message)}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            type="password"
                                            required
                                            value={value}
                                            onChange={onChange}
                                            fullWidth
                                            id="password"
                                            label="Password"
                                            autoComplete="nope"
                                            error={!!(invalid && error.message)}
                                            helperText={invalid && error.message}
                                        />
                                    </FormControl>
                                )}
                                rules={registerPattern.password}
                            />

                            <Controller
                                control={control}
                                name="confirm_password"
                                defaultValue=""
                                render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                    <FormControl fullWidth required={true} error={!!(invalid && error.message)}>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            type="password"
                                            required
                                            value={value}
                                            onChange={onChange}
                                            fullWidth
                                            id="password"
                                            label="Confirm Password"
                                            autoComplete="nope"
                                            error={!!(invalid && error.message)}
                                            helperText={invalid && error.message}
                                        />
                                    </FormControl>
                                )}
                                rules={registerPattern.confirm_password}
                            />
                        </Box>

                        {/* ====== */}
                        {/*<Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>*/}
                        {/*    Sign Up*/}
                        {/*</Button>*/}

                        <LoadingButton
                            isLoading={pending}
                            fullWidth
                            loadingText={'Signing'}
                            type={'submit'}
                            color={'primary'}
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                        >
                            Sign Up
                        </LoadingButton>

                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Link
                                    to="/login"
                                    variant="body2"
                                    style={{fontSize: '14px', textDecoration: 'none', color: '#1976d2'}}
                                >
                                    Already have an account? Sign in
                                </Link>
                            </Grid>
                        </Grid>

                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}
