import React, {useEffect, useState} from 'react';
import {
    Box,
    Divider,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Typography
} from '@mui/material';
import './Styles/topup.css';
import warningLogo from '../../assets/images/Icons/warning-logo.svg';
import {useDispatch, useSelector} from 'react-redux';
import {
    addRechargeBalance,
    getBalanceAmountList,
    getUserPaymentCardList,
    resetUserPaymentCardState
} from '../../state/features/userPayment/userPaymentSlice';
import UserBalanceService from '../../services/UserBalance.service';
import {getAgencyStripePaymentIntegrationData} from '../../state/features/paymentIntegration/paymentIntegrationSlice';
import CoreButton from '../../assets/global/Button/CoreButton';
import {currencyFormat} from '../../helpers/utility/currencyFormat';
import InfoModal from '../../assets/global/Modal/InfoModal';
import WarningModalContent from '../../assets/global/Modal/WarningModalContent';
import CancelButton from '../../assets/global/Button/CancelButton';
import {LoadingButton} from '@mui/lab';
import BasicWithHeaderModal from '../../assets/global/Modal/BasicWithHeaderModal';
import getSum from "../../helpers/utility/getSum";

const TopUp = () => {
    const dispatch = useDispatch();
    const {loading, paymentCardList, balanceAmountList, isAdding, isAddCard} = useSelector(
        state => state.userPayment
    );

    const [balanceModalOpen, setBalanceModalOpen] = useState(false);
    const [balance, setBalance] = useState('');
    const [chargeAmount, setChargeAmount] = useState(0);
    const [statusModalOpen, setStatusModalOpen] = useState(false);
    const [selectedCard, setSelectedCard] = useState('');
    const [balanceError, setBalanceError] = useState('');
    const [cardError, setCardError] = useState('');


    const handleOpenStatusModal = row => {
        setStatusModalOpen(true);
        setBalanceModalOpen(false);
    };

    const handleCloseStatusModal = () => {
        setStatusModalOpen(false);
    };

    const addBalanceHandler = () => {
        if (balance) {
            setBalanceModalOpen(true);
            setBalanceError(null);
        } else {
            setBalanceError('You have to select an amount');
        }
    };
    const closeBalanceHandler = () => {
        setSelectedCard('');
        setCardError('');
        setBalanceModalOpen(false);
    };

    const handleChangeBillingHistory = event => {
        setSelectedCard(event.target.value);
        setCardError('');
    };

    const handleBalanceChange = event => {
        setBalance(event.target.value);
        setBalanceError(null);
    };

    // get System Recharge charge amount
    useEffect(() => {
        if (balance) {
            UserBalanceService.getSystemRechargeCharge({recharge_amount: Number(balance)})
                .then(response => {
                    if (response.success && response.data) {
                        setChargeAmount(response.data);
                    }
                })
                .catch(err => {
                    console.log(err);
                    setChargeAmount(0);
                });
        }
    }, [balance]);

    const submitTopUp = () => {
        if (selectedCard) {
            dispatch(
                addRechargeBalance({
                    recharge_amount: balance,
                    id: selectedCard // user_payments_card_uid from user_payments_table
                })
            );
            setCardError(null);
        } else {
            setCardError('You have to select a card');
        }
    };

    useEffect(() => {
        dispatch(getBalanceAmountList());
        dispatch(getUserPaymentCardList());
        if (!chargeAmount) {
            dispatch(getAgencyStripePaymentIntegrationData());
        }
    }, []);

    useEffect(() => {
        if (isAddCard) {
            if (closeBalanceHandler) {
                handleCloseStatusModal();
                closeBalanceHandler();
            }
        }
        return () => dispatch(resetUserPaymentCardState());
    }, [isAddCard]);

    return (
        <Box className="top__up__wr">
            <Typography color="text.primary" variant="body1" sx={{pb: 1}}>
                Add this balance amount to my account
            </Typography>

            <Box className="top__up__inner">
                <Box className="top__up__inner__left">
                    <FormControl error={!!balanceError} required size="small" fullWidth>
                        <InputLabel id="demo-simple-select-label2" sx={{mb: 2}}>
                            Select Balance
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label2"
                            id="demo-simple-select2"
                            value={balance}
                            label="Select Balance"
                            onChange={handleBalanceChange}
                        >
                            {balanceAmountList &&
                                balanceAmountList.map((balance, index) => (
                                    <MenuItem key={index} value={balance.recharge_amount}>
                                        $ {balance.recharge_amount}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Box>

                <Box className="top__up__inner__right">
                    <CoreButton
                        type="button"
                        color={'secondary'}
                        variant={'contained'}
                        size={'large'}
                        onClick={() => addBalanceHandler()}
                    >
                        Add Balance
                    </CoreButton>
                </Box>
            </Box>

            <BasicWithHeaderModal
                modalTitle="Add Balance"
                open={balanceModalOpen}
                width="500px"
                onClose={closeBalanceHandler}
            >
                <Box sx={{p: 4}}>
                    {/* ## Billing Amount Details ## */}
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Typography variant="body1" color="text.secondary">
                            Top Up Amount:
                        </Typography>
                        <Typography variant="h6" color="primary.main">
                            ${currencyFormat(Number(balance))}
                        </Typography>
                    </Stack>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Typography variant="body1" color="text.secondary">
                            Charge Fee:
                        </Typography>
                        <Typography variant="body2" color="text.primary">
                            + ${currencyFormat(Number(chargeAmount))}
                        </Typography>
                    </Stack>
                    <Divider/>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" pb={2}>
                        <Typography variant="body1" color="text.secondary">
                            Total Cost:
                        </Typography>
                        <Typography variant="h6" color="text.primary">
                            ${currencyFormat(getSum([balance, chargeAmount]))}
                        </Typography>
                    </Stack>

                    {paymentCardList && selectedCard ? (
                        <Typography variant="body1" color="text.secondary" sx={{pb: 2}}>
                            If you add the amount of balance{' '}
                            <Typography component="span" color="text.primary">
                                ${currencyFormat(balance)}
                            </Typography>{' '}
                            to your account.{' '}
                            <Typography component="span" color="text.primary">
                                ${currencyFormat(getSum([balance, chargeAmount]))}
                            </Typography>{' '}
                            will be deducted from this{' '}
                            <Typography component="span" color="text.primary">
                                **** **** ****{' '}
                                {paymentCardList &&
                                    selectedCard &&
                                    paymentCardList.find(card => card.id === selectedCard).card_number}
                            </Typography>{' '}
                            account.
                        </Typography>
                    ) : (
                        <Typography variant="body1" color="text.secondary" sx={{pb: 2}}>
                            Please select a card to complete the process!
                        </Typography>
                    )}

                    <Box>
                        <FormControl error={!!cardError} required size="small" fullWidth>
                            <InputLabel id="demo-simple-select-label">Card Number</InputLabel>
                            <Select
                                sx={{textAlign: 'left'}}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedCard}
                                label="Card Number"
                                onChange={handleChangeBillingHistory}
                            >
                                {paymentCardList &&
                                    paymentCardList.map((card, index) => (
                                        <MenuItem key={index} value={card.id}>
                                            **** **** **** {card.card_number}
                                        </MenuItem>
                                    ))}
                            </Select>
                            {cardError ? <FormHelperText error> {cardError} </FormHelperText> : <div/>}
                        </FormControl>
                    </Box>

                    <Box textAlign={'right'} sx={{mt: 6}}>
                        <CancelButton onClick={closeBalanceHandler}>Cancel</CancelButton>
                        <LoadingButton
                            sx={{ml: 3}}
                            variant="contained"
                            color="success"
                            size={'large'}
                            isLoading={isAdding}
                            loadingText={'Processing...'}
                            onClick={handleOpenStatusModal}
                        >
                            OK
                        </LoadingButton>
                    </Box>
                </Box>
            </BasicWithHeaderModal>

            <InfoModal open={statusModalOpen} width="480px" onClose={handleCloseStatusModal}>
                <WarningModalContent
                    logo={warningLogo}
                    title={'Warning'}
                    subTitle={'Are you sure to topup?'}
                    subTitleSpan={''}
                    buttonName={isAdding ? 'Processing...' : 'Process'}
                    onConfirm={submitTopUp}
                    onClose={handleCloseStatusModal}
                />
            </InfoModal>
        </Box>
    );
};

export default TopUp;
