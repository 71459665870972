import {all, put} from 'redux-saga/effects';
import {call, takeEvery} from '@redux-saga/core/effects';
import {imageToAltTextFailed, imageToAltTextSuccess} from "./imageToAltTextSlice";
import ImageToPromptAltTextService from "../../../services/ImageToPromptAltText.service";
import {showSuccessToaster} from "../../../helpers/utility/toaster";

function* imageToAltTextWatcher() {
    yield takeEvery('imageToAltText/imageToAltText', generateImageToAltTextSaga);
}

function* generateImageToAltTextSaga(action) {
    try {
        const response = yield call(ImageToPromptAltTextService.imageToPromptAltText, action.payload);

        if (response.status) {
            yield put(imageToAltTextSuccess(response.data));
            showSuccessToaster(response.message)
        } else {
            yield put(imageToAltTextFailed(response.message));
        }
    } catch (err) {
        console.log('Error: ', err);
        yield put(imageToAltTextFailed(err.message));
    }
}

export default function* imageToAltTextSaga() {
    yield all([imageToAltTextWatcher()]);
}
