import React from 'react';

import ChatPrompt from '../chat/ChatPrompt';
import {useParams} from 'react-router-dom';

const Prompt = () => {
    let {categoryId} = useParams();
    categoryId = (categoryId === "undefined" || !categoryId) ? "" : categoryId;

    return (
        <React.Fragment>
            <ChatPrompt categoryId={categoryId}/>
        </React.Fragment>
    );
};

export default Prompt;
