import React from 'react';
import {
    DASHBOARD__TOTAL_CONTENT_COUNT,
    DASHBOARD__TOTAL_PLATFORM_COST,
    DASHBOARD__TOTAL_USER_COST,
    PACKAGE_FEATURE__KEY__AUDIO
} from "../constant/coreConstant";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import TranscribeIcon from "@mui/icons-material/Transcribe";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PeopleIcon from "@mui/icons-material/People";

export const appWidgetSummaryHelper = [
    {
        key: DASHBOARD__TOTAL_CONTENT_COUNT,
        title: "Total Content Count",
        icon: <ContentPasteSearchIcon/>,
    },
    {
        key: PACKAGE_FEATURE__KEY__AUDIO,
        title: "Total AI Transcription Count",
        color: "info",
        icon: <TranscribeIcon/>,
    },
    {
        key: DASHBOARD__TOTAL_USER_COST,
        title: "Total User Cost",
        color: "warning",
        icon: <PeopleIcon/>,
    },
    {
        key: DASHBOARD__TOTAL_PLATFORM_COST,
        title: "Total Platform Cost",
        color: "error",
        icon: <MonetizationOnIcon/>,
    },

];
