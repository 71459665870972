import React from 'react';
import {Box, Button, Stack, Typography} from '@mui/material';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import UndoIcon from "@mui/icons-material/Undo";
import {useNavigate} from "react-router-dom";

const PresetTemplateDetailsHeader = ({specificPresetTemplate}) => {
    const navigate = useNavigate();
    return (
        <Box sx={{display:'flex',justifyContent:'space-between',alignItems: 'flex-start',}}>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                {specificPresetTemplate?.icon ? (
                    <Box sx={{backgroundColor: specificPresetTemplate?.extra ? JSON.parse(specificPresetTemplate?.extra).color : '#f0fdf4', padding: '12px 12px 6px', borderRadius: '8px'}}>
                        <img
                            src={specificPresetTemplate?.icon || ''}
                            width="40px"
                            height="40px"
                            alt={specificPresetTemplate?.title}
                        />
                    </Box>
                ) : (
                    <TipsAndUpdatesIcon sx={{width: '40px', height: '40px'}}/>
                )}

                <Box>
                    <Typography
                        gutterBottom
                        variant="h6"
                        component="div"
                        className="max-1line-text"
                        sx={{
                            fontSize: '24px',
                            lineHeight: '1.4',
                            marginBottom: 0,
                            color: 'rgb(17 24 39)',
                            wordBreak: 'break-word'
                        }}
                    >
                        {specificPresetTemplate?.title}
                    </Typography>

                    <Typography
                        variant="body2"
                        color="text.secondary"
                        className="max-3line-text"
                        sx={{
                            marginBottom: '5px',
                            marginTop: '0px',
                            fontSize: '18px',
                            fontWeight: '350!important',
                            color: 'rgb(107 114 128)',
                            lineHeight: '1.4',
                            wordBreak: 'break-word'
                        }}
                    >
                        {specificPresetTemplate?.short_description}
                    </Typography>
                </Box>

            </Stack>

            <Button sx={{backgroundImage: 'linear-gradient(to right, #9333ea, #4f46e5)'}} variant="contained" onClick={() => navigate(-1)}>
                <UndoIcon sx={{marginRight:"2px"}} />
                Back
            </Button>

        </Box>
    );
};

export default PresetTemplateDetailsHeader;
