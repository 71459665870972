import React from 'react';
import Box from "@mui/material/Box";
import {Stack, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {useDispatch, useSelector} from "react-redux";
import {selectTemplateSearchdata} from "../../state/features/textToMeme/templateSearch/templateSearchSelector";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import {Link} from "react-router-dom";
import EmptyPage from "../../assets/global/EmptyComponent/EmptyPage";
import TemplateSearchBar from "../SearchTemplatesForMemes/TemplateSearchBar";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import {templateSearch} from "../../state/features/textToMeme/templateSearch/templateSearchSlice";
import {aiGeneratedTextClearState} from "../../state/features/textToMeme/randomAIGeneratedText/AIGeneratedTextSlice";

const TemplateSearch = ({handleSearchModalClose, title}) => {
    const dispatch = useDispatch();
    const {templateSearchData, isLoading} = useSelector(selectTemplateSearchdata);

    const handelAIGeneratedText = () => {
        dispatch(aiGeneratedTextClearState())
    }

    let content = null;
    if (isLoading) {
        content = (
            <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}} sx={{p: 3}}>
                {[1, 2, 3, 4].map(collection => (
                    <Grid key={collection} item xs={3}>
                        <Box sx={{p: 3, marginRight: 0.5}}>
                            <Skeleton variant="rectangular" height={118}/>
                            <Skeleton/>
                            <Skeleton width="60%"/>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        );
    } else if (templateSearchData?.results.length > 0) {
        content = (
            <Grid container spacing={2}>
                {templateSearchData?.results.map((items, key) => (
                    <Grid item sm={12} md={6} lg={4} xl={4}>
                        <Box
                            sx={{
                                height: '100%',
                                width: '100%',
                                backgroundColor: '#ffffff',
                                padding: '20px',
                                borderRadius: '5px'
                            }}
                        >
                            <img
                                height="300px"
                                width="260px"
                                src={items?.media_formats?.gifpreview?.url}
                            />
                            <Stack direction="row" useFlexGap flexWrap="wrap" spacing={1} mt={2}>

                                {
                                    title == 'themed-meme' && (
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            sx={{
                                                backgroundColor: '#F5F5F5',
                                                borderRadius: '6px',
                                                padding: '5px 10px',
                                                flex: {lg: 1}
                                            }}
                                            startIcon={<ManageHistoryIcon/>}
                                            onClick={handleSearchModalClose}
                                        >
                                            <Link
                                                to={`/themed-memes/?url=${items?.media_formats?.gifpreview?.url}`}
                                                style={{textDecoration: 'none'}}
                                                onClick={handelAIGeneratedText}
                                            >
                                                <Button>Generated with AI</Button>
                                            </Link>
                                        </Button>
                                    )
                                }
                                {
                                    title === 'meme-maker' && (
                                        <>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                sx={{
                                                    backgroundColor: '#F5F5F5',
                                                    borderRadius: '6px',
                                                    padding: '5px 10px',
                                                    flex: {lg: 1}
                                                }}
                                                startIcon={<ManageHistoryIcon/>}
                                                onClick={handleSearchModalClose}
                                            >
                                                <Link
                                                    to={`/themed-memes/?url=${items?.media_formats?.gifpreview?.url}`}
                                                    style={{textDecoration: 'none'}}
                                                    onClick={handelAIGeneratedText}
                                                >
                                                    <Button>Generated with AI</Button>
                                                </Link>
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                sx={{
                                                    backgroundColor: '#F5F5F5',
                                                    borderRadius: '6px',
                                                    padding: '5px 10px',
                                                    flex: {lg: 1}
                                                }}
                                                startIcon={<ManageHistoryIcon/>}
                                                onClick={handleSearchModalClose}
                                            >
                                                <Link
                                                    to={`/meme-edit/?url=${items?.media_formats?.gifpreview?.url}`}
                                                    style={{textDecoration: 'none'}}
                                                >
                                                    Edit Template
                                                </Link>
                                            </Button>

                                        </>
                                    )
                                }
                            </Stack>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        );
    } else {
        content = (
            <EmptyPage title="No theme choose yet..."/>
        );
    }


    const handleGenerateTemplate = (e, searchText) => {
        e.preventDefault();
        // const API_KEY = 'AIzaSyDdchK3f7lWyo1AVNdY64CaH5JG7MQ9EjM';
        const API_KEY = process.env.REACT_APP_TENOR_API_KEY;
        dispatch(
            templateSearch({
                q: searchText,
                key: API_KEY,
                pos: '20',
                limit: '20'
            })
        );

    }

    return (
        <>
            <Box sx={{
                textAlign: 'center',
                width: '100%',
                padding: '30px',
                backgroundColor: '#D3D3D3'
            }}>
                <Box textAlign="center">
                    <Typography variant="h4" fontWeight={800} fontSize={36} marginBottom={3}>
                        World's Most Advanced Multilingual Meme Search Engine
                    </Typography>
                    <Typography variant="p" fontSize={20} fontWeight={500}>
                        Search by describing emotions or actions and hit "Generate" to get relevant memes
                    </Typography>
                </Box>
                <Box maxWidth={920} marginX={'auto'} pt={2} textAlign="center" mb={3}>
                    <TemplateSearchBar handleGenerateTemplate={handleGenerateTemplate}/>
                </Box>
                <Box sx={{
                    textAlign: 'center',
                }}>
                    {content}
                </Box>
            </Box>
        </>
    );

}

export default TemplateSearch;