import React, {useEffect, useState} from 'react';
import {Box, CardContent, IconButton} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import CustomEditor from '../../assets/global/Editor/CustomEditor';
import {useDispatch, useSelector} from 'react-redux';
import {selectPromptState} from '../../state/features/prompts/promptSelector';
import {clearState} from '../../state/features/prompts/promptSlice';
import CoreButton from "../../assets/global/Button/CoreButton";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {showSuccessToaster} from "../../helpers/utility/toaster";
import PromptCollectionForm from "./PromptCollectionForm";
import FullPageDrawer from "../../assets/global/Drawer/FullPageDrawer";
import {createPromptCollection} from "../../state/features/promptCollection/promptCollectionSlice";

const PromptResponseMessage = ({templateDate}) => {
    const dispatch = useDispatch();
    const [text, setText] = useState('');
    const [viewDetailsPageShow, setViewDetailsPageShow] = useState(false);
    const {isShowReCopyButton, responseData, promptInputText} = useSelector(selectPromptState);

    const toggleViewDetailsPage = () => setViewDetailsPageShow(prevState => !prevState);

    const handleViewDetailsPage = data => {
        toggleViewDetailsPage();
    };

    const handlePromptCollectionSubmit = data => {
        const payload = {};
        payload.collection_id = data.collection_id || '';
        payload.template_id = templateDate?.id || '';
        payload.text = (promptInputText && promptInputText.replace(/(<([^>]+)>)/gi, '').replace(/&nbsp;/g, '')) || '';
        payload.response = responseData || '';

        dispatch(createPromptCollection(payload));
    };

    const {
        register,
        control,
        handleSubmit,
        formState: {errors},
        setValue
    } = useForm({
        mode: 'onSubmit'
    });

    useEffect(() => {
        setValue('chat_response', responseData);
    }, []);

    // ## Clear Response State When Leave the page
    useEffect(() => {
        return () => dispatch(clearState());
    }, []);

    const onChangeValue = data => {
        setText(data);
    };

    const handleCopy = data => {
        navigator.clipboard.writeText(data);
        showSuccessToaster('Text copied');
    };

    return (
        <>
            <Box
                sx={{display: "flex"}}
            >
                <Box sx={{p: '1', flexGrow: 1}}>
                    <CardContent>
                        <Controller
                            style={{whiteSpace: 'pre-line'}}
                            control={control}
                            name="chat_response"
                            defaultValue=""
                            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                <CustomEditor
                                    style={{whiteSpace: 'pre-line'}}
                                    placeholder={'Write your message'}
                                    theme={'snow'}
                                    value={value}
                                    onChange={value => {
                                        onChange(value);
                                        onChangeValue(value);
                                    }}
                                />
                            )}
                        />
                    </CardContent>
                </Box>

                <Box sx={{padding: '15px',}}>
                    {isShowReCopyButton && (
                        <Box sx={{display: "flex"}}>
                            <CoreButton
                                sx={{
                                    padding: "0 !important",
                                    marginRight: '5px'
                                }}
                                variant="contained"
                                type={'submit'}
                                size={'small'}
                                color={'primary'}
                                onClick={() => handleViewDetailsPage()}
                            >
                                <IconButton sx={{color: '#fff', padding: "6px 10px"}}>
                                    <SaveAsIcon/>
                                </IconButton>
                            </CoreButton>

                            <CoreButton
                                sx={{
                                    padding: "0 !important"
                                }}
                                variant="contained"
                                type={'submit'}
                                size={'small'}
                                color={'primary'}
                                onClick={() => handleCopy(responseData)}
                            >
                                <IconButton sx={{color: '#fff', padding: "6px 10px"}}>
                                    <ContentCopyIcon/>
                                </IconButton>
                            </CoreButton>
                        </Box>
                    )}
                </Box>
            </Box>

            <FullPageDrawer
                title="Save to collection"
                open={viewDetailsPageShow}
                toggleDrawer={toggleViewDetailsPage}
                width="35%"
                height="60%"
            >
                <PromptCollectionForm
                    toggleDrawer={toggleViewDetailsPage}
                    handlePromptCollectionSubmit={handlePromptCollectionSubmit}
                />
            </FullPageDrawer>
        </>
    );
};

export default PromptResponseMessage;
