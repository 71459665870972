import {Box, Stack, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {styled} from '@mui/material/styles';
import {useDispatch, useSelector} from 'react-redux';
import {selectChatGPTState} from '../../state/features/chatGPT/chatGPTSelector';
import {getChatGPTHistory} from '../../state/features/chatGPT/chatGPTSlice';
import talkImage from '../../assets/images/talk.webp';
import '../../assets/global/css/MaxText.css'
import {selectAccountSettingState} from '../../state/features/accountSetting/accountSettingSelector';

const Item = styled(Paper)(({theme}) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    cursor: 'pointer'
}));

const ChatList = ({handleChatClick}) => {
    const dispatch = useDispatch();
    const {chatHistories = []} = useSelector(selectChatGPTState);
    const [profileData, setProfileData] = useState({});

    const {accountSetting} = useSelector(selectAccountSettingState);

    useEffect(() => {
        dispatch(getChatGPTHistory());
    }, []);

    useEffect(() => {
        if (accountSetting) {
            setProfileData(accountSetting);
        }
    }, [accountSetting]);

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                {!chatHistories.length ? (
                    <Box className="" sx={{textAlign: 'center'}}>
                        <Typography
                            level="h5"
                            sx={{
                                textAlign: 'center',
                                fontSize: '25px',
                                lineHeight: '1.334'
                            }}
                        >
                            Hey, {profileData?.first_name} . Welcome to OLK9AI
                        </Typography>

                        <img src={talkImage} alt="talk"/>
                    </Box>
                ) : (
                    <>
                        <Box className="" sx={{textAlign: 'center'}}>
                            <Typography
                                level="h5"
                                sx={{
                                    textAlign: 'center',
                                    fontSize: '25px',
                                    lineHeight: '1.334'
                                }}
                            >
                                Hey, {profileData?.first_name} . Welcome to OLK9AI
                            </Typography>
                        </Box>
                        <Stack alignItems={'center'} justifyContent={'center'} py={4} px={2} sx={{width: "800px"}}>
                            <Grid container spacing={1} sx={{justifyContent: "center"}}>
                                {chatHistories.map(chatHistory => (
                                    <Grid item sm={4} md={4} spacing={1} key={chatHistory.id} sx={{maxWidth: "700px"}}>
                                        <Item
                                            elevation={0}
                                            onClick={() => handleChatClick(chatHistory)}
                                            sx={{
                                                padding: '12px',
                                                backgroundColor: '#f1f2f6',
                                                color: '#8395a7',
                                                fontSize: '12px',
                                                minHeight:'73px'
                                            }}
                                        >
                                            <Typography className='max-3line-text'>{chatHistory?.text}</Typography>
                                        </Item>
                                    </Grid>
                                ))}
                            </Grid>
                        </Stack>
                    </>
                )}
            </Box>
        </>
    );
};

export default ChatList;
