import React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import DateRangeController from "../../assets/global/DateRangePicker/DateRangeController";
import {useTheme} from "@mui/material/styles";


const DashboardHeader = ({datePickerCallback, data}) => {

    const theme = useTheme();
    let content = null;

    if (data) {
        content = (
            <Typography sx={{
                padding: '8px 15px',
                backgroundColor: theme.palette.background.paper,
                borderRadius: '4px',
                fontWeight: 'bold',
                color: '#808080',
                border: `1px solid ${theme.palette.divider}`,
            }}>
                Data is showing about last <Typography component={'span'} sx={{color: '#810541', fontWeight: 'bold',}}>{data.difference}</Typography> days.
            </Typography>
        );
    } else {
        content = (
            <Typography sx={{
                padding: '5px 15px',
                backgroundColor: theme.palette.background.paper,
                borderRadius: '4px',
                fontWeight: 'bold',
                color: '#808080',
                border: `1px solid ${theme.palette.divider}`,
            }}>
                Data is showing about last <Typography component={'span'} sx={{color: '#810541',fontWeight: 'bold',}}>7</Typography> days.
            </Typography>
        );
    }

    return (
        <>
            <Box>
                <Typography component="div">
                    <Box sx={{fontFamily: 'Roboto', fontSize: 'h6.fontSize', m: 1, fontWeight: 'bold'}}>
                        OLK9AI's Dashboard
                    </Box>
                    <Box sx={{fontFamily: 'Roboto', m: 1}}>
                        Dashboard
                    </Box>
                    <Divider/>
                </Typography>
            </Box>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '15px',
            }}>
                <Box
                    sx={{
                        border: 'none',
                        transition: 'all 0.3s ease',
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '10px 0px'
                    }}
                    component="form"
                    noValidate
                    autoComplete="off"
                >
                    <DateRangeController datePickerCallback={datePickerCallback}/>


                </Box>
                <Box sx={{
                    border: 'none',
                    transition: 'all 0.3s ease',
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '10px 0px'
                }}>
                    {content}
                </Box>
            </Box>
        </>
    );
};

export default DashboardHeader;