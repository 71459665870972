import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    reWriteData: null,
    writeMoreData: null,
    isLoading: false,
    isError: false,
    error: ''
};

const playPenSlice = createSlice({
    name: 'playPen',
    initialState: initialState,
    reducers: {
        rewriteText: (state, action) => {
            state.isLoading = true;
        },
        rewriteTextSuccess: (state, action) => {
            state.reWriteData = action?.payload;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        rewriteTextFailed: (state, action) => {
            state.reWriteData = null;
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },

        writeMoreText: (state, action) => {
            state.isLoading = true;
        },
        writeMoreTextSuccess: (state, action) => {
            state.writeMoreData = action?.payload;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        writeMoreTextFailed: (state, action) => {
            state.writeMoreData = null;
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },
    }
});

export const {
    rewriteText,
    rewriteTextSuccess,
    rewriteTextFailed,
    writeMoreText,
    writeMoreTextSuccess,
    writeMoreTextFailed
} = playPenSlice.actions;

export default playPenSlice.reducer;
