import {all, put} from 'redux-saga/effects';
import {call, takeEvery} from '@redux-saga/core/effects';
import {rewriteTextFailed, rewriteTextSuccess, writeMoreTextFailed, writeMoreTextSuccess} from "./playPenSlice";
import PlayPenService from "../../../services/PlayPen.service";

function* playPenWatcher() {
    yield takeEvery('playPen/rewriteText', rewriteTextSaga);
    yield takeEvery('playPen/writeMoreText', writeMoreTextSaga);
}

function* rewriteTextSaga(action) {
    try {
        const response = yield call(PlayPenService.rewriteText, action.payload);

        if (response.status) {
            yield put(rewriteTextSuccess(response.data));
        } else {
            yield put(rewriteTextFailed(response.message));
        }
    } catch (err) {
        yield put(rewriteTextFailed(err.message));
    }
}

function* writeMoreTextSaga(action) {
    try {
        const response = yield call(PlayPenService.writeMoreText, action.payload);

        if (response.status) {
            yield put(writeMoreTextSuccess(response.data));
        } else {
            yield put(writeMoreTextFailed(response.message));
        }
    } catch (err) {
        yield put(writeMoreTextFailed(err.message));
    }
}

export default function* playPenSaga() {
    yield all([playPenWatcher()]);
}
