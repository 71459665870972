import React, {useState} from 'react';
import {Box, Grid, Typography} from '@mui/material';
import PayLogo from '../../assets/images/Icons/Pay.svg';
import Styles from './Styles/BillingTransactionModalContent.module.css';
import {FileDownload} from '@mui/icons-material';
import CoreButton from '../../assets/global/Button/CoreButton';
import {useTheme} from "@emotion/react";

const BillingTransactionDetailsModalContent = ({invoiceDetails}) => {
    const theme = useTheme();
    // const {profileSetting} = useSelector(state => state.profileSetting);
    // const {agencySettingData} = useSelector(state => state.agencySetting);
    // const [invoiceData, setInvoiceData] = useState({
    //     invoicePaid: '',
    //     invoiceFor: '',
    //     invoiceNumber: '',
    //     paymentDate: '',
    //     paymentMethod: ''
    // });

    const [data, setData] = useState({
        invoiceHeaderInfo: {
            invoiceNumber: '',
            dateOfIssue: '',
            companyLogo: ''
        },
        payToInfo: {
            payToName: 'Ornagetoolz',
            payToAddress: 'Road#13, House#77, Sector#10, Dhaka 1230, Bangladesh',
            payToEmail: 'billing@orangetoolz.com',
            payToPhone: '8801979999519'
        },
        billToInfo: {
            billToName: 'Ornagetoolz',
            billToAddress: 'Road#13, House#77, Sector#10, Dhaka 1230, Bangladesh',
            billToEmail: 'billing@orangetoolz.com',
            billToPhone: '8801979999519'
        },
        paymentInfo: {
            cardHolderName: '',
            cardNumber: '',
            subTotal: 0,
            charge: 0
        },
        items: [
            {
                sno: 1,
                title: 'Agency Pro',
                date: 'March 22, 2022',
                qty: 5,
                price: 405.89
            }
        ]
    });

    // useEffect(() => {
    //     if (invoiceDetails && agencySettingData) {
    //         setInvoiceData({
    //             invoicePaid: invoiceDetails.payment_amount,
    //             invoiceFor: invoiceDetails.invoice_for,
    //             invoiceNumber: invoiceDetails.invoice_id,
    //             paymentDate: invoiceDetails.local_created_at,
    //             paymentMethod: invoiceDetails.card_number
    //         });
    //
    //         setData({
    //             ...data,
    //             invoiceHeaderInfo: {
    //                 ...data.invoiceHeaderInfo,
    //                 invoiceNumber: invoiceDetails.invoice_id,
    //                 dateOfIssue: invoiceDetails.local_created_at.formatted_time,
    //                 // companyLogo: invoiceDetails.company_logo,
    //                 companyLogo: logo
    //             },
    //             payToInfo: {
    //                 ...data.payToInfo,
    //                 payToName: agencySettingData.company_name,
    //                 payToAddress: agencySettingData.address,
    //                 payToPhone: agencySettingData.phone,
    //                 payToEmail: agencySettingData.email
    //             },
    //             billToInfo: {
    //                 ...data.billToInfo,
    //                 billToName: `${profileSetting.first_name} ${profileSetting.last_name}`,
    //                 billToAddress: profileSetting.address,
    //                 billToPhone: profileSetting.phone,
    //                 billToEmail: profileSetting.email
    //             },
    //             paymentInfo: {
    //                 ...data.paymentInfo,
    //                 cardNumber: invoiceDetails.card_number,
    //                 cardHolderName: invoiceDetails.card_holder_name,
    //                 subTotal: invoiceDetails.payment_amount,
    //                 charge: invoiceDetails.charge
    //             },
    //             items: [
    //                 {
    //                     sno: 1,
    //                     title: invoiceForConstant(invoiceDetails.invoice_for),
    //                     date: invoiceDetails.local_created_at.formatted_time,
    //                     qty: 1,
    //                     price: invoiceDetails.payment_amount
    //                 }
    //             ]
    //         });
    //     }
    // }, [invoiceDetails]);
    //

    return (
        <Box className={Styles.wrapper}>
            <Box className={Styles.top}>
                <img src={PayLogo} alt="pay"/>
                <Typography variant="h6" color="text.primary" sx={{pt: 2, pb: 2}}>
                    Invoice Paid
                </Typography>
                <Typography variant="h2" color="primary">
                    {/*{isInvoiceAmountCurrency(invoiceData.invoiceFor)*/}
                    {/*    ? '$' + currencyFormat(invoiceData.invoicePaid)*/}
                    {/*    : invoiceData.invoicePaid}*/}
                </Typography>
            </Box>
            <Box
                sx={{borderColor: `'rgba(148, 157, 178, 0.12)' !important`, m: 3, p: 3}}
                className={Styles.middle}
            >
                <Box className={Styles.single}>
                    <Typography variant="body1" color="text.primary">
                        Invoice number
                    </Typography>
                    <Typography variant="body1" color="text.primary">
                        {/*{invoiceData.invoiceNumber}*/}
                    </Typography>
                </Box>
                <Box className={Styles.single}>
                    <Typography variant="body1" color="text.primary">
                        Payment Date
                    </Typography>
                    <Typography variant="body1" color="text.primary">
                        {/*{invoiceData && invoiceData.paymentDate.formatted_time}*/}
                    </Typography>
                </Box>
                <Box className={Styles.single}>
                    <Typography variant="body1" color="text.primary">
                        Payment method
                    </Typography>
                    <Typography variant="body1" color="text.primary">
                        {/*{invoiceData.paymentMethod ? '•••• •••• •••• ' + invoiceData.paymentMethod : ''}*/}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{pl: 3, pr: 3, pb: 3}}>
                <Grid item lg={12} spacing={3} textAlign="right" xs={12}>
                    <Box className={Styles.button}>
                        <CoreButton
                            color="secondary"
                            startIcon={<FileDownload/>}
                            variant="contained"
                            size="large"
                            sx={{ml: 'auto'}}
                        >
                            Download Invoice
                        </CoreButton>
                    </Box>
                </Grid>
            </Box>
        </Box>
    );
};

export default BillingTransactionDetailsModalContent;
