import React, {useRef, useState} from 'react';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadIcon from '@mui/icons-material/Download';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import {
  ClickAwayListener,
  InputLabel,
  Paper,
  Popper,
  Stack,
  TextField,
  Typography,
  styled
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import * as htmlToImage from 'html-to-image';
import Draggable from 'react-draggable';
import {Resizable} from 'react-resizable';
import 'react-resizable/css/styles.css';
import {useSearchParams} from 'react-router-dom';
import InputFieldRepeater from '../../TextToMeme/InputFieldRepeater';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';
import InputBase from '@mui/material/InputBase';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import CloseIcon from '@mui/icons-material/Close';

const CustomTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const Item = styled(Paper)(({theme}) => ({
  backgroundColor: `#${Math.floor(Math.random() * 16777215).toString(16)} !important`,
  padding: theme.spacing(2),
  textAlign: 'center',
  cursor: 'pointer',
  height: '40px'
}));

const BootstrapInput = styled(InputBase)(({theme}) => ({
  'label + &': {
    marginTop: theme.spacing(3)
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}));

const MemeEdit = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const divRef = useRef(null);
  let imageUrl = searchParams.get('url');
  const [captions, setCaptions] = useState([]);
  const [header, setHeader] = useState('');
  const [showHeader, setShowHeader] = useState(false);
  const [footer, setFooter] = useState('');
  const [showFooter, setShowFooter] = useState(false);

  const [textSize, setTextSize] = useState(20);
  const [showResizeHandles, setShowResizeHandles] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [position, setPosition] = useState({x: 0, y: 0});
  const [size, setSize] = useState({
    width: 100,
    height: 100
  });

  // const [fontColor, setFontColor] = useState('#ffffff');
  // const [borderColor, setBorderColor] = useState('#000000');

  const fontColor = useRef('#ffffff');
  const borderColor = useRef('#000000');

  console.log(fontColor.current);

  //   Popup State
  const [openColorPop, setOpenColorPop] = useState(false);
  const [openFontPop, setOpenFontPop] = useState(false);

  const [age, setAge] = React.useState('Inter');

  const anchorRef = useRef(null);
  const fontRef = useRef(null);

  const onStateUpdate = values => {
    setCaptions(values);
  };

  const handleRemoveHeader = () => {
    setShowHeader(false);
    setHeader('');
  };
  const handleRemoveFooter = () => {
    setShowFooter(false);
    setFooter('');
  };
  const handleDrag = (e, ui) => {
    const {x, y} = ui;
    setPosition({x, y});
  };
  const handleResize = (event, {node, size, handle}) => {
    setSize({width: size.width, height: size.height});
    adjustTextSize(size.height);
  };
  const adjustTextSize = divHeight => {
    const newSize = Math.round(divHeight / 10); // Adjust the division factor as needed
    setTextSize(newSize);
  };
  const handleDownload = () => {
    var node = document.getElementById('my-node');
    htmlToImage.toJpeg(node, {quality: 0.95}).then(function (dataUrl) {
      var link = document.createElement('a');
      link.download = 'my-image-name.jpeg';
      link.href = dataUrl;
      link.click();
    });
  };
  const handleColorPopup = () => {
    setOpenColorPop(prevOpen => !prevOpen);
  };
  const handleFontPopup = () => {
    setOpenFontPop(prevOpen => !prevOpen);
  };
  const handleClickAway = () => {
    setOpenColorPop(false);
    setOpenFontPop(false);
  };
  const handleChange = event => {
    setAge(event.target.value);
  };

  const colorPicker = {
    /* Adjust the width and height as needed */
    width: '25px',
    height: '100%',
    /* Customize the border */
    border: 'none',
    borderRadius: '4px',
    // /* Add box shadow for a nice effect */
    boxShadow: '0 0 4px rgba(0, 0, 0, 0.1)',
    /* Center the color picker vertically and horizontally */
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  };
  const deleteBtnStyle = {
    border: '1px solid #b1b1b1 !important',
    borderInline: 0,
    px: 1,
    py: 0.5,
    display: 'grid',
    alignItems: 'center',
    borderTopRightRadius: 3,
    borderBottomRightRadius: 3,
    cursor: 'pointer',
    '&:hover': {
      background: '#f5f5f5'
    }
  };

  return (
    <Box p={8}>
      {/* Back Button */}
      <Button variant="outlined" sx={{mb: 2}} startIcon={<ArrowBackIcon />}>
        Back
      </Button>

      <Grid container spacing={2}>
        {/* Left Side Content - Make a Meme */}
        <Grid item xs={6} sm={6} md={6}>
          <Box bgcolor={'white'} border={`1px solid #d4d4d4`} borderRadius={3} p={3}>
            {/* Title */}
            <Box textAlign={'center'} mb={2}>
              <Typography variant="h4" fontWeight={800}>
                Make a meme
              </Typography>
            </Box>

            {/* Input with color picker */}
            <Box>
              {/* <InputFieldRepeater onStateUpdate={onStateUpdate} /> */}
              <Stack direction="row" alignItems={'stretch'}>
                <CustomTextField
                  fullWidth
                  id="fullWidth"
                  size="small"
                  // value={field.value}
                  // onChange={event => handleInputChange(index, event)}
                />
                <Stack
                  justifyContent={'center'}
                  sx={{
                    border: '1px solid #b1b1b1',
                    borderLeft: 0,
                    px: 1,
                    py: 0.5,
                    cursor: 'pointer',
                    '&:hover': {
                      background: '#f5f5f5'
                    }
                  }}
                  ref={anchorRef}
                  onClick={handleColorPopup}
                >
                  <Stack direction={'row'} alignItems={'center'} sx={{border: '1px solid #d4d4d4'}}>
                    <Box bgcolor={fontColor.current} width={10} height={20}></Box>
                    <Box bgcolor={borderColor.current} width={10} height={20}></Box>
                  </Stack>
                </Stack>

                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  sx={{
                    border: '1px solid #b1b1b1',
                    borderInline: 0,
                    px: 1,
                    py: 0.5,
                    cursor: 'pointer',
                    '&:hover': {
                      background: '#f5f5f5'
                    }
                  }}
                  ref={fontRef}
                  onClick={handleFontPopup}
                >
                  <TextFormatIcon />
                </Stack>

                <Box sx={deleteBtnStyle}>
                  <CloseIcon sx={{fontSize: 20, fontWeight: 300}} />
                </Box>
              </Stack>

              {/* Color picker popup */}
              {/* <Button ref={anchorRef} onClick={handleColorPopup}>
                Color Picker
              </Button> */}
              <Popper open={openColorPop} anchorEl={anchorRef.current} placement="bottom">
                <ClickAwayListener onClickAway={handleClickAway}>
                  <Box
                    maxWidth={400}
                    p={3}
                    sx={{
                      background: 'rgb(245, 245, 245)',
                      border: '1px solid #d4d4d4',
                      borderRadius: 3
                    }}
                  >
                    <Stack direction={'row'} spacing={5}>
                      <Box>
                        <Typography variant="body2">Font Color</Typography>

                        <Box>
                          <Box sx={{marginTop: '10px'}}>
                            <Stack direction="row" alignItems={'stretch'} mb={1}>
                              <CustomTextField
                                fullWidth
                                id="fullWidth"
                                size="small"
                                sx={{
                                  bgcolor: 'white'
                                }}
                                // value={field.value}
                                // onChange={event => handleInputChange(index, event)}
                              />

                              <Box
                                sx={{
                                  border: '1px solid #b1b1b1',
                                  borderLeft: 0,
                                  //   borderInline: 0,
                                  px: 1,
                                  py: 0.5,
                                  borderTopRightRadius: '6px',
                                  borderBottomRightRadius: '6px'
                                }}
                              >
                                <input
                                  type="color"
                                  value={fontColor.current}
                                  onChange={e => (fontColor.current = e.target.value)}
                                  style={colorPicker}
                                />
                              </Box>
                            </Stack>

                            <Grid
                              container
                              spacing={{xs: 2, md: 1}}
                              columns={{xs: 4, sm: 8, md: 12}}
                            >
                              {Array.from(Array(6)).map((_, index) => (
                                <Grid item xs={2} sm={4} md={4} key={index}>
                                  <Item />
                                </Grid>
                              ))}
                            </Grid>
                          </Box>
                        </Box>
                      </Box>

                      <Box>
                        <Typography variant="body2">Border color</Typography>

                        <Box>
                          <Box sx={{marginTop: '10px'}}>
                            <Stack direction="row" alignItems={'stretch'} mb={1}>
                              <CustomTextField
                                fullWidth
                                id="fullWidth"
                                size="small"
                                sx={{
                                  bgcolor: 'white'
                                }}
                                // value={field.value}
                                // onChange={event => handleInputChange(index, event)}
                              />

                              <Box
                                sx={{
                                  border: '1px solid #b1b1b1',
                                  borderLeft: 0,
                                  //   borderInline: 0,
                                  px: 1,
                                  py: 0.5,
                                  borderTopRightRadius: '6px',
                                  borderBottomRightRadius: '6px'
                                }}
                              >
                                <input type="color" style={colorPicker} />
                              </Box>
                            </Stack>

                            <Grid
                              container
                              spacing={{xs: 2, md: 1}}
                              columns={{xs: 4, sm: 8, md: 12}}
                            >
                              {Array.from(Array(6)).map((_, index) => (
                                <Grid item xs={2} sm={4} md={4} key={index}>
                                  <Item />
                                </Grid>
                              ))}
                            </Grid>
                          </Box>
                        </Box>
                      </Box>
                    </Stack>
                  </Box>
                </ClickAwayListener>
              </Popper>

              {/* Font picker popup */}
              {/* <Button ref={fontRef} onClick={handleFontPopup}>
                Font Picker
              </Button> */}
              <Popper open={openFontPop} anchorEl={fontRef.current} placement="bottom">
                <ClickAwayListener onClickAway={handleClickAway}>
                  <Box
                    maxWidth={400}
                    py={2}
                    px={3}
                    sx={{
                      background: 'rgb(245, 245, 245)',
                      border: '1px solid #d4d4d4',
                      borderRadius: 3
                    }}
                  >
                    <FormControl sx={{m: 1, width: '200px'}} variant="standard">
                      {/* <InputLabel htmlFor="demo-customized-select-native">Age</InputLabel> */}
                      <Typography variant="body2" mb={0.5}>
                        Font family
                      </Typography>
                      <NativeSelect
                        id="demo-customized-select-native"
                        value={age}
                        onChange={handleChange}
                        input={<BootstrapInput />}
                      >
                        <option value={10}>Inter</option>
                        <option value={20}>Twenty</option>
                        <option value={30}>Thirty</option>
                      </NativeSelect>

                      <Typography variant="body2" mt={2} mb={0.5}>
                        Font Size
                      </Typography>

                      <BootstrapInput placeholder="20" id="demo-customized-textbox" />
                    </FormControl>
                  </Box>
                </ClickAwayListener>
              </Popper>

              <Box mt={4}>
                {showHeader && (
                  <Box sx={{marginTop: '10px'}}>
                    <InputLabel htmlFor="component-disabled">Header Caption</InputLabel>
                    <Stack direction="row">
                      <TextField
                        fullWidth
                        id="fullWidth"
                        size="small"
                        value={header}
                        onChange={event => setHeader(event.target.value)}
                      />
                      <Button size="small" onClick={handleRemoveHeader}>
                        <HighlightOffIcon />
                      </Button>
                    </Stack>
                  </Box>
                )}

                {showFooter && (
                  <Box sx={{marginTop: '10px'}}>
                    <InputLabel htmlFor="component-disabled">Footer Caption</InputLabel>
                    <Stack direction="row">
                      <TextField
                        fullWidth
                        id="fullWidth"
                        size="small"
                        value={footer}
                        onChange={event => setFooter(event.target.value)}
                      />
                      <Button size="small" onClick={handleRemoveFooter}>
                        <HighlightOffIcon />
                      </Button>
                    </Stack>
                  </Box>
                )}
              </Box>
            </Box>

            {/* Buttons */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '20px',
                marginBottom: '20px'
              }}
            >
              <Stack direction="row" spacing={5}>
                <Button
                  disabled={showHeader}
                  variant="contained"
                  startIcon={<AddCircleOutlineOutlinedIcon />}
                  // onClick={() => setShowHeader(true)}
                >
                  New Text
                </Button>
                <Button
                  disabled={showHeader}
                  variant="contained"
                  startIcon={<AddCircleOutlineOutlinedIcon />}
                  onClick={() => setShowHeader(true)}
                >
                  Add header
                </Button>
                <Button
                  disabled={showFooter}
                  variant="contained"
                  startIcon={<AddCircleOutlineOutlinedIcon />}
                  onClick={() => setShowFooter(true)}
                >
                  Add Footer
                </Button>
              </Stack>
            </Box>

            {/* footer Text */}
            <Box
              sx={{
                textAlign: 'center',
                marginTop: '20px',
                marginBottom: '10px'
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  color: 'gray'
                }}
              >
                Drag and drop meme text to change text position and size
              </Typography>
            </Box>
          </Box>
        </Grid>

        {/* Right Side Content - Generated Image */}
        <Grid item xs={6} sm={6} md={6}>
          <Box
            id="my-node"
            sx={{
              margin: '0 auto',
              width: '400px',
              height: 'auto',
              backgroundColor: 'white'
            }}
          >
            {header && (
              <Box
                sx={{
                  padding: '10px 15px'
                }}
              >
                <Typography
                  sx={{
                    color: 'black',
                    backgroundColor: 'white',
                    textShadow:
                      'rgb(0, 0, 0) -1px -1px 0px, rgb(0, 0, 0) 1px -1px 0px, rgb(0, 0, 0) -1px 1px 0px, rgb(0, 0, 0) 1px 1px 0px',
                    letterSpacing: '0.2rem'
                  }}
                >
                  {header}
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                position: 'relative'
              }}
            >
              <img src={imageUrl} style={{width: '100%', height: '100%'}} />
              {captions.map((value, index) => (
                <Box
                  onMouseEnter={() => setShowResizeHandles(true)}
                  onMouseLeave={() => setShowResizeHandles(false)}
                  key={index}
                  sx={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    // display: 'flex',
                    // justifyContent: 'center',
                    // alignItems: 'center',
                    cursor: 'move',
                    // textShadow: 'rgb(0, 0, 0) -1px -1px 0px, rgb(0, 0, 0) 1px -1px 0px, rgb(0, 0, 0) -1px 1px 0px, rgb(0, 0, 0) 1px 1px 0px',
                    letterSpacing: '0.1rem'
                  }}
                >
                  <Draggable
                    disabled={isResizing}
                    onDrag={handleDrag}
                    bounds="parent"
                    // defaultPosition={{x: 50, y: 50}}
                  >
                    <Resizable
                      height={size.height}
                      width={size.width}
                      onResize={handleResize}
                      onResizeStart={() => setIsResizing(true)}
                      onResizeStop={() => setIsResizing(false)}
                      resizeHandles={
                        showResizeHandles ? ['n', 'e', 's', 'w', 'ne', 'se', 'sw', 'nw'] : []
                      }
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'se-resize'
                      }}
                    >
                      <Box ref={divRef} className="box" style={{width: size.width + 'px'}}>
                        <Typography
                          key={index}
                          sx={{
                            fontSize: `${textSize}px`,
                            color: value.color,
                            transition: 'fontSize 0.2s ease-in-out'
                          }}
                        >
                          {value.value}
                        </Typography>
                      </Box>
                    </Resizable>
                  </Draggable>
                </Box>
              ))}
            </Box>
            {footer && (
              <Box
                sx={{
                  padding: '10px 15px'
                }}
              >
                <Typography
                  sx={{
                    color: 'black',
                    backgroundColor: 'white',
                    textShadow:
                      'rgb(0, 0, 0) -1px -1px 0px, rgb(0, 0, 0) 1px -1px 0px, rgb(0, 0, 0) -1px 1px 0px, rgb(0, 0, 0) 1px 1px 0px',
                    letterSpacing: '0.2rem'
                  }}
                >
                  {footer}
                </Typography>
              </Box>
            )}
          </Box>

          <Stack
            direction={'row'}
            spacing={1}
            pt={2}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Button startIcon={<DownloadIcon />} variant="contained" onClick={handleDownload}>
              Download
            </Button>

            <Button startIcon={<ContentCopyIcon />} variant="outlined">
              Copy
            </Button>

            <Button startIcon={<StarBorderIcon />} variant="outlined">
              Save
            </Button>
          </Stack>
          {/* <Button sx={{textAlign: 'center'}} onClick={handleDownload}>
              Download
            </Button> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default MemeEdit;
