import React, {useEffect} from 'react';
import Styles from '../../styles/OnBoard.module.css';
import {Box, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {useDispatch, useSelector} from 'react-redux';
import {attemptLogout} from '../../../../state/features/user/userSlice';
import {clearStorageWhenLogOut} from '../../../../helpers/utility/clearStorageWhenLogOut';
import CoreLink from '../../../../assets/global/Link/CoreLink';
import {selectAccountSettingState} from "../../../../state/features/accountSetting/accountSettingSelector";
import {getAccountSettingData} from "../../../../state/features/accountSetting/accountSettingSlice";
import hasAuthenticatedUser from "../../../../helpers/utility/hasAuthenticateUser";
import {useNavigate} from "react-router-dom";

const PackageOnBoardNavbar = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const dispatch = useDispatch();

    const {accountSetting} = useSelector(selectAccountSettingState);

    // check if user authenticated or not
    useEffect(() => {
        if (!accountSetting) {
            dispatch(getAccountSettingData());
        }
        if (!hasAuthenticatedUser()) {
            navigate("/login");
        }
    }, []);

    const handleLogOut = () => {
        clearStorageWhenLogOut();

        dispatch(attemptLogout());
    };

    return (
        <Box className={Styles.navbar} sx={{background: theme.palette.background.paper}}>
            <Box className={`${Styles.navbar__container} ${Styles.container}`}>
                <Box>
                    {/*{accountSetting && accountSetting.company_logo && (*/}
                    {/*    <img height={"60"} src={agencySettingData.company_logo} alt={""} />*/}
                    {/*)}*/}
                    <img
                        height={'40'}
                        src="https://app.pusholk9.com/images/logo/site-logo.png"
                        alt={'logo'}
                    />
                </Box>
                <Box className={Styles.nav__right}>
                    <Typography variant="buttonLarge" color="info.main">
                        {' '}
                        <CoreLink to="/login" onClick={handleLogOut}>
                            Log out
                        </CoreLink>
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default PackageOnBoardNavbar;
