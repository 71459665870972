import React from "react";
import {Box, Grid, Paper, Typography} from "@mui/material";
import AccountSettingPassword from "./AccountSettingPassword";
import AccountSettingForm from "./AccountSettingForm";

const AccountSettings = () => {
    return (
        <Box sx={{p:3}}>
            <Grid spacing={2} container>
                <Grid item sm={12} md={12} lg={7} xl={7}>
                    <Paper elevation={0}>
                        <Box sx={{padding: '20px', m: 1, borderBottom: `1px solid #dfe4ea`, minHeight: 'auto'}}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Typography level="h2" fontSize="lg" fontWeight="bold" id="card-description" mb={0.5}>
                                    Profile Setting
                                </Typography>
                            </Grid>
                        </Box>

                        <Box sx={{ p: "24px" }}>
                            {/* ## Profile Image Section ## */}
                            {/*<AccountSettingProfile />*/}

                            {/* ## Profile Content Section ## */}
                            <AccountSettingForm />
                        </Box>
                    </Paper>
                </Grid>

                {/* ## Password Change Section ## */}
                <Grid item sm={12} md={12} lg={5} xl={5}>
                    <AccountSettingPassword />
                </Grid>
            </Grid>
        </Box>
    );
};

export default AccountSettings;
