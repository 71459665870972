import {Box, Divider, Paper, Stack, Typography} from '@mui/material';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import React, {useEffect, useState} from 'react';
import {addMonths, format} from 'date-fns';
import {useSelector} from 'react-redux';
import getSum from '../../helper/utility/getSum';
import {CoreCardHeader} from '../../../../assets/global/CardHeader/CoreCardHeader';
import {currencyFormat} from "../../../../helpers/utility/currencyFormat";

const OrderSummery = ({theme, packageData, billingCycleData}) => {
    const [nextBilling, setNextBilling] = useState('');

    const {chargeAmount} = useSelector(state => state.paymentIntegration);

    useEffect(() => {
        if (billingCycleData && billingCycleData.billing_cycle) {
            const nextBillingDate = addMonths(new Date(), Number(billingCycleData.billing_cycle));
            const date = format(new Date(nextBillingDate), 'MMMM dd, yyyy');

            setNextBilling(date);
        }
    }, [billingCycleData]);

    return (
        <Paper elevation={0}>
            <CoreCardHeader title="Order Summary" isButtonShow={false}/>
            <Paper elevation={0} sx={{p: 3}}>
                <Stack direction="row" spacing={1} justifyContent="space-between" flexShrink={0}>
                    <Box>
                        <Typography variant="body2" color="text.primary">
                            Subscription
                        </Typography>
                        <Typography variant="h5" color="info.main">
                            {' '}
                            {packageData.title}
                        </Typography>
                        <Typography variant="toolTip" color="text.secondary">
                            {`${billingCycleData.billing_cycle} Months @ $${billingCycleData.discount_monthly_price}/mo - ${billingCycleData.discount_percentage}% Off!`}
                        </Typography>
                        <Typography sx={{mt: 1, mb: 2}} variant="body1" color="text.secondary">
                            Our next invoice is scheduled on:{' '}
                            <Typography variant={'body1'} component={'span'} color={'warning.main'}>
                                {' '}
                                {nextBilling}
                            </Typography>
                        </Typography>
                    </Box>
                </Stack>
                <Divider/>

                <Stack
                    sx={{pt: 2, pb: 1}}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Typography variant="body1" color="text.secondary">
                        Subtotal: {` ($${currencyFormat(Number(packageData.package_price))}/Per Month)`}
                    </Typography>
                    <Stack direction="row" spacing={1} justifyContent="space-between" flexShrink={0}>
                        <Typography variant="h6" color="primary.main">
                            $
                            {currencyFormat(
                                Number(packageData.package_price) * Number(billingCycleData.billing_cycle)
                            )}
                        </Typography>
                    </Stack>
                </Stack>
                <Stack sx={{pb: 2}} direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="body1" color="text.secondary">
                        Discount: {`(${billingCycleData.discount_percentage}%)`}
                    </Typography>
                    <Typography variant="body2" color="text.primary">
                        - $
                        {currencyFormat(
                            (Number(packageData.package_price) -
                                Number(billingCycleData.discount_monthly_price)) *
                            Number(billingCycleData.billing_cycle)
                        )}
                    </Typography>
                </Stack>

                <Stack sx={{pb: 3}} direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="body1" color="text.secondary">
                        Charge Fee:
                    </Typography>
                    <Typography variant="body2" color="text.primary">
                        + ${currencyFormat(chargeAmount)}
                    </Typography>
                </Stack>
                <Divider/>
                <Stack sx={{py: 3}} direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="body1" color="text.secondary">
                        Total Billed:
                    </Typography>
                    <Typography variant="h5" color="text.primary">
                        ${currencyFormat(getSum([billingCycleData.discount_total_price, chargeAmount]))}
                    </Typography>
                </Stack>
                {/*<Box sx={{padding: "24px 0 16px 0"}}>*/}
                {/*    <CoreButton fullWidth disabled={active} variant='contained' size='medium' color='secondary'>*/}
                {/*        Checkout*/}
                {/*    </CoreButton>*/}
                {/*</Box>*/}
                <Divider/>

                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    textAlign="center"
                    justifyContent="center"
                    sx={{mt: 2}}
                >
                    <VerifiedUserIcon fontSize="small" sx={{color: theme.palette.action.active}}/>
                    <Typography variant="body2" color="text.primary">
                        Secure credit card payment
                    </Typography>
                </Stack>
                <Typography
                    sx={{textAlign: 'center', display: 'block', mt: 0.5}}
                    variant="tooltip"
                    color="text.secondary"
                >
                    This is a secure 128-bit SSL encrypted payment
                </Typography>
            </Paper>
        </Paper>
    );
};

export default OrderSummery;
