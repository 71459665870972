import { all, call, put, takeEvery } from "redux-saga/effects";
import {getUserHasPackageDataFailed, getUserHasPackageDataSuccess} from "./userPackageSlice";
import UserPackageService from "../../../services/UserPackage.service";

function* agencyPackageWatcher() {
    yield takeEvery("userPackage/getUserHasPackageData", getUserHasPackageDataSaga);
}

function* getUserHasPackageDataSaga(action) {
    try {
        const response = yield call(UserPackageService.userHasPackageData, action.payload);

        if (response.success) {
            yield put(
                getUserHasPackageDataSuccess({ isPackageExpire: response.data.is_package_expire, data: response.data })
            );
        } else {
            yield put(getUserHasPackageDataFailed({ hasPackage: false, message: response.message }));
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

export default function* userPackageSaga() {
    yield all([agencyPackageWatcher()]);
}
