import {all} from 'redux-saga/effects';
import chatGPTSaga from './features/chatGPT/chatGPTSaga';
import userSaga from './features/user/userSaga';
import promptSaga from './features/prompts/promptSaga';
import collectionSaga from './features/collection/collectionSaga';
import promptCollectionSaga from './features/promptCollection/promptCollectionSaga';
import accountSettingSaga from './features/accountSetting/accountSettingSaga';
import categorySaga from "./features/category/categorySaga";
import promptByCategorySaga from "./features/promptsByCategory/promptByCategorySaga";
import presetTemplateSaga from "./features/presetTemplate/presetTemplateSaga";
import contentRepurposingSaga from "./features/contentRepurposing/contentRepurposingSaga";
import playPenSaga from "./features/playPen/playPenSaga";
import imageToPromptSaga from "./features/imageToPrompt/imageToPromptSaga";
import imageToAltTextSaga from "./features/imageToAltText/imageToAltTextSaga";
import imageVariationSaga from "./features/imageVariation/imageVariationSaga";
import imageCreationSaga from "./features/imageCreation/imageCreationSaga";
import imageInPaintingSaga from "./features/imageInPainting/imageInPaintingSaga";
import aiTranscriptionSaga from "./features/aiTranscription/aiTranscriptionSaga";
import paymentSaga from "./features/payment/paymentSaga";
import packageSaga from "./features/package/packageSaga";
import userPackageSaga from "./features/userPackage/userPackageSaga";
import paymentIntegrationSaga from "./features/paymentIntegration/paymentIntegrationSaga";
import userBalanceSaga from "./features/userBalance/userBalanceSaga";
import userPaymentSaga from "./features/userPayment/userPaymentSaga";
import userBillingSaga from "./features/userBilling/userBillingSaga";
import totalDashboardCountSaga from "./features/dashboard/totalDashboardCountSaga";
import balanceCheckSaga from "./features/balanceCheck/balanceCheckSaga";
import totalGraphDataSaga from "./features/dashboard/graph/totalGraphDataSaga";
import packageAccessFeatureSaga from "./features/packageAccessFeature/packageAccessFeatureSaga";
import textToImageSaga from "./features/textToMeme/textToImage/textToImageSaga";
import memeMakerSaga from "./features/memeMaker/memeMakerSaga";
import templateSearchSaga from "./features/textToMeme/templateSearch/templateSearchSaga";
import AIGeneratedTextSaga from "./features/textToMeme/randomAIGeneratedText/AIGeneratedTextSaga";
import gifDownloadSaga from "./features/textToMeme/gifDownload/gifDownloadSaga";

export default function* rootSaga() {
    yield all([
        chatGPTSaga(),
        userSaga(),
        promptSaga(),
        collectionSaga(),
        promptCollectionSaga(),
        accountSettingSaga(),
        categorySaga(),
        promptByCategorySaga(),
        presetTemplateSaga(),
        contentRepurposingSaga(),
        playPenSaga(),
        imageToPromptSaga(),
        imageVariationSaga(),
        imageToAltTextSaga(),
        imageCreationSaga(),
        imageInPaintingSaga(),
        aiTranscriptionSaga(),
        paymentSaga(),
        packageSaga(),
        userPackageSaga(),
        paymentIntegrationSaga(),
        userBalanceSaga(),
        userPaymentSaga(),
        userBillingSaga(),
        totalDashboardCountSaga(),
        balanceCheckSaga(),
        totalGraphDataSaga(),
        packageAccessFeatureSaga(),
        textToImageSaga(),
        memeMakerSaga(),
        templateSearchSaga(),
        AIGeneratedTextSaga(),
        gifDownloadSaga(),
    ]);
}
