import React from 'react';
import {Avatar, Box, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Stack} from "@mui/material";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import TransitEnterexitIcon from '@mui/icons-material/TransitEnterexit';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ReplayIcon from '@mui/icons-material/Replay';

const ThemedMemeHelperText = () => {
    return (
        <Stack spacing={2} sx={{
            backgroundColor: '#F5F5F5',
            padding: '20px',
            alignItems: "center",
            justifyContent: "center"
        }}
        >
            <List
                sx={{
                    width: '90%',
                    bgcolor: '#e3f3e7',
                    marginBottom: '20px',
                }}
            >

                <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                            <RecordVoiceOverIcon/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Themed Meme"
                                  secondary="Themed Meme
                                   Feature Description"/>
                </ListItem>
                <Divider variant="inset" component="li"/>
                <Box sx={{
                    alignItems: "center",
                    padding: '20px',
                }}>
                    <Typography component="div">
                        <Box sx={{fontFamily: 'Monospace', fontSize: 'h6.fontSize', m: 1, fontWeight: 'bold'}}>
                            Generate a meme with AI in three simple steps
                        </Box>
                        <Divider/>
                        <List>
                            <ListItem disablePadding>
                                <ListItemIcon>
                                    <TransitEnterexitIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Enter Theme"/>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemIcon>
                                    <CheckCircleOutlineIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Choose Template"/>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemIcon>
                                    <ReplayIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Generate meme"/>
                            </ListItem>
                        </List>
                    </Typography>
                </Box>
                <Box sx={{
                    alignItems: "center",
                    padding: '20px',
                }}>
                    <Typography component="div">
                        <Box sx={{fontFamily: 'Monospace', fontSize: 'h6.fontSize', m: 1, fontWeight: 'bold'}}>
                            Search Topic
                        </Box>
                        <Divider/>
                        <Box sx={{fontFamily: 'default', m: 1}}>
                            Write a topic on the SearchBar or hit "USE RANDOM TEXT" that will generate you a random
                            topic.
                        </Box>
                    </Typography>
                </Box>
                <Box sx={{
                    alignItems: "center",
                    padding: '20px',
                }}>
                    <Typography component="div">
                        <Box sx={{fontFamily: 'Monospace', fontSize: 'h6.fontSize', m: 1, fontWeight: 'bold'}}>
                            Choose Templates
                        </Box>
                        <Divider/>
                        <Box sx={{fontFamily: 'default', m: 1}}>
                            Add an image or hit "PICK UP TEMPLATES" this will suggest you templates. Select templates
                            whatever you want then click "GENERATE". This will add AI generated texts on your selected
                            templates.
                        </Box>
                    </Typography>
                </Box>
            </List>
        </Stack>
    );
}

export default ThemedMemeHelperText;