import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    contentRepurposingData: null,
    inputOutputList: [],
    isLoading: false,
    isError: false,
    error: ''
};

const contentRepurposingSlice = createSlice({
    name: 'contentRepurposing',
    initialState: initialState,
    reducers: {
        contentRepurposing: (state, action) => {
            state.isLoading = true;
        },
        contentRepurposingSuccess: (state, action) => {
            state.contentRepurposingData = action?.payload[0]?.text;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        contentRepurposingFailed: (state, action) => {
            state.contentRepurposingData = null;
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },

        getContentRepurposingInputOutputList: (state, action) => {
            state.isLoading = true;
        },
        getContentRepurposingInputOutputListSuccess: (state, action) => {
            state.inputOutputList = action.payload;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        getContentRepurposingInputOutputListFailed: (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },
        contentRepurposingStateClear: (state, action) => {
            state.contentRepurposingData = null;
            state.inputOutputList = [];
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        }
    }
});

export const {
    contentRepurposing,
    contentRepurposingSuccess,
    contentRepurposingFailed,
    getContentRepurposingInputOutputList,
    getContentRepurposingInputOutputListSuccess,
    getContentRepurposingInputOutputListFailed,
    contentRepurposingStateClear
} = contentRepurposingSlice.actions;

export default contentRepurposingSlice.reducer;
