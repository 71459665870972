import React from "react";
import Styles from "../styles/Package.module.css";
import { Divider, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const PackagePreviewFeatureHeader = ({ icon, title = "" }) => {
    const theme = useTheme();

    return (
        <>
            <Stack
                className={Styles.package__list_group_header}
                sx={{
                    py: theme.spacing(1),
                    backgroundColor: theme.palette.background.paper,
                }}
                direction='row'
                alignItems='center'
                spacing={"10px"}
            >
                {icon}
                <Typography variant='subtitle1' color='text.primary'>
                    {title}
                </Typography>
            </Stack>
            <Divider />
        </>
    );
};

export default PackagePreviewFeatureHeader;
