import {alpha, styled} from '@mui/material/styles';
import {Card, Typography} from '@mui/material';
import {useSelector} from "react-redux";
import {selectTotalDashboardCountState} from "../../state/features/dashboard/totalDashboardCountSelector";
import {
    DASHBOARD__TOTAL_CONTENT_COUNT, DASHBOARD__TOTAL_PLATFORM_COST,
    DASHBOARD__TOTAL_USER_COST,
    PACKAGE_FEATURE__KEY__AUDIO
} from "../../helpers/constant/coreConstant";


// ----------------------------------------------------------------------

const StyledIcon = styled('div')(({ theme }) => ({
    margin: 'auto',
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------


const AppWidgetSummary = ({ title, helperKey='', icon, color = 'primary', message="", sx, ...other }) => {

    const {totalDashboardCountData} = useSelector(selectTotalDashboardCountState);

    const {
        total_ai_transcription_count = 0,
        total_content_collection_count = 0,
        total_image_collection_count = 0,
        total_image_edit_count = 0,
        total_image_generate_count = 0,
        total_image_to_alt_text_count = 0,
        total_image_to_prompt_count = 0,
        total_image_variant_count = 0,
        total_platform_cost = 0,
        total_user_cost = 0,

    } = totalDashboardCountData || {};

    let total= 0;
    if (helperKey === PACKAGE_FEATURE__KEY__AUDIO){
        total = total_ai_transcription_count;
    } else if (helperKey == DASHBOARD__TOTAL_CONTENT_COUNT){
        total = total_content_collection_count;
    } else if (helperKey == DASHBOARD__TOTAL_USER_COST){
        total = total_user_cost;
    } else if (helperKey == DASHBOARD__TOTAL_PLATFORM_COST){
        total = total_platform_cost;
    }


    return (
        <Card
            sx={{
                py: 5,
                boxShadow: 0,
                textAlign: 'center',
                color: (theme) => theme.palette[color].darker,
                bgcolor: (theme) => theme.palette[color].lighter,
                ...sx,
            }}
            {...other}
        >
            <StyledIcon
                sx={{
                    color: (theme) => theme.palette[color].dark,
                    backgroundImage: (theme) =>
                        `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(
                            theme.palette[color].dark,
                            0.24
                        )} 100%)`,
                }}
            >
                {icon && icon}
            </StyledIcon>

            <Typography variant="h3">{total}</Typography>

            <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                {title}
            </Typography>
            <Typography sx={{ p: 1, color: 'error.main', margin: '0px', fontSize: '10px' }}>
                {message}
            </Typography>
        </Card>
    );
}

export default AppWidgetSummary;
