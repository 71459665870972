import {all, put} from 'redux-saga/effects';
import {call, takeEvery} from '@redux-saga/core/effects';
import TextToMemeService from "../../../../services/TextToMeme.service";
import {DownloadGifFailed, DownloadGifSuccess} from "./gifDownloadSlice";

function* gifDownloadWatcher() {
    yield takeEvery('gifDownload/DownloadGif', downloadGifSaga);
}

function* downloadGifSaga(action) {
    console.log("========action.payload==========", action.payload);
    try {
        const response = yield call(TextToMemeService.downloadGif, action.payload);

        if (response?.data) {
            yield put(DownloadGifSuccess(response));
        } else {
            yield put(DownloadGifFailed(response?.results));
        }
    } catch (err) {
        yield put(DownloadGifFailed(err.message));
    }
}

export default function* gifDownloadSaga() {
    yield all([gifDownloadWatcher()]);
}
