import {Box, Card, CardContent, Stack} from '@mui/material';
import {RateReview} from '@mui/icons-material';
import {useTheme} from '@mui/material/styles';
import Styles from '../styles/Package.module.css';
import PackagePreviewCard from './PackagePreviewCard';
import React from 'react';
import PackagePreviewFeatureHeader from './PackagePreviewFeatureHeader';
import PackagePreviewFeatureItem from './PackagePreviewFeatureItem';
import PackageBadge from './PackageBadge';
import formatFeatureLimitList from '../helper/utility/formatFeatureLimitList';
import {useNavigate} from 'react-router-dom';
import routeLink from '../../../helpers/constant/routeLink';
import {changeToOutlinedHoverBackground} from "../../../helpers/utility/colorCustomize";
import {USER__HAS__PACKAGE__TYPE__FREE} from "../../../helpers/constant/coreConstant";

export const SinglePaidPackageItem = ({packageData}) => {
    const theme = useTheme();
    const navigate = useNavigate();

    const {dollarBalance, teamMemberFeatures, reviewFeatures} = formatFeatureLimitList(
        packageData?.feature_access
    );

    const onClickPackageCard = packageUid => {
        navigate('/client/package/buy/' + packageUid);
    };

    return (
        <Card
            className={Styles.package__item}
            sx={{boxShadow: '0px 4px 0px ' + theme.palette.info.main}}
        >
            <CardContent sx={{p: 0}}>
                <Box
                    className={Styles.package__header}
                    sx={{backgroundColor: changeToOutlinedHoverBackground(theme.palette.info.main)}}
                >
                    {USER__HAS__PACKAGE__TYPE__FREE === packageData.package_type ? (
                        ''
                    ) : (
                        <PackageBadge
                            className={Styles.package__badge}
                            title={`Get Balance $ ${
                                packageData?.dollar_balance ? packageData?.dollar_balance : 0
                            }`}
                            bgColor={theme.palette.primary.main}
                        />
                    )}
                </Box>

                <PackagePreviewCard
                    title={packageData.title}
                    description={packageData.description}
                    packagePrice={packageData.package_price}
                    buttonText={'Subscribe'}
                    onClickButton={() => onClickPackageCard(packageData.id)}
                />

                <Box className={Styles.package__content}>
                    {/* ## Review Generator Section ## */}
                    <PackagePreviewFeatureHeader
                        icon={<RateReview fontSize="small" color="action"/>}
                        title={'Features'}
                    />
                    <Stack
                        className={Styles.package__list_group}
                        bgcolor={'background.default'}
                        sx={{py: theme.spacing(2)}}
                    >
                        {reviewFeatures.map(item => (
                            <PackagePreviewFeatureItem
                                key={item.id}
                                visibility={item.status}
                                title={`${item.feature_name}`}
                            />
                        ))}
                    </Stack>
                </Box>
            </CardContent>
        </Card>
    );
};
