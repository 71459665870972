import { requests } from "./http.service";

class UserPaymentService {
    addUserPaymentCard(params) {
        return requests.post(
            process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/payment/new/card/add",
            params
        );
    }

    getUserPaymentCardList() {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/payment/card/list");
    }

    updateUserDefaultPaymentCard(params) {
        return requests.put(
            process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/payment/update/card/setup/as/default",
            params
        );
    }

    updateUserPaymentCardActiveStatus(params) {
        return requests.put(
            process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/payment/update/card/status",
            params
        );
    }

    deleteUserPaymentCard(params) {
        return requests.delete(
            process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/payment/delete/card",
            params
        );
    }
}

export default new UserPaymentService();
