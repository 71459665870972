import React, {useEffect, useState} from 'react';
import {Input, InputLabel, Stack, TextField} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import styled from 'styled-components';

const InputFieldRepeater = ({onStateUpdate}) => {
  const [fields, setFields] = useState([{value: '', color: ''}]);

  useEffect(() => {
    onStateUpdate(fields);
  }, [fields]);
  const handleInputChange = (index, event) => {
    const values = [...fields];
    values[index].value = event.target.value;
    setFields(values);
  };
  const handleColorChange = (index, event) => {
    const values = [...fields];
    values[index].color = event.target.value;
    setFields(values);
  };

  const handleAddField = () => {
    const values = [...fields];
    values.push({value: '', color: ''});
    setFields(values);
  };

  const handleRemoveField = index => {
    const values = [...fields];
    values.splice(index, 1);
    setFields(values);
  };

  const CustomTextField = styled(TextField)`
    & .MuiOutlinedInput-root {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  `;

  const CustomColorInput = styled(Input)`
    & input[type='color'] {
      border: none;
      border-radius: 4px;
      outline: none;
      appearance: none;
      cursor: pointer;
      width: 30px;
      height: 100%;
    }
  `;

  const colorPicker = {
    /* Adjust the width and height as needed */
    width: '25px',
    height: '100%',
    /* Customize the border */
    border: 'none',
    borderRadius: '4px',
    // /* Add box shadow for a nice effect */
    boxShadow: '0 0 4px rgba(0, 0, 0, 0.1)',
    /* Center the color picker vertically and horizontally */
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };
  return (
    <>
      {fields.map((field, index) => (
        <Box key={index} sx={{marginTop: '10px'}}>
          <InputLabel htmlFor="component-disabled">Caption {index + 1}</InputLabel>
          <Stack direction="row" alignItems={'stretch'}>
            <CustomTextField
              fullWidth
              id="fullWidth"
              size="small"
              value={field.value}
              onChange={event => handleInputChange(index, event)}
            />
            {/* <Button
              variant="outlined"
              sx={{
                borderLeft: '0 !important',
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderColor: 'gray'
              }}
            ></Button> */}

            {/* <CustomColorInput
              type="color"
              value={field.value}
              onChange={event => handleColorChange(index, event)}
            /> */}
            <Box sx={{border: '1px solid #b1b1b1', borderLeft: 0, borderInline: 0, px: 1, py: 0.5}}>
              <input type="color" style={colorPicker} />
            </Box>

            {fields.length > 1 && (
              <Button size="small" onClick={() => handleRemoveField(index)}>
                <HighlightOffIcon />
              </Button>
            )}
          </Stack>
        </Box>
      ))}
      <Button sx={{marginTop: '10px'}} size="small" variant="contained" onClick={handleAddField}>
        Add Text
      </Button>
    </>
  );
};

export default InputFieldRepeater;
