import React from 'react';
import Typography from '@mui/material/Typography';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import {Box, Stack} from '@mui/material';
import {useSelector} from "react-redux";
import {selectPresetTemplateState} from "../../state/features/presetTemplate/presetTemplateSelector";
import Styles from "./styles/PresetTemplateHeader.module.scss";

const headerButton = {
    display: 'flex', 
    alignItems: 'center', 
    cursor: 'pointer', 
    color: '#6b7280',
    transition: 'color .3s',
    ":hover": {color: '#374151'}
}

const PresetTemplateHeader = () => {
    const {total} = useSelector(selectPresetTemplateState);

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            m={1}
            borderRadius={1}
            className={Styles.wrapper}
        >
            <Typography variant="h6" sx={{fontWeight: 'bold', fontSize: '30px'}}>
                Templates [{total}]
            </Typography>
            <Box className={Styles.animatedUnderline} component="div">
                {/*<Box sx={headerButton}>*/}
                {/*    <PlayCircleIcon sx={{paddingRight: '4px'}}/>*/}
                {/*    <Typography>How to use</Typography>*/}
                {/*</Box>*/}
            </Box>
        </Stack>
    );
};

export default PresetTemplateHeader;
