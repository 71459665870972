import React, {useEffect, useState} from 'react';
import {Box, Grid, Paper, Typography} from '@mui/material';
import {useSelector} from 'react-redux';
import {CoreCardHeader} from '../../assets/global/CardHeader/CoreCardHeader';
import CoreLink from '../../assets/global/Link/CoreLink';
import CoreButton from '../../assets/global/Button/CoreButton';

const Billing = () => {
    const {userPackage} = useSelector(state => state.userPackage);
    const [userHasPackageData, setUserPackageData] = useState({});

    useEffect(() => {
        if (userPackage) {
            setUserPackageData(userPackage);
        }
    }, [userPackage]);

    return (
        <Grid item md={6} lg={6} xl={6}>
            <Paper elevation={0} sx={{height: '100%'}}>
                <CoreCardHeader title={'Billing'}/>

                <Box p={3}>
                    <Grid container alignItems={'center'} justifyContent="space-between">
                        <Grid item md={12} lg={6} xl={6}>
                            <Typography variant={'body2'}>You are currently using the</Typography>

                            <Typography variant={'h5'} color={'info.main'}>
                                {userHasPackageData?.title}
                            </Typography>
                        </Grid>
                        <Grid item md={12} lg={6} xl={6} textAlign="right">
                            {/*<CoreLink to="/settings/packages">*/}
                            {/*    <CoreButton color={'secondary'} variant={'contained'} size={'large'}>*/}
                            {/*        Upgrade*/}
                            {/*    </CoreButton>*/}
                            {/*</CoreLink>*/}
                        </Grid>
                    </Grid>
                </Box>

                <Box p={3} pt={0}>
                    <Typography variant={'body2'}>{userHasPackageData?.title} Package Costs:</Typography>

                    <Typography variant={'h5'} color={'primary'} display="flex">
                        ${userHasPackageData.payment_amount}{' '}
                        <Typography noWrap={true} ml={'5px'} color="text.secondary" variant={'body1'}>
                            /Month
                        </Typography>
                    </Typography>
                </Box>
            </Paper>
        </Grid>
    );
};

export default Billing;
