import React, {useRef, useState} from 'react';
import {Button, Container, Grid, InputLabel, Stack, TextField, Typography,} from '@mui/material';
import {styled} from '@mui/system';
import Box from "@mui/material/Box";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CloseIcon from "@mui/icons-material/Close";
import 'react-resizable/css/styles.css';
import Tooltip from "@mui/material/Tooltip";

const StyledContainer = styled(Container)({
    marginTop: '2rem',
    border: `2px solid Gray`, // Border color
    borderRadius: '10px', // Border radius
    padding: '1rem',
    backgroundColor: 'white'// Add padding as needed
});

const EditForm = ({
                      formFields,
                      setFormFields,
                      header,
                      setHeader,
                      showHeader,
                      setShowHeader,
                      footer,
                      setFooter,
                      showFooter,
                      setShowFooter,
                  }) => {

    const fontColor = useRef('#ffffff');
    const borderColor = useRef('#000000');
    const anchorRef = useRef(null);
    const fontRef = useRef(null);
    const [openColorPop, setOpenColorPop] = useState(false);
    const [openFontPop, setOpenFontPop] = useState(false);
    const [age, setAge] = React.useState('Inter');

    const handleColorPopup = () => {
        setOpenColorPop(prevOpen => !prevOpen);
    };
    const handleFontPopup = () => {
        setOpenFontPop(prevOpen => !prevOpen);
    };
    const handleClickAway = () => {
        setOpenColorPop(false);
        setOpenFontPop(false);
    };
    const handleChange = event => {
        setAge(event.target.value);
    };


    const colorPicker = {
        /* Adjust the width and height as needed */
        width: '25px',
        height: '100%',
        /* Customize the border */
        border: 'none',
        borderRadius: '4px',
        // /* Add box shadow for a nice effect */
        boxShadow: '0 0 4px rgba(0, 0, 0, 0.1)',
        /* Center the color picker vertically and horizontally */
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    };
    const sizePicker = {
        /* Adjust the width and height as needed */
        width: '50px',
        paddingLeft: '8px',
        height: '100%',
        /* Customize the border */
        border: 'none',
        borderRadius: '4px',
        // /* Add box shadow for a nice effect */
        boxShadow: '0 0 4px rgba(0, 0, 0, 0.1)',
        /* Center the color picker vertically and horizontally */
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    };
    const deleteBtnStyle = {
        border: '1px solid #b1b1b1 !important',
        borderInline: 0,
        px: 1,
        py: 0.5,
        display: 'grid',
        alignItems: 'center',
        borderTopRightRadius: 3,
        borderBottomRightRadius: 3,
        cursor: 'pointer',
        '&:hover': {
            background: '#f5f5f5'
        }
    };


    const handleInputChange = (index, event) => {
        setFormFields((prevFields) => {
            const updatedFields = [...prevFields];
            updatedFields[index].value = event.target.value;
            return updatedFields;
        });
    };

    const handleColorSelect = (index, e) => {
        setFormFields((prevFields) => {
            const updatedFields = [...prevFields];
            updatedFields[index].color = e.target.value;
            return updatedFields;
        });
    };

    const handleSizeSelect = (index, e) => {
        setFormFields((prevFields) => {
            const updatedFields = [...prevFields];
            updatedFields[index].size = e.target.value;
            return updatedFields;
        });
    };

    const handleAddField = () => {
        setFormFields((prevFields) => {
            const newIndex = prevFields.length + 1;
            return [...prevFields, {id: newIndex, value: 'Enter Your Text', color: '#ffffff', size: 35,}];
        });
    };

    const handleRemoveField = (index) => {
        setFormFields((prevFields) => {
            const updatedFields = [...prevFields];
            updatedFields.splice(index, 1);
            return updatedFields;
        });
    };

    const handleFontSelect = (index) => {
        // Handle font selection for the text field at the specified index
        // You can open a font picker or implement any other font selection mechanism here
        console.log('Font selected for field', index);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Process the form fields here
    };

    const handleRemoveHeader = () => {
        setShowHeader(false);
        setHeader('');
    };

    const handleRemoveFooter = () => {
        setShowFooter(false);
        setFooter('');
    };

    return (
        <StyledContainer maxWidth="sm">
            <Typography variant="h4" component="h1" align="center" gutterBottom>
                Make a Meme
            </Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    {formFields.map((field, index) => (
                        <Grid item xs={12} key={field.id} style={{display: 'flex'}}>
                            <TextField
                                label={`Caption ${index + 1}`}
                                variant="outlined"
                                fullWidth
                                value={field.value ? field.value : 'Add Text'}
                                onChange={(e) => handleInputChange(index, e)}
                                size="small"
                            />

                            <Box sx={{border: '1px solid #b1b1b1', borderLeft: 0, borderInline: 0, px: 1, py: 0.5}}>
                                <Tooltip title="Font Color">
                                    <input
                                        type="color"
                                        value={field.color ? field.color : '#ffffff'}
                                        style={colorPicker}
                                        onChange={(e) => handleColorSelect(index, e)}
                                    />
                                </Tooltip>
                            </Box>
                            <Box sx={{border: '1px solid #b1b1b1'}}>
                                <Tooltip title="Font Size">
                                    <input
                                        type="number"
                                        style={sizePicker}
                                        value={field.size}
                                        placeholder={field.size}
                                        onChange={(e) => handleSizeSelect(index, e)}
                                    />
                                </Tooltip>
                            </Box>

                            <Box sx={deleteBtnStyle} size="small" onClick={() => handleRemoveField(index)}>
                                <CloseIcon sx={{fontSize: 20, fontWeight: 300}}/>
                            </Box>

                        </Grid>
                    ))}
                </Grid>

                <Box mt={4}>
                    {showHeader && (
                        <Box sx={{marginTop: '10px'}}>
                            <InputLabel htmlFor="component-disabled">Header Caption</InputLabel>
                            <Stack direction="row">
                                <TextField
                                    fullWidth
                                    id="fullWidth"
                                    size="small"
                                    value={header}
                                    onChange={event => setHeader(event.target.value)}
                                />
                                <Button size="small" onClick={handleRemoveHeader}>
                                    <HighlightOffIcon/>
                                </Button>
                            </Stack>
                        </Box>
                    )}

                    {showFooter && (
                        <Box sx={{marginTop: '10px'}}>
                            <InputLabel htmlFor="component-disabled">Footer Caption</InputLabel>
                            <Stack direction="row">
                                <TextField
                                    fullWidth
                                    id="fullWidth"
                                    size="small"
                                    value={footer}
                                    onChange={event => setFooter(event.target.value)}
                                />
                                <Button size="small" onClick={handleRemoveFooter}>
                                    <HighlightOffIcon/>
                                </Button>
                            </Stack>
                        </Box>
                    )}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '20px',
                        marginBottom: '20px'
                    }}
                >
                    <Box spacing={5}>
                        <Button
                            variant="contained"
                            onClick={handleAddField}
                            startIcon={<AddCircleOutlineOutlinedIcon/>}
                            sx={{marginTop: '20px', marginRight: '10px'}}
                        >
                            Add Caption
                        </Button>

                        <Button
                            disabled={showHeader}
                            variant="contained"
                            startIcon={<AddCircleOutlineOutlinedIcon/>}
                            onClick={() => setShowHeader(true)}
                            sx={{marginTop: '20px', marginRight: '10px'}}
                        >
                            Add header
                        </Button>
                        <Button
                            disabled={showFooter}
                            variant="contained"
                            startIcon={<AddCircleOutlineOutlinedIcon/>}
                            onClick={() => setShowFooter(true)}
                            sx={{marginTop: '20px', marginRight: '10px'}}
                        >
                            Add Footer
                        </Button>
                    </Box>
                </Box>
            </form>
        </StyledContainer>
    );
};

export default EditForm;
