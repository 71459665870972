import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    payment: [],
    page: 1,
    perPage: 10,
    total: 10,
    isLoading: false,
    isError: false,
    error: ''
};

const paymentSlice = createSlice({
    name: 'payment',
    initialState: initialState,
    reducers: {
        makePayment: (state, action) => {
            state.isLoading = true;
        },
        makePaymentSuccess: (state, action) => {
            state.payment = action.payload;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        makePaymentFailed: (state, action) => {
            state.payment = [];
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },
    }
});

export const {
    makePayment,
    makePaymentSuccess,
    makePaymentFailed,
} = paymentSlice.actions;

export default paymentSlice.reducer;
