import { all, call, put, takeEvery } from "redux-saga/effects";
import UserPaymentService from "../../../services/UserPayment.service";
import {
    addRechargeBalanceFailed,
    addRechargeBalanceSuccess,
    addUserPaymentCardFailed,
    addUserPaymentCardSuccess,
    changeStatusAutoRechargeFailed,
    changeStatusAutoRechargeSuccess,
    configureAutoRechargeFailed,
    configureAutoRechargeSuccess,
    getBalanceAmountListFailed,
    getBalanceAmountListSuccess,
    getUserPaymentCardList,
    getUserPaymentCardListFailed,
    getUserPaymentCardListSuccess,
    removeAutoRechargeFailed,
    removeAutoRechargeSuccess,
    updateUserDefaultPaymentCardFailed,
    updateUserDefaultPaymentCardSuccess,
    updateUserPaymentCardActiveStatusFailed,
    updateUserPaymentCardActiveStatusSuccess,
} from "./userPaymentSlice";
import { delay } from "@redux-saga/core/effects";
import {showErrorToaster, showSuccessToaster} from "../../../helpers/utility/toaster";
import {getSpecificBalanceData} from "../userBalance/userBalanceSlice";
import {USER_PACKAGE_LIMIT_KEY__DOLLAR_BALANCE} from "../../../helpers/constant/coreConstant";
import {getUserHasPackageData} from "../userPackage/userPackageSlice";
import UserBalanceService from "../../../services/UserBalance.service";

function* userPaymentSagaWatcher() {
    yield takeEvery("userPayment/getUserPaymentCardList", getUserPaymentCardListSaga);
    yield takeEvery("userPayment/addUserPaymentCard", addUserPaymentCardSaga);
    yield takeEvery("userPayment/updateUserDefaultPaymentCard", updateUserDefaultPaymentCardSaga);
    yield takeEvery("userPayment/updateUserPaymentCardActiveStatus", updateUserPaymentCardActiveStatusSaga);

    yield takeEvery("userPayment/addRechargeBalance", addRechargeBalanceSaga);
    yield takeEvery("userPayment/configureAutoRecharge", configureAutoRechargeSaga);
    yield takeEvery("userPayment/changeStatusAutoRecharge", changeStatusAutoRechargeSaga);
    yield takeEvery("userPayment/removeAutoRecharge", removeAutoRechargeSaga);
    yield takeEvery("userPayment/getBalanceAmountList", getBalanceAmountListSaga);
}

const RESPONSE_LOADING_TIME = 2000;

function* getUserPaymentCardListSaga(action) {
    try {
        const response = yield call(UserPaymentService.getUserPaymentCardList, action.payload);

        if (response.success) {
            yield put(getUserPaymentCardListSuccess(response.data.data));
        } else {
            yield put(getUserPaymentCardListFailed(response.message));
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* addUserPaymentCardSaga(action) {
    try {
        console.log(action.payload,"action.payload addUserPaymentCardSaga")
        const response = yield call(UserPaymentService.addUserPaymentCard, action.payload);

        yield delay(RESPONSE_LOADING_TIME);
        if (response.success) {
            yield put(addUserPaymentCardSuccess());
            showSuccessToaster(response.message)
            yield put(getUserPaymentCardList());
        } else {
            yield put(addUserPaymentCardFailed(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* updateUserDefaultPaymentCardSaga(action) {
    try {
        const response = yield call(UserPaymentService.updateUserDefaultPaymentCard, action.payload);

        if (response.success) {
            yield put(updateUserDefaultPaymentCardSuccess(response.data.privacy_policy_info));
            showSuccessToaster(response.message)
            yield put(getUserPaymentCardList());
        } else {
            yield put(updateUserDefaultPaymentCardFailed(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* updateUserPaymentCardActiveStatusSaga(action) {
    try {
        const response = yield call(UserPaymentService.updateUserPaymentCardActiveStatus, action.payload);

        if (response.success) {
            yield put(updateUserPaymentCardActiveStatusSuccess(response.data.privacy_policy_info));
            showSuccessToaster(response.message)
            yield put(getUserPaymentCardList());
        } else {
            yield put(updateUserPaymentCardActiveStatusFailed(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* addRechargeBalanceSaga(action) {
    try {
        const response = yield call(UserBalanceService.addRechargeBalance, action.payload);

        yield delay(RESPONSE_LOADING_TIME);
        if (response.success) {
            yield put(addRechargeBalanceSuccess());
            yield put(getSpecificBalanceData({ key: USER_PACKAGE_LIMIT_KEY__DOLLAR_BALANCE }));
            showSuccessToaster(response.message)
        } else {
            yield put(addRechargeBalanceFailed(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* configureAutoRechargeSaga(action) {
    try {
        const response = yield call(UserBalanceService.configureAutoRecharge, action.payload);

        yield delay(RESPONSE_LOADING_TIME);
        if (response.success) {
            yield put(configureAutoRechargeSuccess(response.message));
            showSuccessToaster(response.message)
            yield put(getUserHasPackageData());
        } else {
            yield put(configureAutoRechargeFailed(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* changeStatusAutoRechargeSaga(action) {
    try {
        const response = yield call(UserBalanceService.changeStatusAutoRecharge, action.payload);

        if (response.success) {
            yield put(changeStatusAutoRechargeSuccess(response.message));
            showSuccessToaster(response.message)
            yield put(getUserHasPackageData());
        } else {
            yield put(changeStatusAutoRechargeFailed(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* removeAutoRechargeSaga(action) {
    try {
        const response = yield call(UserBalanceService.removeAutoRecharge, action.payload);

        if (response.success) {
            yield put(removeAutoRechargeSuccess(response.message));
            showSuccessToaster(response.message)
            yield put(getUserHasPackageData());
        } else {
            yield put(removeAutoRechargeFailed(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* getBalanceAmountListSaga(action) {
    try {
        const response = yield call(UserBalanceService.getBalanceAmountList, action.payload);

        if (response.success) {
            yield put(getBalanceAmountListSuccess(response.data.data));
        } else {
            yield put(getBalanceAmountListFailed(response.message));
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

export default function* userPaymentSaga() {
    yield all([userPaymentSagaWatcher()]);
}
